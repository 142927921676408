import React, { useRef, useEffect } from 'react';
import html2canvas from 'html2canvas';
import { SERVER_URL } from '../../../Config/Config';
import moment from 'moment/moment';


export const generateImageFromData = (data, index) => {
   
    return new Promise((resolve) => {

        // Increase value if you want to increase resolution of preview image
        const heightOfPreviewImage = 1000;
        const widthOfPreviewImage = 1000;

        // Create a new div element to hold the preview and images
        const div = document.createElement('div');
        div.style.position = 'relative';
        div.style.height = `${heightOfPreviewImage}px`; // Adjust height/width as needed
        div.style.width = `${widthOfPreviewImage}px`;
        
        // Add the preview image as the background
        const previewImage = new Image();
        // previewImage.src = SERVER_URL + data.preview;
        previewImage.src = data.preview;
        previewImage.style.height = '100%';
        previewImage.style.width = '100%';
        div.appendChild(previewImage);

        // Add the overlay image on top
        if (data?.imgURL) {
            const overlayImage = new Image();
            overlayImage.src = data.imgURL;
            overlayImage.style.position = 'absolute';
            overlayImage.style.top = `${data.ratioData.y / 5}%`;
            overlayImage.style.left = `${data.ratioData.x / 5}%`;
            overlayImage.style.height = `${data.ratioData.height / 5}%`;
            overlayImage.style.width = `${data.ratioData.width / 5}%`;
            div.appendChild(overlayImage);
        }

        // Append the div to the document body (it will be removed later)
        document.body.appendChild(div);

        // Generate image from the div using html2canvas
        html2canvas(div, {
            allowTaint: true,
            useCORS: true,
            backgroundColor: null,
            scale: 1,
        }).then((canvas) => {
            // Convert the canvas to base64
            const base64Image = canvas.toDataURL('image/png');
            // console.log("data 48 :: ", data, index)
            // Clean up by removing the div
            document.body.removeChild(div);

            // Resolve the promise with the base64 image data
            resolve(base64Image);
        });        
    });
}