import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { FaArrowLeftLong } from 'react-icons/fa6'
import { Link } from 'react-router-dom'

function ViewHeader() {
    return (
        <div>
            <Container className='px-3 '>
                <Row className='flex-between-align border-card p-2 bg-temp' style={{borderRadius:"3rem"}}>
                    <Col className='d-flex justify-content-start'>
                        <Link to={"https://printfuse.in/"} className='custom-single-order-back-link-header justify-content-start w-auto'><FaArrowLeftLong />Back To Printfuse</Link>
                    </Col>
                    <Col className='d-flex justify-content-end'>
                        <Link to={"/signin"} className='justify-content-end w-auto'>
                            <Button className='temp-btn fw-semibold'>
                                Get Started
                            </Button>
                        </Link>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ViewHeader