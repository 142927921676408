import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, Row, Spinner, Table } from 'react-bootstrap';
import { Label } from 'react-konva';
import Editor from 'react-simple-wysiwyg';
import axios from 'axios';
import { frontEnd_API, header, headerImage, siteConfig } from '../../../Config/Config';
import { toast, ToastContainer } from 'react-toastify';
import ProductPreviewImage from './ProductPreviewImageComponent';
import Loader from '../../../Components/Loader';
import Swal from 'sweetalert2';
import { Input } from 'reactstrap';
import { keyCode, pattern } from '../../../Config/Constant';
import { FiPlusCircle } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { TagsInput } from 'react-tag-input-component';

function ProductUpdate() {
    const location = useLocation();
    const navigate = useNavigate();

    const product = { ...location?.state?.data }
    console.log("product 19 :: ", product)
    const [brandingStatus, setBrandingStatus] = useState(product?.isBranding == 1)
    const [priceCountBoxStatus, setPriceCountBoxStatus] = useState(false)
    const [selectedVariantData, setSelectedVariantData] = useState([])
    const [colorVariantData, setColorVariantData] = useState([])
    const [currentMockupObj, setCurrentMockupObj] = useState({})
    const [primaryPreview, setPrimaryPreview] = useState({ type: "previewImagesData", index: 0 })

    // state section ------------
    const [productFormSubmitStatus, setProductFormSubmitStatus] = useState(false)
    const [productForm, setProductForm] = useState({})
    const [err, setErr] = useState({})
    const store = useSelector(state => state.store.value)
    const userData = useSelector((state) => state.user.value);
    // ------------

    const [mockupData, setMockupData] = useState([])
    const [oldMockupData, setOldMockupData] = useState([])

    const taxCountable = !['snapstore', 'SNAPSTORE']?.includes(store?.storeType)
    console.log("taxCountable update 43:: ", taxCountable, store?.storeType)

    const roundToTwoDecimalPlaces = (num = 0) => Number((Math.round(Number(num) * 100) / 100).toFixed(2))
    // const roundToTwoDecimalPlaces = (num = 0) => Math.round(num * 100) / 100
    // const roundToTwoDecimalPlaces = (num = 0) => Math.round(num)

    // function for manage product form data ------------
    const manageProductFormData = async () => {

        const productData = product

        if (product?.variantData)
            setSelectedVariantData([...(product?.variantData?.map(e => e?.name))])

        const previewObject = productData?.previewImagesData?.length > 0 ? productData?.previewImagesData[0] : '';

        const colorData = productData?.variantData?.find(e => ['color', 'colors', 'colour', 'colours']?.includes(e?.name?.toLowerCase()))?.data?.map(x => ({
            label: x?.label,
            code: x?.code,
            ...previewObject
        }))
        if (colorData?.length > 0)
            setColorVariantData(colorData)

        productData.defaultColor = productData.defaultColor ?? (colorData?.length > 0 ? colorData[0]?.code : '')

        productData.providerPayble = roundToTwoDecimalPlaces(productData?.providerPrice + productData?.providerDesignPrice)
        
        productData.taxPercentage = Number(productData?.taxPercentage)
        productData.taxAmount = roundToTwoDecimalPlaces((productData?.providerPayble) * productData?.taxPercentage / 100)
        productData.visibleTaxAmount = !taxCountable ? productData.taxAmount : 0

        if (taxCountable)
            productData.providerPayble = roundToTwoDecimalPlaces(productData.providerPayble + productData?.taxAmount)

        productData.productPlatformChargeAmount = roundToTwoDecimalPlaces(productData?.platformChargeAmount ?? 0)

        productData.payblePrice = roundToTwoDecimalPlaces(productData?.providerPayble)
        productData.visiblePayblePrice = productData.payblePrice + productData.productPlatformChargeAmount
        
        if (!taxCountable) {
            let taxAmount = roundToTwoDecimalPlaces((productData.taxPercentage / (100 + productData?.taxPercentage)) * productData.price)
            productData.visibleTaxAmount = taxAmount
            let totalPayble = roundToTwoDecimalPlaces(productData.visiblePayblePrice + taxAmount)
            productData.profitInAmount = roundToTwoDecimalPlaces(Number(productData.price) - Number(totalPayble))
        }

        productData.cartesianData = productData?.cartesianData?.map(e => {

            let variantPrice = e?.variant?.reduce((sum, a) => sum + a?.providerPrice, 0)

            let providerPrice = Number(roundToTwoDecimalPlaces(variantPrice) + productData.providerPrice + productData.providerDesignPrice);

            let taxAmount = productData.taxPercentage > 0 ? ((providerPrice) * productData?.taxPercentage / 100) : 0
            let visibleTaxAmount = !taxCountable ? taxAmount : 0

            let payblePrice = roundToTwoDecimalPlaces(providerPrice + (taxCountable ? taxAmount : 0));
            let visiblePayblePrice = payblePrice + productData.productPlatformChargeAmount
            
            let diff = roundToTwoDecimalPlaces(e?.price - (taxCountable ? visiblePayblePrice : payblePrice))

            let price = roundToTwoDecimalPlaces(e?.price)

            let profitPercentage = roundToTwoDecimalPlaces((diff * 100 / visiblePayblePrice))
            let profitAmount = roundToTwoDecimalPlaces(visiblePayblePrice * profitPercentage / 100)
            
            let mrp = roundToTwoDecimalPlaces(e.mrp > price ? e.mrp : price)

            if (!taxCountable) {
                visibleTaxAmount = roundToTwoDecimalPlaces((productData.taxPercentage / (100 + productData?.taxPercentage)) * price)
                let totalPayble = roundToTwoDecimalPlaces(visiblePayblePrice + visibleTaxAmount)
                profitAmount = roundToTwoDecimalPlaces(price - totalPayble)
            }

            return {
                ...e,
                providerPrice,
                payblePrice,
                visiblePayblePrice,
                visibleTaxAmount,
                profitPercentage,
                profitAmount,
                mrp,
                isPrimary: variantPrice <= 0
            }
        })

        setOldMockupData(productData?.oldCustomMockupImages ?? [])
        setProductForm(
            {
                masterProductId: productData?.masterProductId,
                providerId: productData?.providerId,
                providerBusinessId: productData?.providerBusinessId,
                providerProductId: productData?.providerProductId,
                defaultColor: productData?.defaultColor,
                productName: productData?.name,
                description: productData?.description,
                metaTitle: productData?.metaTitle ?? "",
                metaDescription: productData?.metaDescription ?? "",
                productType: productData?.productType ?? "",
                productTags: productData?.productTags ?? "",
                providerPrice: productData?.providerPrice,
                providerDesignPrice: productData?.providerDesignPrice,
                providerPayble: productData?.providerPayble,
                platformChargePercentage: productData.platformChargePercentage,
                platformChargeAmount: productData.platformChargeAmount,
                productPlatformChargeAmount: productData?.productPlatformChargeAmount,
                taxPercentage: productData.taxPercentage,
                taxAmount: productData.taxAmount,
                visibleTaxAmount: productData.visibleTaxAmount,
                shippingCharge: productData.shippingCharge,
                rtoCharge: productData.rtoCharge,
                payblePrice: productData.payblePrice,
                visiblePayblePrice: productData.visiblePayblePrice,
                profitInPercentage: productData.profitInPercentage,
                previousProfitInPercentage: productData.profitInPercentage,
                profitInAmount: productData.profitInAmount,
                previousProfitInAmount: productData.profitInAmount,
                price: productData.price,
                previousPrice: productData.price,
                mrp: productData.mrp ?? productData?.price,
                previousMrp: productData.mrp ?? productData?.price,
                height: productData.height,
                width: productData.width,
                length: productData.length,
                weight: productData.weight,
                profitPercentage: productData.profitPercentage,
                isBranding: productData?.isBranding,
                designData: [],
                cartesianData: productData.cartesianData ?? [],
                variantData: productData?.variantData ?? [],
                previewImagesData: productData?.previewImagesData ?? []
            }
        )

        // setPrimaryPreview({ type: "oldCustomMockupImages", index: index })

        if (productData?.oldCustomMockupImages?.length > 0) {
            const tempIndex = productData?.oldCustomMockupImages.findIndex((item) => item?.isPrimary == 1);
            console.log("tempIndex::", tempIndex)
            if (tempIndex != -1) {
                setPrimaryPreview({ type: "oldCustomMockupImages", index: tempIndex })
            } else {
                const tempIndex = productData?.previewImagesData.findIndex((item) => item?.isPrimary == 1);
                if (tempIndex) {
                    setPrimaryPreview({ type: "previewImagesData", index: tempIndex })
                }
            }
        }

        const tempIndex = location
    }
    // ------------

    // function for manage product price ------------
    const manageProductPrice = async (obj = { from: 'amt', val: 0 }) => {
        if (['amt', 'per', 'price'].includes(obj?.from)) {
            let payblePrice = taxCountable ? productForm?.visiblePayblePrice : productForm?.payblePrice
            let diff = roundToTwoDecimalPlaces(obj?.val - payblePrice)

            productForm.price = obj.val

            productForm.profitInAmount = diff
            productForm.previousProfitInAmount = diff

            productForm.profitInPercentage = roundToTwoDecimalPlaces((diff * 100 / payblePrice))
            productForm.previousProfitInPercentage = productForm.profitInPercentage

            if (!taxCountable) {
                productForm.visibleTaxAmount = roundToTwoDecimalPlaces((productForm.taxPercentage / (100 + productForm?.taxPercentage)) * obj?.val)
                let totalPayble = roundToTwoDecimalPlaces(productForm?.visiblePayblePrice + productForm?.visibleTaxAmount)
                productForm.profitInAmount = roundToTwoDecimalPlaces(obj?.val - totalPayble)
            }

            productForm.mrp = productForm?.mrp > productForm?.price ? productForm?.mrp : productForm?.price
        }
        if (obj.from == 'mrp')
            productForm.mrp = obj.val

        setProductForm({ ...productForm })
    }
    // ------------

    // function for save product detail ------------
    const productFormSubmit = async () => {

        productForm.previewImagesData = productForm?.previewImagesData?.map((row, index) => ({
            ...row,
            isPrimary: (primaryPreview?.type == "previewImagesData" && (primaryPreview?.index == index)) ? 1 : 0
        }))

        try {

            // delete productForm?.previousProfitInPercentage
            // delete productForm?.previousProfitInAmount
            // delete productForm?.previousPrice
            // delete productForm?.previousMrp

            productForm.isBranding = brandingStatus ? 1 : 0

            // setProductFormSubmitStatus(false)
            // return
            const updateId = location?.state?.data?.productId
            const { data, status } = await axios.put(
                `${frontEnd_API?.storeProduct}/information/${(updateId)}`,
                {
                    ...productForm,
                    customMockupImages: mockupData ?? [],
                    oldCustomMockupImages: oldMockupData ?? [],
                    primaryCustomMockupImage: primaryPreview?.type != "previewImagesData" ? primaryPreview?.index ?? -1 : -1
                },
                mockupData?.length > 0 ? headerImage : header
            );

            if (status == 200) {
                setProductFormSubmitStatus(false)
                toast(data?.message)
                // return
                navigate('/products')
            }
        } catch (error) {
            setProductFormSubmitStatus(false)
            console.log("err ::: ", error)
            toast.error(error?.response?.data?.message ?? 'Something is wrong')

            if (error?.response?.status == 406 && error?.response?.data?.data) {
                setErr((prevData) => ({
                    ...prevData,
                    ...error?.response?.data?.data
                }))
            }

        }
    }
    // ------------

    // function for update all variant price ------------
    const updateVariantPrice = (obj = {}) => {

        const data = productForm?.cartesianData?.map((row) => {

            if (['price'].includes(obj.from)) {
                let payblePrice = taxCountable ? row?.visiblePayblePrice : row?.payblePrice
                row.price = payblePrice > productForm?.price ? payblePrice : productForm?.price

                let diff = roundToTwoDecimalPlaces(row.price - payblePrice)

                row.profitAmount = diff
                row.profitPercentage = roundToTwoDecimalPlaces((diff * 100 / payblePrice))

                if (!taxCountable) {
                    row.visibleTaxAmount = roundToTwoDecimalPlaces((productForm.taxPercentage / (100 + productForm?.taxPercentage)) * row?.price)
                    let totalPayble = roundToTwoDecimalPlaces(row?.visiblePayblePrice + row?.visibleTaxAmount)
                    row.profitAmount = roundToTwoDecimalPlaces(row?.price - totalPayble)
                }

                row.mrp = row?.mrp > row.price ? row?.mrp : row?.price
            }
            if (obj.from == 'mrp')
                row.mrp = roundToTwoDecimalPlaces(productForm.mrp)

            return row
        })

        productForm.cartesianData = data
        setProductForm({ ...productForm })
    }
    // ------------

    // function for manage variant price ------------
    const manageVariantPrice = (obj = { from: 'amt' }) => {

        if (obj.from) {
            const row = productForm?.cartesianData[obj?.index]

            if (['price'].includes(obj.from)) {
                let payblePrice = taxCountable ? row?.visiblePayblePrice : row?.payblePrice
                row.price = payblePrice > obj?.value ? payblePrice : obj?.value

                let diff = roundToTwoDecimalPlaces(row?.price - payblePrice)
                row.profitAmount = diff
                row.profitPercentage = roundToTwoDecimalPlaces((diff * 100 / payblePrice))

                if (!taxCountable) {
                    row.visibleTaxAmount = roundToTwoDecimalPlaces((productForm.taxPercentage / (100 + productForm?.taxPercentage)) * row?.price)
                    let totalPayble = roundToTwoDecimalPlaces(row?.visiblePayblePrice + row?.visibleTaxAmount)
                    row.profitAmount = roundToTwoDecimalPlaces(row?.price - totalPayble)
                }

                row.mrp = row?.mrp > row.price ? row?.mrp : row?.price
            }
            if (obj.from == 'mrp')
                row.mrp = obj.value

            productForm.cartesianData[obj.index] = row

            setProductForm({ ...productForm })
        }
    }
    // ------------

    useEffect(() => { manageProductFormData() }, [])

    const handleMockupData = (event) => {
        const files = Array.from(event.target.files);
        if (files?.length > 0) {
            let tempData = [];

            // Store images that size is more then 200KB 
            for (let i = 0; i < files?.length; i++) {
                const sizeValue = ((files[i]?.size / 1024))
                if (sizeValue <= 200) {
                    tempData.push(files[i])
                }
            }
            tempData = tempData.map((file) => Object.assign(file, { preview: URL.createObjectURL(file) }));

            // Show alert if any image size is more then 200KB 
            if (tempData?.length != files?.length) {
                Swal.fire("Maximum file size is 200KB", `We have removed ${files?.length - tempData?.length} image(s) exceeding 200KB from your selection.`, "warning");
            }

            const acceptImage = 4 - oldMockupData?.length;

            // Store maximum 4 images only
            if (tempData?.length > acceptImage) {
                Swal.fire("", `You can upload maximum ${acceptImage} files at a time.`, "warning");
                setMockupData(tempData.slice(0, Number(acceptImage)));
            }
            else {
                setMockupData(tempData);
            }
            setPrimaryPreview({ type: "customMockupImages", index: 0 })
        }
    }

    const handleDeleteMockup = (index) => () => {
        const data = mockupData.filter((_, i) => i !== index);
        setMockupData(data);
        if (primaryPreview?.type == "customMockupImages" && (primaryPreview?.index > index)) {
            setPrimaryPreview({
                type: "customMockupImages",
                index: primaryPreview?.index - 1
            })
        }
    }

    const handleDeleteOldMockup = (index) => () => {
        const data = oldMockupData.filter((_, i) => i !== index);
        setOldMockupData(data);
    }

    const handleRedirect = (item) => {
        // const { url, data } = redirectValue;
        if (item) {
            const kebabCaseStr = item.replace(/'/g, "")?.trim()?.replace(/\s+/g, ' ')?.replace(/ - /g, '-')?.replace(/ /g, '-')?.toLowerCase();
            return kebabCaseStr;
        }
    };

    const handleInputChange = (val) => {
        setErr((prevErr) => {
            const newErr = { ...prevErr };
            delete newErr[val?.name]; // Properly updating error state

            // Check for required fields
            if (val?.required && val?.value?.trim().length === 0) {
                newErr[val?.name] = `${val?.name} is required`;
            }

            // Check regex pattern validation
            if (val?.pattern && val?.value?.trim().length > 0) {
                const patternCheck = new RegExp(val?.pattern);
                if (!patternCheck.test(val?.value)) {
                    if (val?.type === "productSlug") {
                        newErr[val?.name] = "Only letters, numbers, hyphens (-), underscores (_), single ('), and double (\") quotes are allowed.";
                    } else {
                        newErr[val?.name] = `Invalid pattern for ${val?.name}`;
                    }
                }
            }

            return newErr; // Ensure state is updated
        });

        // Update storeData state properly
        setProductForm((prevData) => ({ ...prevData, [val.name]: val.value }));
    };

    return (
        <div className='custom-product-create position-relative'>

            {/* Loader For Product Create */}
            {
                productFormSubmitStatus &&
                <div className='position-absolute w-100 h-100'>
                    <div
                        className='position-sticky top-0 start-0 w-100 flex-center-align'
                        style={{
                            backgroundColor: 'rgba(255,255,255,1)',
                            zIndex: "9999",
                            height: "100vh"
                        }}
                    >
                        <div className='d-grid text-center text-success gap-1'>
                            <div className='w-100 aspect-2-1 aspect-md-2-1'>
                                <Loader />
                            </div>
                        </div>
                    </div>
                </div>
            }

            <Container className='py-4'>
                <Row>
                    <Col xl='12'>
                        <Card className='rounded-0 mb-4'>
                            <CardHeader className='bg-transparent py-3'>
                                <h4 className='mb-0 fw-bolder'>Product Preview & Mockup</h4>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col xl='5' md='6' xs='12'>
                                        <div
                                            className='preview-image border border-2 border-dashed'
                                        >
                                            <ProductPreviewImage
                                                colorCode={productForm?.defaultColor ?? '#ffffff'}
                                                objectData={{
                                                    preview: productForm?.previewImagesData?.length > 0
                                                        ? (primaryPreview?.type == "previewImagesData")
                                                            ? productForm?.previewImagesData[primaryPreview?.index]?.preview
                                                            : (primaryPreview?.type == "customMockupImages")
                                                                ? mockupData[primaryPreview?.index]?.preview
                                                                : (primaryPreview?.type == "oldCustomMockupImages")
                                                                    ? oldMockupData[primaryPreview?.index]?.preview
                                                                    : null
                                                        : null
                                                }}
                                            />
                                        </div>
                                    </Col>
                                    <Col xl='7' md='6' xs='12'>
                                        <Row className='gy-2'>
                                            <Col xl='12' className='custom-scrollbar'>
                                                <div className='fs-20 fs-md-18 fs-sm-16 fw-semibold mb-2 mt-2 mt-md-0'>
                                                    <span>Color Images</span>
                                                </div>
                                                <div className='d-flex custom-color-scroll gap-2 gap-sm-3 mb-3'>
                                                    {
                                                        colorVariantData?.map((row, index) => <>
                                                            <div
                                                                key={index}
                                                                className={`pointer`}
                                                                onClick={() => {
                                                                    productForm.defaultColor = row?.code ?? ''
                                                                    setPrimaryPreview({ type: "previewImagesData", index: 0 })
                                                                    setProductForm({ ...productForm })
                                                                }}
                                                            >
                                                                <div className=' sm-preview-img position-relative'>
                                                                    <img
                                                                        src={row?.preview}
                                                                        style={{ objectFit: "cover", backgroundColor: `${row?.code ?? '#ffffff'}` }}
                                                                        alt={productForm?.productName + ' ' + row?.label}
                                                                        className={`w-100 h-100 rounded-1 border ${row?.code == productForm?.defaultColor ? 'border-2 border-dark' : ''}`}
                                                                    />
                                                                    {productForm?.defaultColor == row?.code && (
                                                                        <span className='position-absolute top-0 end-0 fs-10 badge bg-success border-0 rounded-0'>Default</span>
                                                                    )}
                                                                </div>
                                                                <div
                                                                    className='bg-transparent fs-12 fs-sm-10 fw-semibold text-center h-25 pt-1 text-capitalize'
                                                                    style={{ color: '#808c98' }}
                                                                >
                                                                    {row?.label}
                                                                </div>
                                                            </div>
                                                        </>)
                                                    }
                                                </div>
                                            </Col>
                                            <Col xl='12'>
                                                <div className='fs-20 fs-md-18 fs-sm-16 fw-semibold mb-2 mt-2 mt-md-0'>
                                                    <span>Mockup Images</span>
                                                </div>
                                                <div className='d-flex flex-wrap gap-2 gap-sm-3 mb-3'>
                                                    {
                                                        productForm?.previewImagesData?.map((row, index) => {
                                                            return (
                                                                <div
                                                                    key={index}
                                                                    className={`position-relative sm-preview-img pointer rounded-1 border mt-2 ${(primaryPreview?.type == "previewImagesData" && primaryPreview?.index == index) ? 'border-2 border-dark' : ''}`}
                                                                >
                                                                    <ProductPreviewImage
                                                                        isList={true}
                                                                        colorCode={productForm?.defaultColor ?? '#ffffff'}
                                                                        objectData={{ preview: row?.preview }}
                                                                        // selectionData={index}
                                                                        onPrevSelect={() => {
                                                                            setPrimaryPreview({ type: "previewImagesData", index })
                                                                        }}
                                                                    />
                                                                    {/* {primaryPreview == index && ( */}
                                                                    {(primaryPreview?.type == "previewImagesData" && primaryPreview?.index == index) && (
                                                                        <span className='position-absolute top-0 start-0 fs-10 badge bg-success border-0 rounded-0'>Default</span>
                                                                    )}
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </Col>
                                            <Col xl='12'>
                                                <div className='fs-20 fs-md-18 fs-sm-16 fw-semibold mb-2 mt-2 mt-md-0'>
                                                    <span>Custom Mockup Images</span>
                                                </div>
                                                <div className='d-flex flex-wrap gap-2 gap-sm-3 mb-3'>
                                                    {
                                                        oldMockupData?.length > 0 &&
                                                        oldMockupData?.map((file, index) => (
                                                            <div
                                                                key={index}
                                                                className={`position-relative sm-preview-img pointer rounded-1 border mt-2 flex-center-align hover-increase  ${(primaryPreview?.type == "oldCustomMockupImages" && primaryPreview?.index == index) ? 'border-2 border-dark' : ''}`}
                                                            >
                                                                <img
                                                                    src={file?.preview} // Generate preview URL for the image
                                                                    alt={file.name}
                                                                    className='h-100 w-100 object-cover'
                                                                    onClick={() => {
                                                                        setPrimaryPreview({ type: "oldCustomMockupImages", index: index })
                                                                    }}
                                                                />

                                                                {(primaryPreview?.type == "oldCustomMockupImages" && primaryPreview?.index == index) &&
                                                                    <span className='position-absolute top-0 start-0 fs-10 badge bg-success border-0 rounded-0'>Default</span>
                                                                    // : <span
                                                                    //     className='delete-btn'
                                                                    //     onClick={handleDeleteOldMockup(index)}
                                                                    // >
                                                                    //     <i className='bi bi-x-lg fs-12'></i>
                                                                    // </span>
                                                                }
                                                            </div>
                                                        ))
                                                    }

                                                    {
                                                        mockupData?.length > 0 &&
                                                        mockupData?.map((file, index) => (
                                                            <div
                                                                key={index}
                                                                className={`position-relative sm-preview-img pointer rounded-1 border mt-2 flex-center-align hover-increase  ${(primaryPreview?.type == "customMockupImages" && primaryPreview?.index == index) ? 'border-2 border-dark' : ''}`}
                                                            >
                                                                <img
                                                                    src={file?.preview} // Generate preview URL for the image
                                                                    alt={file.name}
                                                                    className='h-100 w-100 object-cover'
                                                                    onClick={() => {
                                                                        setPrimaryPreview({ type: "customMockupImages", index: index })
                                                                    }}
                                                                />

                                                                {(primaryPreview?.type == "customMockupImages" && primaryPreview?.index == index) ?
                                                                    <span className='position-absolute top-0 start-0 fs-10 badge bg-success border-0 rounded-0'>Default</span>
                                                                    : <span
                                                                        className='delete-btn'
                                                                        onClick={handleDeleteMockup(index)}
                                                                    >
                                                                        <i className='bi bi-x-lg fs-12'></i>
                                                                    </span>
                                                                }
                                                            </div>
                                                        ))
                                                    }
                                                    <label
                                                        className={`position-relative sm-preview-img pointer rounded-1 border mt-2 flex-center-align hover-increase`}
                                                        htmlFor="mockups"
                                                    >
                                                        <div className='d-grid text-center gap-2'>
                                                            <FiPlusCircle className='fs-35 color-temp mx-auto' />
                                                            <span className='fs-10 fw-semibold lh-1'>Add Custom Mockup</span>
                                                        </div>
                                                        <input
                                                            type='file'
                                                            className='w-100 d-none'
                                                            id='mockups'
                                                            accept="image/*"
                                                            multiple
                                                            onChange={handleMockupData}
                                                        />
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className='rounded-0 mb-4'>
                            <CardHeader className='bg-transparent py-3'>
                                <h4 className='mb-0 fw-bolder'>Product Detail</h4>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col xl='12'>
                                        <div className='form-group mb-3'>
                                            <Label className='form-label'>Title</Label>
                                            <input
                                                className={`form-control rounded-0 fs-6 py-2 ${(err?.name || err?.productName) ? "border-danger" : ""}`}
                                                placeholder='product title'
                                                value={productForm?.productName}
                                                name='productName'
                                                required
                                                onChange={(e) => {
                                                    console.log("e::", e?.target.value);
                                                    console.log("err::", err);
                                                    delete err?.name;
                                                    setErr(err)
                                                    handleInputChange({ name: e.target.name, value: e.target.value, required: true, pattern: pattern?.productSlug, type: "productSlug" })

                                                    // productForm.productName = e?.target?.value
                                                    // setProductForm({ ...productForm })
                                                    // delete err.name;
                                                    // setErr(err)
                                                }}
                                            />
                                            {(err?.name || err?.productName) && <span className='text-danger fs-12 fw-semibold fs-sm-10'>*{err?.name || err?.productName}</span>}
                                        </div>
                                    </Col>
                                    <Col xl='12'>
                                        <div className='form-group'>
                                            <Label className='form-label'>Description</Label>
                                            <Editor
                                                value={productForm?.description}
                                                onChange={(e) => {
                                                    productForm.description = e?.target?.value
                                                    setProductForm({ ...productForm })
                                                }}
                                                style={{ height: '250px' }}
                                            />
                                        </div>
                                    </Col>
                                    {
                                        (product?.brandingStatus && 1 < 0) && (
                                            <Col xl='12'>
                                                <div className='d-flex gap-1 flex-column my-3'>
                                                    <span className='fw-semibold'>Do you want to enable custom branding services for this product?</span>
                                                    <div>
                                                        <Input
                                                            id='branding'
                                                            type='checkbox'
                                                            checked={brandingStatus}
                                                            onChange={(e) => setBrandingStatus(!brandingStatus)}
                                                        />&nbsp;
                                                        <Label for="branding" className="mb-0 pointer">Yes</Label>
                                                    </div>
                                                </div>
                                            </Col>
                                        )
                                    }
                                </Row>
                            </CardBody>
                        </Card>
                        {
                            store?.storeType == 'SNAPSTORE' && (
                                <>
                                    <Card className='rounded-0 mb-4'>
                                        <CardHeader className='bg-transparent py-3'>
                                            <h4 className='mb-0 fw-bolder'>Meta Details</h4>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col xl='12'>
                                                    <div className='form-group mb-3'>
                                                        <Label className='form-label'>Meta Title</Label>
                                                        <input
                                                            className={`form-control rounded-0 fs-6 py-2`}
                                                            placeholder='product meta title'
                                                            value={productForm?.metaTitle ?? productForm?.productName ?? ""}
                                                            required
                                                            onChange={(e) => {
                                                                productForm.metaTitle = e?.target?.value
                                                                setProductForm({ ...productForm })
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col xl='12'>
                                                    <div className='form-group d-grid gap-1'>
                                                        <Label className='form-label'>Meta Description</Label>
                                                        <textarea
                                                            className='seller-box form-control rounded-0 fs-6 py-2'
                                                            placeholder="Enter Meta description for better SEO"
                                                            value={productForm?.metaDescription ?? ""}
                                                            onChange={(e) => {
                                                                productForm.metaDescription = e?.target?.value
                                                                setProductForm({ ...productForm })
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                    <Card className='rounded-0 mb-4'>
                                        <CardHeader className='bg-transparent py-3'>
                                            <h4 className='mb-0 fw-bolder'>Search engine listing</h4>
                                        </CardHeader>
                                        <CardBody>
                                            <div className='d-grid gap-2'>
                                                <div className='d-flex gap-2 align-items-center'>
                                                    <div
                                                        className='aspect-1-1 temp-border-rad border border-secondary-subtle overflow-hidden flex-center-align'
                                                        style={{ width: "28px", padding: "2px" }}
                                                    >
                                                        <img src={store?.favicon ?? siteConfig.logo} alt="" className='w-100 h-100 object-contain' />
                                                    </div>
                                                    <div className='d-grid gap-0 ms-1'>
                                                        <h6 className='text-capitalize fs-14 fw-medium m-0'>
                                                            {store?.name}
                                                        </h6>
                                                        <p className='m-0 fs-12 lh-base new-p-color'>
                                                            {/* https://{(store?.customDomain?.length > 0 && !userData?.expired) ? store?.customDomain : store?.domain} {`>`} product {`>`} {handleRedirect(productForm?.productName)} */}
                                                            {store?.storeBaseURL}
                                                        </p>
                                                    </div>
                                                </div>
                                                <h5 className='fs-20 google-title m-0'>{productForm?.metaTitle ?? productForm?.productName ?? ""}</h5>
                                                <p className='m-0 fs-13 new-p-color'>{productForm?.metaDescription?.length > 0 ? productForm?.metaDescription : "{--- Enter meta description to see preview ---}"}</p>
                                                <h5 className='m-0 fs-13 new-p-color'>₹{Number(productForm?.price).toFixed(2)} INR</h5>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </>
                            )
                        }
                        {
                            store?.storeType == 'SHOPIFY' && (
                                <Card className='rounded-0 mb-4'>
                                    <CardHeader className='bg-transparent py-3'>
                                        <h4 className='mb-0 fw-bolder'>Type and tags</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col xl='12'>
                                                <div className='form-group mb-3'>
                                                    <Label className='form-label'>Type</Label>
                                                    <input
                                                        className={`form-control rounded-0 fs-6 py-2`}
                                                        placeholder='product type'
                                                        value={productForm?.productType ?? ""}
                                                        required
                                                        onChange={(e) => {
                                                            productForm.productType = e?.target?.value
                                                            setProductForm({ ...productForm })
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col xl='12'>
                                                <div className='form-group d-grid gap-1'>
                                                    <Label className='form-label mb-0'>Tags</Label>
                                                    <TagsInput
                                                        value={productForm?.productTags ?? []}
                                                        onChange={(e) => {
                                                            productForm.productTags = e
                                                            setProductForm({ ...productForm })
                                                        }}
                                                        placeHolder="enter product tags"
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            )
                        }
                        <Card className='rounded-0 mb-4'>
                            <CardHeader className='bg-transparent py-3'>
                                <h4 className='mb-0 fw-bolder'>Price & Variant</h4>
                            </CardHeader>
                            <CardBody>
                                <Row xl={4} md={3} xs={1}>
                                    <Col>
                                        <div className='form-group mb-3'>
                                            <Label className='form-label d-flex justify-content-between align-items-center'>
                                                <span>Payable amount</span>
                                                <div className='position-relative'>
                                                    <span onClick={() => setPriceCountBoxStatus(!priceCountBoxStatus)} className="bi bi-info-circle-fill mr-1"></span>
                                                    {
                                                        (priceCountBoxStatus) && (
                                                            <div className='position-absolute border rounded-2 p-2 bg-white bottom-100 end-50 fs-12' style={{ width: '250px!important;' }}>
                                                                <div className='d-flex gap-3 justify-content-between ml-2'>
                                                                    <span className='text-nowrap'>Provider price</span>
                                                                    <span className='fw-bold'>{productForm?.providerPrice}</span>
                                                                </div>
                                                                <div className='d-flex gap-3 justify-content-between ml-2'>
                                                                    <span className='text-nowrap'>Design charges</span>
                                                                    <span className='fw-bold'>{productForm?.providerDesignPrice}</span>
                                                                </div>
                                                                {
                                                                    taxCountable && (
                                                                        <div className='d-flex gap-3 justify-content-between ml-2'>
                                                                            <span className='text-nowrap'>Tax ({productForm?.taxPercentage}%)</span>
                                                                            <span className='fw-bold'>{productForm?.taxAmount}</span>
                                                                        </div>
                                                                    )
                                                                }
                                                                {
                                                                    !taxCountable && (
                                                                        <div className='d-flex gap-3 justify-content-between ml-2'>
                                                                            <span className='text-nowrap'>Tax collected on selling price({productForm?.taxPercentage}%)</span>
                                                                            <span className='fw-bold'>{productForm?.visibleTaxAmount}</span>
                                                                        </div>
                                                                    )
                                                                }
                                                                <div className='d-flex gap-3 justify-content-between ml-2'>
                                                                    <span className='text-nowrap'>Platform fee</span>
                                                                    <span className='fw-bold'>{productForm?.productPlatformChargeAmount}</span>
                                                                </div>
                                                                <div className='d-flex gap-3 justify-content-between ml-2'>
                                                                    <span className='text-nowrap'>Shipping as per actual</span>
                                                                    {/* <span className='fw-bold'>{productForm?.shippingCharge}</span> */}
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </Label>
                                            <input
                                                className='form-control rounded-0 fs-6 py-2'
                                                // value={productForm?.payblePrice}
                                                value={roundToTwoDecimalPlaces(Number(productForm?.visiblePayblePrice?? 0) + Number(productForm?.visibleTaxAmount?? 0))}
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className='form-group mb-3'>
                                            <Label className='form-label'>Profit ({keyCode?.inr})</Label>
                                            <input
                                                className='form-control rounded-0 fs-6 py-2'
                                                value={productForm?.profitInAmount}
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className='form-group mb-3'>
                                            <Label className='form-label d-flex align-items-end'>
                                                Selling Price
                                                {/* Selling Price{productForm?.taxPercentage > 0 && (<div className='text-dark fs-12 fw-semibold'>&nbsp;({productForm?.taxPercentage}% incl.)</div>)} */}
                                            </Label>
                                            <input
                                                className='form-control rounded-0 fs-6 py-2'
                                                required
                                                value={productForm?.price}
                                                onChange={(e) => {
                                                    productForm.price = e?.target?.value
                                                    setProductForm({ ...productForm })
                                                }}
                                                onBlur={(e) => {
                                                    productForm.price = productForm?.payblePrice > e?.target?.value ? productForm?.payblePrice : productForm?.price
                                                    if (productForm.price != productForm.previousPrice) {
                                                        Swal.fire({
                                                            title: `Do you want to apply this change to all variant??`,
                                                            icon: 'warning',
                                                            showCancelButton: true,
                                                            confirmButtonColor: '#04ac84',
                                                            cancelButtonColor: '#d33',
                                                            confirmButtonText: 'Confirm',
                                                            cancelButtonText: 'No',
                                                        }).then(async (result) => {
                                                            if (result.isConfirmed) {
                                                                productForm.previousPrice = productForm.price
                                                                productForm.mrp = productForm?.price > productForm?.mrp ? productForm?.price : productForm?.mrp
                                                                // productForm.mrp = productForm?.price
                                                                productForm.previousMrp = productForm?.mrp
                                                                setProductForm({ ...productForm })
                                                                updateVariantPrice({ from: 'price' })
                                                                manageProductPrice({ from: 'price', val: Number(productForm.price ?? 0) })
                                                            } else {
                                                                productForm.price = productForm?.payblePrice > e?.target?.value ? productForm?.payblePrice : productForm?.price
                                                                productForm.previousPrice = productForm?.payblePrice > Number(productForm?.previousPrice) ? productForm?.payblePrice : productForm?.previousPrice
                                                                manageProductPrice({ from: 'price', val: Number(productForm?.previousPrice ?? 0) })
                                                            }
                                                        });
                                                    }
                                                }}
                                            // disabled
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className='form-group mb-3'>
                                            <Label className='form-label'>Set MRP</Label>
                                            <input
                                                className='form-control rounded-0 fs-6 py-2'
                                                value={productForm?.mrp}
                                                onChange={(e) => manageProductPrice({ from: 'mrp', val: Number(e?.target?.value > 0 ? e?.target?.value : 0) })}
                                                onBlur={(e) => {
                                                    if (productForm.mrp != productForm.previousMrp) {

                                                        Swal.fire({
                                                            title: `Do you want to apply this MRP to all variant??`,
                                                            icon: 'warning',
                                                            showCancelButton: true,
                                                            confirmButtonColor: '#04ac84',
                                                            cancelButtonColor: '#d33',
                                                            confirmButtonText: 'Confirm',
                                                            cancelButtonText: 'No',
                                                        }).then(async (result) => {
                                                            if (result.isConfirmed) {
                                                                productForm.previousMrp = productForm.mrp
                                                                setProductForm({ ...productForm })
                                                                updateVariantPrice({ from: 'mrp' })
                                                                return
                                                            }
                                                            manageProductPrice({ from: 'mrp', val: Number(productForm?.previousMrp ?? 0) })
                                                        });
                                                    }
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    {
                                        productForm?.cartesianData && (
                                            <Col xl='12' className='cartesian'>
                                                <hr />
                                                <div className='table-responsive mb-4'>
                                                    <Table className='table table-centered table-hover align-middle table-nowrap mb-0'>
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={8}>
                                                                    <h5 className='mb-0'>Product Variant Cartesian</h5>
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                {selectedVariantData?.map((row) => <th className='fs-sm-14 text-capitalize'>{row}</th>)}
                                                                <th className='fs-sm-14'>Production cost</th>
                                                                {/* <th className='fs-sm-14'>Profit (%)</th> */}
                                                                <th className='fs-sm-14'>Profit ({keyCode?.inr})</th>
                                                                <th className='fs-sm-14'>Sale price</th>
                                                                <th className='fs-sm-14'>MRP</th>
                                                                {/* <th>Stock</th> */}
                                                                <th className='fs-sm-14'>Available</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                productForm?.cartesianData?.map((row, index) => <>
                                                                    <tr className={row?.isPrimary ? 'disabled' : ''}>
                                                                        {row?.variant?.map((val) => <td className='fs-sm-14'>{val?.label}</td>)}
                                                                        <th>
                                                                            <input
                                                                                className='form-control rounded-0 fs-sm-14'
                                                                                // value={row?.payblePrice}
                                                                                value={roundToTwoDecimalPlaces(Number(row?.visiblePayblePrice) + Number(row?.visibleTaxAmount ?? 0))}
                                                                                style={{ width: '100px' }}
                                                                                disabled
                                                                            />
                                                                        </th>
                                                                        {
                                                                            row?.isPrimary ? (
                                                                                <>
                                                                                    <th>
                                                                                        <input
                                                                                            className='form-control rounded-0 fs-sm-14'
                                                                                            value={row?.profitAmount}
                                                                                            style={{ width: '100px' }}
                                                                                            disabled
                                                                                        />
                                                                                    </th>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <th>
                                                                                        <input
                                                                                            type='number'
                                                                                            className='form-control rounded-0 fs-sm-14'
                                                                                            min='0'
                                                                                            value={row?.profitAmount}
                                                                                            style={{ width: '100px' }}
                                                                                            disabled
                                                                                        />
                                                                                    </th>
                                                                                </>
                                                                            )
                                                                        }
                                                                        <th>
                                                                            <input
                                                                                className='form-control rounded-0 fs-sm-14'
                                                                                value={row?.price}
                                                                                style={{ width: '100px' }}
                                                                                onChange={(e) => {
                                                                                    productForm.cartesianData[index].price = e?.target?.value ?? 0
                                                                                    setProductForm({ ...productForm })

                                                                                }}
                                                                                onBlur={(e) => manageVariantPrice({ index, value: Number(e?.target?.value), from: 'price' })}
                                                                            />
                                                                        </th>
                                                                        <th>
                                                                            <input
                                                                                className='form-control rounded-0 fs-sm-14'
                                                                                value={row?.mrp}
                                                                                style={{ width: '100px' }}
                                                                                onChange={(e) => manageVariantPrice({ index, value: e?.target?.value > 0 ? Number(e?.target?.value) : 0, from: 'mrp' })}
                                                                            />
                                                                        </th>
                                                                        <td>
                                                                            <label className="switch">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    checked={row?.isAvailable}
                                                                                    value={row?.isAvailable}
                                                                                    onChange={(e) => {
                                                                                        productForm.cartesianData[index].isAvailable = !row?.isAvailable
                                                                                        setProductForm({ ...productForm })
                                                                                    }}
                                                                                />
                                                                                <span className="slider"></span>
                                                                            </label>
                                                                        </td>
                                                                    </tr>
                                                                </>)
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </Col>
                                        )
                                    }
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className='rounded-0 mb-4'>
                            <CardBody>
                                <div className='d-flex gap-2 align-items-center justify-conten-end'>
                                    {
                                        productFormSubmitStatus ? (
                                            <Button
                                                className='text-capitalize btn btn-success rounded-0 py-2 px-3 fs-6'
                                                color='success'
                                                disabled
                                                type='button'
                                                style={{ minWidth: "139px" }}
                                            >
                                                Please wait..
                                            </Button>
                                        ) : (
                                            <Button
                                                className='text-capitalize btn btn-success rounded-0 py-2 px-3 fs-6'
                                                color='success'
                                                onClick={() => {
                                                    setProductFormSubmitStatus(true)
                                                    setTimeout(() => {
                                                        productFormSubmit()
                                                    }, 1200)
                                                }}
                                                type='button'
                                                disabled={productFormSubmitStatus || Object?.keys(err).length !== 0}
                                                style={{ minWidth: "139px" }}
                                            >
                                                Update product
                                            </Button>
                                        )
                                    }
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <ToastContainer />
        </div>
    )
}

export default ProductUpdate