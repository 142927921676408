import React from "react";
import { Breadcrumb, Container, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

function CategoryBreadCrumbs() {
  const location = useLocation();
  const pathSegments = location?.pathname.startsWith('/view') ? location.pathname.split("/").filter(Boolean).filter((state)=>state !=="view") : location.pathname.split("/").filter(Boolean) ;

  console.log("pathSegment::", pathSegments);
  

  return (
    <Container>
      <Row>
        <Breadcrumb>
          {pathSegments.map((segment, index) => {
            const path = location?.pathname.startsWith('/view') 
            ? `/view/${pathSegments.slice(0, index + 1).join("/")}`
            : `/${pathSegments.slice(0, index + 1).join("/")}`;
            return (
              <Breadcrumb.Item key={index} as={"div"} className="cs-bc-item">
                <Link to={path} className={`text-capitalize text-decoration-none ${index + 1 == pathSegments?.length? "color-temp":"text-dark"} fs-14 fs-sm-12 fw-semibold`}>
                {decodeURIComponent(segment.replace(/-/g, " ").replace(/~/g, "-"))}
                </Link>
              </Breadcrumb.Item>
            );
          })}
        </Breadcrumb>
      </Row>
    </Container>
  );
}

export default CategoryBreadCrumbs;
