import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { storeCode, token } from '../Config/Config';
import { IMask } from 'react-imask';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import Editor from 'react-simple-wysiwyg';

function FormComponent(props = {}) {

    const [formData, setFormData] = useState({});
    const [error, setError] = useState({});
    const dispatch = useDispatch();
    const [showEye, setShowEye] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setFormData((props.valueData) ? props.valueData : {})
    }, [props.valueData])

    const handleChange = (item) => {

        delete error[item?.key];

        // For Chacking Required value
        if (item?.pattern || item?.required) {
            if (item?.required && item?.value?.length <= 0) {
                error[item?.key] = `${item?.name} is required`
            }

            if (item?.pattern && item?.value?.length > 0) {
                const patternCheck = new RegExp(item?.pattern);
                if (!patternCheck.test(item?.value))
                    error[item?.key] = `Invalid pattern for ${item?.name}`;
            }
        }

        // Store Data in formData
        const { key, value } = item
        setFormData((prevData) => ({
            ...prevData,
            // [key]: value
            [key]: (key == "email") ? value?.trim() : value
        }))

        setError({ ...error });

    }

    const handleFileChange = (item) => {
        console.log("item::", item);

        delete error[item?.key];

        // For Chacking Required value
        if (item?.pattern || item?.required) {
            if (item?.required && item?.value?.length <= 0 || item.value == undefined) {
                error[item?.key] = `${item?.name} is required`
            }

            if (item.value != undefined) {
                if (item?.validFile?.length > 0 && !item?.validFile.includes(item?.value.type)) {
                    error[item?.key] = `Enter valid file type(${item?.accept}) for ${item?.name}`;
                }
                if (item?.validSizeMax && (Number(item?.validSizeMax) * 1000) < item?.value.size) {
                    error[item?.key] = `Enter valid file Size(Max - ${item?.validSizeMax}KB) for ${item?.name}`;
                }
            }
        }

        // Store Data in formData
        const { key, value } = item
        setFormData((prevData) => ({
            ...prevData,
            [key]: value
        }))

        setError({ ...error });

    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const fileCheck = props.fields.find((data) => data.type === 'file');
        const header = {
            headers: {
                'token': token,
                'storecode': storeCode,
                'Content-Type': (fileCheck) ? 'multipart/form-data' : 'application/json'
            }
        }
        console.log("header::", header);

        const actionMethod = props?.actionType || 'post';
        const actionUrl = props.action + `${props.actionValue ? `/${props.actionValue}` : ''}`;
        if (props?.noAction) {
            toast(props?.formMessage ?? 'Form Submitted Successfully !')
            if (props?.onSubmit) {
                props?.onSubmit(formData)
            }
            if (props?.loadFirst) {
                if (props?.loadPage) {
                    window.location.reload();
                }
                if (props?.navigatePageURL) {
                    navigate(props?.navigatePageURL)
                }
            }
            else {
                if (props?.navigatePageURL) {
                    navigate(props?.navigatePageURL)
                }
                if (props?.loadPage) {
                    window.location.reload();
                }
            }
            setFormData({});
        }
        else {
            axios[actionMethod](actionUrl,
                formData,
                header)
                .then((res) => {
                    console.log(res?.data);
                    if (props?.onSubmit) {
                        props?.onSubmit(res?.data)
                    }
                    if (props?.dispatchRedux) {
                        dispatch(props?.dispatchRedux(res?.data?.data));
                    }
                    if(!props?.formMessage){
                        toast(props?.formMessage ?? 'Form Submitted Successfully !')
                    }
                    if(!props?.noCleanFormData){
                        setFormData({});
                    }
                    // if (props?.reloadPageURL) {
                    //     window.location.href = BASE_URL + props?.reloadPageURL;
                    // }
                    if (props?.loadFirst) {
                        if (props?.loadPage) {
                            window.location.reload();
                        }
                        if (props?.navigatePageURL) {
                            navigate(props?.navigatePageURL)
                        }
                    }
                    else {
                        if (props?.navigatePageURL) {
                            navigate(props?.navigatePageURL)
                        }
                        if (props?.loadPage) {
                            window.location.reload();
                        }
                    }
                })
                .catch((err) => {
                    console.log("err::", err);
                    if (err?.response?.tatus == 500) {
                        setFormData({});
                    }
                    if (err?.response?.data?.data?.non_field_message) {
                        toast(err?.response?.data?.data?.non_field_message)
                    }
                    else {
                        if (err?.response?.data?.message) toast(err?.response?.data?.message)

                        if (err?.response?.data?.data) setError(err?.response?.data?.data);
                    }
                })
        }
    }

    console.log("formData::", formData);
    

    const renderValues = (val) => {
        if (val?.value != 0) {
            const check = val?.options?.find((e) => e.value === val?.value);
            const checkCity = props?.fields?.find((e) => e.name === val.to);
            checkCity.options = check[val?.from];

            if (formData[val.to]) {
                setFormData((data) => ({
                    ...data,
                    [val.to]: ''
                }))
            }
        }
    }

    const inputRefs = useRef({});

    useEffect(() => {
        // Apply IMask to input fields with patterns
        props.fields.forEach(field => {
            if (field.mask) {
                const maskOptions = {
                    mask: field.mask
                };
                const inputElement = inputRefs.current[field.name];
                if (inputElement) {
                    IMask(inputElement, maskOptions);
                }
            }
        });
    }, [props.fields]);

    const toggleEye = (field) => {
        setShowEye((prev) => ({
            ...prev,
            [field]: !prev[field]
        }));
    }

    return (
        <div>
            <Form onSubmit={(e) => handleSubmit(e)}>
                <Row className='g-2'>
                    {
                        props.fields.map((item, index) => {
                            const type = item?.type ?? 'text';

                            return (
                                <Col className={`px-1 d-grid align-content-start ${item?.divClass ?? 'col-12'}`} key={index}>
                                    <label htmlFor={item?.key} className='fs-sm-14 text-capitalize'>{item?.label || item?.name}<span className={(item.type !== 'checkbox' ? 'text-danger' : 'd-none')}>{(item?.required) && '*'}</span> </label>
                                    {
                                        (['select']?.includes(type))
                                        &&
                                        <>
                                            <select
                                                id={item?.key}
                                                className={`text-capitalize ${(item?.type !== 'checkbox') && 'w-100'} p-2 fs-sm-14 seller-box ${(error[item?.key]) && 'border-danger'}`}
                                                name={item?.name}
                                                value={formData[item?.name] ? formData[item?.name] : 0}
                                                onChange={(e) => {
                                                    if (item?.onChange) {
                                                        renderValues({ ...item?.onChange, value: e.target.value });
                                                    }
                                                    if (e.target.value != 0) {
                                                        handleChange({ key: item?.name, value: e.target.value, required: item?.required })
                                                    }
                                                }}
                                                required={item?.required}
                                            >
                                                <option value={0}>Select {item?.label ?? item?.name}</option>
                                                {
                                                    item?.options?.map((opt, subIndex) => {
                                                        return (
                                                            <option className='text-capitalize' value={opt.value} key={subIndex}>{opt.label}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </>
                                    }
                                    {
                                        (['text', 'date', 'checkbox', 'link', 'email', 'number', 'password']?.includes(type))
                                        &&
                                        <span className={(item.type === 'checkbox') ? 'd-flex align-items-start justify-content-start gap-2' : `${(item?.key === 'mobile') && 'custom-remove-number'} d-block custom-password-input`}>
                                            <input
                                                type={(item?.type) ? (item?.type !== 'password') ? item?.type : (showEye[item.key]) ? 'text' : 'password' : 'text'}
                                                id={item?.key}
                                                placeholder={item?.placeholder ? item?.placeholder : `enter ${item?.name}`}
                                                className={`${(item?.type !== 'checkbox') && 'w-100'} p-2 fs-sm-14 seller-box ${(error[item?.key]) && 'border-danger'}`}
                                                name={item?.name}
                                                value={formData[item?.key] ? formData[item?.key] : ''}
                                                ref={el => inputRefs.current[item?.name] = el}
                                                // Prevent number change on scroll
                                                onWheel={(e) => {
                                                    if (["mobile", "Mobile"].includes(item?.key)) {
                                                        e.target.blur()
                                                    }
                                                }} 
                                                // Prevent number change on Arrowup and down key
                                                onKeyDown={(e) => {
                                                    if (["mobile", "Mobile"].includes(item?.key)) {
                                                        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                                                            e.preventDefault();
                                                        }
                                                    }
                                                }}
                                                onChange={(e) => handleChange(
                                                    {
                                                        name: item?.name,
                                                        key: item?.key,
                                                        value: e.target?.value,
                                                        pattern: item?.pattern,
                                                        required: item?.required
                                                    }
                                                )}
                                                onBlur={(e) => handleChange(
                                                    {
                                                        name: item?.name,
                                                        key: item?.key,
                                                        value: e.target?.value,
                                                        pattern: item?.pattern,
                                                        required: item?.required
                                                    }
                                                )}
                                                disabled={item?.disabled ?? false}
                                                autoComplete='off'
                                                required={item?.required}
                                            />
                                            <span className={`custom-eye ${(item.type !== 'password') && 'd-none'}`}>
                                                <i className={`bi fs-18 ${(!showEye[item.key]) ? 'bi-eye-slash' : "bi-eye"}`} onClick={() => toggleEye(item.key)}></i>
                                            </span>
                                            <label htmlFor={item.key} className={(item?.type !== 'checkbox' ? 'd-none' : `${item.checkCSS} w-100`)}>{item?.checkLabel}</label>
                                        </span>
                                    }
                                    {
                                        (['file']?.includes(type))
                                        &&
                                        <>
                                            <label htmlFor={item.key} className='custom-img-selector-lable'>
                                                {
                                                    (formData[item?.key])
                                                        ? <div>
                                                            <img src={
                                                                (typeof formData[item?.key] === 'string')
                                                                    ? formData[item?.key]
                                                                    : URL.createObjectURL(formData[item?.key])
                                                            } alt="" style={{ width: `${(item?.width) ?? "150px"}` }} />
                                                        </div>
                                                        : <div style={{ width: `${(item?.width) ?? "150px"}` }}>
                                                            <i className="bi bi-image fs-40" />
                                                        </div>
                                                }
                                                <input
                                                    type={"file"}
                                                    id={item?.key}
                                                    placeholder={item?.placeholder ? item?.placeholder : `enter ${item?.name}`}
                                                    className={`${(item?.type !== 'checkbox') && 'w-100'} p-2 fs-sm-14 seller-box ${(error[item?.key]) && 'border-danger'}`}
                                                    name={item?.name}
                                                    // value={formData[item?.key] ? formData[item?.key] : ''}
                                                    ref={el => inputRefs.current[item?.name] = el}

                                                    onChange={(e) => handleChange(
                                                        {
                                                            name: item?.name,
                                                            key: item?.key,
                                                            value: e.target?.files[0],
                                                            required: item?.required
                                                        }
                                                    )}
                                                    autoComplete='off'
                                                    required={item?.required}
                                                />
                                                {item?.checkLabel}
                                            </label>
                                        </>
                                    }
                                    {
                                        (['image']?.includes(type))
                                        &&
                                        <>
                                            <label htmlFor={item.key} className='custom-img-selector-lable'>
                                                {
                                                    (formData[item?.key])
                                                        ? <div style={{ width: `${(item?.width) ?? "150px"}` }}>
                                                            <img src={
                                                                (typeof formData[item?.key] === 'string')
                                                                    ? formData[item?.key]
                                                                    : URL.createObjectURL(formData[item?.key])
                                                            } alt="" style={{ objectFit: `${(item?.width) ? "contain" : "cover"}` }} />
                                                        </div>
                                                        : <div style={{ width: `${(item?.width) ?? "150px"}` }}>
                                                            <i className="bi bi-image fs-40" />
                                                        </div>
                                                }
                                                <input
                                                    type={"file"}
                                                    id={item?.key}
                                                    placeholder={item?.placeholder ? item?.placeholder : `enter ${item?.name}`}
                                                    className={`${(item?.type !== 'checkbox') && 'w-100'} p-2 fs-sm-14 seller-box ${(error[item?.key]) && 'border-danger'}`}
                                                    name={item?.name}
                                                    // value={formData[item?.key] ? formData[item?.key] : ''}
                                                    ref={el => inputRefs.current[item?.name] = el}
                                                    accept={item?.accept}

                                                    onChange={(e) => handleFileChange(
                                                        {
                                                            name: item?.name,
                                                            key: item?.key,
                                                            value: e.target?.files[0],
                                                            required: item?.required,
                                                            validFile: item?.validFile,
                                                            validSizeMax: item?.validSizeMax
                                                        }
                                                    )}
                                                    autoComplete='off'
                                                    required={item?.required}
                                                />
                                                {item?.checkLabel}
                                            </label>
                                        </>
                                    }
                                    {
                                        (['audio']?.includes(type))
                                        &&
                                        <>
                                            <label htmlFor={item.key} className='custom-img-selector-lable'>
                                                {
                                                    (formData[item?.key])
                                                        ? <div style={{ width: `${(item?.width) ?? "150px"}` }}>
                                                            <audio controls>
                                                                <source
                                                                    src={
                                                                        (typeof formData[item?.key] === 'string')
                                                                            ? formData[item?.key]
                                                                            : URL.createObjectURL(formData[item?.key])
                                                                    }
                                                                    type={formData[item?.key]?.type || "audio/mpeg"}
                                                                />
                                                                Your browser does not support the audio element.
                                                            </audio>
                                                        </div>
                                                        : <div style={{ width: `${(item?.width) ?? "150px"}` }}>
                                                            <i className="bi bi-music-note fs-40" />
                                                        </div>
                                                }
                                                <input
                                                    type={"file"}
                                                    id={item?.key}
                                                    placeholder={item?.placeholder ? item?.placeholder : `enter ${item?.name}`}
                                                    className={`${(item?.type !== 'checkbox') && 'w-100'} p-2 fs-sm-14 seller-box ${(error[item?.key]) && 'border-danger'}`}
                                                    name={item?.name}
                                                    ref={el => inputRefs.current[item?.name] = el}
                                                    accept={"audio/mpeg"}
                                                    onChange={(e) => handleFileChange(
                                                        {
                                                            name: item?.name,
                                                            key: item?.key,
                                                            value: e.target?.files[0],
                                                            required: item?.required,
                                                            validFile: item?.validFile,
                                                            accept: item?.accept
                                                        }
                                                    )}
                                                    autoComplete='off'
                                                    required={item?.required}
                                                />
                                                {item?.checkLabel}
                                            </label>
                                        </>
                                    }

                                    {
                                        (['textarea']?.includes(type))
                                        && <textarea
                                            id={item.key}
                                            rows={item.areaRow}
                                            value={formData[item?.key] && formData[item?.key]}
                                            placeholder={item?.placeholder ? item.placeholder : `enter ${item?.name}`}
                                            className={`${(item?.type !== 'checkbox') && 'w-100'} p-2 fs-sm-14 seller-box ${(error[item?.key]) && 'border-danger'}`}
                                            name={item?.name}
                                            ref={el => inputRefs.current[item?.name] = el}
                                            onChange={(e) => handleChange(
                                                {
                                                    name: item?.name,
                                                    key: item?.key,
                                                    value: e.target.value,
                                                    pattern: item?.pattern,
                                                    required: item?.required
                                                }
                                            )}
                                            onBlur={(e) => handleChange(
                                                {
                                                    name: item?.name,
                                                    key: item?.key,
                                                    value: e.target.value,
                                                    pattern: item?.pattern,
                                                    required: item?.required
                                                }
                                            )}
                                            autoComplete='off'
                                            required={item?.required}
                                        />
                                    }
                                    {
                                        (type == 'editor') && (
                                            <>
                                                <Editor
                                                    value={formData[item?.key] && formData[item?.key]}
                                                    onChange={(e) =>
                                                        handleChange(
                                                            {
                                                                name: item?.name,
                                                                key: item?.key,
                                                                value: e.target.value,
                                                                required: item?.required
                                                            }
                                                        )
                                                    }
                                                    style={{ height: '300px', overflow: 'auto' }}
                                                />
                                            </>
                                        )
                                    }
                                    <span className='text-danger fs-12 mb-2 custom-error-height fw-semibold'>{error[item?.key]}</span>
                                </Col>
                            )
                        })
                    }
                    {
                        props?.isForgetPassword &&
                        <Col lg='12' md='12'>
                            <Link to={"/forgot-password"} className="color-temp fs-14 fw-semibold text-decoration-none">Forgot Password?</Link>
                        </Col>
                    }
                    <Col lg='12' md='12' className="mt-3">
                        <div className={`text-start d-flex ${props?.submitBTNAlign}`}>
                            <Button
                                color="info"
                                className={`${props?.buttonClass ?? ''} ${props?.submitFullWidth && 'w-100'} btn fw-semibold info-btn text-capitalize`}
                                type="submit"
                                disabled={Object?.keys(error).length !== 0}>
                                {props?.button ?? 'Submit'}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </div >
    )
}

export default FormComponent