import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaArrowLeft } from "react-icons/fa6";
import { IoClose, IoSearch } from "react-icons/io5";
import { Link } from "react-router-dom";
import { frontEnd_API, header } from "../../../Config/Config";
import axios from "axios";

const GraphicsPicker = ({ onSelect }) => {
    const [graphics, setGraphics] = useState([]);
    const [filters, setFilters] = useState({
        search: ""
    });

    const getGraphics = async () => {
        try {
        const { data } = await axios.put(
            frontEnd_API.graphics,
            filters,
            header
        )
            console.log("data::", data);
            if(data?.data?.length > 0){
                if((filters?.search !== "") || (filters?.graphicCategoryId)){
                    setGraphics(data?.data[0])
                } else {
                    setGraphics(data?.data)
                }
            }
        } catch (e) {
            console.error(e);
            setGraphics([])
        }
    }

    useEffect(() => {
        getGraphics();
    }, [filters]);

    return (
        <div>
        {
            <Container className="p-0 overflow-hidden d-grid gap-4">
                
                {/* Search input start */}
                {/* <Row className="">
                    <Col className="col-12">
                        <label htmlFor="" className="d-grid position-relative">
                            <input 
                                type="text"
                                className="search-input-clipart w-100"
                                value={filters?.search}
                                placeholder="Search graphics..."
                                onChange={(e)=>{
                                    delete filters?.graphicCategoryId
                                    setFilters({
                                        ...filters,
                                        search: e?.target?.value
                                    })
                                }}
                            />
                            {
                            (filters?.search !== "") &&
                            <span 
                                className="position-absolute end-0 cursor-pointer"
                                style={{
                                top: "50%",
                                transform: "translate(-50%, -50%)",
                                }}
                                onClick={()=>{
                                    setFilters((prevData)=>({
                                        ...prevData,
                                        search: ""
                                    }))
                                }}
                            >
                                <IoClose />
                            </span>
                            }
                        </label>
                    </Col>
                </Row> */}
                {/* Search input end */}

                {
                    ((filters?.search !== "") || filters?.graphicCategoryId )
                    ? <Row className="gy-2">
                        <Col className="col-12">
                        <Col className="col-12">
                            <div className="flex-between-align">
                            <h5 className="fw-semibold">{filters?.search || graphics?.label}</h5>
                            {
                                filters?.graphicCategoryId &&
                                <span 
                                    className="fs-14 flex-center-align gap-1 text-dark text-decoration-none pointer"
                                    onClick={()=>{
                                        // setSelectedCategory("")
                                        delete filters?.graphicCategoryId
                                        setFilters({...filters})
                                    }}
                                >
                                    <FaArrowLeft />
                                    Back
                                </span>
                            } 
                            </div>
                        </Col>
                        </Col>
                        <Col>
                        <Row  xs={3} className="p-0 gy-2">
                            {
                            graphics?.data?.length > 0 ?
                            graphics?.data?.map((img, index) => {
                                return(
                                    <Col>
                                        <div className="w-100 aspect-1-1 cursor-pointer half-border-rad overflow-hidden">
                                            <img
                                                key={index}
                                                src={img}
                                                alt={img}
                                                title={img}
                                                className="w-100 h-100 object-cover bg-light"
                                                // style={{ width: "40px", cursor: "pointer" }}
                                                onClick={() => onSelect(img)}
                                            />
                                        </div>
                                    </Col>
                                )
                            })
                            : <h5 className="text-center w-100 m-0 py-4">No search result found</h5>
                            }
                            </Row>
                        </Col>
                    </Row>
                    : (graphics?.length >0) && graphics.map((graphic, mainIndex)=>{
                        return(
                            <Row key={mainIndex} className="gy-2">
                                <Col className="col-12">
                                    <div className="flex-between-align">
                                    <h6 className="fw-semibold first-cap m-0">{graphic?.label}</h6>
                                    {
                                        graphic?.data?.length > 6 &&
                                        <span
                                            className="fs-14 text-dark text-decoration-underline pointer"
                                            onClick={()=>{
                                                setFilters((prevData)=>({
                                                    ...prevData,
                                                    graphicCategoryId: graphic?.value,
                                                }))
                                            }}
                                        >
                                            See all
                                        </span>
                                    } 
                                    </div>
                                </Col>
                                <Col>
                                    <Row xs={3} md={3} className="p-0 gy-2">
                                    {graphic?.data?.slice(0, 6).map((img, index) => {
                                        return(
                                            <Col>
                                                <div className="w-100 aspect-1-1 cursor-pointer half-border-rad overflow-hidden">
                                                    <img
                                                    key={index}
                                                    src={img}
                                                    alt={img}
                                                    title={img}
                                                    className="w-100 h-100 object-cover bg-light"
                                                    // style={{ width: "40px", cursor: "pointer" }}
                                                    onClick={() => onSelect(img)}
                                                    />
                                                </div>
                                            </Col>
                                        )
                                    })}
                                    </Row>
                                </Col>
                            </Row>
                        )
                    })
                }
            </Container>
        }
        </div>
    );
};

export default GraphicsPicker;
