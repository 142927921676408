import React from 'react'

function Loader() {
    return (
        <>
            <video
                src={require('../Assets/Gif/Animation - 1727266995472.webm')}
                autoPlay
                loop
                muted
                playsInline
                className='w-100 h-100'
            />
        </>
    )
}

export default Loader