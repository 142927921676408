import React, { useState } from 'react'
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { frontEnd_API, header, siteConfig } from '../../Config/Config';
import FormComponent from '../../Components/FormComponent';
import { storeUser } from '../../Store/Slices/UserSlice';
import { pattern } from '../../Config/Constant';
import { toast, ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { PiStarFourFill } from "react-icons/pi";
import { CiAt } from "react-icons/ci";
import { IoDiamondOutline } from "react-icons/io5";
import axios from 'axios';
import Swal from 'sweetalert2';
import { MdDone, MdDoneAll } from 'react-icons/md';

import tShirtImg from '../../Assets/Images/tshirt.png'
import cupImg from '../../Assets/Images/cup.png'
import hoodieImg from '../../Assets/Images/hoodie.png'
import socksImg from '../../Assets/Images/socks.png'
import { Helmet } from 'react-helmet-async';


function SignupPage() {

    const localDomain = useSelector((state) => state.domain.value);
    const [formData, setFormData] = useState({});
    const [error, setError] = useState({});
    const [showEye, setShowEye] = useState(false);
    const [otpBox, setOtpBox] = useState(false);
    const [validOTP, setValidOTP] = useState(false);
    const [waitingTime, setWaitingTime] = useState(true);
    const [timeSeconds, setTimeSeconds] = useState();
    const dispatch = useDispatch();

    const handleChange = (item) => {

        delete error[item?.key];

        // For Chacking Required value
        if (item?.pattern || item?.required) {
            if (item?.required && item?.value?.length <= 0) {
                error[item?.key] = `${item?.name} is required`
            }

            if (item?.pattern && item?.value?.length > 0) {
                const patternCheck = new RegExp(item?.pattern);
                if (!patternCheck.test(item?.value))
                    error[item?.key] = `Invalid pattern for ${item?.name}`;
            }
        }
        console.log("item::", item);

        // Store Data in formData
        const { key, value } = item
        setFormData((prevData) => ({
            ...prevData,
            [key]: value
        }))

        setError({ ...error });

        if (item?.name == "mobile") {
            if (item?.pattern && item?.value?.length > 0) {
                const patternCheck = new RegExp(item?.pattern);
                if (!patternCheck.test(item?.value)) {
                    setOtpBox(false);
                    setValidOTP(false);
                }
            }
        }

    }

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post(frontEnd_API.signup, formData, header)
            .then((res) => {
                console.log(res?.data);
                dispatch(storeUser(res?.data?.data));
                // toast('User Created Successfully !')
                setError({})
                setFormData({})
                window.location.reload()
            })
            .catch((error) => {
                console.log("err::", error)
                setError({ ...error?.response?.data?.data })
            })
    }

    const toggleEye = (field) => {
        setShowEye((prev) => ({
            ...prev,
            [field]: !prev[field]
        }));
    }

    const generateOTP = async () => {
        if (formData?.mobile) {
            try {
                let response = {};
                if (!otpBox) {
                    console.log("First");
                    response = await axios.put(frontEnd_API?.sendotp, { mobile: formData?.mobile }, header)
                }
                else {
                    console.log("Retry");
                    response = await axios.put(frontEnd_API?.resendotp, { mobile: formData?.mobile }, header)
                }
                console.log("data?.data::", response);
                setOtpBox(true);
                setWaitingTime(false);

                let countdown = 45;
                setTimeSeconds(countdown);
                const timer = setInterval(() => {
                    setTimeSeconds(countdown);
                    countdown--;

                    if (countdown === 0) {
                        clearInterval(timer);
                        setWaitingTime(true);
                    }
                }, 1000);
            }
            catch (err) {
                console.log("Err::", err);
                if (err?.response?.data?.data?.mobile) {
                    setError((prevData) => ({
                        ...prevData,
                        ...err?.response?.data?.data
                    }));
                    setOtpBox(false);
                }
            }
        }
    }

    const validateOTP = async () => {
        try {
            const response = await axios.put(frontEnd_API?.verifyotp, { mobile: formData?.mobile, otp: formData?.otp }, header)
            console.log("response::", response);
            if (response?.status == 200)
                setValidOTP(true);
        }
        catch (err) {
            console.log("Err::", err);
            if (err?.response?.data?.data?.otp) {
                setError((prevData) => ({
                    ...prevData,
                    ...err?.response?.data?.data
                }));
                setValidOTP(false);
            }
        }
    }

    document.title = `Seller Sign up | ${siteConfig?.name}`;

    return (
        <div>
            <Helmet>
                <title>Sign up | Printfuse Print on Demand Dropshipping Platform</title>
                <meta
                    name="description"
                    content="Create and sell 500+ print on demand products online. No inventory, no upfront costs. Build your free snap store today and start selling instantly. Sign up now."
                />
            </Helmet>
            <Container fluid className='d-grid align-items-center custom-signin-page'>
                <Row className='custom-signin-page-row'>
                    <Col className='col-12 col-md-6 d-grid p-0'>
                        <div className='p-4 p-md-5 custom-signin-page-img align-content-between align-content-md-end'>
                            <div className='custom-img-dark-color' />
                            <div className='custom-bg-dark-color' />
                            <div className='flex-center-align d-flex d-md-none'>
                                <a href={"https://printfuse.in/"} className="logo-holder p-1">
                                    <img src={require('../../Assets/Images/printfuse blue.png')} alt="" />
                                </a>
                            </div>
                            <div className='d-grid gap-4 content-holder'>
                                <div className='d-grid gap-1'>
                                    <h5 className='m-0 fw-semibold fs-sm-16 fs-md-18 '>
                                        Sign up and choose from over 200+ high-quality products.
                                    </h5>
                                    <h5 className='m-0 fw-semibold fs-sm-16 fs-md-18 '>
                                        Customize them and start selling custom merch for free.
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className='col-12 col-md-6 d-grid p-0'>
                        <div className='px-3 px-lg-5 py-5 custom-signin-page-form position-relative'>
                            <div className='flex-center-align start-50 d-none d-md-flex position-absolute'
                                style={{
                                    transform: "translate(-50%, 100%)",
                                }}>
                                <a href={"https://printfuse.in/"} className="logo-holder p-1">
                                    <img src={require('../../Assets/Images/printfuse blue.png')} alt="" />
                                </a>
                            </div>
                            <Container className='cs-container-sm d-grid gap-4  custom-signin-content'>
                                <div className='custom-sticky-icons d-none d-md-block'>
                                    <img src={tShirtImg} className='first-icon min-img-h-50px' />
                                    <img src={cupImg} className='second-icon min-img-h-50px' />
                                    <img src={hoodieImg} className='third-icon min-img-h-50px' />
                                    <img src={socksImg} className='fourth-icon min-img-h-50px' />
                                    {/* <CiAt className='first-icon' />
                                    <PiStarFourFill className='second-icon' />
                                    <IoDiamondOutline className='third-icon' />
                                    <PiStarFourFill className='fourth-icon' /> */}
                                </div>
                                <div className='d-grid'>
                                    <h1 className='fw-bold m-0 text-center text-uppercase display-4 d-grid'>
                                        <span className='fw-bold m-0 text-center m-0 text-uppercase h2' style={{ letterSpacing: "4px" }}>get started</span>
                                        for free
                                    </h1>
                                </div>

                                {/*
                                <div className='flex-center-align px-sm-5 gap-2 btn-list'>
                                    <button className='git-btn'><i className="bi bi-github"></i></button>
                                    <button className='google-btn'><i className="bi bi-google"></i></button>
                                </div>
                                <span className='text-center p-color'>OR</span>
                                */}

                                <div className='d-grid px-md-5'>
                                    {/* <FormComponent
                                        action={frontEnd_API.signup}
                                        dispatchRedux={storeUser}
                                        submitFullWidth={true}
                                        submitBTNAlign={'justify-content-start custom-submit-btn'}
                                        loadPage={true}
                                        formSubmit={"User Created Successfully !"}
                                        button={'Create my account'}
                                        navigatePageURL={(localDomain) && '/app/create-store'}
                                        fields={[
                                            {
                                                divClass: 'col-12',
                                                name: 'name',
                                                key: 'name',
                                                required: true,
                                                placeholder: 'Your name',
                                            },
                                            {
                                                divClass: 'col-12 col-md-6',
                                                name: 'email',
                                                key: 'email',
                                                placeholder: 'Your Email',
                                                required: true,
                                                pattern: pattern.email,
                                            },
                                            {
                                                divClass: 'col-12 col-md-6',
                                                name: 'mobile',
                                                key: 'mobile',
                                                type: 'number',
                                                placeholder: 'Enter 10 digit number',
                                                required: true,
                                                pattern: pattern.mobile,
                                            },
                                            {
                                                divClass: 'col-12',
                                                name: 'Password',
                                                key: 'password',
                                                type: 'password',
                                                required: true,
                                                placeholder: 'Enter strong password',
                                                pattern: pattern.password,
                                            }
                                        ]}
                                    /> */}
                                    <Form onSubmit={(e) => handleSubmit(e)}>
                                        <Row className='g-2'>

                                            <Col className={`${new RegExp(pattern?.mobile).test(formData?.mobile) ? "col-12 col-xl-8" : "col-12"}`}>
                                                <label htmlFor={"mobile"} className='fs-sm-14 text-capitalize'>Mobile<span className={'text-danger'}>*</span> </label>
                                                <span className={`d-block custom-password-input custom-remove-number`} >
                                                    <input
                                                        type={'number'}
                                                        id={"mobile"}
                                                        placeholder={`Enter 10 digit mobile number`}
                                                        className={`w-100 p-2 fs-sm-14 seller-box ${(error?.mobile) && 'border-danger'}`}
                                                        name={"mobile"}
                                                        value={formData?.mobile ? formData?.mobile : ''}

                                                        onChange={(e) => handleChange(
                                                            {
                                                                name: e.target?.name,
                                                                key: e.target?.id,
                                                                pattern: pattern?.mobile,
                                                                value: e.target?.value,
                                                                required: true
                                                            }
                                                        )}
                                                        onBlur={(e) => handleChange(
                                                            {
                                                                name: e.target?.name,
                                                                key: e.target?.id,
                                                                pattern: pattern?.mobile,
                                                                value: e.target?.value,
                                                                required: true
                                                            }
                                                        )}
                                                        autoComplete='off'
                                                        required={true}
                                                        disabled={otpBox}
                                                    />
                                                    {(formData?.mobile) &&
                                                        <span className={`custom-eye`}>
                                                            <i
                                                                className={`bi fs-18 ${(otpBox) ? "bi-pen-fill text-dark" : (new RegExp(pattern?.mobile).test(formData?.mobile) && !error?.mobile) ? "bi-check-lg text-success" : 'bi-x-lg text-danger'}`}
                                                                onClick={() => {
                                                                    if (otpBox) {
                                                                        Swal.fire({
                                                                            title: `Confirm Mobile Number Edit`,
                                                                            text: `This action cannot be undone. Once you click "Yes," you will need to re-enter all the required information.`,
                                                                            icon: 'warning',
                                                                            showCancelButton: true,
                                                                            confirmButtonColor: '#04ac84',
                                                                            cancelButtonColor: '#d33',
                                                                            confirmButtonText: 'Yes',
                                                                            cancelButtonText: 'No',
                                                                        }).then(async (result) => {
                                                                            if (result.isConfirmed) {
                                                                                setOtpBox(false)
                                                                                setValidOTP(false)
                                                                            }
                                                                        });
                                                                    }
                                                                }}
                                                            />
                                                        </span>
                                                    }
                                                </span>
                                                <span className='text-danger fs-12 mb-2 custom-error-height fw-semibold'>{error?.mobile}</span>
                                            </Col>
                                            {(new RegExp(pattern?.mobile).test(formData?.mobile)) &&
                                                <Col className={`col-12 col-xl-4 d-grid ${error?.mobile ? "align-content-center" : "align-content-end"}`}>
                                                    <Button
                                                        onClick={() => generateOTP()}
                                                        disabled={validOTP || !waitingTime}
                                                        style={{ minHeight: "42px" }}>
                                                        {
                                                            (validOTP) ? <span className='fs-18 flex-center-align'><MdDone /></span>
                                                                : (waitingTime)
                                                                    ? (otpBox) ? "Resend OTP" : "Send OTP"
                                                                    : <span className='flex-center-align gap-2 fs-12 fw-semibold'>
                                                                        <Spinner animation="grow" size="sm" />
                                                                        Wait for {timeSeconds}s
                                                                    </span>
                                                        }
                                                    </Button>
                                                </Col>
                                            }
                                            {(new RegExp(pattern?.mobile).test(formData?.mobile) && otpBox && !validOTP) &&
                                                <Col className={`col-12 fs-12 ${(error?.mobile || error?.otp) ? "d-none" : ""}`}>
                                                    *The OTP is valid for 3 minutes.
                                                </Col>
                                            }
                                            {(new RegExp(pattern?.mobile).test(formData?.mobile) && otpBox) &&
                                                <>
                                                    <Col className={`col-8`}>
                                                        <label htmlFor={"otp"} className='fs-sm-14 text-capitalize'>OTP<span className={'text-danger'}>*</span> </label>
                                                        <span className={`d-block custom-password-input custom-remove-number`} >
                                                            <input
                                                                type={'number'}
                                                                id={"otp"}
                                                                placeholder={`Enter OTP here ...`}
                                                                className={`w-100 p-2 fs-sm-14 seller-box ${(error?.otp) && 'border-danger'}`}
                                                                name={"otp"}
                                                                autoComplete='off'
                                                                required={true}
                                                                onChange={(e) => handleChange(
                                                                    {
                                                                        name: e.target?.name,
                                                                        key: e.target?.id,
                                                                        value: e.target?.value,
                                                                        required: true
                                                                    }
                                                                )}
                                                                onBlur={(e) => handleChange(
                                                                    {
                                                                        name: e.target?.name,
                                                                        key: e.target?.id,
                                                                        value: e.target?.value,
                                                                        required: true
                                                                    }
                                                                )}
                                                                disabled={validOTP}
                                                            />
                                                        </span>
                                                        <span className='text-danger fs-12 mb-2 custom-error-height fw-semibold'>{error?.otp}</span>
                                                    </Col>
                                                    <Col className={`col-4 d-grid ${error?.otp ? "align-content-center" : "align-content-end"}`}>
                                                        <Button
                                                            variant='success'
                                                            onClick={() => validateOTP()}
                                                            style={{ minHeight: "42px" }}
                                                            disabled={validOTP ?? error?.otp ? true : formData?.otp?.length > 0 ? false : true}
                                                        >
                                                            {
                                                                (!validOTP) ?
                                                                    "Verify"
                                                                    : <span className='fs-18 flex-center-align'><MdDoneAll /></span>
                                                            }
                                                        </Button>
                                                    </Col>
                                                </>
                                            }
                                            {
                                                (new RegExp(pattern?.mobile).test(formData?.mobile) && otpBox && validOTP) &&
                                                <>
                                                    <Col className="col-12">
                                                        <label htmlFor={"name"} className='fs-sm-14 text-capitalize'>Name<span className={'text-danger'}>*</span> </label>
                                                        <span className={`d-block custom-password-input`} >
                                                            <input
                                                                type={'text'}
                                                                id={"name"}
                                                                placeholder={`enter your name`}
                                                                className={`w-100 p-2 fs-sm-14 seller-box ${(error?.name) && 'border-danger'}`}
                                                                name={"name"}
                                                                value={formData?.name ? formData?.name : ''}

                                                                onChange={(e) => handleChange(
                                                                    {
                                                                        name: e.target?.name,
                                                                        key: e.target?.id,
                                                                        value: e.target?.value,
                                                                        required: true
                                                                    }
                                                                )}
                                                                onBlur={(e) => handleChange(
                                                                    {
                                                                        name: e.target?.name,
                                                                        key: e.target?.id,
                                                                        value: e.target?.value,
                                                                        required: true
                                                                    }
                                                                )}
                                                                autoComplete='off'
                                                                required={true}
                                                            />
                                                        </span>
                                                        <span className='text-danger fs-12 mb-2 custom-error-height fw-semibold'>{error?.name}</span>
                                                    </Col>
                                                    <Col className="col-12">
                                                        <label htmlFor={"email"} className='fs-sm-14 text-capitalize'>Email<span className={'text-danger'}>*</span> </label>
                                                        <span className={`d-block custom-password-input`} >
                                                            <input
                                                                type={'email'}
                                                                id={"email"}
                                                                placeholder={`sample@gmail.com`}
                                                                className={`w-100 p-2 fs-sm-14 seller-box ${(error?.email) && 'border-danger'}`}
                                                                name={"email"}
                                                                value={formData?.email ? formData?.email : ''}

                                                                onChange={(e) => handleChange(
                                                                    {
                                                                        name: e.target?.name,
                                                                        pattern: pattern?.email,
                                                                        key: e.target?.id,
                                                                        value: e.target?.value,
                                                                        required: true
                                                                    }
                                                                )}
                                                                onBlur={(e) => handleChange(
                                                                    {
                                                                        name: e.target?.name,
                                                                        pattern: pattern?.email,
                                                                        key: e.target?.id,
                                                                        value: e.target?.value,
                                                                        required: true
                                                                    }
                                                                )}
                                                                autoComplete='off'
                                                                required={true}
                                                            />
                                                        </span>
                                                        <span className='text-danger fs-12 mb-2 custom-error-height fw-semibold'>{error?.email}</span>
                                                    </Col>
                                                    <Col className="col-12">
                                                        <label htmlFor={"password"} className='fs-sm-14 text-capitalize'>password<span className={'text-danger'}>*</span> </label>
                                                        <span className={`d-block custom-password-input`} >
                                                            <input
                                                                type={(showEye?.password) ? 'text' : 'password'}
                                                                id={"password"}
                                                                placeholder={`Enter Strong password`}
                                                                className={`w-100 p-2 fs-sm-14 seller-box ${(error?.password) && 'border-danger'}`}
                                                                name={"password"}
                                                                value={formData?.password ? formData?.password : ''}

                                                                onChange={(e) => handleChange(
                                                                    {
                                                                        name: e.target?.name,
                                                                        // pattern: pattern?.password,
                                                                        key: e.target?.id,
                                                                        value: e.target?.value,
                                                                        required: true
                                                                    }
                                                                )}
                                                                onBlur={(e) => handleChange(
                                                                    {
                                                                        name: e.target?.name,
                                                                        // pattern: pattern?.password,
                                                                        key: e.target?.id,
                                                                        value: e.target?.value,
                                                                        required: true
                                                                    }
                                                                )}
                                                                autoComplete='off'
                                                                required={true}
                                                            />
                                                            <span className={`custom-eye`}>
                                                                <i className={`bi fs-18 ${(!showEye.password) ? 'bi-eye-slash' : "bi-eye"}`} onClick={() => toggleEye("password")}></i>
                                                            </span>
                                                        </span>
                                                        <span className='text-danger fs-12 mb-2 custom-error-height fw-semibold'>{error?.password}</span>
                                                    </Col>
                                                    <Col lg='12' md='12' className="mt-3">
                                                        <div className={`text-start d-flex justify-content-center`}>
                                                            <Button color="info" className={`custom-submit-btn w-100 btn fw-semibold info-btn text-capitalize`} type="submit" disabled={Object?.keys(error).length !== 0}>
                                                                Sign up
                                                            </Button>
                                                        </div>
                                                    </Col>
                                                </>
                                            }
                                        </Row>
                                    </Form>
                                </div>
                                <div className='d-grid gap-3 pt-2 px-md-5'>
                                    <p className='fs-10 m-0 text-center'>
                                        By clicking Sign up you agree to Printfuse <a target='_blank' href='https://printfuse.in/terms'>Terms of Service</a>, <a target='_blank' href='https://printfuse.in/privacy'>Privacy Policy</a> and <a target='_blank' href='https://printfuse.in/ippolicy'>Intellectual Property Policy</a>
                                    </p>
                                    <span className='fs-16 text-center flex-center-align gap-1 flex-wrap'>
                                        <span>Already have an account?</span> <Link className='text-decoration-none color-temp fw-semibold' to={'/signin'}>Sign in</Link>
                                    </span>
                                </div>
                            </Container>
                        </div>
                    </Col>
                </Row>
            </Container>
            <ToastContainer />
        </div>
    )
}

export default SignupPage