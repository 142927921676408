import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { frontEnd_API, header, siteConfig } from "../../Config/Config";
import { Link, useLocation, useParams } from "react-router-dom";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Redirection from "../../Components/Redirection/Redirection";
import SearchBar from "../../Components/SearchBar";
import CommonPagination from "../../Components/CommonPagination";
import ViewHeader from "../../Components/ViewHeader";
import { keyCode } from "../../Config/Constant";
import SingleProductCard from "../../Components/SingleProductCard";
import SingleProductSkeleton from "../../Components/SingleProductSkeleton";
import CategoryBreadCrumbs from "../../Components/CategoryBreadCrumbs";

function SellerProductListingPage() {
  const [category, setCategory] = useState({});
  const [subCategory, setSubCategory] = useState({});
  const location = useLocation();
  const params = useParams();
  const [show, setShow] = useState(false);
  const [filterProducts, setFilterProducts] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [filterValue, setFilterValue] = useState({limit: 12});
  const [totalCount, setTotalCount] = useState(0);
  const [loader, setLoader] = useState(true);
  const prevFilterValueRef = useRef(filterValue);

  // useEffect(() => {
  //     if (location?.pathname.startsWith('/view')) {
  //         console.log("true");
  //     }
  //     else {
  //         console.log("false");
  //     }
  // }, [])

  useEffect(() => {
    if (params?.category) {
      axios
        .get(frontEnd_API.getcategory, header)
        .then((res) => {
          let hyphenatedString = params.category;
          let tempProduct = params.product;
          let originalString = hyphenatedString
            .replace(/-/g, " ")
            .replace(/~/g, "-");
          let originalProduct = tempProduct
            .replace(/-/g, " ")
            .replace(/~/g, "-");
          const cdata = res?.data?.data;
          const found = cdata.find(
            (item) =>
              item.label?.trim()?.toLowerCase() ===
              originalString?.trim()?.toLowerCase()
          );
          let foundProduct = found?.children?.find(
            (item) =>
              item.label?.trim()?.toLowerCase() ===
              originalProduct?.trim()?.toLowerCase()
          );
          foundProduct = foundProduct ?? { label: originalProduct, value: -1 };
          setCategory(found);
          setSubCategory(foundProduct);
          setFilterValue((prevData) => ({
            ...prevData,
            categoryId: [foundProduct?.value],
          }));
        })
        .catch((e) => {
          console.log("e::", e);
        });
    } else {
      if(params?.search || window?.location?.pathname?.startsWith('/search-result')){
        getProductList(true);
        setCategory({});
        setSubCategory({});
        setFilterValue((prevData) => ({
          ...prevData,
          categoryId: [],
        }));
      }
    }
  // }, [location]);
}, [location, params]);

  useEffect(() => {
    axios
      .put(frontEnd_API.filters,
        {
          categoryId: filterValue?.categoryId ?? []
        },
        header)
      .then((res) => {
        // console.log("res::", res?.data?.data);
        setFilterList(res?.data?.data);
      })
      .catch((e) => {
        console.log("er::", e);
      });
  }, [filterValue?.categoryId]);

  const getProductList = async (isNull= false) => {
    try {
      const { data } = await axios.put(
        frontEnd_API.filterProductList,
        {
          ...filterValue,
          categoryId: isNull ? [] : filterValue?.categoryId,
          search: params?.search,
        },
        header
      );
      setTotalCount(data?.totalCount);
      setTimeout(() => {
        setLoader(false);
      }, [1000]);
      setTimeout(() => {
        setFilterProducts(data?.data);
      }, [800]);
      console.log("data?.data::", data);
    } catch (e) {
      console.log("Err::", e);
      setLoader(false);
    }
  };

  useEffect(() => {
    console.log("params?.search::", params?.search);
    
    if(params?.search || window?.location?.pathname?.startsWith('/search-result')){
      getProductList(true);
    }
  }, [params])

  // Debounce Function to Optimize API Calls
  const debounce = (func, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => func(...args), delay);
    };
  };

  // Optimized Effect
  useEffect(() => {
    if (
      JSON.stringify(prevFilterValueRef.current) !== JSON.stringify(filterValue)
    ) {
      prevFilterValueRef.current = filterValue; // Update ref to track previous value
      debounce(getProductList, 300)(); // Call API with 300ms debounce
    }
  }, [filterValue]);

  const handleFilterChange = (item = {}) => {
    console.log(item);
    const { key, value, checked } = item;
    setFilterValue((prevData) => ({
      ...prevData,
      [key]: checked
        ? [...(prevData[key] || []), value]
        : (prevData[key] || []).filter((v) => v !== value),
    }));
  };

  useEffect(()=>{
    console.log("loader::", loader);
  },[loader])

  document.title = `Product Listing | ${siteConfig?.name}`;
  return (
    <div>
      <div className="p-2 pt-3">
        {/* Search Button */}
        {location.pathname.startsWith("/view") && (
          <div className="pt-3">
            <ViewHeader />
          </div>
        )}
        <div
          className={`d-grid pb-2 ${
            location?.pathname.startsWith("/view") ? "pt-0" : "pt-3"
          }`}
        >
          <SearchBar defaultValue={params?.search} />
        </div>

            <div className='pb-1'>
                <CategoryBreadCrumbs />
            </div>

        {/* Title bar */}
        <Container>
          <div className="d-grid gap-2">
            <div
              className={`flex-between-align ${
                category && subCategory ? "" : "py-3 pb-4"
              }`}
            >
              {category && subCategory ? (
                <h4 className="fw-bold text-capitalize mb-0">
                  {params?.search ? (
                    <>
                      <span className="fw-medium">Search By : </span>
                      {params?.search}
                    </>
                  ) : (
                    subCategory?.label
                  )}
                </h4>
              ) : (
                <h4 className="fw-bold text-capitalize mb-0">
                  All Products{" "}
                  <span className="fs-10 fw-light">(Cause of wrong URL)</span>
                </h4>
              )}
              {/* <div className="d-none d-sm-flex flex-between-align gap-2">
                <span>Sort by</span>
                <select name="" id="" className="p-2 px-4">
                  <option value="">Popularity</option>
                  <option value="">latest</option>
                  <option value="">Lowest Price</option>
                  <option value="">Highest Price</option>
                </select>
              </div> */}
            </div>
            {category && subCategory && (
              <p className="fs-sm-12 fs-md-14">{subCategory?.description}</p>
            )}
          </div>
        </Container>

        {/* Content */}
        <Container className="seller-product-page">
          <Row className="g-4">
            <Col className="col-12 col-sm-4 col-lg-3 px-2 px-sm-1">
              <div className="seller-product-bar">
                <Accordion
                  defaultActiveKey={[0]}
                  className="d-none d-sm-block"
                  alwaysOpen
                >
                  {filterList.map((item, index) => (
                    <Accordion.Item eventKey={index} key={index}>
                      <Accordion.Header>
                        <span className="text-capitalize">{item.label}</span>
                      </Accordion.Header>
                      <Accordion.Body className="py-2">
                        <div className="d-grid gap-1 gap-sm-2">
                          {item?.data?.map((subItem, subIndex) => (
                            <div
                              className="d-flex align-items-center gap-2"
                              key={subIndex}
                            >
                              <input
                                type={"checkbox"}
                                id={subItem.label}
                                name={subItem.label}
                                className="custom-input-check-size"
                                style={{ cursor: "pointer" }}
                                value={subItem.value}
                                onChange={(e) =>
                                  handleFilterChange({
                                    key: item.name,
                                    value: e.target.value,
                                    checked: e.target.checked,
                                  })
                                }
                              />
                              <label
                                htmlFor={subItem.label}
                                style={{ cursor: "pointer" }}
                              >
                                {subItem.label}
                              </label>
                            </div>
                          ))}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
                <div className="d-flex d-sm-none flex-between-align gap-2">
                  <select name="" id="" className="seller-box p-2 px-4 w-100">
                    <option value="">Popularity</option>
                    <option value="">latest</option>
                    <option value="">Lowest Price</option>
                    <option value="">Highest Price</option>
                  </select>
                  <Link
                    className="seller-box p-2 px-3 link-to-normal"
                    onClick={() => setShow(!show)}
                  >
                    <i className="bi bi-funnel-fill"></i>
                  </Link>
                  <Modal
                    show={show}
                    onHide={() => setShow(!show)}
                    className="custom-modal-accordion-container"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Filter</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-accordion-container">
                      <div className="modal-accordion">
                        <Accordion
                          defaultActiveKey={["0"]}
                          className=""
                          alwaysOpen
                        >
                          {filterList.map((item, index) => (
                            <Accordion.Item eventKey={index} key={index}>
                              <Accordion.Header>
                                <span className="text-capitalize">
                                  {item.label}
                                </span>
                              </Accordion.Header>
                              <Accordion.Body>
                                <div className="d-grid gap-1 gap-sm-2">
                                  {item?.data?.map((subItem, subIndex) => (
                                    <div
                                      className="d-flex align-items-center gap-2"
                                      key={subIndex}
                                    >
                                      <input
                                        type={"checkbox"}
                                        id={`${subItem.label}-1`}
                                        name={subItem.label}
                                        className="custom-input-check-size"
                                        style={{ cursor: "pointer" }}
                                        value={subItem.value}
                                        onChange={(e) =>
                                          handleFilterChange({
                                            key: item.name,
                                            value: e.target.value,
                                            checked: e.target.checked,
                                          })
                                        }
                                      />
                                      <label
                                        htmlFor={`${subItem.label}-1`}
                                        style={{ cursor: "pointer" }}
                                      >
                                        {subItem.label}
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          ))}
                        </Accordion>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={() => setShow(!show)}
                      >
                        Close
                      </Button>
                      <Button variant="primary" onClick={() => setShow(!show)}>
                        Save Changes
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
            </Col>
            <Col
              className={`col-12 col-sm-8 col-lg-9 px-1 ${
                filterProducts?.length === 0 && "d-grid align-content-center"
              }`}
            >
              <Container>
                <Row className="g-3" style={{ minHeight: "83vh" }}>
                  {loader ? (
                    totalCount > 0 ? totalCount > 12 ? (
                      Array.from({ length: totalCount }).slice(0, 12).map((_, index) => (
                        <Col
                          key={index}
                          className="col-12 col-sm-6 col-lg-4 px-0 px-sm-2 seller-product-detail"
                        >
                          <SingleProductSkeleton />
                        </Col>
                      ))
                    ) : (
                      Array.from({ length: totalCount }).map((_, index) => (
                        <Col
                          key={index}
                          className="col-12 col-sm-6 col-lg-4 px-0 px-sm-2 seller-product-detail"
                        >
                          <SingleProductSkeleton />
                        </Col>
                      ))
                    ) : (
                        <h2 className="fw-semibold text-center d-flex flex-center-align gap-sm-3">
                      {/* <i className="bi bi-slash-circle text-danger" />
                      Not assigned */}
                      <Spinner animation="grow" variant="success" size="sm" />
                      <Spinner animation="grow" variant="success" />
                      <Spinner animation="grow" variant="success" size="sm" />
                    </h2>
                    )
                  ) : filterProducts?.length > 0 ? (
                    filterProducts?.map((item, index) => (
                      <Col
                        key={index}
                        className="col-12 col-sm-6 col-lg-4 px-0 px-sm-2 seller-product-detail"
                      >
                        <SingleProductCard
                          item={{
                            ...item,
                          }}
                          catgoryName={subCategory}
                        />
                      </Col>
                    ))
                  ) : (
                    <h2 className="fw-semibold text-center d-grid d-sm-flex flex-center-align gap-sm-3">
                      <i className="bi bi-slash-circle text-danger" />
                      No products found
                    </h2>
                  )}
                  {(filterProducts?.length > 0 || totalCount > 0) && (
                    <Col className="col-12 d-grid">
                      <CommonPagination
                        totalCount={totalCount}
                        limit={filterValue?.limit ?? 12}
                        changePage={(data) => {
                          setFilterValue((prevData) => ({
                            ...prevData,
                            limit: data?.limit,
                            page: data?.page,
                          }));
                        }}
                      />
                    </Col>
                  )}
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default SellerProductListingPage;
