import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Accordion, Col, Container, OverlayTrigger, Row, Tab, Tabs, Tooltip } from 'react-bootstrap'
import Slider from 'react-slick';
import { productSingleData, ProductSliderList, sizeChart } from '../../Data/localData'
import { Link, useLocation, useParams } from 'react-router-dom';
import { GoDotFill } from "react-icons/go";
import CommonSlider from '../../Components/CommonSlider';
import Modal from 'react-bootstrap/Modal';
import Redirection from '../../Components/Redirection/Redirection';
import { constConfig, frontEnd_API, header, SERVER_URL } from '../../Config/Config';
import axios from 'axios';
import SearchBar from '../../Components/SearchBar';
import ViewHeader from '../../Components/ViewHeader';
import { keyCode } from '../../Config/Constant';

function SellerSingleProduct() {
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    const [drop, setDrop] = useState(1);
    let sliderRef1 = useRef(null);
    let sliderRef2 = useRef(null);
    const [modalTab, setModalTab] = useState(true);
    const params = useParams();
    const location = useLocation();
    const [product, setProduct] = useState();

    const [show, setShow] = useState(false);
    const [modalData, setModalData] = useState();

    const itemsToDisplay = modalTab ? modalData?.availableVariantData[0]?.data : modalData?.availableVariantData[1]?.data;

    const handleModal = () => setShow(!show);

    useEffect(() => {
        if (window.innerWidth < 768) {
            setDrop(0);
        }
    }, [])

    useEffect(() => {

    }, [])


    useEffect(() => {
        setNav1(sliderRef1);
        setNav2(sliderRef2);
    }, []);

    const setting2 = {
        slidesToShow: 5,
        swipeToSlide: true,
        focusOnSelect: true,
        // centerMode: true,
        centerMode: false,
        centerPadding: "0px",
        // infinite: true,
        infinite: false,
        className: 'product-custom-slick new-product-detail-slide',
    }

    const ratingTotal = (rating) => {
        let val = 0;
        for (let i = 0; i < rating.length; i++) {
            val += rating[i].value
        }
        return (val / rating.length).toFixed(1);
    }

    const [heart, setHeart] = useState(Array(product?.providerData?.length).fill(false));

    const toggleHeart = (index) => {
        setHeart((prevHeart) => {
            const newHeart = [...prevHeart];
            newHeart[index] = !newHeart[index];
            return newHeart;
        });
    };

    const handleBoth = (item) => {
        handleModal();
        setModalData(item);
        console.log(item);
    }

    // Uncomment below code after data starts

    const loadProductData = useCallback(() => {
        const categoryName = location?.state?.data?.categoryName;

        axios.get(`${frontEnd_API.singleProduct}/${params?.productId}${(categoryName ? `/${categoryName?.value}` : "")}`, header)
            .then((res) => {
                console.log("res::", res);
                setProduct(res?.data?.data);
            })
            .catch((e) => {
                console.log("err::", e);
            });
    }, [params?.productId]);

    useEffect(() => {
        loadProductData();
    }, [loadProductData]);

    return (
        <div className='custom-single-product py-4 px-0 px-lg-2'>

            {
                (location.pathname.startsWith('/view')) &&
                <ViewHeader />
            }
            {/* Search Bar */}
            <div className='d-grid pb-4 gap-4'>
                <SearchBar
                    defaultValue={params?.search}
                />
            </div>
            <>
                {/* Product Slider and info */}
                <Container className='py-4'>
                    <Row className='gy-4 gy-md-0'>
                        <Col className='col-12 col-md-6'>
                            <div className="slider-container">
                                <Slider asNavFor={nav2} ref={slider => (sliderRef1 = slider)} arrows={false} infinite={false}>
                                    {
                                        product?.imagesData.map((subItem, subIndex) => {
                                            return (
                                                <div key={subIndex} className='w-100 custom-slider-img-container'>
                                                    {/* <img src={SERVER_URL + subItem} alt="" /> */}
                                                    <img src={subItem} alt="" />
                                                </div>
                                            )
                                        })
                                    }
                                </Slider>
                                <Slider
                                    asNavFor={nav1}
                                    ref={slider => (sliderRef2 = slider)}
                                    {...setting2}
                                    arrows={product?.imagesData?.length > 1}
                                >
                                    {
                                        product?.imagesData.map((subItem, subIndex) => {
                                            return (
                                                <div key={subIndex} className='w-100 custom-slider-img-container'>
                                                    {/* <img src={SERVER_URL + subItem} alt="" /> */}
                                                    <img src={subItem} alt="" />
                                                </div>
                                            )
                                        })
                                    }
                                </Slider>
                            </div>
                        </Col>
                        <Col className='col-12 col-md-6 d-grid ps-md-5'>
                            <aside className='d-grid gap-3 align-content-start'>
                                <h2 className='fw-semibold m-0 text-capitalize'>{product?.name}</h2>
                                {/* <ul className='d-grid gap-1 gap-sm-2'>dfsd
                                    {
                                        productSingleData?.features.map((item, index) => {
                                            return (
                                                <li key={index} className='p-color fs-sm-12'>{item?.description}</li>
                                            )
                                        })
                                    }
                                </ul> */}
                                <div dangerouslySetInnerHTML={{ __html: product?.shortDescription }}></div>
                                {
                                    product?.canvasSize && (
                                        <div className='fs-16 fw-semibold'>Print Area : <span className='fw-normal'>UPTO {product?.canvasSize} Size</span></div>
                                    )
                                }
                                <a href="#detail" className='fw-semibold'>Product details</a>
                                {/* <div className='mt-2' dangerouslySetInnerHTML={{ __html: product?.longDescription }} ></div> */}
                            </aside>
                        </Col>
                    </Row>
                </Container>

                {/* Provider Info */}
                {
                    product?.providerData?.length > 0 && (
                        <Container className='py-4'>
                            <Row className='gy-4'>
                                <Col className='col-12 d-grid d-sm-flex flex-between-align gap-2'>
                                    <h4 className='fw-semibold fs-md-18 m-0'>Select your Provider</h4>
                                    <div className='flex-between-align justify-content-start gap-2'>
                                        <span className='p-color fs-md-14'>Sort by</span>
                                        <select name="" id="" className='fs-md-14 py-1 px-2 py-sm-2 px-sm-3'>
                                            <option value="">Lowest Price</option>
                                            <option value="">Highest Price</option>
                                            <option value="">Lowest Shipping</option>
                                            <option value="">Highest Shipping</option>
                                        </select>
                                    </div>
                                </Col>
                                <Col className='col-12 d-grid gap-3 custom-accordian'>
                                    {
                                        product?.providerData?.map((item, index) =>
                                            <Accordion key={index} defaultActiveKey={drop} className='custom-accordian-bg' alwaysOpen>
                                                <Accordion.Item eventKey={1}>
                                                    <div className='d-grid'>
                                                        <div className='custom-header d-grid d-md-flex align-items-center justify-content-md-between gap-3 p-3'>
                                                            <div className='d-flex gap-2 align-items-center'>
                                                                <div className='d-flex align-items-center gap-2'>
                                                                    <Link className='h5 fw-semibold m-0 fs-sm-16 text-decoration-none text-capitalize'>{item.providerName}</Link>
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={<Tooltip id="button-tooltip">{item.providerState}</Tooltip>}
                                                                    >
                                                                        <i className="bi bi-pin-map-fill" />
                                                                    </OverlayTrigger>
                                                                </div>
                                                            </div>
                                                            <div className='custom-btns d-grid text-center d-sm-flex gap-2 gap-sm-3 justify-content-sm-end'>
                                                                {
                                                                    (location.pathname.startsWith('/view')) ?
                                                                        <Link to={'/signin'} className='d-block d-sm-none fs-sm-14 text-uppercase'>
                                                                            Start Designing
                                                                        </Link>
                                                                        : <Redirection
                                                                            redirectValue={{
                                                                                url: `/product/${params.productId}/${item.providerId}/canvas`,
                                                                                data: {
                                                                                    'provider': item,
                                                                                    'product': { ...product, subCategoryId: location?.state?.data?.categoryName?.value ?? 0 },
                                                                                    'from': constConfig.seller
                                                                                }
                                                                            }}>
                                                                            <Link className='d-block d-sm-none fs-sm-14'>
                                                                                Start Designing
                                                                            </Link>
                                                                        </Redirection>
                                                                }

                                                                <Link
                                                                    className='custom-provider fs-sm-14 d-none'
                                                                    onClick={() => handleBoth(item)}
                                                                >
                                                                    Provider info
                                                                </Link>
                                                                {
                                                                    (location.pathname.startsWith('/view')) ?
                                                                        <Link to={'/signin'} className='d-none d-sm-block text-uppercase'>
                                                                            Start designing
                                                                        </Link>
                                                                        : <Redirection
                                                                            redirectValue={{
                                                                                url: `/product/${params.productId}/${item.providerId}/canvas`,
                                                                                data: {
                                                                                    'provider': item,
                                                                                    'product': { ...product, subCategoryId: location?.state?.data?.categoryName?.value ?? 0 },
                                                                                    'from': constConfig.seller
                                                                                }
                                                                            }}>
                                                                            <Link className='d-none d-sm-block'>
                                                                                Start designing
                                                                            </Link>
                                                                        </Redirection>
                                                                }
                                                            </div>
                                                        </div>


                                                        <hr className='m-0' />
                                                        <div className='row p-3'>
                                                            <div className='col-12 col-md-5'>
                                                                <div className='row align-items-start'>
                                                                    {/* <div className='col-6 col-md-5 d-grid align-content-start gap-2'>
                                                                                <span className='p-color fw-semibold'>Location</span>
                                                                                <h6 className='m-0'>{item.location}</h6>
                                                                            </div> */}
                                                                    <div className='col-6 col-md-6 d-grid align-content-start gap-2'>
                                                                        <span className='p-color fw-semibold'>Price</span>
                                                                        <div className='d-grid gap-1'>
                                                                            {
                                                                                item?.expired ? (
                                                                                    <>
                                                                                        <h6 className='m-0'>From {keyCode?.inr}{item?.productPrice}</h6>
                                                                                        <h6 className='m-0 fs-14 color-info'>From {keyCode?.inr}{item?.offeredProductPrice} with Printfuse Premium</h6>
                                                                                    </>
                                                                                ) : (<h6 className='m-0'>From {keyCode?.inr}{item?.productPrice}</h6>)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-6 col-md-6 d-grid align-content-start gap-2'>
                                                                        <span className='p-color fw-semibold fs-14'>Print areas • {item?.designSidesData?.length}</span>
                                                                        {/* <div className='d-grid gap-1 ' style={{ gridTemplateColumns: 'auto auto' }}> 
                                                                        {
                                                                                item?.designSidesData.map(e => e?.label)?.map((e, subIndex) => <div key={subIndex} className='badge text-dark border rounded w-100 border-dark'>{e}</div>)
                                                                            }
                                                                        </div>
                                                                        */}
                                                                        <div className='d-flex gap-1 flex-wrap'>
                                                                            {
                                                                                item?.designSidesData.map(e => e?.label)?.map((e, subIndex) => <div key={subIndex} className='badge text-dark border rounded border-dark' style={{ minWidth: "calc(50% - 4px)" }}>{e}</div>)
                                                                            }
                                                                        </div>
                                                                        <div>
                                                                            {
                                                                                item?.brandingStatus == 1 && (
                                                                                    <div className='fs-12 border rounded border-secondary fw-semibold px-2 py-1 text-center bg-secondary text-white'>Branding insert available</div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-12 col-md-7'>
                                                                <Accordion.Body className='pt-3 pt-md-0'>
                                                                    <div className='row gy-3 '>
                                                                        <div className='col-12 col-md-6 col-xl-5 d-flex justify-content-end justify-content-xl-between gap-2 p-0 pe-md-3'>
                                                                            {/* <div className='d-grid gap-2'>
                                                                                <span className='p-color fw-semibold fs-14'>Shipping • {item?.shippingData?.length}</span>
                                                                                <h6 className='m-0'>From {keyCode?.inr}{item?.shippingData[0]?.value}</h6>
                                                                            </div> */}
                                                                            <div className='d-grid gap-2'>
                                                                                <span className='p-color fw-semibold fs-14'>Avg. production time</span>
                                                                                <h6 className='text-end text-md-start m-0'>{`<${item?.productionTime}`} Day</h6>
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            item.availableVariantData?.map((row, index) => <>
                                                                                {
                                                                                    ['color', 'colors', 'colour', 'colours']?.includes(row?.name) ? (
                                                                                        <>
                                                                                            <div className='col-12 col-xl-5 d-grid gap-2 custom-color-balls-container align-content-start p-0'>
                                                                                                <span className='p-color fw-semibold fs-14 text-start text-md-end text-xl-start'>Colors • {row?.data.length}</span>
                                                                                                <div className='custom-color-balls d-flex gap-2 justify-content-start justify-content-md-end justify-content-xl-start'>
                                                                                                    {
                                                                                                        row?.data?.map((petaItem, petaIndex) => {
                                                                                                            return (
                                                                                                                <span key={petaIndex} style={{ backgroundColor: petaItem?.code }}></span>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            <div className='col-12 col-md-6 col-xl-2 d-flex justify-content-between gap-2 p-0 pe-md-3'>
                                                                                                <div className='d-grid gap-2'>
                                                                                                    <span className='p-color fw-semibold fs-14 text-capitalize'>{row?.name} • {row?.data?.length}</span>
                                                                                                    <h6 className='text-end text-md-start m-0'>{row?.data[0]?.label} -
                                                                                                        {row?.data?.[row?.data?.length - 1]?.label}</h6>
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                            </>)
                                                                        }
                                                                    </div>
                                                                </Accordion.Body>
                                                                <Accordion.Header className='d-block d-md-none'></Accordion.Header>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Accordion.Item>
                                            </Accordion>
                                        )
                                    }
                                </Col>
                            </Row>
                        </Container>
                    )
                }

                {/* Product Instruction */}
                <Container id='detail' className='custom-description-tabs py-4'>
                    <div className="fw-bold fs-2 border-bottom border-dark">Description</div>
                    <div className='mt-2' dangerouslySetInnerHTML={{ __html: product?.longDescription }} ></div>
                    <div className='my-2 d-flex flex-column gap-3 align-items-start'>
                        {product?.productWashCareImage?.length > 0 && (<img className='img-thumbnail' style={{ height: '300px' }} src={product?.productWashCareImage} />)}
                        {product?.productSizeChartImage?.length > 0 && (<img className='img-thumbnail' style={{ height: '400px' }} src={product?.productSizeChartImage} />)}
                    </div>
                    <hr />
                </Container>

                {/* Product Size */}
                <Container className='py-4 size-tabs-container d-none'>
                    <Row className='gy-4'>
                        <Col className='col-12 col-md-4'>
                            <h2 className='m-0 fw-semibold'>Size guide</h2>
                        </Col>
                        <Col className='col-12 col-md-8 d-grid'>
                            <div>
                                <p className='p-color fs-md-12'>All measurements in the table refer to product dimensions.</p>
                            </div>
                            <div className='custom-size-tabs'>
                                <Tabs
                                    defaultActiveKey="inch"
                                    id="uncontrolled-tab-example"
                                    className="mb-3"
                                >
                                    <Tab eventKey="inch" title="IN" className='custom-tabs'>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th className='fw-light'></th>
                                                    <th className='fw-light'>Width (in)</th>
                                                    <th className='fw-light'>Length (in)</th>
                                                    <th className='fw-light'>Sleeve length (in)</th>
                                                    <th className='fw-light'>Size tolerance (in)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    sizeChart.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{item.label}</td>
                                                                {
                                                                    item.value.map((subItem, subIndex) => {
                                                                        return (
                                                                            <td key={subIndex} className='p-color'>{subItem}</td>
                                                                        )
                                                                    })
                                                                }
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </Tab>
                                    <Tab eventKey="cm" title="CM" className='custom-tabs'>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th className='fw-light'></th>
                                                    <th className='fw-light'>Width (cm)</th>
                                                    <th className='fw-light'>Length (cm)</th>
                                                    <th className='fw-light'>Sleeve length (cm)</th>
                                                    <th className='fw-light'>Size tolerance (cm)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    sizeChart.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{item.label}</td>
                                                                {
                                                                    item.value.map((subItem, subIndex) => {
                                                                        return (
                                                                            <td key={subIndex} className='p-color'>{(subItem * 2.54).toFixed(2)}</td>
                                                                        )
                                                                    })
                                                                }
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </Tab>
                                </Tabs>
                            </div>
                        </Col>
                    </Row>
                </Container>
                {
                    product?.relatedProductData?.length > 0 && (
                        <Container className='py-4'>
                            <CommonSlider
                                data={product?.relatedProductData ?? []}
                                title={'Related Products'}
                                seeClose={false}
                                fromAPI={true}
                                url={`/category/${product?.categoryName?.toLowerCase()?.replace(/-/g, '~')?.replace(/ /g, '-')}/${product?.subCategoryName?.toLowerCase()?.replace(/-/g, '~')?.replace(/ /g, '-')}`}
                            />
                        </Container>
                    )
                }

                {/* Provider Modal */}
                <Modal show={show} onHide={handleModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div className='d-grid d-flex gap-1 fs-sm-16'>
                                Provider : <span className='fw-semibold text-capitalize'> {modalData?.providerName}</span>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container className='custom-provider-tabs-container'>
                            <div className='custom-provider-tabs'>
                                <Tabs
                                    defaultActiveKey="variants"
                                    id="uncontrolled-tab-example"
                                    className="mb-3"
                                >
                                    <Tab eventKey="variants" title="Product Variants" className='px-2 px-sm-3 py-4'>
                                        <Container className='d-grid gap-4'>
                                            <Row className='align-items-center gy-3'>
                                                <Col className='col-12 p-0'>
                                                    <div className='d-grid gap-1'>
                                                        <span className='fs-12 p-color'>Group by:</span>
                                                        <div className='d-flex custom-btns'>
                                                            <Link className={(modalTab) && 'active'} onClick={() => setModalTab(true)}>Sizes</Link>
                                                            <Link className={(!modalTab) && 'active'} onClick={() => setModalTab(false)}>Colors</Link>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col className='col-12 p-0 d-grid'>
                                                    <table className='table'>
                                                        <thead>
                                                            <tr>
                                                                <th className='fs-sm-12'>{(modalTab) ? 'Size' : 'Color'}</th>
                                                                <th className='fs-sm-12'>Name</th>
                                                                <th className='fs-sm-12'>{(modalTab) ? 'Color' : 'Size'}</th>
                                                                <th className='fs-sm-12'>Inventory</th>
                                                                <th className='fs-sm-12'>Price</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                (itemsToDisplay)
                                                                &&
                                                                itemsToDisplay.map((item, index) => {
                                                                    return (
                                                                        <tr key={index} className=''>
                                                                            <td>
                                                                                <div className={`gap-1 ${(!modalTab) && 'custom-balls '} fs-sm-10`}>
                                                                                    <span key={index} style={{ backgroundColor: item.code }}></span>
                                                                                    <div className='fs-14 fs-sm-10'>
                                                                                        {item?.code}
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <span className='fs-14 fs-sm-10'>
                                                                                    {item?.label}
                                                                                </span>
                                                                            </td>
                                                                            <td>
                                                                                <span className='fs-14 fs-sm-10'>
                                                                                    8 {(modalTab) ? 'Color' : 'Size'}
                                                                                </span>
                                                                            </td>
                                                                            <td>
                                                                                <span className='fs-14 fs-sm-10'>
                                                                                    All in stock
                                                                                </span>
                                                                            </td>
                                                                            <td className='fs-14 fs-sm-10'>
                                                                                <span>₹5499</span>
                                                                                -
                                                                                <span>₹7499</span>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Tab>
                                    {/*
                                    <Tab eventKey="shipping" title="Shipping" className='px-2 px-sm-3 py-4'>
                                        <Col className='col-12 p-0 d-grid'>
                                            <table className='table'>
                                                <thead>
                                                    <tr>
                                                        <th className='fs-sm-12'>Method</th>
                                                        <th className='fs-sm-12'>Delivery Time</th>
                                                        <th className='fs-sm-12'>Size</th>
                                                        <th className='fs-sm-12'>First Item</th>
                                                        <th className='fs-sm-12'>Additional</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        (modalData)
                                                        &&
                                                        modalData?.shippingData?.map((item, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <span className='fs-16 fs-sm-12 text-capitalize fw-semibold'>
                                                                            {item.label}
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <span className='fs-14 fs-sm-10'>
                                                                            {item.time}
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <span className='fs-14 fs-sm-10'>
                                                                            All
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <span className='fs-14 fs-sm-10'>
                                                                            {keyCode?.inr}{item?.value}
                                                                        </span>
                                                                    </td>
                                                                    <td className='fs-14 fs-sm-10'>
                                                                        {keyCode?.inr}{item?.value + 100}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            <div className='d-flex gap-0'>
                                                <span className='d-grid text-danger fs-sm-14'>*</span>
                                                <p className='d-grid p-color fs-14 fs-sm-12'>Please note that the delivery time is estimated and not guaranteed.</p>
                                            </div>
                                        </Col>
                                    </Tab>
                                    */}
                                </Tabs>
                            </div>
                        </Container>
                    </Modal.Body>
                </Modal>
            </>
        </div>
    )
}

export default SellerSingleProduct