import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, ProgressBar, Row, Spinner, Table } from 'react-bootstrap';
import { Label } from 'react-konva';
import Editor from 'react-simple-wysiwyg';
import axios from 'axios';
import { defaultTransparentImage, frontEnd_API, header, headerImage, SERVER_URL, siteConfig, storage } from '../../../Config/Config';
import { toast, ToastContainer } from 'react-toastify';
import ProductPreviewImage from './ProductPreviewImageComponent';
import { generateImageFromData } from './GeneratePreviewImage';
import Redirection from '../../../Components/Redirection/Redirection';
import Loader from '../../../Components/Loader';
import Swal from 'sweetalert2';
import { Input } from 'reactstrap';
import { keyCode, pattern } from '../../../Config/Constant';
import { FiPlusCircle } from 'react-icons/fi';
import { FaShopify } from 'react-icons/fa6';
import { TagsInput } from 'react-tag-input-component';

function ProductCreate() {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [platformObj, setPlatformObj] = useState({})
    const [brandingStatus, setBrandingStatus] = useState(false)
    const [product, setProduct] = useState(location?.state?.data)
    const [priceCountBoxStatus, setPriceCountBoxStatus] = useState(false)
    const [storeImageStatus, setStoreImageStatus] = useState(true)
    const [selectedVariantData, setSelectedVariantData] = useState([])
    const [colorVariantData, setColorVariantData] = useState([])
    const [currentMockupObj, setCurrentMockupObj] = useState({})
    const [postVariant, setPostVariant] = useState()
    const [primaryPreview, setPrimaryPreview] = useState({ type: "previewImagesData", index: 0 })
    const params = useParams();
    const store = useSelector(state => state.store.value)
    const userData = useSelector((state) => state.user.value);
    const acceptImage = 4;
    const taxCountable = !['snapstore', 'SNAPSTORE']?.includes(store?.storeType)
    const [imageProcessedLength, setImageProcessedLength] = useState(0);

    // state section ------------
    const [productFormSubmitStatus, setProductFormSubmitStatus] = useState(false)
    const [productForm, setProductForm] = useState({})
    const [productPreviewImagesData, setProductPreviewImagesData] = useState([])
    const [err, setErr] = useState({})
    const [loaderMockup, setLoaderMockup] = useState(false);
    const [subLoaderMockup, setSubLoaderMockup] = useState(false);

    const [storedCanvasImages, setStoredCanvasImages] = useState(
        product?.product?.previewImagesData?.map(() => ({ imgURL: null, isPrimary: 0 }))
    );
    // ------------

    useEffect(()=>{
        subToggleLoader();
    },[])

    const toggleLoader = () =>{ 
        setLoaderMockup(true);
        setTimeout(()=>{
          setLoaderMockup(false);
        }, 800)
      }

      const subToggleLoader = () =>{ 
        setSubLoaderMockup(true);
        setLoaderMockup(true);
        setTimeout(()=>{
          setSubLoaderMockup(false);
          setLoaderMockup(false);
        }, 3000)
      }

    const [mockupData, setMockupData] = useState([])

    const roundToTwoDecimalPlaces = (num = 0) => Number((Math.round(Number(num) * 100) / 100).toFixed(2))
    // const roundToTwoDecimalPlaces = (num = 0) => Math.round(num * 100) / 100
    // const roundToTwoDecimalPlaces = (num = 0) => Math.round(num)

    useEffect(() => {
        setProduct((prevData) => ({ ...prevData }))
        createPreviewImages(location?.state?.data);
    }, [])

    useEffect(() => {
        const previewImagesData = productPreviewImagesData?.length > 0
            ? productPreviewImagesData.map((link, index) => {
                return {
                    ...link,
                    isPrimary: (primaryPreview?.type == "previewImagesData" && primaryPreview?.index == index) ? 1 : 0
                }
            })
            : []
        setProductPreviewImagesData(previewImagesData)
    }, [primaryPreview])

    // function for generate/create product cartesian ------------
    const generateVariantCartesian = (arr = []) => {
        if (arr?.length > 0) {
            const cartesian = arr.reduce((acc, array) => {
                return acc.flatMap(a => array.map(b => [...a, b]));
            }, [[]])?.map(e => ({ variant: e, stock: 50, isAvailable: true }));
            return cartesian
        } else {
            return []
        }
    }
    // ------------

    // function for manage product form data ------------
    const manageProductFormData = async () => {
        const productData = { ...product.product, ...product.provider }

        productData.providerPrice = Number(productData?.productPrice)
        productData.designData = product?.canvas?.map(e => ({ ...e, isDesigned: e?.data?.objects?.length > 0 }))
        productData.providerDesignPrice = productData?.designData?.filter(e => e.isDesigned)?.reduce((sum, x) => sum + x?.printingPrice, 0)
        productData.providerPayble = Number(productData?.productPrice + productData?.providerDesignPrice)

        productData.taxPercentage = Number(productData?.gstTaxPercentage)
        productData.taxAmount = roundToTwoDecimalPlaces((productData?.providerPayble) * productData?.taxPercentage / 100)
        productData.visibleTaxAmount = !taxCountable ? productData.taxAmount : 0

        if (taxCountable)
            productData.providerPayble = roundToTwoDecimalPlaces(productData.providerPayble + productData?.taxAmount)

        productData.platformChargePercentage = 0

        productData.productPlatformChargeAmount = roundToTwoDecimalPlaces(productData?.platformChargeAmount ?? 0)
        productData.platformChargeAmount = 0

        productData.shippingCharge = 50
        productData.rtoCharge = 60

        productData.payblePrice = roundToTwoDecimalPlaces(productData?.providerPayble)
        productData.visiblePayblePrice = productData.payblePrice + productData.productPlatformChargeAmount

        productData.profitInPercentage = 100
        productData.profitInAmount = roundToTwoDecimalPlaces(Number(productData?.visiblePayblePrice) * productData.profitInPercentage / 100)
        productData.price = roundToTwoDecimalPlaces(productData.visiblePayblePrice + productData.profitInAmount)
        productData.mrp = productData.price

        if (!taxCountable) {
            let taxAmount = roundToTwoDecimalPlaces((productData.taxPercentage / (100 + productData?.taxPercentage)) * productData.price)
            productData.visibleTaxAmount = taxAmount
            let totalPayble = roundToTwoDecimalPlaces(productData.visiblePayblePrice + taxAmount)
            productData.profitInAmount = roundToTwoDecimalPlaces(productData.price - totalPayble)
        }

        let cartesianData = []
        if (product?.variation)
            cartesianData = generateVariantCartesian(Object.values(product?.variation))

        productData.cartesianData = await Promise.all(cartesianData?.map(e => {
            const variantPrice = roundToTwoDecimalPlaces(e?.variant?.reduce((sum, a) => sum + a?.providerPrice, 0))
            const providerPrice = Number(variantPrice + productData.providerPrice + productData.providerDesignPrice);

            const taxAmount = productData.taxPercentage > 0 ? ((providerPrice) * productData?.taxPercentage / 100) : 0
            let visibleTaxAmount = !taxCountable ? taxAmount : 0

            const payblePrice = roundToTwoDecimalPlaces(providerPrice + (taxCountable ? taxAmount : 0))
            const visiblePayblePrice = payblePrice + productData.productPlatformChargeAmount

            let profitAmount = roundToTwoDecimalPlaces(visiblePayblePrice * Number(productData.profitInPercentage) / 100)
            let price = roundToTwoDecimalPlaces(visiblePayblePrice + profitAmount)
            let mrp = price

            if (!taxCountable) {
                visibleTaxAmount = roundToTwoDecimalPlaces((productData.taxPercentage / (100 + productData?.taxPercentage)) * price)
                let totalPayble = roundToTwoDecimalPlaces(visiblePayblePrice + visibleTaxAmount)
                profitAmount = roundToTwoDecimalPlaces(price - totalPayble)
            }

            return {
                ...e,
                providerPrice,
                payblePrice,
                visiblePayblePrice,
                visibleTaxAmount,
                profitPercentage: productData?.profitInPercentage,
                profitAmount,
                price,
                mrp,
                isPrimary: variantPrice <= 0
            }
        }))

        if (location?.state?.data?.updateProduct !== null) {
            const updateProduct = location?.state?.data?.updateProduct;

            productData.name = updateProduct?.name;
            productData.shortDescription = updateProduct?.description;
        }

        if (product?.variation)
            setSelectedVariantData([...Object?.keys(product?.variation)])

        // variant data
        const data = product?.provider?.availableVariantData?.filter(e => Object?.keys(product?.variation).includes(e?.name))?.map((x) => {
            return {
                ...x,
                data: product?.variation[x?.name]?.map((e) => ({ ...e, isPrimary: e?.providerPrice <= 0 }))
            }
        })
        productData.variantData = data

        setPostVariant(data);

        const previewObject = productData?.previewImagesData?.length > 0 ? productData?.previewImagesData[0] : '';
        const colorData = productData?.variantData?.find(e => ['color', 'colors', 'colour', 'colours']?.includes(e?.name?.toLowerCase()))?.data?.map(x => ({
            label: x?.label,
            code: x?.code,
            ...previewObject
        }))
        if (colorData?.length > 0) {
            setColorVariantData(colorData)
        }
        productData.defaultColor = colorData?.length > 0 ? colorData[0]?.code : ''

        setProductForm(
            {
                platformProviderCommissionPercentage: productData?.platformCommissionPercentage,
                masterProductId: productData?.id,
                providerId: productData?.providerId,
                providerBusinessId: productData?.providerBusinessId,
                providerProductId: productData?.providerProductId ?? 0,
                defaultColor: productData?.defaultColor,
                productName: productData?.name,
                description: productData?.shortDescription,
                providerPrice: productData?.productPrice,
                providerDesignPrice: productData?.providerDesignPrice,
                providerPayble: productData?.providerPayble,
                platformChargePercentage: productData.platformChargePercentage,
                platformChargeAmount: productData.platformChargeAmount,
                productPlatformChargeAmount: productData?.productPlatformChargeAmount,

                shippingCharge: productData.shippingCharge,
                rtoCharge: productData.rtoCharge,
                taxPercentage: productData.taxPercentage,
                taxAmount: productData.taxAmount,
                visibleTaxAmount: productData.visibleTaxAmount,

                payblePrice: productData.payblePrice,
                visiblePayblePrice: productData.visiblePayblePrice,

                profitInPercentage: productData.profitInPercentage,
                previousProfitInPercentage: productData.profitInPercentage,
                profitInAmount: productData.profitInAmount,
                previousProfitInAmount: productData.profitInAmount,
                price: productData.price,
                previousPrice: productData.price,
                mrp: productData.price,
                previousMrp: productData.mrp,
                height: productData.height,
                width: productData.width,
                length: productData.length,
                weight: productData.weight,
                profitPercentage: 20,
                variantProfitPercentage: 20,
                designData: productData?.designData ?? [],
                cartesianData: productData.cartesianData ?? [],
                variantData: productData?.variantData ?? [],
                previewImagesData: productData?.previewImagesData ?? [],
                productType: '',
                productTags: [],
            }
        )

        const tempIndex = location

        manageMockupDetail(colorData?.length > 0 ? colorData[0] : {})
    }
    // ------------

    // function for manage product price ------------
    const manageProductPrice = async (obj = { from: 'amt', val: 0 }) => {
        if (['amt', 'per', 'price'].includes(obj?.from)) {
            let payblePrice = taxCountable ? productForm?.visiblePayblePrice : productForm?.payblePrice
            let diff = roundToTwoDecimalPlaces(obj?.val - payblePrice)

            productForm.price = obj.val

            productForm.profitInAmount = diff
            productForm.previousProfitInAmount = diff

            productForm.profitInPercentage = roundToTwoDecimalPlaces((diff * 100 / payblePrice))
            productForm.previousProfitInPercentage = productForm.profitInPercentage

            if (!taxCountable) {
                productForm.visibleTaxAmount = roundToTwoDecimalPlaces((productForm.taxPercentage / (100 + productForm?.taxPercentage)) * obj?.val)
                let totalPayble = roundToTwoDecimalPlaces(productForm?.visiblePayblePrice + productForm?.visibleTaxAmount)
                productForm.profitInAmount = roundToTwoDecimalPlaces(obj?.val - totalPayble)
            }

            productForm.mrp = productForm?.mrp > productForm?.price ? productForm?.mrp : productForm?.price
        }
        if (obj.from == 'mrp')
            productForm.mrp = obj.val

        setProductForm({ ...productForm })
    }

    const createPreviewImages = async (product) => {
        const data = product?.product?.previewImagesData?.map((row, index) => {
            const obj = productForm?.designData?.filter(e => e?.value === row?.value)?.map(e => ({
                ratioData: e?.ratioData,
                imgURL: e?.imgURL ?? null
            }));

            return {
                seq: index + 1,
                preview: row?.preview,
                ...(obj?.length > 0 ? obj[0] : {})
            }
        });

        const imagePromises = data.map((item, index) => generateImageFromData(item, index));

        // Wait for all promises to resolve and get the base64 images
        const base64Images = await Promise.all(imagePromises);

        const previewImagesData = base64Images
            ? base64Images.map((link, index) => ({
                imgURL: link,
                isPrimary: (primaryPreview?.type == "previewImagesData" && primaryPreview?.index == index) ? 1 : 0
            }))
            : []
        setProductPreviewImagesData(previewImagesData)
    }

    // ------------
    // function for save product detail ------------
    const productFormSubmit = async ({ productStatus = -1 }) => {
        console.log("productForm 326 :: ", productForm)
        // return

        if (location?.state?.data?.product?.productCanvasType == "CUSTOM") {
            const findIsNullImage = storedCanvasImages.some((state)=>state?.imgURL === null);
            if(findIsNullImage){
                console.log("storedCanvasImages::", storedCanvasImages);
                setProductFormSubmitStatus(false)
                return toast("Images not stored completelly!")
            }
            productForm.previewImagesData = await Promise.all(storedCanvasImages.map((item, index) => {
                return{
                    ...item,
                    isPrimary: (primaryPreview?.type == "previewImagesData" && primaryPreview?.index == index) ? 1 : 0
                }
            }));
        } else {
            const data = product?.product?.previewImagesData?.map((row, index) => {
                const obj = productForm?.designData?.filter(e => e?.value === row?.value)?.map(e => ({
                    ratioData: e?.ratioData,
                    imgURL: e?.imgURL ?? null
                }));

                return {
                    seq: index + 1,
                    preview: row?.preview,
                    ...(obj?.length > 0 ? obj[0] : {})
                }
            });

            const imagePromises = data.map((item, index) => generateImageFromData(item, index));

            // Wait for all promises to resolve and get the base64 images
            const base64Images = await Promise.all(imagePromises);

            productForm.previewImagesData = base64Images
                ? base64Images.map((link, index) => ({
                    imgURL: link,
                    isPrimary: (primaryPreview?.type == "previewImagesData" && primaryPreview?.index == index) ? 1 : 0
                }))
                : []
        }        

        try {

            // productForm.previewImagesData = await new Promise((resolve) => {
            //     resolve(productPreviewImagesData);
            // });

            // delete productForm?.previousProfitInPercentage
            // delete productForm?.previousProfitInAmount
            // delete productForm?.previousPrice
            // delete productForm?.previousMrp
            // delete productForm?.desginPlatformCharge

            productForm.isBranding = Number(store?.brandingStatus) ?? 0
            productForm.platformChargeAmount = Number(productForm?.platformChargeAmount ?? 0)
            const methodType = (location?.state?.data?.updateProduct == null) ? 'post' : 'put'
            const updateId = location?.state?.data?.updateProduct?.productId
            let dataToBody = {
                ...productForm,
                subCategoryId: location?.state?.data?.product?.subCategoryId ?? 0,
                customMockupImages: mockupData,
                primaryCustomMockupImage: primaryPreview?.type == "customMockupImages" ? primaryPreview?.index : -1,
            }

            if (productStatus != -1) {
                dataToBody = {
                    ...dataToBody,
                    productStatus
                }
            }

            // console.log("dataToBody::", dataToBody);
            // console.log("previewImagesData::", productForm?.previewImagesData);
            // setProductFormSubmitStatus(false)
            // return

            const response = await axios[methodType](
                `${frontEnd_API?.storeProduct}${(updateId) ? `/${updateId}` : ''}`,
                dataToBody,
                mockupData?.length > 0 ? headerImage : header
            );

            if (response?.status == 200) {
                setProductFormSubmitStatus(false)
                toast(response?.data?.message)
                navigate('/products')
            }
        } catch (error) {

            if (error?.response?.status == 406 && error?.response?.data?.data) {
                setErr((prevData) => ({
                    ...prevData,
                    ...error?.response?.data?.data
                }))
            }

            setProductFormSubmitStatus(false)
            console.log("err ::: ", error)
            toast.error(error?.response?.data?.message ?? 'Something is wrong')
        }
    }
    // ------------

    // function for update all variant price ------------
    const updateVariantPrice = (obj = {}) => {

        const data = productForm?.cartesianData?.map((row) => {

            if (['price'].includes(obj.from)) {

                let payblePrice = taxCountable ? row?.visiblePayblePrice : row?.payblePrice
                row.price = payblePrice > productForm?.price ? payblePrice : productForm?.price

                let diff = roundToTwoDecimalPlaces(row.price - payblePrice)

                row.profitAmount = diff
                row.profitPercentage = roundToTwoDecimalPlaces((diff * 100 / payblePrice))

                if (!taxCountable) {
                    row.visibleTaxAmount = roundToTwoDecimalPlaces((productForm.taxPercentage / (100 + productForm?.taxPercentage)) * row?.price)
                    let totalPayble = roundToTwoDecimalPlaces(row?.visiblePayblePrice + row?.visibleTaxAmount)
                    row.profitAmount = roundToTwoDecimalPlaces(row?.price - totalPayble)
                }

                row.mrp = row?.mrp > row.price ? row?.mrp : row?.price
            }
            if (obj.from == 'mrp')
                row.mrp = roundToTwoDecimalPlaces(productForm.mrp)

            return row
        })

        productForm.cartesianData = data
        setProductForm({ ...productForm })
    }
    // ------------

    // function for manage variant price ------------
    const manageVariantPrice = (obj = { from: 'amt' }) => {

        if (obj.from) {
            const row = productForm?.cartesianData[obj?.index]

            if (['price'].includes(obj.from)) {
                
                let payblePrice = taxCountable ? row?.visiblePayblePrice : row?.payblePrice

                row.price = payblePrice > obj?.value ? payblePrice : obj?.value

                let diff = roundToTwoDecimalPlaces(row?.price - payblePrice)
                row.profitAmount = diff
                row.profitPercentage = roundToTwoDecimalPlaces((diff * 100 / payblePrice))

                if (!taxCountable) {
                    row.visibleTaxAmount = roundToTwoDecimalPlaces((productForm.taxPercentage / (100 + productForm?.taxPercentage)) * row?.price)
                    let totalPayble = roundToTwoDecimalPlaces(row?.visiblePayblePrice + row?.visibleTaxAmount)
                    row.profitAmount = roundToTwoDecimalPlaces(row?.price - totalPayble)
                }

                row.mrp = row?.mrp > row.price ? row?.mrp : row?.price
            }
            if (obj.from == 'mrp')
                row.mrp = obj.value

            productForm.cartesianData[obj.index] = row

            setProductForm({ ...productForm })
        }
    }
    // ------------

    // function for manage product preview and mockup data ------------
    const manageMockupDetail = (obj = {}) => {

        const row = obj?.isPreview ? { ...currentMockupObj, ...obj } : obj;

        if (row?.preview) {
            const designData = productForm?.designData?.find(e => e?.value == obj?.value)?.data
            row.designData = designData ?? []
            setCurrentMockupObj({ ...row })
        }
    }
    // ------------

    const getPlatformConfigDetail = async () => {
        const { data, status } = await axios?.get(`${frontEnd_API?.home}/platformconfig`, header)
        setPlatformObj({ ...data?.data })
    }

    useEffect(() => {
        getPlatformConfigDetail()
        manageProductFormData()
    }, [product])

    const handleMockupData = (event) => {
        const files = Array.from(event.target.files);
        if (files?.length > 0) {
            let tempData = [];

            // Store images that size is more then 200KB 
            for (let i = 0; i < files?.length; i++) {
                const sizeValue = ((files[i]?.size / 1024))
                if (sizeValue <= 200) {
                    tempData.push(files[i])
                }
            }
            tempData = tempData.map((file) => Object.assign(file, { preview: URL.createObjectURL(file) }));

            // Show alert if any image size is more then 200KB 
            if (tempData?.length != files?.length) {
                Swal.fire("Maximum file size is 200KB", `We have removed ${files?.length - tempData?.length} image(s) exceeding 200KB from your selection.`, "warning");
            }

            // Store maximum 4 images only
            if (mockupData?.length > 0) {
                if ((tempData?.length + mockupData?.length) > acceptImage) {
                    Swal.fire("", `You can upload maximum ${acceptImage} files at a time.`, "warning");
                    const mixedData = [
                        ...mockupData,
                        ...tempData.slice(0, acceptImage - mockupData?.length)
                    ]
                    setMockupData(mixedData);
                } else {
                    const mixedData = [
                        ...mockupData,
                        ...tempData
                    ]
                    setMockupData(mixedData);
                }
            }
            else {
                if (tempData?.length > acceptImage) {
                    Swal.fire("", `You can upload maximum ${acceptImage} files at a time.`, "warning");
                    setMockupData(tempData.slice(0, acceptImage));
                }
                else {
                    setMockupData(tempData);
                }
            }
            setPrimaryPreview({ type: "customMockupImages", index: 0 })
        }
    }

    const handleDeleteMockup = (index) => () => {
        const data = mockupData.filter((_, i) => i !== index);
        setMockupData(data);

        if (primaryPreview?.type == "customMockupImages" && (primaryPreview?.index > index)) {
            setPrimaryPreview({
                type: "customMockupImages",
                index: primaryPreview?.index - 1
            })
        }
    }

    const handleRedirect = (item) => {
        // const { url, data } = redirectValue;
        if (item) {
            const kebabCaseStr = item.replace(/'/g, "")?.trim()?.replace(/\s+/g, ' ')?.replace(/ - /g, '-')?.replace(/ /g, '-')?.toLowerCase();
            return kebabCaseStr;
        }
    };

    const handleInputChange = (val) => {
        setErr((prevErr) => {
            const newErr = { ...prevErr };
            delete newErr[val?.name]; // Properly updating error state

            // Check for required fields
            if (val?.required && val?.value?.trim().length === 0) {
                newErr[val?.name] = `${val?.name} is required`;
            }

            // Check regex pattern validation
            if (val?.pattern && val?.value?.trim().length > 0) {
                const patternCheck = new RegExp(val?.pattern);
                if (!patternCheck.test(val?.value)) {
                    if (val?.type === "productSlug") {
                        newErr[val?.name] = "Only letters, numbers, hyphens (-), underscores (_), single ('), and double (\") quotes are allowed.";
                    } else {
                        newErr[val?.name] = `Invalid pattern for ${val?.name}`;
                    }
                }
            }

            return newErr; // Ensure state is updated
        });

        // Update storeData state properly
        setProductForm((prevData) => ({ ...prevData, [val.name]: val.value }));
    };

    const handlePreviewImagesStore = async ({ imgURL, index }) => {
        return new Promise((resolve, reject) => {
            try {
                setStoredCanvasImages((prevImages) => {
                    const updatedImages = [...prevImages];
                    updatedImages[index] = {
                        imgURL,
                        isPrimary: (primaryPreview?.type == "previewImagesData" && primaryPreview?.index == index) ? 1 : 0
                    };
                    resolve(updatedImages);
                    return updatedImages;
                });
                
            } catch (error) {
                reject(error);
            }
        });
    }     

    useEffect(() => {
        setTimeout(() => {
            setStoreImageStatus(false);
        }, 3000)
    }, [])

    // useEffect(()=>{
    //     if (location?.state?.data?.product?.productCanvasType == "CUSTOM") {
    //         const imgData = productForm?.designData?.find(e => e?.value == product?.product?.previewImagesData[0]?.value);
    //         const tempMockupData = location?.state?.data?.product?.previewImagesData?.find((state) => state?.preview === product?.product?.previewImagesData[0]?.preview)?.mockupData

    //         toggleLoader();
    //         const obj = {
    //             preview: product?.product?.previewImagesData[0]?.preview,
    //             value: imgData?.value,
    //             index: 0,
    //             code: currentMockupObj?.code ?? "#ffffff",
    //             isPreview: true,
    //             mockupData: tempMockupData ?? {}
    //         }

    //         const tempRow = obj?.isPreview ? { ...currentMockupObj, ...obj } : obj;

    //         if (tempRow?.preview) {
    //             const designData = productForm?.designData?.find(e => e?.value == obj?.value)?.data
    //             tempRow.designData = designData ?? []
    //             setCurrentMockupObj({ ...tempRow })
    //             setPrimaryPreview({ type: "previewImagesData", index: 0 })
    //         }
    //     }
    // },[])

    useEffect(()=>{
        const newLength = (((storedCanvasImages?.filter((state)=>state?.imgURL !== null)?.length) * 100) / storedCanvasImages?.length);
        setImageProcessedLength(newLength > 100 ? 100 : newLength)
    },[storedCanvasImages])

    useEffect(() => {
        const interval = setInterval(() => {
            
            if (storedCanvasImages?.length !== ((imageProcessedLength / 100) * storedCanvasImages?.length)) {
                setStoreImageStatus(true);
                console.log("Image Store occurs",);
            }
        }, 4000); // Every 4 seconds
    
        return () => clearInterval(interval);
    }, [imageProcessedLength]);

    return (
        <div className='custom-product-create position-relative'>

            {/* Loader For Product Create */}
            {
                productFormSubmitStatus &&
                <div className='position-absolute w-100 h-100'>
                    <div
                        className='position-sticky top-0 start-0 w-100 flex-center-align'
                        style={{
                            backgroundColor: 'rgba(255,255,255,1)',
                            zIndex: "9999",
                            height: "100vh"
                        }}
                    >
                        <div className='d-grid text-center text-success gap-1'>
                            <div className='w-100 aspect-2-1 aspect-md-2-1'>
                                <Loader />
                            </div>
                        </div>
                    </div>
                </div>
            }

            <Container className='py-4'>
                {
                    (location?.state?.data?.updateProduct == null) &&
                    <div className='flex-between-align custom-header mb-3'>
                        <h4 className='fw-semibold'>{product?.product?.name}</h4>
                        <Redirection
                            redirectValue={{
                                url: `/product/${params?.productId}/${params?.providerId}/canvas`,
                                data: { 'editCanvas': location?.state?.data?.canvas, 'canvasWidth': location?.state?.data?.canvasWidth, 'varientData': postVariant }
                            }}
                        >
                            <Link className='flex-center-align gap-2'>
                                <i className="bi bi-pencil-fill fs-14"></i>
                                Edit Design
                            </Link>
                        </Redirection>
                    </div>
                }
                <Row>
                    <Col xl='12'>
                        <Card className='rounded-0 mb-4'>
                            <CardHeader className='bg-transparent py-3'>
                                <h4 className='mb-0 fw-bolder'>Product Preview & Mockup</h4>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col xl='5' md='6' xs='12'>
                                        <div
                                            className='preview-image border border-2 border-dashed'
                                        >
                                            {
                                                [currentMockupObj]?.map((row) => {
                                                    const newRow = (Object.keys(row)?.length == 0) ? product?.product?.previewImagesData[0] : row;
                                                    const imgData = productForm?.designData?.find(e => e?.value == (newRow?.value));
                                                    const tempMockupData = location?.state?.data?.product?.previewImagesData?.find((state) => state?.preview === newRow?.preview)?.mockupData
                                                    return (
                                                        <ProductPreviewImage
                                                            colorCode={newRow?.code ?? '#ffffff'}
                                                            isCustom={location?.state?.data?.product?.productCanvasType == "CUSTOM" ? true : false}
                                                            loader={loaderMockup}
                                                            objectData={{
                                                                ratioData: imgData?.ratioData ?? {},
                                                                // preview: newRow?.preview || null,
                                                                imgURL: imgData?.imgURL || null,
                                                                mockupData: tempMockupData ?? {},
                                                                // mockupData: newRow?.mockupData ?? {},
                                                                preview: (primaryPreview?.type == "previewImagesData")
                                                                    ? newRow?.preview
                                                                    : (primaryPreview?.type == "customMockupImages")
                                                                        ? mockupData[primaryPreview?.index]?.preview
                                                                        : null,
                                                                // imgURL: (primaryPreview?.type == "previewImagesData") ? imgData?.imgURL ?? defaultTransparentImage : defaultTransparentImage,
                                                                imgURL: (primaryPreview?.type == "previewImagesData") ? imgData?.imgURL ?? defaultTransparentImage : defaultTransparentImage,
                                                            }}
                                                        />
                                                    )
                                                })
                                            }
                                        </div>
                                    </Col>
                                    <Col xl='7' md='6' xs='12'>
                                        <Row className='gy-2'>
                                            {
                                                colorVariantData?.length> 0 &&
                                                <Col xl='12' className='custom-scrollbar'>
                                                    <div className='fs-20 fs-md-18 fs-sm-16 fw-semibold mb-2 mt-2 mt-md-0'>
                                                        <span>Color Images</span>
                                                    </div>
                                                    <div className='custom-color-scroll d-flex gap-2 gap-sm-3 mb-3'>
                                                        {
                                                            colorVariantData?.map((row, index) => <>
                                                                <div
                                                                    key={index}
                                                                    className={`pointer`}
                                                                    onClick={() => {
                                                                        toggleLoader();
                                                                        productForm.defaultColor = row?.code ?? ''
                                                                        setPrimaryPreview({ type: "previewImagesData", index: 0 })
                                                                        setProductForm({ ...productForm })
                                                                        manageMockupDetail(row)
                                                                    }}
                                                                >
                                                                    <div className=' sm-preview-img position-relative'>
                                                                        <img
                                                                            // src={SERVER_URL + row?.preview}
                                                                            src={row?.preview}
                                                                            style={{ objectFit: "cover", backgroundColor: `${row?.code ?? '#ffffff'}` }}
                                                                            alt={productForm?.productName + ' ' + row?.label}
                                                                            className={`w-100 h-100 rounded-1 border ${row?.code == currentMockupObj?.code ? 'border-2 border-dark' : ''}`}
                                                                        />
                                                                        {productForm?.defaultColor == row?.code && (
                                                                            <span className='position-absolute top-0 end-0 fs-10 badge bg-success border-0 rounded-0'>Default</span>
                                                                        )}
                                                                    </div>
                                                                    <div
                                                                        className='bg-transparent fs-12 fs-sm-10 fw-semibold text-center h-25 pt-1'
                                                                        style={{ color: '#808c98' }}
                                                                    >
                                                                        {row?.label}
                                                                    </div>
                                                                </div>
                                                            </>)
                                                        }
                                                    </div>
                                                </Col>
                                            }
                                            <Col xl='12'>
                                                <div className='fs-20 fs-md-18 fs-sm-16 fw-semibold mb-2 mt-2 mt-md-0'>
                                                    <span>Mockup Images</span>
                                                </div>
                                                <div className='d-flex flex-wrap gap-2 gap-sm-3 mb-3'>
                                                    {
                                                        product?.product?.previewImagesData?.map((row, index) => {
                                                            const imgData = productForm?.designData?.find(e => e?.value == row?.value);
                                                            const tempMockupData = location?.state?.data?.product?.previewImagesData?.find((state) => state?.preview === row?.preview)?.mockupData
                                                            return (
                                                                <div
                                                                    key={index}
                                                                    className={`position-relative sm-preview-img pointer rounded-1 border mt-2 ${(primaryPreview?.type == "previewImagesData" && primaryPreview?.index == index) ? 'border-2 border-dark' : 'border-2'}`}
                                                                    onClick={() => {
                                                                        if (location?.state?.data?.product?.productCanvasType == "CUSTOM") {
                                                                            toggleLoader();
                                                                            const obj = {
                                                                                preview: row?.preview,
                                                                                value: imgData?.value,
                                                                                index,
                                                                                code: currentMockupObj?.code ?? "#ffffff",
                                                                                isPreview: true,
                                                                                mockupData: tempMockupData ?? {}
                                                                            }

                                                                            const tempRow = obj?.isPreview ? { ...currentMockupObj, ...obj } : obj;

                                                                            if (tempRow?.preview) {
                                                                                const designData = productForm?.designData?.find(e => e?.value == obj?.value)?.data
                                                                                tempRow.designData = designData ?? []
                                                                                setCurrentMockupObj({ ...tempRow })
                                                                                setPrimaryPreview({ type: "previewImagesData", index })
                                                                            }
                                                                        }
                                                                    }}
                                                                >
                                                                    <ProductPreviewImage
                                                                        isList={true}
                                                                        isCustom={location?.state?.data?.product?.productCanvasType == "CUSTOM" ? true : false}
                                                                        colorCode={currentMockupObj?.code ?? '#ffffff'}
                                                                        objectData={{
                                                                            mockupData: tempMockupData ?? {},
                                                                            ratioData: imgData?.ratioData ?? {},
                                                                            preview: row?.preview || null,
                                                                            imgURL: imgData?.imgURL ?? defaultTransparentImage,
                                                                            imgURL: imgData?.imgURL || null,
                                                                            value: imgData?.value,
                                                                            index,
                                                                            label: imgData?.label
                                                                        }}
                                                                        isStoreCanvasImage={storeImageStatus}
                                                                        storeCanvasImage={(e) => { 
                                                                            handlePreviewImagesStore({ imgURL: e, index }) 
                                                                        }}
                                                                        // selectionData={index}
                                                                        loader={subLoaderMockup}
                                                                        onPrevSelect={(e) => {
                                                                            manageMockupDetail({ ...e, code: currentMockupObj?.code ?? '#ffffff', isPreview: true })
                                                                            setPrimaryPreview({ type: "previewImagesData", index: e?.index })
                                                                        }}
                                                                    />
                                                                    {(primaryPreview?.type == "previewImagesData" && primaryPreview?.index == index) && (
                                                                        <span className='position-absolute top-0 start-0 fs-10 badge bg-success border-0 rounded-0'>Default</span>
                                                                    )}
                                                                </div>
                                                            )
                                                        })
                                                    }

                                                    {/* {
                                                        mockupData?.map((file, index) => (
                                                            <div
                                                                key={index}
                                                                className={`position-relative sm-preview-img pointer rounded-1 border mt-2 flex-center-align hover-increase  ${(primaryPreview?.type == "customMockupImages" && primaryPreview?.index == index) ? 'border-2 border-dark' : ''}`}
                                                            >
                                                                <img
                                                                    src={file?.preview} // Generate preview URL for the image
                                                                    alt={file.name}
                                                                    className='h-100 w-100 object-cover'
                                                                    onClick={() => {
                                                                        setPrimaryPreview({ type: "customMockupImages", index: index })
                                                                    }}
                                                                />

                                                                {(primaryPreview?.type == "customMockupImages" && primaryPreview?.index == index) ?
                                                                    <span className='position-absolute top-0 start-0 fs-10 badge bg-success border-0 rounded-0'>Default</span>
                                                                    : <span
                                                                        className='delete-btn'
                                                                        onClick={handleDeleteMockup(index)}
                                                                    >
                                                                        <i className='bi bi-x-lg fs-12'></i>
                                                                    </span>
                                                                }
                                                            </div>
                                                        ))
                                                    }

                                                    <label
                                                        className={`position-relative sm-preview-img pointer rounded-1 border mt-2 flex-center-align hover-increase`}
                                                        htmlFor="mockups"
                                                    >
                                                        <div className='d-grid text-center gap-2'>
                                                            <FiPlusCircle className='fs-35 color-temp mx-auto' />
                                                            <span className='fs-10 fw-semibold lh-1'>Add Custom Mockup</span>
                                                        </div>
                                                        <input
                                                            type='file'
                                                            className='w-100 d-none'
                                                            id='mockups'
                                                            accept="image/*"
                                                            multiple
                                                            onChange={handleMockupData}
                                                        />
                                                    </label> */}
                                                </div>
                                            </Col>
                                            <Col xl='12'>
                                                <div className='fs-20 fs-md-18 fs-sm-16 fw-semibold mb-2 mt-2 mt-md-0'>
                                                    <span>Custom Mockup Images</span>
                                                </div>
                                                <div className='d-flex flex-wrap gap-2 gap-sm-3 mb-3'>
                                                    {
                                                        mockupData?.map((file, index) => (
                                                            <div
                                                                key={index}
                                                                className={`position-relative sm-preview-img pointer rounded-1 border mt-2 flex-center-align hover-increase  ${(primaryPreview?.type == "customMockupImages" && primaryPreview?.index == index) ? 'border-2 border-dark' : ''}`}
                                                            >
                                                                <img
                                                                    src={file?.preview} // Generate preview URL for the image
                                                                    alt={file.name}
                                                                    className='h-100 w-100 object-cover'
                                                                    onClick={() => {
                                                                        setPrimaryPreview({ type: "customMockupImages", index: index })
                                                                    }}
                                                                />

                                                                {(primaryPreview?.type == "customMockupImages" && primaryPreview?.index == index) ?
                                                                    <span className='position-absolute top-0 start-0 fs-10 badge bg-success border-0 rounded-0'>Default</span>
                                                                    : <span
                                                                        className='delete-btn'
                                                                        onClick={handleDeleteMockup(index)}
                                                                    >
                                                                        <i className='bi bi-x-lg fs-12'></i>
                                                                    </span>
                                                                }
                                                            </div>
                                                        ))
                                                    }

                                                    <label
                                                        className={`position-relative sm-preview-img pointer rounded-1 border mt-2 flex-center-align hover-increase`}
                                                        htmlFor="mockups"
                                                    >
                                                        <div className='d-grid text-center gap-2'>
                                                            <FiPlusCircle className='fs-35 color-temp mx-auto' />
                                                            <span className='fs-10 fw-semibold lh-1'>Add Custom Mockup</span>
                                                        </div>
                                                        <input
                                                            type='file'
                                                            className='w-100 d-none'
                                                            id='mockups'
                                                            accept="image/*"
                                                            multiple
                                                            onChange={handleMockupData}
                                                        />
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    {/* {
                                        location?.state?.data?.product?.productCanvasType == "CUSTOM" &&
                                        <Col xl='12' md='12' xs='12'>
                                            <div className="card p-3 gap-1 d-grid rounded-0 mt-3">
                                                <div className='flex-between-align'>
                                                    <h5>Image Processor</h5>
                                                    <p>Progress: {imageProcessedLength}%</p>
                                                </div>
                                                <ProgressBar variant="success" now={imageProcessedLength} />
                                            </div>
                                        </Col>
                                    } */}
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className='rounded-0 mb-4'>
                            <CardHeader className='bg-transparent py-3'>
                                <h4 className='mb-0 fw-bolder'>Product Detail</h4>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col xl='12'>
                                        <div className='form-group mb-3'>
                                            <Label className='form-label'>Title</Label>
                                            <input
                                                className={`form-control rounded-0 fs-6 py-2 ${(err?.name || err?.productName) ? "border-danger" : ""}`}
                                                placeholder='product title'
                                                value={productForm?.productName}
                                                name='productName'
                                                required
                                                onChange={(e) => {
                                                    delete err?.name;
                                                    setErr(err)
                                                    handleInputChange({ name: e.target.name, value: e.target.value, required: true, pattern: pattern?.productSlug, type: "productSlug" })
                                                }}
                                            />
                                            {(err?.name || err?.productName) && <span className='text-danger fs-12 fw-semibold fs-sm-10'>*{err?.name || err?.productName}</span>}
                                        </div>
                                    </Col>
                                    <Col xl='12'>
                                        <div className='form-group'>
                                            <Label className='form-label'>Description</Label>
                                            <Editor
                                                value={productForm?.description}
                                                onChange={(e) => {
                                                    productForm.description = e?.target?.value
                                                    setProductForm({ ...productForm })
                                                }}
                                            />
                                        </div>
                                    </Col>
                                    {/* {
                                        product?.provider?.brandingStatus > 0 && (
                                            <Col xl='12'>
                                                <div className='d-flex gap-1 flex-column my-3'>
                                                    <span className='fw-semibold'>Do you want to enable custom branding services for this product?</span>
                                                    <div>
                                                        <Input
                                                            id='branding'
                                                            type='checkbox'
                                                            checked={brandingStatus}
                                                            onChange={(e) => setBrandingStatus(!brandingStatus)}
                                                        />&nbsp;
                                                        <Label for="branding" className="mb-0 pointer">Yes</Label>
                                                    </div>
                                                </div>
                                            </Col>
                                        )
                                    } */}
                                </Row>
                            </CardBody>
                        </Card>
                        {
                            store?.storeType == 'SNAPSTORE' && (
                                <>
                                    <Card className='rounded-0 mb-4'>
                                        <CardHeader className='bg-transparent py-3'>
                                            <h4 className='mb-0 fw-bolder'>Meta Details</h4>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col xl='12'>
                                                    <div className='form-group mb-3'>
                                                        <Label className='form-label'>Meta Title</Label>
                                                        <input
                                                            className={`form-control rounded-0 fs-6 py-2`}
                                                            placeholder='product meta title'
                                                            value={productForm?.metaTitle ?? productForm?.productName ?? ""}
                                                            required
                                                            onChange={(e) => {
                                                                productForm.metaTitle = e?.target?.value
                                                                setProductForm({ ...productForm })
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col xl='12'>
                                                    <div className='form-group d-grid gap-1'>
                                                        <Label className='form-label'>Meta Description</Label>
                                                        <textarea
                                                            className='seller-box form-control rounded-0 fs-6 py-2'
                                                            placeholder="Enter Meta description for better SEO"
                                                            value={productForm?.metaDescription ?? ""}
                                                            onChange={(e) => {
                                                                productForm.metaDescription = e?.target?.value
                                                                setProductForm({ ...productForm })
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                    <Card className='rounded-0 mb-4'>
                                        <CardHeader className='bg-transparent py-3'>
                                            <h4 className='mb-0 fw-bolder'>Search engine listing</h4>
                                        </CardHeader>
                                        <CardBody>
                                            <div className='d-grid gap-2'>
                                                <div className='d-flex gap-2 align-items-center'>
                                                    <div
                                                        className='aspect-1-1 temp-border-rad border border-secondary-subtle overflow-hidden flex-center-align'
                                                        style={{ width: "28px", padding: "2px" }}
                                                    >
                                                        <img src={store?.favicon ?? siteConfig.logo} alt="" className='w-100 h-100 object-contain' />
                                                    </div>
                                                    <div className='d-grid gap-0 ms-1'>
                                                        <h6 className='text-capitalize fs-14 fw-medium m-0'>
                                                            {store?.name}
                                                        </h6>
                                                        <p className='m-0 fs-12 lh-base new-p-color'>
                                                            {/* https://{(store?.customDomain?.length > 0 && !userData?.expired) ? store?.customDomain : store?.domain} {`>`} product {`>`} {handleRedirect(productForm?.productName)} */}
                                                            {store?.storeBaseURL}
                                                        </p>
                                                    </div>
                                                </div>
                                                <h5 className='fs-20 google-title m-0'>{productForm?.metaTitle ?? productForm?.productName ?? ""}</h5>
                                                <p className='m-0 fs-13 new-p-color'>{productForm?.metaDescription?.length > 0 ? productForm?.metaDescription : "{--- Enter meta description to see preview ---}"}</p>
                                                <h5 className='m-0 fs-13 new-p-color'>₹{Number(productForm?.price).toFixed(2)} INR</h5>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </>
                            )
                        }
                        {
                            store?.storeType == 'SHOPIFY' && (
                                <Card className='rounded-0 mb-4'>
                                    <CardHeader className='bg-transparent py-3'>
                                        <h4 className='mb-0 fw-bolder'>Type and tags</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col xl='12'>
                                                <div className='form-group mb-3'>
                                                    <Label className='form-label'>Type</Label>
                                                    <input
                                                        className={`form-control rounded-0 fs-6 py-2`}
                                                        placeholder='product type'
                                                        value={productForm?.productType ?? ""}
                                                        required
                                                        onChange={(e) => {
                                                            productForm.productType = e?.target?.value
                                                            setProductForm({ ...productForm })
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col xl='12'>
                                                <div className='form-group d-grid gap-1'>
                                                    <Label className='form-label mb-0'>Tags</Label>
                                                    <TagsInput
                                                        value={productForm?.productTags ?? []}
                                                        onChange={(e) => {
                                                            productForm.productTags = e
                                                            setProductForm({ ...productForm })
                                                        }}
                                                        placeHolder="enter product tags"
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            )
                        }
                        <Card className='rounded-0 mb-4'>
                            <CardHeader className='bg-transparent py-3'>
                                <h4 className='mb-0 fw-bolder'>Price & Variant</h4>
                            </CardHeader>
                            <CardBody>

                                {/*
                                <Row>
                                    <Col xl='3' md='6' xs='12'>
                                        <div className='form-group mb-3'>
                                            <Label className='form-label'>product price</Label>
                                            <input
                                                className='form-control rounded-0 fs-6 py-2'
                                                value={productForm?.providerPrice}
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                    <Col xl='3' md='6' xs='12'>
                                        <div className='form-group mb-3'>
                                            <Label className='form-label'>Design charges</Label>
                                            <input
                                                className='form-control rounded-0 fs-6 py-2'
                                                value={productForm?.providerDesignPrice}
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                    <Col xl='3' md='6' xs='12'>
                                        <div className='form-group mb-3'>
                                            <Label className='form-label'>provider Payble price</Label>
                                            <input
                                                className='form-control rounded-0 fs-6 py-2'
                                                value={productForm?.providerPayble}
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                    <Col xl='3' md='6' xs='12'>
                                        <div className='form-group mb-3'>
                                            <Label className='form-label'>platform charge</Label>
                                            <input
                                                className='form-control rounded-0 fs-6 py-2'
                                                value={productForm?.platformChargeAmount}
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl='4' md='4' xs='12'>
                                        <div className='form-group mb-3'>
                                            <Label className='form-label'>shipping charges</Label>
                                            <input
                                                className='form-control rounded-0 fs-6 py-2'
                                                value={productForm?.shippingCharge}
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                    <Col xl='4' md='4' xs='12'>
                                        <div className='form-group mb-3'>
                                            <Label className='form-label'>rto charges(ord return time)</Label>
                                            <input
                                                className='form-control rounded-0 fs-6 py-2'
                                                value={productForm?.rtoCharge}
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                    <Col xl='4' md='4' xs='12'>
                                        <div className='form-group mb-3'>
                                            <Label className='form-label'>payble amount</Label>
                                            <input
                                                className='form-control rounded-0 fs-6 py-2'
                                                value={productForm?.payblePrice}
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                */}

                                <Row xl={4} md={4} xs={1}>
                                    <Col>
                                        <div className='form-group mb-3'>
                                            <Label className='form-label d-flex justify-content-between align-items-center'>
                                                <span>Payable amount</span>
                                                <div className='position-relative'>
                                                    <span onClick={() => setPriceCountBoxStatus(!priceCountBoxStatus)} className="bi bi-info-circle-fill mr-1"></span>
                                                    {
                                                        (priceCountBoxStatus) && (
                                                            <div className='position-absolute border rounded-2 p-2 bg-white bottom-100 end-50 fs-12' style={{ width: '250px!important;' }}>
                                                                <div className='d-flex gap-3 justify-content-between ml-2'>
                                                                    <span className='text-nowrap'>Provider price</span>
                                                                    <span className='fw-bold'>{productForm?.providerPrice}</span>
                                                                </div>
                                                                <div className='d-flex gap-3 justify-content-between ml-2'>
                                                                    <span className='text-nowrap'>Design charges</span>
                                                                    <span className='fw-bold'>{productForm?.providerDesignPrice}</span>
                                                                </div>
                                                                {
                                                                    taxCountable && (
                                                                        <div className='d-flex gap-3 justify-content-between ml-2'>
                                                                            <span className='text-nowrap'>Tax ({productForm?.taxPercentage}%)</span>
                                                                            <span className='fw-bold'>{productForm?.taxAmount}</span>
                                                                        </div>
                                                                    )
                                                                }
                                                                {
                                                                    !taxCountable && (
                                                                        <div className='d-flex gap-3 justify-content-between ml-2'>
                                                                            <span className='text-nowrap'>Tax collected on selling price({productForm?.taxPercentage}%)</span>
                                                                            <span className='fw-bold'>{productForm?.visibleTaxAmount}</span>
                                                                        </div>
                                                                    )
                                                                }
                                                                <div className='d-flex gap-3 justify-content-between ml-2'>
                                                                    <span className='text-nowrap'>Platform fee</span>
                                                                    <span className='fw-bold'>{productForm?.productPlatformChargeAmount}</span>
                                                                </div>
                                                                <div className='d-flex gap-3 justify-content-between ml-2'>
                                                                    <span className='text-nowrap'>Shipping as per actual</span>
                                                                    {/* <span className='fw-bold'>{productForm?.shippingCharge}</span> */}
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </Label>
                                            <input
                                                className='form-control rounded-0 fs-6 py-2'
                                                value={roundToTwoDecimalPlaces(Number(productForm.visiblePayblePrice) + Number(!taxCountable ? productForm?.visibleTaxAmount : 0))}
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className='form-group mb-3'>
                                            <Label className='form-label'>Profit ({keyCode?.inr})</Label>
                                            <input
                                                className='form-control rounded-0 fs-6 py-2'
                                                value={productForm?.profitInAmount}
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className='form-group mb-3'>
                                            <Label className='form-label d-flex align-items-end'>
                                                Selling Price
                                                {/* Selling Price{productForm?.taxPercentage > 0 && (<div className='text-dark fs-12 fw-semibold'>&nbsp;({productForm?.taxPercentage}% incl.)</div>)} */}
                                            </Label>
                                            <input
                                                className='form-control rounded-0 fs-6 py-2'
                                                required
                                                value={productForm?.price}
                                                onChange={(e) => {
                                                    productForm.price = e?.target?.value
                                                    setProductForm({ ...productForm })
                                                }}
                                                onBlur={(e) => {
                                                    productForm.price = productForm?.payblePrice > e?.target?.value ? productForm?.payblePrice : productForm?.price
                                                    if (productForm.price != productForm.previousPrice) {
                                                        Swal.fire({
                                                            title: `Do you want to apply this change to all variant??`,
                                                            icon: 'warning',
                                                            showCancelButton: true,
                                                            confirmButtonColor: '#04ac84',
                                                            cancelButtonColor: '#d33',
                                                            confirmButtonText: 'Confirm',
                                                            cancelButtonText: 'No',
                                                        }).then(async (result) => {
                                                            if (result.isConfirmed) {
                                                                productForm.previousPrice = productForm.price
                                                                productForm.mrp = productForm?.price > productForm?.mrp ? productForm?.price : productForm?.mrp
                                                                // productForm.mrp = productForm?.price
                                                                productForm.previousMrp = productForm?.mrp
                                                                setProductForm({ ...productForm })
                                                                updateVariantPrice({ from: 'price' })
                                                                manageProductPrice({ from: 'price', val: Number(productForm.price ?? 0) })
                                                            } else {
                                                                manageProductPrice({ from: 'price', val: Number(productForm?.previousPrice ?? 0) })
                                                            }
                                                        });
                                                    }
                                                }}
                                            // disabled
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className='form-group mb-3'>
                                            <Label className='form-label'>Set MRP</Label>
                                            <input
                                                className='form-control rounded-0 fs-6 py-2'
                                                value={productForm?.mrp}
                                                onChange={(e) => manageProductPrice({ from: 'mrp', val: parseFloat(e?.target?.value > 0 ? e?.target?.value : 0) })}
                                                onBlur={(e) => {
                                                    if (productForm.mrp != productForm.previousMrp) {

                                                        Swal.fire({
                                                            title: `Do you want to apply this MRP to all variant??`,
                                                            icon: 'warning',
                                                            showCancelButton: true,
                                                            confirmButtonColor: '#04ac84',
                                                            cancelButtonColor: '#d33',
                                                            confirmButtonText: 'Confirm',
                                                            cancelButtonText: 'No',
                                                        }).then(async (result) => {
                                                            if (result.isConfirmed) {
                                                                productForm.previousMrp = productForm.mrp
                                                                setProductForm({ ...productForm })
                                                                updateVariantPrice({ from: 'mrp' })
                                                            } else {
                                                                manageProductPrice({ from: 'mrp', val: Number(productForm?.previousMrp ?? 0) })
                                                            }
                                                        });
                                                    }
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    {
                                        productForm?.cartesianData && (
                                            <Col xl='12' className='cartesian'>
                                                <hr />
                                                <div className='table-responsive mb-4'>
                                                    <Table className='table table-centered table-hover align-middle table-nowrap mb-0'>
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={8}>
                                                                    <h5 className='mb-0 fs-sm-16'>Product Variant Cartesian</h5>
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                {selectedVariantData?.map((row) => <th className='fs-sm-14 text-capitalize'>{row}</th>)}
                                                                <th className='fs-sm-14'>Production cost</th>
                                                                {/* <th className='fs-sm-14'>Profit (%)</th> */}
                                                                <th className='fs-sm-14'>Profit ({keyCode?.inr})</th>
                                                                <th className='fs-sm-14'>Sale price</th>
                                                                <th className='fs-sm-14'>MRP</th>
                                                                {/* <th>Stock</th> */}
                                                                <th className='fs-sm-14'>Available</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                productForm?.cartesianData?.map((row, index) => <>
                                                                    <tr className={row?.isPrimary ? 'disabled' : ''}>
                                                                        {row?.variant?.map((val) => <td className='fs-sm-14'>{val?.label}</td>)}
                                                                        <th>
                                                                            <input
                                                                                className='form-control rounded-0 fs-sm-14'
                                                                                // value={row?.payblePrice}
                                                                                value={roundToTwoDecimalPlaces(Number(row?.visiblePayblePrice) + Number(row?.visibleTaxAmount ?? 0))}
                                                                                style={{ width: '100px' }}
                                                                                disabled
                                                                            />
                                                                        </th>
                                                                        {
                                                                            row?.isPrimary ? (
                                                                                <>
                                                                                    <th>
                                                                                        <input
                                                                                            className='form-control rounded-0 fs-sm-14'
                                                                                            value={row?.profitAmount}
                                                                                            style={{ width: '100px' }}
                                                                                            disabled
                                                                                        />
                                                                                    </th>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <th>
                                                                                        <input
                                                                                            type='number'
                                                                                            className='form-control rounded-0 fs-sm-14'
                                                                                            min='0'
                                                                                            value={row?.profitAmount}
                                                                                            style={{ width: '100px' }}
                                                                                            disabled
                                                                                        />
                                                                                    </th>
                                                                                </>
                                                                            )
                                                                        }
                                                                        <th>
                                                                            <input
                                                                                className='form-control rounded-0 fs-sm-14'
                                                                                value={row?.price}
                                                                                style={{ width: '100px' }}
                                                                                onChange={(e) => {
                                                                                    productForm.cartesianData[index].price = e?.target?.value ?? 0
                                                                                    setProductForm({ ...productForm })

                                                                                }}
                                                                                onBlur={(e) => manageVariantPrice({ index, value: Number(e?.target?.value), from: 'price' })}
                                                                            />
                                                                        </th>
                                                                        <th>
                                                                            <input
                                                                                className='form-control rounded-0 fs-sm-14'
                                                                                value={row?.mrp}
                                                                                style={{ width: '100px' }}
                                                                                onChange={(e) => manageVariantPrice({ index, value: e?.target?.value > 0 ? Number(e?.target?.value) : 0, from: 'mrp' })}
                                                                            />
                                                                        </th>
                                                                        <td>
                                                                            <label className="switch">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    checked={row?.isAvailable}
                                                                                    value={row?.isAvailable}
                                                                                    onChange={(e) => {
                                                                                        productForm.cartesianData[index].isAvailable = !row?.isAvailable
                                                                                        setProductForm({ ...productForm })
                                                                                    }}
                                                                                />
                                                                                <span className="slider"></span>
                                                                            </label>
                                                                        </td>
                                                                    </tr>
                                                                </>)
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </Col>
                                        )
                                    }
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className='rounded-0 mb-4'>
                            <CardBody>
                                <div className='d-flex gap-3 align-items-center justify-conten-end'>
                                    {
                                        productFormSubmitStatus ? (
                                            <Button
                                                className='text-capitalize btn btn-success rounded-0 py-2 px-3 fs-6'
                                                color='success'
                                                disabled
                                                type='button'
                                                style={{ minWidth: "139px" }}
                                            >
                                                Please wait..
                                            </Button>
                                        ) : (
                                            <Button
                                                className='btn btn-success rounded-0 py-2 px-3 fs-6'
                                                color='success'
                                                onClick={() => {
                                                    setProductFormSubmitStatus(true)
                                                    setTimeout(() => {
                                                        productFormSubmit({ productStatus: store?.storeType == "SHOPIFY" ? 0 : 1 })
                                                    }, 1200)
                                                }}
                                                type='button'
                                                disabled={productFormSubmitStatus || Object?.keys(err).length !== 0 || ((location?.state?.data?.product?.productCanvasType == "CUSTOM") ? (storedCanvasImages?.length !== ((imageProcessedLength / 100) * storedCanvasImages?.length)) : false)}
                                                style={{ minWidth: "139px" }}
                                            >
                                                {store?.storeType == "SHOPIFY" ? "Save as draft" : "Save Product"}
                                            </Button>
                                        )
                                    }
                                    {
                                        productFormSubmitStatus ? (
                                            <Button
                                                className='text-capitalize btn btn-success rounded-0 py-2 px-3 fs-6'
                                                color='success'
                                                disabled
                                                type='button'
                                                style={{ minWidth: "139px" }}
                                            >
                                                Please wait..
                                            </Button>
                                        ) :
                                            store?.storeType == "SHOPIFY" &&
                                            <Button
                                                className='btn btn-success flex-center-align gap-1 rounded-0 py-2 px-3 fs-6'
                                                color='success'
                                                onClick={() => {
                                                    setProductFormSubmitStatus(true)
                                                    setTimeout(() => {
                                                        productFormSubmit({ productStatus: 1 })
                                                    }, 1200)
                                                }}
                                                type='button'
                                                disabled={productFormSubmitStatus}
                                                style={{ minWidth: "139px" }}
                                            >
                                                <FaShopify className='text-light' />  Save & Publish to Shopify
                                            </Button>
                                    }
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <ToastContainer />
        </div>
    )
}

export default ProductCreate