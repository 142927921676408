import React from 'react';
import { useNavigate } from 'react-router-dom';

function Redirection({ redirectValue = {}, children }) {
  const navigate = useNavigate();

  const handleRedirect = () => {
    const { url, data } = redirectValue;
    if (url) {
      const kebabCaseStr = url.toLowerCase().replace(/-/g, '~').replace(/ /g, '-');
      
      navigate(`${kebabCaseStr}`, {
        state: { data }
      });
    }
  };

  return (
    <div onClick={handleRedirect}>
      {children}
    </div>
  );
}

export default Redirection;