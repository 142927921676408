import React from "react";
import { AgCharts } from "ag-charts-react";

const ChartExample = ({
    data = {
        data: getData(),
        series: [
            {
                type: "line",
                xKey: "quarter",
                yKey: "visit",
                yName: "Petrol",
            },
            {
                type: "line",
                xKey: "quarter",
                yKey: "order",
                yName: "Diesel",
            },
        ],
        axes: [
            {
                type: "category",
                position: "bottom", // X-axis
            },
            {
                type: "number",
                position: "left", // Y-axis
                interval: { step: 1 }
            },
        ],
    },
}) => {
    return <AgCharts options={data} />;
};

const getData = () => [
    { quarter: "Surat", visit: 3, order: 1 },
    { quarter: "Vadodara", visit: 0, order: 1 },
    { quarter: "Bharuch", visit: 5, order: 0 },
    { quarter: "Kutch", visit: 0, order: 1 },
];

export default ChartExample;
