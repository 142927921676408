import { createSlice } from '@reduxjs/toolkit';
import { storage } from '../../Config/Config';

const initialState = {
    value: JSON.parse(localStorage.getItem(storage.canvas)) || null
};

const canvasSlice = createSlice({
    name: 'canvas',
    initialState,
    reducers: {
        updateCanvas: (state, action) => {
            state.value = action.payload;
            localStorage.setItem(storage.canvas, JSON.stringify(state.value));
        },
        removeCanvas: (state) => {
            state.value = null;
            localStorage.removeItem(storage.canvas);
        },
    },
});

// Action creators are generated for each case reducer function
export const { updateCanvas, removeCanvas } = canvasSlice.actions;

export default canvasSlice.reducer;
