import { createSlice } from '@reduxjs/toolkit';
import { storage } from '../../Config/Config';

const initialState = {
    value: JSON.parse(localStorage.getItem(storage.domain)) || null
};

const domainSlice = createSlice({
    name: 'domain',
    initialState,
    reducers: {
        addDomain: (state, action) => {
            state.value = action.payload;
            localStorage.setItem(storage.domain, JSON.stringify(state.value));
        },
        updateDomain: (state, action) => {
            state.value = {
                ...state.value,
                ...action.payload,
            };
            localStorage.setItem(storage.domain, JSON.stringify(state.value));
        },
        removeDomain: (state) => {
            state.value = null;
            localStorage.removeItem(storage.domain);
        },
    },
});

// Action creators are generated for each case reducer function
export const { addDomain, updateDomain, removeDomain } = domainSlice.actions;

export default domainSlice.reducer;
