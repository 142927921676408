import React, { useEffect, useState } from "react";
import { LuGalleryThumbnails } from "react-icons/lu";
import { Accordion, Alert, Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { IoClose } from "react-icons/io5";
import { PiSubtitlesFill } from "react-icons/pi";
import { Label } from 'react-konva';
import { Input } from 'reactstrap';
import Editor from 'react-simple-wysiwyg';
import { keyCode, pattern } from "../../Config/Constant";
import { useSelector } from "react-redux";
import { frontEnd_API, header, headerImage, siteConfig } from "../../Config/Config";
import { RiSeoLine } from "react-icons/ri";
import { AiOutlineProduct } from "react-icons/ai";
import axios from "axios";
import { FaShopify } from "react-icons/fa6";
import zohoImg from '../../Assets/Images/zoho.png'
import { GoDotFill } from "react-icons/go";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

function CreateComboProduct() {

    const store = useSelector(state => state.store.value)
    const userData = useSelector((state) => state.user.value);
    const params = useParams();
    const comboProductCode = params?.comboProductCode ?? null;
    const [formData, setFormData] = useState({ thumbnails: [] });
    const [error, setError] = useState('');
    const [productData, setProductData] = useState([]);
    const [selectedProductData, setSelectedProductData] = useState([]);
    const [filters, setFilters] = useState({limit: 200, isComboProductList: true});   
    const navigate = useNavigate();

    const getComboDetail = async () => {
        try{
            const { data } = await axios.get(`${frontEnd_API?.comboProduct}/${comboProductCode}`, header)
            console.log("data::", data);
            setFormData({...data?.data})
            const newData = data?.data?.productData.map((item)=>{
                if(item?.id){
                    return{
                        id: item?.id,
                        price: item?.price
                    }
                }
            })
            setSelectedProductData([...newData])
        } catch (err) {
            console.error("error::", err);
        }
    }
    
    useEffect( () => {
        if(comboProductCode)
            getComboDetail();
    },[comboProductCode])
 
    const onDrop = (acceptedFiles) => {
        const validFiles = acceptedFiles.filter(file => 
            ['image/png', 'image/jpg', 'image/jpeg', 'image/webp'].includes(file.type) &&
            file.size <= 2 * 1024 * 1024
        );

        const maxLimit = 4 ;
        // if (validFiles.length + formData?.thumbnails.length > 4) {
        if ((validFiles?.length + (formData?.thumbnails?.length ?? 0) + (formData?.oldThumbnails?.length ?? 0)) > maxLimit) {
            setError((prevData)=>({...prevData, thumbnails: `You can upload a maximum of ${maxLimit} images.`}));
            return;
        }

        if (validFiles.length < 1) {
            setError((prevData)=>({...prevData, thumbnails: "Invalid file format or file size exceeds 2MB."}));
            return;
        }

        setFormData((prevData)=>({...prevData, thumbnails: [...formData?.thumbnails ?? [], ...validFiles]}))
        setError((prevData)=>({...prevData, thumbnails: ""}));
    };

    const removeImage = (index) => {
        const updatedImages = formData?.thumbnails.filter((_, i) => i !== index);
        setFormData((prevData)=>({...prevData, thumbnails: updatedImages}))
    };
    const removeOldImage = (index) => {
        const updatedImages = formData?.oldThumbnails.filter((_, i) => i !== index);
        setFormData((prevData)=>({...prevData, oldThumbnails: updatedImages}))
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'image/png, image/jpg, image/jpeg, image/webp',
        maxSize: 2 * 1024 * 1024,
    });

    const handleRedirect = (item) => {
        // const { url, data } = redirectValue;
        if (item) {
            const kebabCaseStr = item.replace(/'/g, "")?.trim()?.replace(/\s+/g, ' ')?.replace(/ - /g, '-')?.replace(/ /g, '-')?.toLowerCase();
            return kebabCaseStr;
        }
    };

    const handleInputChange = (val) => {
            setError((prevErr) => {
                const newErr = { ...prevErr };
                delete newErr[val?.name]; // Properly updating error state
    
                // Check for required fields
                if (val?.required && val?.value?.trim().length === 0) {
                    newErr[val?.name] = `${val?.name} is required`;
                }
    
                // Check regex pattern validation
                if (val?.pattern && val?.value?.trim().length > 0) {
                    const patternCheck = new RegExp(val?.pattern);
                    if (!patternCheck.test(val?.value)) {
                        if (val?.type === "productSlug") {
                            newErr[val?.name] = "Only letters, numbers, hyphens (-), underscores (_), single ('), and double (\") quotes are allowed.";
                        } else {
                            newErr[val?.name] = `Invalid pattern for ${val?.name}`;
                        }
                    }
                }
    
                return newErr; // Ensure state is updated
            });
    
            // Update storeData state properly
            setFormData((prevData) => ({ ...prevData, [val.name]: val.value }));
    };

    const getProductData = async () => {
        try {
            const response = await axios?.put(frontEnd_API?.storeProduct, { ...filters }, header);
            if (response?.status === 200) {
                setProductData([...response?.data?.data]);
                console.log("response::", response);
            }
        } catch (error) {
            setProductData([]);
            console.log("error::", error);
        }
    }

    const handleCheckboxChange = (product) => {
        const newData = selectedProductData.some((item) => item.id == product.id) 
        ? selectedProductData.filter((item) => item.id != product.id)
        : [...selectedProductData, product]
        setSelectedProductData([...newData]);
        handleComboPrice({data: newData})
    };

    useEffect(()=>{
        getProductData();
    },[filters])

    const handleComboPrice = ({value = formData?.price ?? 1, data = selectedProductData}) => {
        if(value > 0) {
            setFormData((prevData)=>({
                ...prevData,
                price: value
            }))
            let totalValue = 0;
            for (let i = 0; i < data?.length; i++) {
                let tempPrice = productData.find((item)=>item?.id == data[i]?.id)?.price
                totalValue += Number(tempPrice);
            }
            const newData = data.map((item)=>{
                let tempPrice = productData.find((state)=>state?.id == item?.id)?.price
                const getPrice = (Number(value) * Number(tempPrice)) / totalValue;
                return{
                    ...item,
                    price: getPrice.toFixed(2)
                }
            })
            setSelectedProductData([...newData])
        } else {
            setError((prevData)=>({...prevData, price: "Price should be greater then 0."}))
        }
    }

    const submitData = async () => {
        try{

            let totalValue = 0;
            for (let i = 0; i < selectedProductData?.length; i++) {
                let tempPrice = productData.find((item)=>item?.id == selectedProductData[i]?.id)?.price
                totalValue += Number(tempPrice);
            }

            const postData = {
                ...formData,
                mrp: totalValue > formData?.price ? totalValue : formData?.price,
                productData: [...selectedProductData]
            }

            const actionMethod = comboProductCode ? "put" : "post";
            delete postData?.comboCode
            delete postData?.id
            // delete postData?.comboProductCode
            console.log("postData::", postData);
                
            // return
            const data = await axios[actionMethod]((frontEnd_API?.comboProduct + (comboProductCode ? `/${comboProductCode}` : '')), {...postData}, headerImage);
            console.log("Data::", data);
            toast(`Combo ${comboProductCode ? "updated": "created"} successfully!`);
            navigate('/products/combo')
        } catch (err) {
            setError(err?.response?.data?.data)
            
            const errorMessage = (err?.response?.status == 406) 
            ? Object.values(err?.response?.data?.data).join(", ")
            : (err?.response?.data?.message ?? "Something went wrong!" );

            toast.error(errorMessage);
        }
    }
   
    document.title = `Seller Create Combo | ${siteConfig?.name}`;

  return (
    <div
      className="seller-product-home seller-bg-grey"
      style={{ minHeight: "100vh" }}
    >
      <div className="py-5 px-2 px-lg-3 d-grid">
        <Container>
          <Row>
            <Col className="col-12 p-0">
                <div className="d-grid gap-2">
                    <Card>
                        <Card.Header className="h4 fw-semibold text-center py-3"> {comboProductCode ? "Update" : "Create"} combo products </Card.Header>
                        <Card.Body className="d-grid gap-4 p-4">

                            {/* Start Thumbnails of combo */}
                            <Card className="custom-card-shadow">
                                <Card.Header className="fw-semibold"><LuGalleryThumbnails /> Combo Thumbnails</Card.Header>
                                <Card.Body>
                                        {/* <h3 className="text-center">Image Uploader</h3> */}
                                        <div {...getRootProps()} className="p-5 border border-success text-center bg-light rounded-2 cursor-pointer">
                                            <input {...getInputProps()} />
                                            <p className="text-muted m-0">Drag & drop some images here, or click to select images</p>
                                        </div>

                                        {(error?.thumbnails) && <Alert variant="danger" className="mt-3" onClose={() => setError((prevData)=>({...prevData,thumbnails: ""}))} dismissible>{error?.thumbnails}</Alert>}

                                        <div className={`d-flex flex-wrap gap-2 ${formData?.thumbnails?.length > 0?'mt-3':''}`}>
                                            {
                                                formData?.thumbnails?.length > 0 &&
                                                formData?.thumbnails.map((file, index) => (
                                                    <div key={index} className="position-relative">
                                                        <Image src={URL.createObjectURL(file)} thumbnail width={100} className="aspect-1-1 object-contain" />
                                                        <Button
                                                            variant="danger" 
                                                            size="sm" 
                                                            className="position-absolute top-0 end-0 m-1 px-1 py-0"
                                                            onClick={() => removeImage(index)}
                                                        >
                                                            <IoClose />
                                                        </Button>
                                                    </div>
                                                ))
                                            }
                                            {
                                                (comboProductCode) &&
                                                (formData?.oldThumbnails?.length > 0) &&
                                                formData?.oldThumbnails.map((file,index)=>{
                                                    return(
                                                        <div key={index} className="position-relative">
                                                        <Image src={file} thumbnail width={100} className="aspect-1-1 object-contain" />
                                                        <Button
                                                            variant="danger" 
                                                            size="sm" 
                                                            className="position-absolute top-0 end-0 m-1 px-1 py-0"
                                                            onClick={() => removeOldImage(index)}
                                                        >
                                                            <IoClose />
                                                        </Button>
                                                    </div>
                                                    )
                                                })
                                            }
                                        </div>
                                </Card.Body>
                            </Card>
                            {/* End Thumbnails of combo */}

                            {/* Start combo details */}
                            <Card className="custom-card-shadow">
                                <Card.Header className="fw-semibold"><PiSubtitlesFill /> Combo Details</Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col xl='12'>
                                            <div className='form-group mb-3'>
                                                <Label className='form-label'>Title</Label>
                                                <input
                                                    className={`form-control rounded-0 fs-6 py-2 ${(error?.title) ? "border-danger" : ""}`}
                                                    placeholder='Enter combo title'
                                                    value={formData?.title}
                                                    name='title'
                                                    required
                                                    onChange={(e) => {
                                                        delete error?.title;
                                                        setError(error)
                                                        handleInputChange({ name: e.target.name, value: e.target.value, required: true, pattern: pattern?.productSlug, type: "productSlug" })

                                                    }}
                                                />
                                                {(error?.title) && <span className='text-danger fs-12 fw-semibold fs-sm-10'>*{error?.title}</span>}
                                            </div>
                                        </Col>
                                        <Col xl='12'>
                                            <div className='form-group'>
                                                <Label className='form-label'>Description</Label>
                                                <Editor
                                                    value={formData?.description}
                                                    onChange={(e) => {
                                                        formData.description = e?.target?.value
                                                        setFormData({ ...formData })
                                                    }}
                                                    style={{ height: '250px' }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                            {/* End combo details */}

                            {/* Start combo meta details */}
                            <Card className="custom-card-shadow">
                                <Card.Header className="fw-semibold"><PiSubtitlesFill /> Combo Meta Details</Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col xl='12'>
                                            <div className='form-group mb-3'>
                                                <Label className='form-label'>Meta Title</Label>
                                                <input
                                                    className={`form-control rounded-0 fs-6 py-2`}
                                                    placeholder='Enter combo meta title'
                                                    value={formData?.metaTitle ?? ""}
                                                    required
                                                    onChange={(e) => {
                                                        formData.metaTitle = e?.target?.value
                                                        setFormData({ ...formData })
                                                    }}
                                                />
                                                {(error?.metaTitle) && <span className='text-danger fs-12 fw-semibold fs-sm-10'>*{error?.metaTitle}</span>}
                                            </div>
                                        </Col>
                                        <Col xl='12'>
                                            <div className='form-group d-grid gap-1'>
                                                <Label className='form-label'>Meta Description</Label>
                                                <textarea
                                                    className='seller-box form-control rounded-0 fs-6 py-2'
                                                    placeholder="Enter Meta description for better SEO"
                                                    value={formData?.metaDescription ?? ""}
                                                    onChange={(e) => {
                                                        formData.metaDescription = e?.target?.value
                                                        setFormData({ ...formData })
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                            {/* End combo meta details */}
                            
                            {/* Start SEO */}
                            <Card className='custom-card-shadow'>
                                <Card.Header className='fw-semibold'><RiSeoLine className="me-1" /> Search engine listing</Card.Header>
                                <Card.Body>
                                    <div className='d-grid gap-2'>
                                        <div className='d-flex gap-2 align-items-center'>
                                            <div
                                                className='aspect-1-1 temp-border-rad border border-secondary-subtle overflow-hidden flex-center-align'
                                                style={{ width: "28px", padding: "2px" }}
                                            >
                                                <img src={store?.favicon ?? siteConfig.logo} alt="" className='w-100 h-100 object-contain' />
                                            </div>
                                            <div className='d-grid gap-0 ms-1'>
                                                <h6 className='text-capitalize fs-14 fw-medium m-0'>
                                                    {store?.name}
                                                </h6>
                                                <p className='m-0 fs-12 lh-base new-p-color'>
                                                    {/* https://{(store?.customDomain?.length > 0 && !userData?.expired) ? store?.customDomain : store?.domain} {`>`} combos {`>`} {handleRedirect(formData?.metaTitle)} */}
                                                    {store?.storeBaseURL}
                                                </p>
                                            </div>
                                        </div>
                                        <h5 className='fs-20 google-title m-0'>{formData?.metaTitle ?? formData?.productName ?? ""}</h5>
                                        <p className='m-0 fs-13 new-p-color'>{formData?.metaDescription?.length > 0 ? formData?.metaDescription : "{--- Enter meta description to see preview ---}"}</p>
                                        <h5 className='m-0 fs-13 new-p-color'>₹{formData?.price?Number(formData?.price).toFixed(2):0} INR</h5>
                                    </div>
                                </Card.Body>
                            </Card>
                            {/* End SEO */}

                            {/* Start Select Products */}
                            <Card className='custom-card-shadow'>
                                <Card.Header className='fw-semibold'><AiOutlineProduct className="me-1" /> Select Products</Card.Header>
                                <Card.Body>
                                    <div className='d-grid gap-2'>
                                    <Accordion defaultActiveKey="0">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>Products Dropdown:</Accordion.Header>
                                            <Accordion.Body>
                                                <div className="d-grid gap-3 ">
                                                    <div className='d-flex align-items-center gap-2 gap-lg-3 px-2 px-lg-3 product-search-input'>
                                                        <label className='bi bi-search' htmlFor='text'></label>
                                                        <input
                                                            id='text'
                                                            type="text"
                                                            value={filters?.search}
                                                            className='py-2 fs-sm-12'
                                                            placeholder='Search by product name'
                                                            autoComplete='off'
                                                            onChange={(e) => {
                                                                setFilters((prevData)=>({
                                                                    ...prevData,
                                                                    search: e.target.value
                                                                }))
                                                            }}
                                                        />
                                                        <span
                                                            className={`custom-close-btn bi bi-x-lg me-2 ${(!filters?.search) && 'd-none'}`}
                                                            onClick={() => {
                                                                setFilters((prevData)=>({
                                                                    ...prevData,
                                                                    search: ""
                                                                }))
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="d-grid gap-3 custom-product-list-dropdown pe-3">
                                                        {
                                                            productData?.length > 0 ?
                                                            productData?.map((product, index)=>{
                                                                return(
                                                                    <label 
                                                                        key={index} 
                                                                        htmlFor={`product${index}`} 
                                                                        className="border border-1 rounded-2 p-2 cursor-pointer flex-start-align gap-2"
                                                                    >
                                                                        <input 
                                                                            type="checkbox"  
                                                                            id={`product${index}`}
                                                                            checked={selectedProductData.some((item) => item?.id == product?.id)}
                                                                            onChange={() => handleCheckboxChange({id: product?.id, price: product?.price})}
                                                                        />
                                                                        <Row className="align-items-center">
                                                                            <Col className="col-1">
                                                                                <div className='img-holder aspect-1-1 overflow-hidden border rounded-2 w-100'>
                                                                                    <Image  
                                                                                        style={product?.defaultColor ? { backgroundColor: `${product?.defaultColor}` } : {}} 
                                                                                        src={product?.thumbnail} 
                                                                                        alt={product?.name} 
                                                                                        className="w-100 h-100 object-cover"
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            <Col className="col-4 fs-14">
                                                                                <h6 className='fw-semibold m-0 d-flex gap-2 fs-14'>
                                                                                    <span>{product?.name}</span>
                                                                                    {
                                                                                        product?.shopifyProductId &&
                                                                                        <FaShopify className='color-temp fs-18' />
                                                                                    }
                                                                                    {
                                                                                        product?.zohoCommerceProductId &&
                                                                                        <img src={zohoImg} style={{ height: '20px' }} />
                                                                                    }
                                                                                </h6>
                                                                                <span className='p-color fs-14 fs-sm-12'>{product?.businessName}</span>
                                                                                <div className='d-flex gap-1 flex-wrap align-items-center fs-10 p-color'>
                                                                                    {/* {
                                                                                        product?.variantData.map((subItem, subIndex) => (
                                                                                            <>
                                                                                                <span key={subIndex} className='text-lowercase'>
                                                                                                    <span className='pe-1'>
                                                                                                        {subItem?.value}
                                                                                                    </span>
                                                                                                    {subItem?.label}
                                                                                                </span>
                                                                                                <GoDotFill className={`fs-12 fs-sm-10`} />
                                                                                            </>
                                                                                        ))
                                                                                    } */}
                                                                                    <span>
                                                                                        Total
                                                                                        <span className='px-1'>
                                                                                            {product?.cartesianCount}
                                                                                        </span>
                                                                                        variants
                                                                                    </span>
                                                                                </div>
                                                                            </Col>
                                                                            <Col className='col-4'>
                                                                                <Row className=''>
                                                                                    <Col className='col-12 col-lg-7 fs-12'>
                                                                                        <div className='d-flex flex-column gap-1 justify-content-start'>
                                                                                            {product?.categoryName?.map(e => <div>{e}</div>)}
                                                                                        </div>
                                                                                    </Col>
                                                                                    <Col className='col-12 col-lg-5 fs-12'>All in stock</Col>
                                                                                </Row>
                                                                            </Col>
                                                                            <Col className='col-3'>
                                                                                <Row className='fw-semibold'>
                                                                                    <Col className='col-12 col-lg-6 fs-14 fs-sm-12'>Min {keyCode?.inr}{product?.minPrice??product?.price}</Col>
                                                                                    <Col className='col-12 col-lg-6 fs-14 fs-sm-12'>Max {keyCode?.inr}{product?.maxPrice??product?.price}</Col>
                                                                                </Row>
                                                                            </Col>
                                                                        </Row>
                                                                    </label>
                                                                )
                                                            })
                                                            : <h4 className="m-0 text-center fw-semibold my-2 text-danger fst-italic">--- Product not found ---</h4>
                                                        }
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                    </div>
                                </Card.Body>
                            </Card>
                            {/* End Select Products */}

                            {/* Start Combo Price */}
                            <Card className="custom-card-shadow mt-3">
                                <Card.Header className="fw-semibold">{keyCode?.inr} Combo Price</Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col xl='12'>
                                            <div className='form-group mb-3 custom-remove-number'>
                                                <input
                                                    className={`form-control rounded-2 fs-20 py-2`}
                                                    placeholder='Enter combo price'
                                                    value={formData?.price ?? 1}
                                                    required
                                                    type="number"
                                                    onKeyDown={(e) => {
                                                        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    onChange={(e) => {
                                                        delete error?.price;
                                                        setError(error);
                                                        handleComboPrice({value: e?.target?.value});
                                                    }}
                                                    disabled={selectedProductData?.length <= 0}
                                                />
                                                {(error?.price) && <span className='text-danger fs-12 fw-semibold fs-sm-10'>*{error?.price}</span>}
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                            {/* End Combo Price */}
                            
                            {/* Start Selected Products */}
                            <Card className='custom-card-shadow'>
                                <Card.Header className='fw-semibold'><AiOutlineProduct className="me-1" /> Selected Products</Card.Header>
                                <Card.Body>
                                    <div className='d-grid gap-2'>
                                        <div className="d-grid gap-3 custom-product-list-dropdown pe-3">
                                            {
                                                selectedProductData?.length > 0 ?
                                                selectedProductData?.map((tempProduct, index)=>{
                                                    
                                                    const product = productData?.find((item)=>item?.id == tempProduct?.id);
                                                    
                                                    return(
                                                        <div 
                                                            key={index} 
                                                            className="border border-1 rounded-2 p-2 flex-start-align gap-2"
                                                        >
                                                            <Button
                                                                variant="danger" 
                                                                size="sm" 
                                                                className="m-1 px-1 py-0"
                                                                onClick={() => handleCheckboxChange(product)}
                                                            >
                                                                <IoClose />
                                                            </Button>
                                                            <Row className="align-items-center">
                                                                <Col className="col-1">
                                                                    <div className='img-holder aspect-1-1 overflow-hidden border rounded-2 w-100'>
                                                                        <Image  
                                                                            style={product?.defaultColor ? { backgroundColor: `${product?.defaultColor}` } : {}} 
                                                                            src={product?.thumbnail} 
                                                                            alt={product?.name} 
                                                                            className="w-100 h-100 object-cover"
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col className="col-4 fs-14">
                                                                    <h6 className='fw-semibold m-0 d-flex gap-2 fs-14'>
                                                                        <span>{product?.name}</span>
                                                                        {
                                                                            product?.shopifyProductId &&
                                                                            <FaShopify className='color-temp fs-18' />
                                                                        }
                                                                        {
                                                                            product?.zohoCommerceProductId &&
                                                                            <img src={zohoImg} style={{ height: '20px' }} />
                                                                        }
                                                                    </h6>
                                                                    <span className='p-color fs-14 fs-sm-12'>{product?.businessName}</span>
                                                                    <div className='d-flex gap-1 flex-wrap align-items-center fs-10 p-color'>
                                                                        <span>
                                                                            Total
                                                                            <span className='px-1'>
                                                                                {product?.cartesianCount}
                                                                            </span>
                                                                            variants
                                                                        </span>
                                                                    </div>
                                                                </Col>
                                                                <Col className='col-2'>
                                                                    <Row className=''>
                                                                        <Col className='col-12 fs-12'>
                                                                            <div className='d-flex flex-column gap-1 justify-content-start'>
                                                                                {product?.categoryName?.map(e => <div>{e}</div>)}
                                                                            </div>
                                                                        </Col>
                                                                        {/* <Col className='col-12 col-lg-5 fs-12'>All in stock</Col> */}
                                                                    </Row>
                                                                </Col>
                                                                <Col className='col-2'>
                                                                    <Row className='fw-semibold'>
                                                                        <Col className='col-12 col-lg-6 fs-12'>Min {keyCode?.inr}{product?.minPrice??product?.price}</Col>
                                                                        <Col className='col-12 col-lg-6 fs-12'>Max {keyCode?.inr}{product?.maxPrice??product?.price}</Col>
                                                                    </Row>
                                                                </Col>
                                                                <Col className='col-3'>
                                                                    <Row className='fw-semibold'>
                                                                        <Col className='col-12 fs-14 fs-sm-12 flex-between-align justify-content-end gap-3'>
                                                                            <span className="text-success">You get</span>
                                                                            <div className="flex-start-align text-success gap-1">
                                                                                {keyCode?.inr}<input 
                                                                                    type="number" 
                                                                                    value={tempProduct?.price ?? product?.price} 
                                                                                    className="rounded-2 text-success fw-semibold p-1 text-center" 
                                                                                    style={{width: "100px"}}
                                                                                    readOnly 
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    )
                                                })
                                                : <h4 className="m-0 text-center fw-semibold my-2 text-danger fst-italic">Select products to add on combo</h4>
                                            }
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                            {/* Start Selected Products */}

                            <div className="d-flex gap-3 my-3">
                                <Button 
                                    variant="success" 
                                    className="w-50" 
                                    disabled={selectedProductData?.length <= 1} 
                                    onClick={submitData}
                                >
                                    {
                                        comboProductCode
                                        ?"Update"
                                        :"Submit"
                                    }
                                </Button>
                                <Button variant="secondary" className="w-50">Reset</Button>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer/>
    </div>
  );
}

export default CreateComboProduct;
