import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { FaArrowLeftLong, FaLocationArrow, FaLocationCrosshairs } from "react-icons/fa6";
import Dropdown from 'react-bootstrap/Dropdown';
import { MdEdit, MdOutlineDone } from "react-icons/md";
import { GoDotFill } from "react-icons/go";
import { BsDownload } from "react-icons/bs";
import { frontEnd_API, header, siteConfig } from '../../Config/Config';
import axios from 'axios';
import ProductPreviewImage from './Products/ProductPreviewImageComponent';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Invoices from '../../Components/Invoices';
import Loader from '../../Components/Loader';
import { BiSolidEdit } from 'react-icons/bi';
import { Tooltip } from 'react-tooltip';
import FormComponent from '../../Components/FormComponent';
import { pattern } from '../../Config/Constant';
import Swal from 'sweetalert2';
import { CiLocationArrow1 } from 'react-icons/ci';

function SellerSingleRtsOrderPage() {
    const params = useParams();
    const [orderData, setOrderData] = useState();
    const [globalData, setGlobalData] = useState();
    const [cnt, setCnt] = useState(0);
    const [show, setShow] = useState(false);
    const [loader, setLoader] = useState(true);
    const moment = require('moment');

    const getSingleOrder = async () => {
        try {
            const { data } = await axios.get(`${frontEnd_API.rtsOrder}/${params.orderAnotherNumber}`, header)
            console.log("data::", data);
            setOrderData(data?.data)
            if (["pending", "seller-accepted"].includes(data?.data?.orderStatus)) {
                setCnt(1);
            }
            else if (["processing", "cancelled", "printed", "ready-to-ship"].includes(data?.data?.orderStatus)) {
                setCnt(2);
            }
            else if (["dispatch", "in-transit"].includes(data?.data?.orderStatus)) {
                setCnt(3);
            }
            else if (["delivered"].includes(data?.data?.orderStatus)) {
                setCnt(4);
            }
            else if (["return-by-user", "user-cancelled"].includes(data?.data?.orderStatus)) {
                setCnt(0);
            }
            else if (["seller-cancelled"].includes(data?.data?.orderStatus)) {
                setCnt(-1);
            }
            else {
                setCnt(-2);
            }

            if (data?.status === 400) {
                window.location = '/orders'
            }
            setLoader(false);
        }
        catch (e) {
            console.log("e::", e);
            setLoader(false);
        }
    }

    const getGlobalData = async () => {
        try {
            const { data } = await axios.get(frontEnd_API?.getGlobal, header)
            console.log("data?.data::", data?.data);
            setGlobalData(data?.data)
        }
        catch (e) {
            console.log("Err::", e);
        }
    }

    useEffect(() => {
        getSingleOrder();
        getGlobalData();
    }, [])

    useEffect(() => {
        console.log("orderData::", orderData);
    }, [orderData])

    const statusBar = [
        {
            label: 'created',
            value: 1,
        },
        {
            label: 'Processing',
            value: 1,
        },
        {
            label: 'Shipped',
            value: 0,
        },
        {
            label: 'Delivered',
            value: 0,
        }
    ];

    document.title = `#${orderData?.orderId} | ${siteConfig?.name}`;

    return (
        <div className='py-2 custom-single-order-container'>
            <Container className='custom-single-order py-4'>
                <div className='d-grid'>
                    <div className='d-flex'>
                        <Link
                            to={(params?.orderStatus) ? `/returns/rts/${params?.childStatus}` : `/returns/rts/all`} className='custom-single-order-back-link'><FaArrowLeftLong />Back</Link>
                    </div>
                    <div className='d-grid gap-2 gap-lg-0 d-lg-flex justify-content-sm-between align-items-center pt-3'>
                        <div className='d-grid'>
                            <span className='h3 fw-semibold m-0 fs-sm-20 text-capitalize'>{(orderData?.orderType.toLowerCase() == "customer") ? "" : orderData?.orderType} Order #{orderData?.orderNumber}</span>
                            <span className='p-color fs-14 fs-sm-12'>{moment(orderData?.orderDate).format('MMM D, YYYY h:mm A')}</span>
                        </div>
                        <div className='d-grid d-sm-none w-100'>
                            <Dropdown className='w-100'>
                                <Dropdown.Toggle variant="light" id="dropdown-basic" className='w-100' style={{ backgroundColor: "white" }}>
                                    More
                                </Dropdown.Toggle>

                                <Dropdown.Menu className='w-100'>
                                    <Dropdown.Item className='d-flex align-items-center gap-2'><BsDownload />Download Invoice</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>

                {
                    loader ?
                        <div className='d-grid align-content-center' style={{ minHeight: "80vh" }}>
                            <div className='w-100 aspect-4-1 aspect-md-2-1'>
                                <Loader />
                            </div>
                        </div>
                        : <Row className='pt-3'>
                            <Col className='col-12 col-md-8 d-grid gap-3 align-content-start' >

                                <div className='d-grid seller-box p-3 p-lg-4 gap-3'>

                                    {/* Order Status Bar */}
                                    <div className={`d-block mt-2 d-sm-flex flex-between-align custom-order-status-bar mb-sm-3 ${(cnt <= 0) ? "d-flex w-100" : ""}`}>
                                        <div className='w-100 fw-semibold fs-14 bg-danger text-center text-light py-1 rounded-5'>
                                            Order is in RTS (Return to Stock) mode
                                        </div>
                                    </div>
                                    {/* Price List */}
                                    <div className='d-grid gap-1 gap-sm-2'>
                                        <div className='custom-price-list-table'>
                                            <div>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th className='fs-14 text-center'>Image</th>
                                                            <th className='fs-14 text-center'>Product name</th>
                                                            <th className='fs-14 text-center'>Qty</th>
                                                            <th className='fs-14 text-center'>Price</th>
                                                            <th className='fs-14 text-center'>Total</th>
                                                            <th className='fs-14 text-center'>Discount</th>
                                                            <th className='fs-14 text-center'>Sub Total</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            (orderData?.orderChildData?.length > 0) &&
                                                            orderData?.orderChildData.map((row, pIndex) => {
                                                                // console.log("row::", row);
                                                                const colorCode = row?.childOrderVariantData?.filter(e => ['color', 'colors', 'colour', 'colours']?.includes(e?.parentName))?.map(e => e?.variantCode)

                                                                return (
                                                                    <tr className='fs-14'>
                                                                        <td className='text-center p-0 m-0' style={{ width: "75px" }}>
                                                                            <div className='custom-order-product-img border-card'>
                                                                                <ProductPreviewImage
                                                                                    colorCode={colorCode ?? '#ffffff'}
                                                                                    isButtonShow={false}
                                                                                    customScale={10}
                                                                                    objectData={{
                                                                                        preview: row?.productThumbnail || null,
                                                                                    }} />
                                                                            </div>
                                                                        </td>
                                                                        <td className='ps-2 text-capitalize'>
                                                                            <div className="d-grid gap-1 align-content-center">
                                                                                {/* <span>{row?.productName.substring(0, 18) + '...'}</span> */}
                                                                                <span>{row?.productName}</span>
                                                                                <div className='d-flex flex-wrap gap-2'>
                                                                                    {
                                                                                        row?.childOrderVariantData?.length > 0 &&
                                                                                        row?.childOrderVariantData.map((variant, varIndex) => (
                                                                                            <span key={varIndex} className='text-capitalize fs-12 fs-sm-12'>{variant?.parentName} : <span className='text-uppercase'>{variant?.variantName}</span></span>
                                                                                        ))
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className='text-center'>{parseInt(row?.productQuantity)}</td>
                                                                        <td className='text-center'>{parseInt(row?.productPrice)}</td>
                                                                        <td className='text-center'>{parseInt(row?.productSubTotal)}</td>
                                                                        <td className='text-center'>{parseInt(row?.productDiscount) + parseInt(row?.productCouponAmount)}</td>
                                                                        <td className='text-center fw-semibold'>₹{parseInt(row?.productTotal)}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <th className='fs-16 text-center' colSpan={6}>Product Total</th>
                                                            <th className='fs-16 text-center'>₹{parseInt(orderData?.orderNetAmount)}</th>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Order Main Tracking */}

                                <div className='d-grid seller-box p-3 p-lg-4 gap-2'>
                                    <h5 className='my-2 fw-semibold color-temp d-flex align-items-center gap-3'><FaLocationCrosshairs /> Order Summary :</h5>
                                    <div className='d-grid timeline'>
                                        {
                                            (orderData?.orderTrackingData?.length > 0) &&
                                            orderData?.orderTrackingData.map((item, index) => {
                                                return (
                                                    <div key={index} className={`timeline-item ${((orderData?.orderTrackingData?.length - 1) === index) ? 'lastItem' : ''}`}>
                                                        <div
                                                            className={`timeline-icon circle-one mt-1 ms-1`}
                                                            style={{
                                                                backgroundColor: (index === 0) ? 'darkblue' : 'grey',
                                                                outlineColor: (index === 0) ? 'darkblue' : 'grey',
                                                            }}
                                                        ></div>
                                                        <div className='timeline-content'>
                                                            <span className='timestamp'>{moment(item?.updatedAt).format('MMM D, YYYY h:mm A')}</span>
                                                            <div>
                                                                <b className='text-capitalize'>{item?.trackingStatus}</b>
                                                                <span className='description'>{item?.trackingRemark}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </Col>
                            <Col className='col-12 col-md-4 gap-2 pt-4 pt-md-0 '>
                                <div className='position-sticky ' style={{ top: "25px" }}>
                                    {
                                        (orderData?.orderCourierCompanyId > 0) &&
                                        <div className='d-grid mb-4 seller-box align-content-start' style={{ overflow: 'hidden' }}>
                                            <div className='flex-between-align p-3 border-bottom'>
                                                <h5 className='fw-semibold m-0 fs-lg-16'>Logistics Details</h5>
                                                {
                                                    (orderData?.shipRocketOrderId) > 0 &&
                                                    <Button variant='success' className='fs-12 fs-sm-10 flex-between-align gap-2'><FaLocationArrow />Track</Button>
                                                }
                                            </div>
                                            <div className='d-grid p-3'>
                                                <div className='w-100 aspect-3-1'>
                                                    <img src={require('../../Assets/Gif/truck.c55607e57cfb92d8eca1.gif')} className='w-100 h-100' style={{ objectFit: "contain" }} alt="" />
                                                </div>
                                                <h6 className='fw-semibold m-0 fs-lg-16 text-center'>{orderData?.orderCourierDetail?.courierName}</h6>
                                            </div>
                                            {(orderData?.shipRocketOrderId) > 0 &&
                                                <div className='d-grid p-3'>
                                                    <section className='d-grid gap-2'>
                                                        <div className='d-grid d-flex justify-content-between gap-2 fs-12'>
                                                            <div className="d-grid">
                                                                <span>Shipping Order ID</span>
                                                                <span className='fw-semibold'>{orderData?.shipRocketOrderId}</span>
                                                            </div>
                                                            <div className="d-grid">
                                                                <span className='text-end'>Shipment ID</span>
                                                                <span className='fw-semibold text-end'>{orderData?.shipRocketShipmentId}</span>
                                                            </div>
                                                        </div>
                                                        <div className='d-grid d-flex justify-content-between gap-2 fs-12'>
                                                            <div className="d-grid">
                                                                <span>Routine Code</span>
                                                                <span className='fw-semibold'>{orderData?.shipRocketOrderTrackingDetail?.routing_code}</span>
                                                            </div>
                                                            <div className="d-grid">
                                                                <span className='text-end'>Pickup Date</span>
                                                                <span className='fw-semibold text-end'>{moment(orderData?.shipRocketOrderTrackingDetail?.pickup_scheduled_date).format('MMM D, YYYY h:mm A')}</span>
                                                            </div>
                                                        </div>
                                                    </section>
                                                </div>}
                                        </div>
                                    }
                                    <div className='d-grid seller-box align-content-start' style={{ overflow: 'hidden' }}>
                                        <div className='flex-between-align p-3 border-bottom'>
                                            <h5 className='fw-semibold m-0 fs-lg-16'>Customer</h5>
                                            {/* <Link className='fs-18 fs-lg-14 link-to-normal flex-between-align gap-2'><MdEdit />Edit</Link> */}
                                        </div>
                                        <div className='d-grid p-3 border-bottom'>
                                            <b className='fw-semibold fs-lg-16 fs-18 pb-1'>Personal details</b>
                                            <span className='fs-lg-12 fs-14 d-flex gap-1 fw-semibold'>
                                                <i className="bi bi-person-circle"></i>
                                                {orderData?.orderBillingName}
                                            </span>
                                            <span className='fs-lg-12 fs-14 d-flex gap-1'>
                                                <i className="bi bi-envelope"></i>
                                                {orderData?.orderBillingEmail}
                                            </span>
                                            <span className='fs-lg-12 fs-14 d-flex gap-1'>
                                                <i className="bi bi-telephone-fill"></i>
                                                {orderData?.orderBillingMobile}
                                            </span>
                                        </div>
                                        <div className='d-grid p-3'>
                                            <section className='flex-between-align'>
                                                <b className='fw-semibold pb-1 fs-lg-16 fs-18'>Shipping address</b>
                                                {
                                                    ["pending"]?.includes(orderData?.orderStatus) && (
                                                        <>
                                                            <Button
                                                                variant='success'
                                                                className='py-1 px-3 fs-14'
                                                                data-tooltip-id="my-info"
                                                                data-tooltip-content="Edit address"
                                                                onClick={() => setShow(true)}
                                                            >
                                                                <BiSolidEdit />
                                                            </Button>
                                                            <Tooltip id="my-info" place="top" />
                                                        </>
                                                    )
                                                }
                                            </section>
                                            <p className='m-0 fs-lg-12 fs-14 p-color d-flex flex-wrap text-capitalize'>
                                                {orderData?.orderShippingAddressLine1}, {orderData?.orderShippingAddressLine2}, {orderData?.orderShippingArea}, {orderData?.orderShippingCity}, {orderData?.orderShippingState}, {orderData?.orderShippingCountry} - {orderData?.orderShippingPincode}
                                            </p>
                                        </div>
                                    </div>
                                    <div className='mt-4 d-grid seller-box align-content-start' style={{ overflow: 'hidden' }}>
                                        <div className='flex-between-align p-3 border-bottom'>
                                            <h5 className='fw-semibold m-0 fs-lg-16'>Billing</h5>
                                            {/* <Link className='fs-16 fs-lg-14 fw-semibold link-to-normal seller-box flex-between-align  px-lg-3 py-lg-1 px-md-2 px-3 py-1 gap-2'><BsDownload />Download</Link> */}
                                        </div>
                                        <div className='flex-between-align p-3 border-bottom'>
                                            <span className='fs-lg-14'>Sub total</span>
                                            <b className='fs-lg-14 text-success'>₹{orderData?.orderSubTotalAmount}</b>
                                        </div>
                                        <div className='d-grid gap-1 p-3 border-bottom'>
                                            <div className='flex-between-align'>
                                                <span className='fs-lg-14'>Discount</span>
                                                <b className='fs-lg-14'>- ₹{orderData?.orderCouponAmount}</b>
                                            </div>
                                        </div>
                                        {/* <div className='d-grid gap-1 p-3 border-bottom'>
                                            <div className='flex-between-align'>
                                                <span className='fs-lg-14'>Total Shipping cost</span>
                                                <b className='fs-lg-14 text-danger'>- ₹{orderData?.orderShippingChargeAmount}</b>
                                            </div>
                                        </div> */}
                                        <div className='flex-between-align p-3'>
                                            <b className='fs-lg-14'>Total costs</b>
                                            <b className='color-info fs-lg-16 fs-18'>₹{orderData?.orderNetAmount}</b>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                }
            </Container>
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
                onHide={() => setShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className="text-center">
                        Update shipping address
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormComponent
                        valueData={{
                            orderShippingName: orderData?.orderShippingName,
                            orderShippingAddressLine1: orderData?.orderShippingAddressLine1,
                            orderShippingAddressLine2: orderData?.orderShippingAddressLine2,
                            orderShippingArea: orderData?.orderShippingArea,
                            orderShippingCity: orderData?.orderShippingCity,
                            orderShippingState: orderData?.orderShippingState,
                            orderShippingPincode: orderData?.orderShippingPincode
                        }}
                        action={frontEnd_API.orders}
                        actionType={`put`}
                        actionValue={params?.orderId}
                        submitFullWidth={true}
                        onSubmit={(e) => {
                            console.log("data from form::", e);
                            setShow(false);
                            setOrderData((prevData) => ({
                                ...prevData,
                                ...e?.data
                            }))
                        }}
                        fields={[
                            {
                                divClass: 'col-12 col-md-6',
                                name: 'Name',
                                key: 'orderShippingName',
                                required: true,
                                placeholder: "Enter coupon name"
                            },
                            {
                                divClass: 'col-12 col-md-6',
                                name: 'Address Line 1',
                                key: 'orderShippingAddressLine1',
                                required: true,
                                placeholder: "Enter amount or percentager value"
                            },
                            {
                                divClass: 'col-12 col-md-6',
                                name: 'Address Line 2',
                                key: 'orderShippingAddressLine2',
                                required: true,
                                placeholder: "Enter amount or percentager value"
                            },
                            {
                                divClass: 'col-12 col-md-6',
                                name: 'Area',
                                key: 'orderShippingArea',
                                required: true,
                                placeholder: "Enter limit condition"
                            },
                            {
                                type: 'select',
                                options: (globalData) && globalData,
                                divClass: 'col-12 col-md-4',
                                name: 'orderShippingState',
                                label: "State",
                                key: 'orderShippingState',
                                required: true,
                                onChange: {
                                    from: 'children',
                                    to: 'orderShippingCity',
                                    options: (globalData) && globalData
                                }
                            },
                            {
                                divClass: 'col-12 col-md-4',
                                type: 'select',
                                name: 'orderShippingCity',
                                label: "City",
                                key: 'orderShippingCity',
                                required: true,
                                options: globalData?.filter(item => item.value === orderData?.orderShippingState)[0]?.children
                            },
                            {
                                divClass: 'col-12 col-md-4',
                                name: 'Pincode',
                                key: 'orderShippingPincode',
                                required: true,
                                pattern: pattern?.pinCode
                            },
                        ]}
                    />
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button onClick={() => setShow(false)}>Close</Button>
                </Modal.Footer> */}
            </Modal>
        </div >
    )
}

export default SellerSingleRtsOrderPage