import React, { useEffect, useRef, useState } from 'react'
import { Accordion, Alert, Button, Card, Col, Container, Dropdown, Modal, Row, Tab, Tabs } from 'react-bootstrap'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { frontEnd_API, header, SERVER_URL, storage } from '../../Config/Config'
import axios from 'axios';
import CommonPagination from '../../Components/CommonPagination';
import { FaArrowLeftLong, FaMinus, FaPlus, FaRegCreditCard, FaTruckFast } from 'react-icons/fa6';
import Slider from 'react-slick';
import { useDispatch, useSelector } from 'react-redux';
import { addCart, deleteCart, removeCart, updateCart } from '../../Store/Slices/cartSlice';
import { toast, ToastContainer } from 'react-toastify';
import ProductPreviewImage from './Products/ProductPreviewImageComponent';
import { CiCirclePlus, CiWallet } from "react-icons/ci";
import Loader from '../../Components/Loader';
import { GiNotebook } from 'react-icons/gi';
import FormComponent from '../../Components/FormComponent';
import { pattern } from '../../Config/Constant';
import { FaDotCircle } from "react-icons/fa";
import moment from 'moment';
import { PiNoteBlank } from "react-icons/pi";
import Swal from 'sweetalert2';
import { HiOutlineCreditCard } from "react-icons/hi2";
import { AiOutlineBank } from "react-icons/ai";
import { initializeRazorpay } from '../../Components/InitializeRazorPay';
import { IoShirtOutline } from 'react-icons/io5';
import { TbTruckReturn } from 'react-icons/tb';
import { Tooltip } from 'react-tooltip';
import SelectProductTab from './CreateOrder/SelectProductTab';
// import SwiperCore, { Thumbs } from 'swiper';

// SwiperCore.use([Thumbs]);

function SellerCreateOrder() {

    const location = useLocation();
    // const { stateActiveTab, stateCartData } = location?.state;
    const { stateActiveTab = '', stateCartData = [] } = location?.state || {};
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    const [isOutOfStock, setIsOutOfStock] = useState(true);

    const [tabHolder, setTabHolder] = useState(stateActiveTab?.length > 0 ? stateActiveTab : "selectProduct");
    // const [tabHolder, setTabHolder] = useState(stateActiveTab?.length > 0 ? stateActiveTab : "shipping");
    // const [tabHolder, setTabHolder] = useState(stateActiveTab?.length > 0 ? stateActiveTab : "payment");
    const [product, setProduct] = useState();
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();
    const cartData = useSelector((state) => state.cart.value);
    const storeData = useSelector((state) => state.store.value);
    const [backColor, setBackColor] = useState();
    const [loader, setLoader] = useState(true);
    const [totalUser, setTotalUser] = useState(0);
    const [userList, setUserList] = useState([]);
    const [filters, setFilters] = useState({});
    const [selectedUser, setSelectedUser] = useState();
    const [apiData, setApiData] = useState();
    const [data, setData] = useState([]);
    const [paymentType, setPaymentType] = useState();
    const [orderData, setOrderData] = useState();
    const [newCartData, setNewCartData] = useState();
    const [show, setShow] = useState(false);
    const [otherPayment, setOtherPayment] = useState();
    const [innerTabValue, setInnerTabValue] = useState({
        tab:"products",
        value: "",
    });

    useEffect(() => {
        if (stateCartData?.length > 0) {
            dispatch(addCart(stateCartData))
        }
    }, [location?.state])

    // 
    // User List
    // 

    const getUserList = async () => {
        try {
            const response = await axios.put(frontEnd_API?.users, {...filters}, header)
            if (response?.status == 200) {
                setTotalUser(response?.data?.totalCount)
                setUserList(response?.data?.data)
                setLoader(false);
            }
        }
        catch (e) {
            console.error("Err::", e);
            setLoader(false);
        }
    }

    useEffect(() => {
        getUserList();
    }, [filters])

    // 
    // Cart Data
    // 

    const getCartList = async () => {
        const petaTemp = cartData?.map(e => ({
            ...e,
            variantData: (e?.variantData) ? Object.values(e?.variantData) : [],
        }))

        try {
            const { data } = await axios.put(frontEnd_API.getCartList, {
                "cartData": petaTemp,
                "couponCode": "",
                "couponAmount": 0
            }, header)
            setApiData(data?.data)
            const checkApplicable = data?.data?.couponData?.filter((item) => {
                return item?.limitCondition < data?.data?.subTotalAmount
            })
            const checkPending = (checkApplicable?.length > 0)
                ? data?.data?.couponData?.filter((item) => {
                    return !checkApplicable.includes(item);
                })
                : data?.data?.couponData
        }
        catch (e) {
            console.error("e::", e);
        }
    }

    useEffect(() => {
        getCartList();
    }, [cartData])

    const updateCartList = async (data = apiData?.cartData) => {
        const petaTemp = data?.map(e => ({
            ...e,
            variantData: (e?.variantData) ? Object.values(e?.variantData) : [],
        }))
        try {
            const { data } = await axios.put(frontEnd_API.getCartList, {
                "cartData": petaTemp,
                "couponCode": "",
                "couponAmount": 0
            }, header)
            setApiData(data?.data)
            localStorage?.setItem(storage?.cart, JSON.stringify(data?.data?.cartData))
            const checkApplicable = data?.data?.couponData?.filter((item) => {
                return item?.limitCondition < data?.data?.subTotalAmount
            })
            const checkPending = (checkApplicable?.length > 0)
                ? data?.data?.couponData?.filter((item) => {
                    return !checkApplicable.includes(item);
                })
                : data?.data?.couponData
        }
        catch (e) {
            console.error("e::", e);
        }
    }

    const deleteCartItem = (item) => {
        Swal.fire({
            title: `Are you sure to remove item from cart ?`,
            text: ``,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#04ac84',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'No',
        }).then(async (result) => {
            if (result.isConfirmed) {
                dispatch(deleteCart(item))
                apiData.cartData = apiData?.cartData?.filter((_, i) => i !== item)
                setApiData({ ...apiData })
                // getCartList();
                toast("Item removed from cart ! ")
            }
        });
    }

    const handleCartClear = () => {
        Swal.fire({
            title: `Are sure to clean card data ?`,
            text: ``,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#04ac84',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'No',
        }).then(async (result) => {
            if (result.isConfirmed) {
                dispatch(removeCart());
            }
        });
    }

    useEffect(() => {
        axios.get(frontEnd_API.getGlobal, header)
            .then((res) => {
                setData(res?.data?.data);
            })
            .catch((err) => {
                console.error("err::", err);
            })
    }, [])

    // 
    // Payment code
    // 

    const handleOrderProcess = () => {
        const checkCouponId = newCartData?.couponData.find((item) => item?.label === newCartData?.couponCode)
        const storeData = {
            "userId": selectedUser?.id,
            "couponId": (newCartData?.couponCode) ? checkCouponId.value : 0,
            "couponCode": newCartData?.couponCode,
            "orderSubTotalAmount": orderData?.orderSubTotalAmount,
            "orderDiscountAmount": orderData?.orderDiscountAmount,
            "orderCouponAmount": newCartData?.couponAmount,
            "orderGrossAmount": orderData?.orderGrossAmount,
            "orderShippingChargeAmount": orderData?.orderShippingChargeAmount,
            "orderTaxAmount": orderData?.orderTaxAmount,
            "orderNetAmount": orderData?.orderNetAmount,
            "orderPaymentMode": (paymentType === "COD") ? "COD" : 'ONLINE',
            // "orderPaymentModeName": (paymentType === "COD") ? "COD" : `${paymentType}`,
            "orderPaymentModeName": (paymentType === "COD") ? "COD" : `PREPAID`,
            // "orderPaymentTransactionNumber": (razorCode) ?? (Math.floor(1000000 + Math.random() * 9000000) + moment().format('YYYYMMDDhhmmss')).toString(),
            "orderPaymentTransactionNumber": "",

            "orderUPI": otherPayment?.orderUPI ?? "", // IF Mode Name is UPI
            "orderBankName": otherPayment?.orderBankName ?? "", // IF Mode Name is BANK
            "orderAccountHolderName": otherPayment?.orderAccountHolderName ?? "", // IF Mode Name is BANK
            "orderAccountNumber": otherPayment?.orderAccountNumber ?? "", // IF Mode Name is BANK
            "orderIfscCode": otherPayment?.orderIfscCode ?? "", // IF Mode Name is BANK

            "orderType": "CUSTOM",
            "orderBillingName": selectedUser?.address?.name,
            "orderBillingEmail": selectedUser?.address?.email,
            "orderBillingMobile": selectedUser?.address?.mobile,
            "orderBillingAddressLine1": selectedUser?.address?.addressLine1,
            "orderBillingAddressLine2": selectedUser?.address?.addressLine2,
            "orderBillingArea": selectedUser?.address?.addressLine2,
            "orderBillingCity": selectedUser?.address?.city,
            "orderBillingState": selectedUser?.address?.state,
            "orderBillingCountry": "INDIA",
            "orderBillingPincode": selectedUser?.address?.pinCode,
            "orderShippingName": selectedUser?.address?.name,
            "orderShippingEmail": selectedUser?.address?.email,
            "orderShippingMobile": selectedUser?.address?.mobile,
            "orderShippingAddressLine1": selectedUser?.address?.addressLine1,
            "orderShippingAddressLine2": selectedUser?.address?.addressLine2,
            "orderShippingArea": selectedUser?.address?.addressLine2,
            "orderShippingCity": selectedUser?.address?.city,
            "orderShippingState": selectedUser?.address?.state,
            "orderShippingCountry": "INDIA",
            "orderShippingPincode": selectedUser?.address?.pinCode,
            "orderChildData": orderData?.productData
        }
        // return
        postOrderDetails(storeData);
    }

    const postOrderDetails = (storeData) => {

        // return

        axios.post(frontEnd_API.orders, storeData, header)
            .then((res) => {
                dispatch(removeCart());
                setShow(true);
                setTimeout(() => {
                    navigate('/orders');
                }, 4200)
            })
            .catch((err) => console.error("Err::", err))
    }

    const handleTaxFromAPI = async (state) => {

        const tempState = state ?? selectedUser?.address?.state

        const tempProductData = cartData?.map((item)=> {
            if(item?.variantData){
                return {
                    ...item,
                    variantData: Object.values(item?.variantData)
                }
            } else {
                return {...item}
            }
            
        })
        try {
            const response = await axios.put(frontEnd_API?.getTaxList, {
                productData: tempProductData,
                state: tempState,
                couponCode: "",
                cartTotalAmount: apiData?.subTotalAmount
            }, header)
            if (response?.status == 200) {
                const data = response?.data?.data;
                setNewCartData({
                    ...apiData,
                    orderShippingChargeAmount: data?.orderShippingChargeAmount,
                    orderNetAmount: data?.orderNetAmount,
                    orderTaxAmount: data?.orderTaxAmount
                })
                setOrderData(data)
                setTabHolder("payment");
            }
        }
        catch (e) {
            Swal.fire(
                `Request failed`,
                `${e?.response?.data?.message}`,
                'error'
            );
            console.error("err::", e);
        }
    }

    return (
        <div className='seller-order p-0' style={{ minHeight: "100vh" }}>
            <Container className='seller-order-main-container pb-5'>
                <div className=' d-grid d-sm-flex justify-content-sm-between gap-3 py-4'>
                    <h3 className='fw-semibold d-flex gap-2 align-items-center m-0'><IoShirtOutline className='color-temp' />Create manual order :</h3>
                </div>
                <div className='seller-order-container p-2 p-sm-3'>
                    <Tabs
                        activeKey={tabHolder == "selectedProduct" ? "selectProduct" : tabHolder}
                        // activeKey={'shipping'}
                        id="fill-tab-example"
                        className="mb-3 custom-tabs custom-tabs-with-even justify-content-between"
                        onSelect={(k) => {
                            setTabHolder(k);
                            setThumbsSwiper(null);
                            if (k == "payment") {
                                handleTaxFromAPI();
                            } else if (k=="shipping"){
                                getCartList();
                                console.log("cartData::", cartData);
                            }
                        }}
                        fill
                    >
                        <Tab eventKey="selectProduct" title="1. Select Product">
                            <SelectProductTab 
                                tempTabValue={innerTabValue}
                                tabHolder={tabHolder}
                                setTabHolder={(e)=>setTabHolder(e)}
                                // setTabHolder={setTabHolder}
                                thumbsSwiper={thumbsSwiper}
                                setThumbsSwiper={setThumbsSwiper}
                            />
                        </Tab>
                        <Tab eventKey="selectUser" title="2. User Detail" disabled={cartData?.length > 0 ? false : true}>
                            <Container className='py-3 seller-product-page'>
                                <Row className='gy-3 gy-sm-4'>
                                    <Col className='col-12 custom-create-order-accordion'>
                                        <Accordion>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>
                                                    <span className='fw-semibold d-flex gap-2 align-items-center fs-18'>
                                                        <CiCirclePlus />Add new user
                                                    </span>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <FormComponent
                                                        action={frontEnd_API.users}
                                                        submitFullWidth={false}
                                                        submitBTNAlign={'justify-content-center'}
                                                        formSubmit={"User Created Successfully !"}
                                                        button={'Create User'}
                                                        onSubmit={(res) => {
                                                            getUserList();
                                                            setSelectedUser(res?.data);
                                                            setTabHolder("shipping")
                                                        }
                                                        }
                                                        fields={
                                                            [
                                                                {
                                                                    divClass: 'col-12 col-md-6',
                                                                    name: 'name',
                                                                    key: 'name',
                                                                    required: true,
                                                                },
                                                                {
                                                                    divClass: 'col-12 col-md-6',
                                                                    name: 'email',
                                                                    key: 'email',
                                                                    placeholder: 'sample@gmail.com',
                                                                    required: true,
                                                                    pattern: pattern.email,
                                                                },
                                                                {
                                                                    divClass: 'col-12 col-md-6',
                                                                    name: 'mobile',
                                                                    key: 'mobile',
                                                                    type: 'number',
                                                                    placeholder: 'Enter 10 digit mobile number',
                                                                    required: true,
                                                                    pattern: pattern.mobile,
                                                                },
                                                                // {
                                                                //     divClass: 'col-12 col-md-6',
                                                                //     name: 'Password',
                                                                //     key: 'password',
                                                                //     type: 'password',
                                                                //     required: true,
                                                                //     pattern: pattern.password,
                                                                // }
                                                            ]
                                                        }
                                                    />
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </Col>
                                    <Col className='col-12 d-grid gap-2'>
                                        <span className='ps-2 pb-2 ms-1 fw-semibold fs-20 fs-sm-14 fs-md-16'>Or select from existing user :</span>
                                        <Container className='custom-order-list'>
                                            <Row className='d-none d-md-flex custom-order-list-filter-bar py-3 flex-between-align mb-2 mb-md-0'>
                                                <Col className='col-2 text-center'>Select</Col>
                                                <Col className='col-2'>User Name</Col>
                                                <Col className='col-3'>Email</Col>
                                                <Col className='col-2'>Mobile</Col>
                                                <Col className='col-3'>Address</Col>
                                            </Row>
                                            {/* Order List Starts here... */}
                                            <Row className='border-bottom'>
                                                <Col className='col-12 py-3 custom-seller-category-search'>
                                                <div className='d-flex align-items-center gap-2 gap-lg-3 px-2 px-lg-3'>
                                                    <label className='bi bi-search' htmlFor='text'></label>
                                                    <input
                                                        type="text"
                                                        className='py-2 fs-sm-14 pe-2'
                                                        id='text'
                                                        placeholder='Search by user name, mobile'
                                                        autoComplete='off'
                                                        value={filters?.search}
                                                        onChange={(e) => {
                                                            setFilters((prevData) => ({
                                                                ...prevData,
                                                                search: e?.target?.value
                                                            }))
                                                        }}
                                                    />
                                                </div>
                                                </Col>
                                            </Row>
                                            {
                                                loader ?
                                                    <div className='w-100 aspect-4-1 aspect-md-2-1'>
                                                        <Loader />
                                                    </div>
                                                    : (!userList?.length > 0)
                                                        ? <div className='py-5'>
                                                            <div className='custom-transaction-tab d-grid justify-content-center text-center gap-2'>
                                                                <div>
                                                                    <GiNotebook />
                                                                </div>
                                                                <h5 className='fw-semibold'>No users yet</h5>
                                                                <p className='p-color fs-12'>users data will appear here after your users create account.</p>
                                                            </div>
                                                        </div>
                                                        :
                                                        userList?.length>0 &&
                                                            <>
                                                                {
                                                                    userList.map((item, index) => {
                                                                        return (
                                                                            <Row
                                                                                key={index}
                                                                                className='custom-order-list-bar-item py-3 flex-between-align gy-2 gy-md-0 mb-2 mb-md-0 align-items-center'
                                                                            >
                                                                                <Col className='col-12 col-md-2 custom-user-list d-grid'>
                                                                                    <button
                                                                                        className={`fw-semibold flex-center-align gap-2 
                                                                                            ${(selectedUser?.id === item.id) ? 'active' : ''}`}
                                                                                        onClick={() => {
                                                                                            setSelectedUser(item)
                                                                                            setTabHolder("shipping")
                                                                                        }}
                                                                                    >
                                                                                        <i className="bi bi-check"></i>
                                                                                        <span>{(selectedUser?.id === item.id) ? 'Selected' : 'Select'}</span>
                                                                                    </button>
                                                                                </Col>
                                                                                <Col className='col-12 col-md-2 text-center text-md-start'>
                                                                                    {item?.name}
                                                                                </Col>
                                                                                <Col className='col-12 col-md-3 fs-14 text-center text-md-start'>{item?.email}</Col>
                                                                                <Col className='col-12 col-md-2 text-center text-md-start'>{item?.mobile}</Col>
                                                                                <Col className='col-12 col-md-3 text-center text-md-start'>
                                                                                    <address className='fs-12 m-0 fs-sm-10'>
                                                                                        {
                                                                                            (item?.address?.name) ?
                                                                                                <>
                                                                                                    {item?.address?.addressLine1}, {item?.address?.addressLine2}, {item?.address?.city}, {item?.address?.state} - {item?.address?.pinCode}
                                                                                                </>
                                                                                                : <i className='fw-semibold'>
                                                                                                    Address not added yet !
                                                                                                </i>
                                                                                        }
                                                                                    </address>
                                                                                </Col>
                                                                            </Row>
                                                                        )
                                                                    })
                                                                }
                                                            </>
                                            }
                                            <Row>
                                                <Col className='col-12 py-3'>
                                                <CommonPagination
                                                    totalCount={totalUser}
                                                    changePage={(data) => {
                                                        setFilters((prevData) => ({
                                                            ...prevData,
                                                            limit: data?.limit,
                                                            page: data?.page
                                                        }))
                                                    }}
                                                />
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Col>
                                </Row>
                            </Container>
                        </Tab>
                        <Tab eventKey="shipping" title={`3. Shipping ${cartData?.filter(e => e.productId > 0)?.length > 0 ? "(" + cartData?.length + " product)" : ''}`} disabled={(selectedUser?.id && cartData?.length > 0) ? false : true}>
                            <Container className='py-3 seller-product-page'>
                                <Row className='gy-3 gy-sm-4'>
                                    <Col className='col-12 p-0 custom-create-order-accordion d-grid gap-3'>
                                        <Accordion defaultActiveKey={"0"}>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>
                                                    <span className='fw-semibold d-flex gap-2 align-items-center fs-18 fs-sm-14'>
                                                        <FaDotCircle />Selected Product List :
                                                    </span>
                                                </Accordion.Header>
                                                <Accordion.Body className='p-2 ps-sm-3'>
                                                    <div className='cartDetail ff-lexend'>
                                                        <Container className='py-4 m-0 w-100'>
                                                            {
                                                                (cartData?.filter(e => e.productId > 0)?.length > 0) ?
                                                                    <Row>
                                                                        <Col className='col-12 col-lg-8 pe-4'>
                                                                            <div style={{ position: 'sticky', top: '15px' }}>
                                                                                {
                                                                                    (apiData?.cartData?.length > 0) &&
                                                                                    <div className='d-grid gap-3'>{
                                                                                        apiData?.cartData?.filter((state)=>state?.productName?.length>0)?.map((item, index) => {
                                                                                            const colorCode = item?.variantDetail?.filter(e => ['color', 'colors', 'colour', 'colours']?.includes(e?.parentName))?.map(e => e?.variantCode)
                                                                                            return (
                                                                                                <>
                                                                                                    <Row
                                                                                                        key={index}
                                                                                                        className={`position-relative cart-card py-2 py-sm-3 ${['RTN', 'RTO', 'RTS']?.includes(item?.from) ? "border-danger border-2" : ""}`}
                                                                                                        style={{ backgroundColor: (item?.returnOrderId > 0) && "#fff7f8" }}
                                                                                                    >
                                                                                                        {
                                                                                                            ['RTN', 'RTO', 'RTS']?.includes(item?.from) && (
                                                                                                                <div style={{ width: '40px' }} className='fw-semibold bg-danger text-white position-absolute top-0 end-0 fs-12'>{item?.from}</div>
                                                                                                            )
                                                                                                        }
                                                                                                        <Col className='col-3 col-sm-2 ps-0 pe-1 pe-sm-2'>
                                                                                                            <div className='cart-img-holder position-relative'>
                                                                                                                <img src={item?.thumbnail} className='w-100' alt="" style={{ background: colorCode?.length > 0 ? colorCode[0] : '#ffffff' }} />
                                                                                                                <Link className='position-absolute top-0 start-0 w-100 h-100'
                                                                                                                    onClick={() => {
                                                                                                                        setTabHolder("selectedProduct")
                                                                                                                        setInnerTabValue({
                                                                                                                            tab: item?.isComboProduct ? "combos":"products",
                                                                                                                            value: item?.isComboProduct ? item?.productSlug : item?.productId
                                                                                                                        })
                                                                                                                        // getSingleProduct(item?.productId)
                                                                                                                    }}
                                                                                                                ></Link>
                                                                                                            </div>
                                                                                                        </Col>
                                                                                                        <Col className='col-9 col-sm-10 p-0 py-2 ps-auto'>
                                                                                                            <div className='d-flex h-100'>
                                                                                                                <div className='w-100 d-grid align-items-start'>
                                                                                                                    <div className='d-grid d-lg-flex justify-content-between cartProductsize'>
                                                                                                                        <Link className='m-0' onClick={() => {
                                                                                                                            setTabHolder("selectedProduct")
                                                                                                                            console.log("test::", {
                                                                                                                                tab: item?.isComboProduct ? "combos":"products",
                                                                                                                                value: item?.isComboProduct ? item?.productSlug : item?.productId
                                                                                                                            });
                                                                                                                            
                                                                                                                            setInnerTabValue({
                                                                                                                                tab: item?.isComboProduct ? "combos":"products",
                                                                                                                                value: item?.isComboProduct ? item?.productSlug : item?.productId
                                                                                                                            })
                                                                                                                            // getSingleProduct(item?.productId)
                                                                                                                        }}>{item?.productName}</Link>
                                                                                                                        <h5 className='m-0 text-start text-lg-end theme-color fs-15'><span className='text-secondary fs-14'>Price</span> ₹{item?.total}</h5>
                                                                                                                    </div>
                                                                                                                    <div className='d-grid d-sm-flex qtyPlusMinuse pt-2'>
                                                                                                                        {
                                                                                                                            (item?.variantDetail?.length > 0) &&
                                                                                                                            item?.variantDetail.map((varItem, varIndex) => (
                                                                                                                                <section className='p-1 px-lg-2' key={varIndex}>
                                                                                                                                    {varItem?.parentName}:
                                                                                                                                    <span className='ps-1 text-capitalize'>{varItem?.variantName}</span>
                                                                                                                                </section>
                                                                                                                            ))
                                                                                                                        }
                                                                                                                        <section className='p-1 px-lg-2 d-flex gap-2 align-items-center'>
                                                                                                                            <span>Qty: </span>
                                                                                                                            {
                                                                                                                                <div className='d-flex align-items-center border rounded-1 py-1'>
                                                                                                                                    <div
                                                                                                                                        className='px-2 pointer'
                                                                                                                                        onClick={() => {
                                                                                                                                            if (item?.quantity > 1) {
                                                                                                                                                apiData.cartData[index].quantity = parseInt(item?.quantity) - 1
                                                                                                                                                setApiData(apiData)
                                                                                                                                                updateCartList(apiData.cartData)
                                                                                                                                            }
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <FaMinus />
                                                                                                                                    </div>
                                                                                                                                    <div className='text-center' style={{ width: '30px' }}>{item?.quantity}</div>
                                                                                                                                    <div
                                                                                                                                        className='px-2 pointer'
                                                                                                                                        onClick={() => {
                                                                                                                                            apiData.cartData[index].quantity = parseInt(item?.quantity) + 1
                                                                                                                                            setApiData(apiData)
                                                                                                                                            updateCartList(apiData.cartData)
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <FaPlus />
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            }
                                                                                                                            {/* {
                                                                                                                            ['RTN', 'RTS', 'RTO']?.includes(item?.from) ? (
                                                                                                                                <div className='d-flex align-items-center border rounded-1 py-1'>
                                                                                                                                    <div
                                                                                                                                        className='px-2 pointer'
                                                                                                                                        onClick={() => {
                                                                                                                                            if (item?.quantity > 1) {
                                                                                                                                                apiData.cartData[index].quantity = parseInt(item?.quantity) - 1
                                                                                                                                                setApiData(apiData)
                                                                                                                                                updateCartList(apiData.cartData)
                                                                                                                                            }
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <FaMinus />
                                                                                                                                    </div>
                                                                                                                                    <div className='text-center' style={{width:'30px'}}>{item?.quantity}</div>
                                                                                                                                    <div
                                                                                                                                        className='px-2 pointer'
                                                                                                                                        onClick={() => {
                                                                                                                                            apiData.cartData[index].quantity = parseInt(item?.quantity) + 1
                                                                                                                                            setApiData(apiData)
                                                                                                                                            updateCartList(apiData.cartData)
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <FaPlus />
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            ) : (<span>{item?.quantity}</span>)
                                                                                                                        } */}
                                                                                                                        </section>
                                                                                                                    </div>
                                                                                                                    <div className='fs-12 ps-1 p-color d-none d-sm-block'>Other Details </div>
                                                                                                                    <hr className='m-0 mb-2 d-none d-sm-block' />
                                                                                                                    <div className='d-none d-sm-flex align-items-center fs-14 theme-color'>
                                                                                                                        <FaTruckFast /><section className='ps-1'>Est Delivery: <span>{moment().add(7, 'days').format('dddd, DD MMM YYYY')}</span></section>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className='px-2 px-sm-4 px-lg-4 fs-20 customHeartColor d-grid align-content-between'>
                                                                                                                    <Link onClick={() => deleteCartItem(index)}><i className="bi bi-trash3"></i></Link>
                                                                                                                </div>

                                                                                                            </div>
                                                                                                        </Col>
                                                                                                        <Col className='cartItemPrd d-flex d-sm-none fs-12 py-2 mt-2 theme-color'>
                                                                                                            <FaTruckFast /><section className='ps-1'>Est Delivery: <span>{moment().add(7, 'days').format('dddd, DD MMM YYYY')}</span></section>
                                                                                                        </Col>
                                                                                                        {/* {(item?.returnOrderId > 0) &&
                                                                                                            <Alert variant="danger" className="fw-semibold m-0 position-absolute bottom-0 end-0 fs-14 py-1" style={{ width: "auto" }}>
                                                                                                                Return Order
                                                                                                            </Alert>
                                                                                                        } */}
                                                                                                    </Row>
                                                                                                </>
                                                                                            )
                                                                                        })}
                                                                                        <div
                                                                                            className='text-danger fw-bold d-flex gap-1'
                                                                                            style={{ cursor: 'pointer' }}
                                                                                            onClick={handleCartClear}
                                                                                        >
                                                                                            <i className="bi bi-trash3-fill"></i>Clear All
                                                                                        </div>
                                                                                    </div>

                                                                                }
                                                                            </div>
                                                                        </Col>
                                                                        <Col className='col-12 col-lg-4 p-0 mt-5 mt-lg-0'>
                                                                            <div className='priceBox' style={{ position: 'sticky', top: '15px' }}>
                                                                                <div className='p-3'>
                                                                                    <h5 className='fs-18 pb-2'>Price Details</h5>
                                                                                    <div className='d-flex justify-content-between'>
                                                                                        <span className='fs-16 w-100'>Subtotal</span>
                                                                                        <span className='w-100 text-end'>₹{apiData?.subTotalAmount}</span>
                                                                                    </div>
                                                                                    <div className='d-flex justify-content-between' pt-1>
                                                                                        <span className='fs-16 w-100'>Discount {`${apiData?.couponCode && `(${apiData?.couponCode})`}`}</span>
                                                                                        <span className='w-100 text-end'>- ₹{parseInt(apiData?.couponAmount)}</span>
                                                                                    </div>
                                                                                    <hr className='my-2' />
                                                                                    <div className='d-flex justify-content-between fw-semibold'>
                                                                                        <span className='fs-18 w-100'>Grand Total</span>
                                                                                        <span className='fs-18 w-100 text-end'>₹{apiData?.grossAmount}</span>
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    (apiData?.couponAmount) ? <div className='fs-12 py-1 text-center bgPink'>
                                                                                        <span>Congrats! You saved total <span className='text-success fs-14'>₹{apiData?.couponAmount}</span> on this order.</span>
                                                                                    </div>
                                                                                        : ''
                                                                                }
                                                                                {/* <hr className='my-0' />
                                                                                <div className='d-grid finalCheckOutBtn p-3 pt-1 pt-md-3 pb-0 pb-md-2 text-center footerForCart'>
                                                                                    <button
                                                                                        className='mt-2 half-border-rad fw-semibold'
                                                                                        onClick={handleCheckOut}
                                                                                    >Proceed to checkout</button>
                                                                                </div> */}
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    :
                                                                    <div className='text-center d-grid align-items-center'
                                                                        style={{ height: '50vh' }}>
                                                                        <h1 className='flex-center-align gap-3 theme-color flex-wrap'><PiNoteBlank />Cart is Empty</h1>
                                                                    </div>
                                                            }
                                                        </Container>
                                                    </div >
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                        {/* <Accordion defaultActiveKey={!selectedUser?.address?.name ? "2" : "1"}>
                                            <Accordion.Item eventKey={!selectedUser?.address?.name ? "2" : "1"}> */}
                                        <Accordion defaultActiveKey={"10"}>
                                            <Accordion.Item eventKey={"10"}>
                                                <Accordion.Header>
                                                    <span className='fw-semibold d-flex gap-2 align-items-center fs-18 fs-sm-12'>
                                                        <CiCirclePlus />Shipping Address Details :
                                                    </span>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <FormComponent
                                                        // noAction={true}
                                                        action={frontEnd_API.userAddress}
                                                        actionType={`${(selectedUser?.address?.id) ? 'put' : 'post'}`}
                                                        actionValue={(selectedUser?.address?.id) && selectedUser?.address?.id}
                                                        onSubmit={(res) => {
                                                            setSelectedUser((prevData)=>({
                                                                ...prevData,
                                                                address: {
                                                                    ...res?.data
                                                                }
                                                            }))
                                                            getUserList();
                                                            handleTaxFromAPI(res?.data?.state);
                                                        }}
                                                        valueData={{userId: selectedUser?.id, ...selectedUser?.address}}
                                                        // submitFullWidth={false}
                                                        isNoFormMessage={selectedUser?.address?.id && true}
                                                        noCleanFormData={true}
                                                        submitFullWidth={true}
                                                        submitBTNAlign={'justify-content-start'}
                                                        formMessage={(!selectedUser?.address?.id) && "New address selected successfully!"}
                                                        button={selectedUser?.address?.id ? "Next" : "Add Address"}
                                                        fields={[
                                                            {
                                                                divClass: 'col-12',
                                                                name: 'Name',
                                                                key: 'name',
                                                                required: true,
                                                            },
                                                            {
                                                                type: 'email',
                                                                divClass: 'col-12 col-md-6',
                                                                name: 'Email',
                                                                key: 'email',
                                                                required: true,
                                                                pattern: pattern.email
                                                            },
                                                            {
                                                                type: 'number',
                                                                divClass: 'col-12 col-md-6',
                                                                name: 'Mobile',
                                                                key: 'mobile',
                                                                required: true,
                                                                pattern: pattern.mobile
                                                            },
                                                            {
                                                                divClass: 'col-12 col-md-6',
                                                                name: 'Address Line 1',
                                                                key: 'addressLine1',
                                                                // pattern:pattern?.addressWithNumber,
                                                                required: true,
                                                            },
                                                            {
                                                                divClass: 'col-12 col-md-6',
                                                                name: 'Address Line 2',
                                                                key: 'addressLine2',
                                                                // pattern:pattern?.address,
                                                                required: true,
                                                            },
                                                            {
                                                                type: 'select',
                                                                options: data,
                                                                divClass: 'col-6 col-lg-4',
                                                                name: 'state',
                                                                key: 'state',
                                                                required: true,
                                                                onChange: {
                                                                    from: 'children',
                                                                    to: 'city',
                                                                    options: data
                                                                }
                                                            },
                                                            {
                                                                divClass: 'col-6 col-lg-4',
                                                                type: 'select',
                                                                name: 'city',
                                                                key: 'city',
                                                                required: true,
                                                                options: ((selectedUser?.address) && data.filter(item => item.value === selectedUser?.address?.state)[0]?.children)
                                                            },
                                                            {
                                                                divClass: 'col-12 col-lg-4',
                                                                name: 'Pincode',
                                                                key: 'pinCode',
                                                                required: true,
                                                            },
                                                        ]}
                                                    />
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                        {/* {(selectedUser?.address?.name) &&
                                            <Accordion defaultActiveKey={"0"}>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>
                                                        <span className='fw-semibold d-flex gap-2 align-items-center fs-18 fs-sm-12'>
                                                            <FaDotCircle />Selected Shipping Address :
                                                        </span>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <Card>
                                                            <Card.Header className='fw-semibold'><i className="bi bi-geo-alt-fill fs-14"></i> {selectedUser?.address?.name}</Card.Header>
                                                            <Card.Body className='d-grid align-content-between'>
                                                                <Card.Text className='d-grid gap-1 gap-md-2'>
                                                                    <div className='d-flex gap-2 fs-14'>
                                                                        <i className="bi bi-envelope fs-14"></i>
                                                                        <span>{selectedUser?.address?.email}</span>
                                                                    </div>
                                                                    <div className='d-flex gap-2 fs-14'>
                                                                        <i className="bi bi-telephone-fill fs-14"></i>
                                                                        <span>{selectedUser?.address?.mobile}</span>
                                                                    </div>
                                                                    <div className='d-flex gap-2'>
                                                                        <i className="bi bi-geo-alt-fill fs-14"></i>
                                                                        <address className='fs-14 m-0 fs-sm-12'>
                                                                            {selectedUser?.address?.addressLine1}, {selectedUser?.address?.addressLine2}, {selectedUser?.address?.city}, {selectedUser?.address?.state} - {selectedUser?.address?.pinCode}
                                                                        </address>
                                                                    </div>
                                                                </Card.Text>
                                                            </Card.Body>
                                                        </Card>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        } */}
                                        {/* <Button
                                            variant={"success"}
                                            disabled={(selectedUser?.address?.name && cartData?.length > 0) ? false : true}
                                            onClick={() => {
                                                handleTaxFromAPI();
                                            }}
                                        >Next</Button> */}
                                    </Col>
                                </Row>
                            </Container>
                        </Tab>
                        <Tab eventKey="payment" title="4. Payment" disabled={(selectedUser?.address?.name && cartData?.length > 0) ? false : true}>
                            <Container fluid className='custom-checkout-page'>
                                <Row className='pf-flex-lg-col-reverse'>
                                    <Col className='col-12 col-lg-6 custom-checkout-page-steps py-4 py-md-4 pb-0 d-grid position-relative'>
                                        <div className='position-sticky top-0'>
                                            <div className='custom-img-holder-container d-flex align-items-center gap-3'>
                                                <Link to={'/'} className='h3 text-decoration-none m-0 fw-bold text-capitalize d-none d-md-block me-3'>{storeData?.name}</Link>
                                                <Link to={'/'} className='h3 text-decoration-none m-0 fw-bold text-capitalize d-block d-md-none'>{storeData?.name.substring(0, 12)}{(storeData?.name.length > 12) && '...'}</Link>
                                            </div>
                                            <Container className='flex-center-align'>
                                                <div className='text-center d-grid align-content-start gap-3 custom-payment-tab py-4 py-sm-5'>
                                                    <div className='flex-center-align justify-content-start  flex-wrap gap-2 gap-md-3 gap-lg-4 px-0 px-sm-4'>
                                                        <div
                                                            className={`payment-list-item flex-center-align full-border-rad w-100 gap-2 gap-sm-3 ${(paymentType === "COD") ? 'active' : ''}`}
                                                            onClick={() => {
                                                                setPaymentType("COD")
                                                                setOtherPayment()
                                                            }}
                                                        >
                                                            <div
                                                                className='h4 m-0 fs-sm-16 fs-20 flex-center-align gap-2 px-2 px-sm-3 py-1 py-sm-2' >
                                                                <FaTruckFast />
                                                                <span>COD</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <span className='text-center text-secondary fs-16 fs-md-14 fs-sm-12'>-- OR --</span>
                                                    <div className='flex-center-align justify-content-start  flex-wrap gap-2 gap-md-3 gap-lg-4 px-0 px-sm-4'>
                                                        <div
                                                            className={`payment-list-item flex-center-align full-border-rad w-100 gap-2 gap-sm-3 ${(paymentType === "PREPAID") ? 'active' : ''}`}
                                                            onClick={() => {
                                                                setPaymentType("PREPAID")
                                                                setOtherPayment()
                                                            }}
                                                        >
                                                            <div
                                                                className='h4 m-0 fs-sm-16 fs-20 flex-center-align gap-2 px-2 px-sm-3 py-1 py-sm-2' >
                                                                <CiWallet />
                                                                <span>PREPAID</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='px-3 d-grid mt-5'>
                                                        <button
                                                            className={`custom-final-btn fs-18 fw-semibold ${['COD', 'PREPAID']?.includes(paymentType) ? 'active' : ''}`}
                                                            onClick={handleOrderProcess}
                                                            disabled={!['COD', 'PREPAID']?.includes(paymentType)}
                                                        >
                                                            Confirm Order
                                                        </button>
                                                    </div>
                                                </div>
                                            </Container>
                                        </div>
                                    </Col>
                                    <Col className='col-12 col-lg-6 custom-checkout-page-summary p-4 d-grid align-items-center'>
                                        <div className='py-4 py-sm-5 px-0 px-sm-2 px-lg-0 px-xl-2'>
                                            <div className='d-grid gap-4 gap-sm-5'>
                                                <h3 className='fw-semibpold m-0'>Order summary</h3>
                                                <div className='d-grid gap-3'>
                                                    {
                                                        (newCartData?.cartData?.length > 0) &&
                                                        newCartData?.cartData?.map((item, index) => {
                                                            const colorCode = item?.variantDetail?.filter(e => ['color', 'colors', 'colour', 'colours']?.includes(e?.parentName))?.map(e => e?.variantCode)
                                                            return (
                                                                <div className='order-product-list gap-2' key={index}>
                                                                    <div className='img-holder'>
                                                                        <img src={item?.thumbnail} alt="" style={{ background: colorCode?.length > 0 ? colorCode[0] : '#ffffff' }} />
                                                                    </div>
                                                                    <div className='d-flex justify-content-between flex-wrap gap-2 w-100'>
                                                                        <div className='d-grid gap-2 gap-sm-3 align-content-start'>
                                                                            <h5 className='fw-semibold m-0 fs-14 fs-sm-12'>{item?.productName}</h5>
                                                                            <div className='d-flex gap-2 varient-list-data flex-wrap text-capitalize'>
                                                                                {
                                                                                    (item?.variantDetail?.length > 0) &&
                                                                                    item?.variantDetail.map((varItem, varIndex) => (
                                                                                        <span key={varIndex} className='px-2 py-1 fs-12 fs-sm-10 d-flex align-items-center'>
                                                                                            {varItem?.parentName}: {varItem?.variantName}
                                                                                        </span>))
                                                                                }
                                                                                <span className='px-2 py-1 fs-12 fs-sm-10 d-flex align-items-center'>Qty : {item?.quantity}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <h5 className='fw-semibold m-0 fs-16 fs-sm-12'>₹{item?.total}</h5>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                <div className='d-grid gap-2 gap-sm-4'>
                                                    <div className='list-calc d-grid gap-2'>
                                                        <div className='flex-between-align fs-18 fs-sm-14 fs-lg-16'>
                                                            <span>Items ({newCartData?.cartData?.length})</span>
                                                            <span>₹{newCartData?.subTotalAmount}</span>
                                                        </div>
                                                        <div className='flex-between-align fs-18 fs-sm-14 fs-lg-16'>
                                                            <span>Discount <span className='fs-14 fs-sm-10 fs-lg-12'>{newCartData?.couponCode ? `(${newCartData?.couponCode})` : ''}</span></span>
                                                            <span>- ₹{newCartData?.couponAmount}</span>
                                                        </div>
                                                        <div className='flex-between-align fs-18 fs-sm-14 fs-lg-16'>
                                                            <span>Shipping <span className={`fs-14 fs-sm-10 fs-lg-12 ${newCartData?.orderShippingChargeAmount ? "text-success fw-semibold" : "d-none"}`}>(Free Shipping)</span></span>
                                                            <span className='d-flex gap-2 align-items-center'>
                                                                <span className={`fs-sm-12 ${(!newCartData?.orderShippingChargeAmount) ? 'fs-16' : ''}`}>
                                                                    {
                                                                        (newCartData?.orderShippingChargeAmount || (newCartData?.orderShippingChargeAmount == 0)) ?
                                                                            <>- <span className='text-decoration-line-through'>₹{newCartData?.orderShippingChargeAmount}</span></>
                                                                            : "Calculated at next step"
                                                                    }
                                                                </span>
                                                            </span>
                                                        </div>
                                                        {/* 
                                                        <div className='flex-between-align fs-18 fs-sm-14 fs-lg-16'>
                                                            <span>Tax</span>
                                                            <span className='d-flex gap-2 align-items-center'>
                                                                <span className={`fs-sm-12 ${(!newCartData?.orderTaxAmount) ? 'fs-16' : ''}`}>
                                                                    {
                                                                        (newCartData?.orderTaxAmount) ?
                                                                            `+ ₹${newCartData?.orderTaxAmount}`
                                                                            : "Calculated at next step"
                                                                    }
                                                                </span>
                                                            </span>
                                                        </div>
                                                        */}
                                                        <hr className='mb-2 mb-sm-0' />
                                                    </div>
                                                    <div className='flex-between-align gap-2'>
                                                        {/* <h2 className='fw-semibold m-0 fs-sm-20'>Total (Inc. Tax)</h2> */}
                                                        <h2 className='fw-semibold m-0 fs-sm-20'>Total</h2>
                                                        <h2 className='fw-semibold m-0 fs-sm-20'>₹{newCartData?.orderNetAmount ?? newCartData?.grossAmount}</h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>

                            <Modal show={show}
                                size="lg"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                            >
                                <Modal.Body>
                                    <Container>
                                        <Row>
                                            <Col className='col-12 flex-center-align'>
                                                <div style={{ width: "300px", aspectRatio: "4/3" }}>
                                                    <img src={require('../../Assets/Gif/Animation - 1725267698496.gif')} alt="Description of the GIF" style={{
                                                        objectFit: "contain",
                                                        width: "100%",
                                                        height: "100%"
                                                    }} />
                                                </div>
                                            </Col>
                                            <Col className='col-12 d-grid text-center'>
                                                <h2 className='text-success fw-bold'>Order Placed Successfully !</h2>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Modal.Body>
                            </Modal>
                        </Tab>
                    </Tabs>
                </div>
            </Container>
            <ToastContainer />
        </div>
    )
}

export default SellerCreateOrder