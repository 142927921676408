// src/components/NotificationBell.jsx

import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { frontEnd_API, header } from '../Config/Config';
import { useSelector } from 'react-redux';
import notificationSound from "../Assets/Audio/notification.mp3";

function NotificationBell() {
    const [notificationLength, setNotificationLength] = useState(0);
    const [previousNotificationLength, setPreviousNotificationLength] = useState(0);
    const [isAudioAllowed, setIsAudioAllowed] = useState(false);
    const user = useSelector((state) => state.user.value);
    const navigate = useNavigate();
    const location = useLocation();
    const showNotification = (
        location?.pathname !== "/notification" &&
        !location.pathname.startsWith('/signin') &&
        !location.pathname.startsWith('/signup') &&
        !location.pathname.endsWith('/canvas') &&
        !location.pathname.endsWith('/app/create-store')
    );

    const audio = new Audio(notificationSound);

    // Allow audio playback after user interaction
    const enableAudio = () => {
        setIsAudioAllowed(true);
        document.removeEventListener('click', enableAudio);
    };

    useEffect(() => {
        document.addEventListener('click', enableAudio);
        return () => document.removeEventListener('click', enableAudio);
    }, []);

    const getNotificationList = async () => {
        if (user?.storeId !== 0) {
            try {
                const { data } = await axios.put(frontEnd_API?.notification, {}, header);
                setNotificationLength(data?.unReadCount);

                // if (data?.unReadCount > previousNotificationLength && isAudioAllowed) {
                if (data?.unReadCount > previousNotificationLength) {
                    audio.play().catch((error) => {
                        console.error("Audio playback failed:", error);
                    });
                }

                setPreviousNotificationLength(data?.unReadCount);
            } catch (e) {
                console.log("Error fetching notifications:", e);
                const { pathname } = location;

                const isRestrictedPath =
                    pathname.startsWith('/') && !pathname.startsWith('/view') ||
                    pathname === '/create-store';

                const isSignUpOrSignInPage =
                    pathname.endsWith('/signup') ||
                    pathname.endsWith('/forgot-password') ||
                    pathname.endsWith('/signin') ||
                    pathname.startsWith('/master') ||
                    pathname.startsWith('/verifyemail');

                if (e?.response?.status == "401" && isRestrictedPath && !isSignUpOrSignInPage) {
                    localStorage.clear();
                    // navigate('/signin');
                    window.location.href = "/signin";
                }
            }
        }
    };

    useEffect(() => {
        getNotificationList();
        const intervalId = setInterval(getNotificationList, 10000);
        return () => clearInterval(intervalId);
    }, []); // Run on mount

    useEffect(() => {
        if (location?.pathname === "/notification") {
            getNotificationList();
        }
    }, [location]);

    return (
        <div>
            {
                showNotification && (
                    <span className='notification-bell'>
                        <Link
                            className={`${notificationLength !== 0 ? "loader" : "loader loader-stop"}`}
                            to={"/notification"}
                        >
                            <div>
                                <svg viewBox="0 0 24 24" fill="none" height={24} width={24} xmlns="http://www.w3.org/2000/svg" aria-hidden="true" className="w-6 h-6 text-gray-800 dark:text-white">
                                    <path d="M12 5.365V3m0 2.365a5.338 5.338 0 0 1 5.133 5.368v1.8c0 2.386 1.867 2.982 1.867 4.175 0 .593 0 1.292-.538 1.292H5.538C5 18 5 17.301 5 16.708c0-1.193 1.867-1.789 1.867-4.175v-1.8A5.338 5.338 0 0 1 12 5.365ZM8.733 18c.094.852.306 1.54.944 2.112a3.48 3.48 0 0 0 4.646 0c.638-.572 1.236-1.26 1.33-2.112h-6.92Z" strokeWidth={2} strokeLinejoin="round" strokeLinecap="round" stroke="currentColor" />
                                </svg>
                                {
                                    notificationLength !== 0 && (
                                        <div className="point fs-md-12 text-center align-content-center d-grid fw-bold">
                                            <span className='lh-sm'>{notificationLength}</span>
                                        </div>
                                    )
                                }
                            </div>
                        </Link>
                    </span>
                )
            }
        </div>
    );
}

export default NotificationBell;
