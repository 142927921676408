import React, { useRef } from "react";
import { Link } from "react-router-dom";
// import { BASE_URL, SERVER_URL } from '../../../Config/Config';
import html2canvas from "html2canvas";
import MugFinalPreview from "../../../Components/CustomMockup/MugFinalPreview";

const ProductPreviewImage = ({
  isList = false,
  colorCode = "#ffffff",
  objectData = {},
  isCustom = false,
  onPrevSelect,
  isButtonShow = true,
  customScale = 3,
  isStoreCanvasImage = false,
  storeCanvasImage, 
  loader = false
}) => {
  const divRef = useRef(null);

  const redirectToPreview = () => {
    if (divRef.current) {
      html2canvas(divRef.current, {
        allowTaint: false,
        useCORS: true,
        logging: true,
        backgroundColor: null,
        scale: customScale ?? 1,
      }).then((canvas) => {
        // Create a new canvas element with fixed dimensions
        const fixedCanvas = document.createElement("canvas");
        fixedCanvas.width = 600;
        fixedCanvas.height = 600;
        const ctx = fixedCanvas.getContext("2d");

        // Draw the original canvas onto the new canvas
        ctx.drawImage(canvas, 0, 0, 600, 600);

        // Convert the new canvas to a Blob
        fixedCanvas.toBlob((blob) => {
          if (blob) {
            const a = document.createElement("a");
            a.href = URL.createObjectURL(blob);
            a.target = "_blank";
            a.click();
          }
        }, "image/png");
      });
    }
  };

  const BigPreview = () => {
    return (
      <div
        className="custom-big-preview-hover position-relative h-100 w-100 pointer rounded-inherit"
        ref={divRef}
      >
        <img
          className="preview-img-background rounded-inherit"
          style={{ height: "100%", width: "100%", backgroundColor: colorCode }}
          src={objectData?.preview}
        />
        {objectData?.imgURL && (
          <img
            className="position-absolute preview-img-overlay"
            src={objectData?.imgURL}
            style={{
              height: `${objectData?.ratioData?.height / 5}%`,
              width: `${objectData?.ratioData?.width / 5}%`,
              top: `${objectData?.ratioData?.y / 5}%`,
              left: `${objectData?.ratioData?.x / 5}%`,
            }}
          />
        )}
        {isButtonShow && (
          <span
            className="fs-20 fs-md-18 fs-sm-16"
            style={{ "--textColor": colorCode }}
          >
            Click to Preview
          </span>
        )}
        <a
          className="position-absolute h-100 w-100 top-0 start-0"
          onClick={redirectToPreview}
          onContextMenu={() => false}
        ></a>
      </div>
    );
  };

  const Listpreview = () => {
    return (
      <>
        <div className="d-grid gap-1 text-center">
          <div
            className="position-relative aspect-1-1 w-100"
            onClick={() =>
              onPrevSelect({
                value: objectData?.value,
                preview: objectData?.preview,
                index: objectData?.index,
              })
            }
          >
            <img
              style={{
                backgroundColor: colorCode,
                height: "inherit",
                width: "inherit",
              }}
              // src={SERVER_URL + objectData?.preview}
              src={objectData?.preview}
            />
            {objectData?.imgURL && (
              <img
                className="position-absolute"
                src={objectData?.imgURL}
                style={{
                  height: `${objectData?.ratioData?.height / 5}%`,
                  width: `${objectData?.ratioData?.width / 5}%`,
                  top: `${objectData?.ratioData?.y / 5}%`,
                  left: `${objectData?.ratioData?.x / 5}%`,
                }}
              />
            )}
            <a
              className="position-absolute h-100 w-100 top-0 start-0 z-3"
              onContextMenu={() => false}
            ></a>
          </div>
          {/* {objectData?.label && <h6 className='fw-semibold fs-12 fs-sm-12'>{objectData?.label}</h6>} */}
        </div>
      </>
    );
  };

  return (
    <>
      {isList ? (

        // For List preview
        isCustom ? (
          <MugFinalPreview 
            data={objectData} 
            isStoreCanvasImage={isStoreCanvasImage}
            storeCanvasImage={storeCanvasImage}
            colorCode={colorCode}
            newLoader={loader}
          />
        ) : (
          <Listpreview />
        )
        // For single preview
      ) : isCustom ? (
        <MugFinalPreview 
          data={objectData} 
          colorCode={colorCode} 
          loader={loader}
        />
      ) : (
        <BigPreview />
      )}
    </>
  );
};

export default ProductPreviewImage;
