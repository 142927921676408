// export default function getCroppedImg(imageSrc, croppedAreaPixels) {
//   return new Promise((resolve, reject) => {
//     const image = new Image();
//     image.src = imageSrc;
//     image.crossOrigin = "anonymous"; // Important for avoiding CORS issues

//     image.onload = () => {
//       const canvas = document.createElement("canvas");
//       const ctx = canvas.getContext("2d");

//       // Set canvas size to the cropped area
//       canvas.width = croppedAreaPixels.width;
//       canvas.height = croppedAreaPixels.height;

//       // Draw the cropped image onto the canvas
//       ctx.drawImage(
//         image,
//         croppedAreaPixels.x, croppedAreaPixels.y, // Source start (x, y)
//         croppedAreaPixels.width, croppedAreaPixels.height, // Source size (w, h)
//         0, 0, // Destination start (x, y)
//         croppedAreaPixels.width, croppedAreaPixels.height // Destination size (w, h)
//       );

//       // Convert canvas to Base64 image
//       resolve(canvas.toDataURL("image/png"));
//     };

//     image.onerror = (error) => reject(error);
//   });
// }

export default function getCroppedImg(imageSrc, croppedAreaPixels) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = imageSrc;
    image.crossOrigin = "anonymous"; // Important to avoid CORS issues

    image.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      // Set canvas size to the cropped area
      canvas.width = croppedAreaPixels.width;
      canvas.height = croppedAreaPixels.height;

      // Draw the cropped image onto the canvas
      ctx.drawImage(
        image,
        croppedAreaPixels.x, croppedAreaPixels.y, // Source start (x, y)
        croppedAreaPixels.width, croppedAreaPixels.height, // Source size (w, h)
        0, 0, // Destination start (x, y)
        croppedAreaPixels.width, croppedAreaPixels.height // Destination size (w, h)
      );

      // Convert canvas to a Blob instead of Base64
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error("Canvas is empty"));
          return;
        }
        const blobUrl = URL.createObjectURL(blob); // Create a Blob URL
        resolve(blobUrl); // Return Blob URL
      }, "image/png"); // You can change the format if needed
    };

    image.onerror = (error) => reject(error);
  });
}
