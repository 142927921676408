import { useEffect, useRef, useState } from "react";
import { Stage, Layer, Image as KonvaImage, Line } from "react-konva";
import getCroppedImg from "../../Helpers/selector_helper";
import { Spinner } from "react-bootstrap";

const MugFinalPreview = ({ data, isStoreCanvasImage, storeCanvasImage, colorCode, newLoader = false }) => {
  const [loader, setLoader] = useState(newLoader);
  const [mug, setMug] = useState(null);
  const [cropped, setCropped] = useState(null);
  const [canvasImage, setCanvasImage] = useState(null); // State for high-resolution image
  const selectedRatio = data?.mockupData?.selectedRation;
  const isFaded = data?.mockupData?.croppedAreaPixels?.isFaded ?? false;
  const polygonPoints = data?.mockupData?.points ?? [];
  const parentRef = useRef();
  const stageRef = useRef(); // Ref for the stage
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (data?.preview) {
      const img = new window.Image();
      img.src = data?.preview;
      img.crossOrigin = "Anonymous";
      img.onload = () => setMug(img);
    }
  }, [data?.preview]);

  const applyFadeEffect = (image) => {
    const canvas = document.createElement("canvas");
    canvas.width = image.width;
    canvas.height = image.height;
    const ctx = canvas.getContext("2d");
  
    // Draw the original cropped image
    ctx.drawImage(image, 0, 0, image.width, image.height);
  
    // Create gradient mask
    const gradient = ctx.createLinearGradient(0, 0, 0, image.height);
    gradient.addColorStop(0, "rgba(0,0,0,0)"); // Fully transparent at top
    gradient.addColorStop(0, "rgba(0,0,0,0)"); // Fully transparent at top
    gradient.addColorStop(1, "rgba(0,0,0,1)"); // Fully opaque at bottom
    gradient.addColorStop(1, "rgba(0,0,0,1)"); // Fully opaque at bottom
  
    // Apply the gradient as a mask
    ctx.globalCompositeOperation = "destination-in";
    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, image.width, image.height);
  
    return canvas.toDataURL(); // Convert to base64 image
  };
  
  // const getCroppedImageFun = async () => {
  //   const { imgURL, mockupData, ratioData } = data;

  //   const getImageSize = (url) => {
  //     return new Promise((resolve) => {
  //       const img = new window.Image();
  //       img.src = url;
  //       img.crossOrigin = "Anonymous";
  //       img.onload = () => {
  //         resolve({ width: img.width, height: img.height });
  //       };
  //     });
  //   };
  
  //   const newDesignImageSize = await getImageSize(imgURL);
  
  //   const newCroppedRationData = {
  //     width:
  //       (Number(mockupData?.croppedAreaPixels?.width) *
  //         Number(newDesignImageSize?.width)) /
  //       Number(ratioData?.width),
  //     height:
  //       (Number(mockupData?.croppedAreaPixels?.height) *
  //         Number(newDesignImageSize?.height)) /
  //       Number(ratioData?.height),
  //     x:
  //       (Number(mockupData?.croppedAreaPixels?.x) *
  //         Number(newDesignImageSize?.width)) /
  //       Number(ratioData?.width),
  //     y:
  //       (Number(mockupData?.croppedAreaPixels?.y) *
  //         Number(newDesignImageSize?.height)) /
  //       Number(ratioData?.height),
  //   };
  
  //   const newImageSrc = await getCroppedImg(imgURL, newCroppedRationData);
  
  //   // Convert base64 to HTMLImageElement
  //   const croppedImg = new window.Image();
  //   croppedImg.src = newImageSrc;
  //   croppedImg.crossOrigin = "Anonymous";
  
  //   croppedImg.onload = () => {
  //     setLoader(false);
  //     if (isFaded) {
  //       // Apply fade effect after image loads
  //       const fadedImageSrc = applyFadeEffect(croppedImg);
  
  //       const fadedImg = new window.Image();
  //       fadedImg.src = fadedImageSrc;
  //       fadedImg.crossOrigin = "Anonymous";
  //       fadedImg.onload = () => setCropped(fadedImg);
  //     } else {
  //       setCropped(croppedImg);
  //     }
  //   };
  // };

  const getCroppedImageFun = async () => {
    try {
      const { imgURL, mockupData, ratioData } = data;
      let imageSrc = imgURL;
  
      // If imgURL is missing or invalid, use a blank transparent image
      if (!imgURL || (imgURL.startsWith("data:image/png;base64,") && imgURL.length < 50)) {
        console.warn("Image URL is empty or invalid, using a blank transparent image.");
        imageSrc = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAfQAAAH0CAYAAADL1t+KAAAD30lEQVR4nO3BAQEAAACCIP+vbkhAAQ";
      }
  
      const getImageSize = (url) => {
        return new Promise((resolve) => {
          const img = new window.Image();
          img.src = url;
          img.crossOrigin = "Anonymous";
          img.onload = () => resolve({ width: img.width, height: img.height });
          img.onerror = () => resolve(null); // Resolve with null if image fails
        });
      };
  
      const newDesignImageSize = await getImageSize(imageSrc);
      if (!newDesignImageSize) {
        console.warn("Failed to load image, proceeding with default size.");
        newDesignImageSize = { width: 500, height: 500 }; // Default size for blank canvas
      }
  
      const newCroppedRationData = {
        width:
          (Number(mockupData?.croppedAreaPixels?.width) *
            Number(newDesignImageSize?.width)) /
          Number(ratioData?.width),
        height:
          (Number(mockupData?.croppedAreaPixels?.height) *
            Number(newDesignImageSize?.height)) /
          Number(ratioData?.height),
        x:
          (Number(mockupData?.croppedAreaPixels?.x) *
            Number(newDesignImageSize?.width)) /
          Number(ratioData?.width),
        y:
          (Number(mockupData?.croppedAreaPixels?.y) *
            Number(newDesignImageSize?.height)) /
          Number(ratioData?.height),
      };
  
      const newImageSrc = await getCroppedImg(imageSrc, newCroppedRationData).catch((err) => {
        console.warn("Error cropping image, using blank canvas.");
        return imageSrc; // Use blank image instead of failing
      });
  
      // Convert base64 to HTMLImageElement
      const croppedImg = new window.Image();
      croppedImg.src = newImageSrc;
      croppedImg.crossOrigin = "Anonymous";
  
      croppedImg.onload = () => {
        setLoader(false);
        if (isFaded) {
          // Apply fade effect after image loads
          const fadedImageSrc = applyFadeEffect(croppedImg);
  
          const fadedImg = new window.Image();
          fadedImg.src = fadedImageSrc;
          fadedImg.crossOrigin = "Anonymous";
          fadedImg.onload = () => setCropped(fadedImg);
        } else {
          setCropped(croppedImg);
        }
      };
  
      croppedImg.onerror = () => {
        console.warn("Failed to load cropped image, using blank canvas.");
        setCropped(croppedImg);
      };
  
    } catch (error) {
      console.error("Unexpected error in getCroppedImageFun:", error);
    }
  };
  

  useEffect(() => {
    if (data?.imgURL) {
      setLoader(true);
      getCroppedImageFun();
    }
  }, [data?.imgURL, data?.mockupData]);

  useEffect(() => {
    if (parentRef.current) {
      setDimensions({
        width: parentRef.current.offsetWidth,
        height: parentRef.current.offsetHeight,
        aspect: 500 / parentRef.current.offsetHeight,
      });
    }
  }, []);
  // Capture the high-res image after render
  
  const captureCanvasImage = async () => {
    try {
        if (stageRef.current) {
            let scaleFactor = 16;
            const limit = 1200000; // 1.2MB limit

            // Force redraw before export
            stageRef.current.batchDraw();

            // Export high-resolution image
            let uri = await new Promise((resolve, reject) => {
                try {
                    const data = stageRef.current.toDataURL({ pixelRatio: scaleFactor });
                    resolve(data);
                } catch (error) {
                    reject(error);
                }
            });

            if (uri.length < limit) {
              storeCanvasImage(uri);
              setCanvasImage(uri);
            } else {
              // Adjust scale factor to reduce size below 1MB
              // console.log("uri.length::", uri.length);
              
              scaleFactor = Math.sqrt((16 ** 2) * (limit / uri.length));

              // Force redraw before export
              stageRef.current.batchDraw();

              // Export compressed image
              const shortUri = await new Promise((resolve, reject) => {
                  try {
                      const data = stageRef.current.toDataURL({ pixelRatio: scaleFactor });
                      resolve(data);
                  } catch (error) {
                      reject(error);
                  }
              });
              // console.log("shortUri::", shortUri);
              
              storeCanvasImage(shortUri);
              setCanvasImage(shortUri);
            }
        }
      } catch (error) {
          console.error("Error capturing canvas image:", error);
      }
  };

  useEffect(() => {
    if (cropped && isStoreCanvasImage) {
      setTimeout(() => {
        captureCanvasImage().catch((error) => {
          console.error("Failed to capture canvas image:", error);
      });
      }, 500); // Delay to ensure rendering is done
    }
  }, [mug, cropped, isStoreCanvasImage]);

  return (
    <div className="w-100 h-100 custom-mockup-style" ref={parentRef} style={{backgroundColor: colorCode}}>
      {
        loader 
        ? <div className="fw-semibold text-center h-100 p-3 d-flex flex-center-align gap-sm-3 bg-light">
          <Spinner animation="grow" variant="success" size="sm" />
          <Spinner animation="grow" variant="success" />
          <Spinner animation="grow" variant="success" size="sm" />
        </div>
        : <Stage 
          width={dimensions?.width} 
          height={dimensions?.height} 
          ref={stageRef}
        >
          <Layer>
            {mug && <KonvaImage image={mug} width={dimensions?.width} height={dimensions?.height} />}
            {cropped && polygonPoints?.length > 0 && (
              <KonvaImage
                image={cropped}
                x={0}
                y={0}
                width={selectedRatio?.width / dimensions?.aspect}
                height={selectedRatio?.height / dimensions?.aspect}
                // width={(selectedRatio?.width * dimensions.width) / ratioData.width}
                // height={(selectedRatio?.height * dimensions.height) / ratioData.height}
                sceneFunc={(ctx, shape) => {
                  ctx.save();
                  ctx.beginPath();
                  ctx.moveTo(
                    polygonPoints[0] / dimensions?.aspect,
                    polygonPoints[1] / dimensions?.aspect
                  );
                  for (let i = 2; i < polygonPoints.length; i += 2) {
                    ctx.lineTo(
                      polygonPoints[i] / dimensions?.aspect,
                      polygonPoints[i + 1] / dimensions?.aspect
                    );
                  }
                  ctx.closePath();
                  ctx.clip();

                  const scaleX = selectedRatio?.width / cropped.width / dimensions?.aspect;
                  const scaleY = selectedRatio?.height / cropped.height / dimensions?.aspect;

                  ctx.drawImage(
                    cropped,
                    selectedRatio?.minX / dimensions?.aspect,
                    selectedRatio?.minY / dimensions?.aspect,
                    cropped.width * scaleX,
                    cropped.height * scaleY
                  );
                }}
              />
            )}
          </Layer>
        </Stage>
      }
    </div>
  );
};

export default MugFinalPreview;
