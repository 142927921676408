import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, CardTitle, Col, Container, Dropdown, Row, Table } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { GiProfit } from "react-icons/gi";
import { IoShirtSharp } from "react-icons/io5";
import { CiShare1 } from "react-icons/ci";
import { HiOutlineSwitchVertical } from "react-icons/hi";
import { IoSettingsSharp } from "react-icons/io5";
import Modal from 'react-bootstrap/Modal';
import { FaShop } from 'react-icons/fa6';
import CommonSlider from '../../Components/CommonSlider';
import { useDispatch, useSelector } from 'react-redux';
import { frontEnd_API, header, siteConfig } from '../../Config/Config';
import axios from 'axios';
import { addStore, updateStore } from '../../Store/Slices/StoreSlice';
import { updateUser } from '../../Store/Slices/UserSlice';
import Loader from '../../Components/Loader';
import { keyCode } from '../../Config/Constant';
import ChartExample from '../../Components/charts/ChartExample';

function SellerDashboard() {

    const [show, setShow] = useState(false);
    const toggleShow = () => {
        setShow(!show)
    };
    const userData = useSelector((state) => state.user.value);
    const storeData = useSelector((state) => state.store.value);
    const [store, setStore] = useState([]);
    const [dashboard, setDashboard] = useState({});
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loader, setLoader] = useState(true);
    useEffect(() => {
        if (userData?.storeId === 0)
            navigate('/create-store')
    }, [userData, navigate])

    const getStoreList = async () => {
        try {
            const { data } = await axios.get(frontEnd_API.getStore, header)
            setStore(data?.data)
            if (data?.data?.length > 0) {
                setShow(true)
            }
        }
        catch (e) {
            console.log("Err::", e);
        }
    }

    const fetchData = async () => {
        if (!storeData) {
            // if (store?.length > 0) {
            try {
                const { data } = await axios.get(`${frontEnd_API.getSingleStore}/${userData?.storeId}`, header);
                console.log("data::", data);
                dispatch(addStore(data?.data));
                dispatch(updateUser({
                    storeCode: data?.data?.storeCode,
                    storeId: data?.data?.id
                }))
            } catch (e) {
                console.log(e);
            }
            // }
        }
    };

    useEffect(() => {
        fetchData();
    }, [])

    const customContainClick = async (item) => {
        toggleShow();
        console.log(item);
        try {
            const { data } = await axios.get(`${frontEnd_API.getSingleStore}/${item.id}`, header)
            console.log(data);
            dispatch(updateStore(data?.data));
            dispatch(updateUser({
                storeCode: data?.data?.storeCode,
                store: data?.data,
                storeId: data?.data?.id
            }))
            window.location.reload();
        }
        catch (e) {
            console.log(e);
        }
    }

    const date = new Date();
    const hour = date.getHours();
    let time = '';

    if (hour >= 0 && hour <= 11) {
        time = 'Good Morning';
    }
    else if (hour >= 12 && hour <= 17) {
        time = 'Good Afternoon';
    }
    else if (hour >= 18 && hour <= 23) {
        time = 'Good Evening';
    }

    const getDashboardData = async () => {
        try {
            const { data } = await axios.get(frontEnd_API.dashboard, header)
            setDashboard(data?.data);
            console.log("data: 114 :", data?.data, data?.data?.chartData?.customerDetailChart);
            setLoader(false);
        }
        catch (e) {
            if (e?.response.status == 401) {
                localStorage.clear();
                navigate('/signin');
            }
            console.log("e::", e);
            setLoader(false);
        }
    }

    useEffect(() => {
        getDashboardData();
        if (userData?.length > 0) {
            dispatch(updateUser(storeData?.storeCode))
        }
    }, [storeData?.id]);

    const [isSmall, setIsSmall] = useState(false);

    useEffect(() => {
        const checkScreenSize = () => {
            setIsSmall(window.innerWidth < 576);
        };
        window.addEventListener("resize", checkScreenSize);
        checkScreenSize();
        return () => window.removeEventListener("resize", checkScreenSize);
    }, [window.innerWidth]);

    document.title = `Seller Dashboard | ${siteConfig?.name}`;

    return (
        <div
            className='p-3'
            style={{ backgroundColor: '#f7f7f7', minHeight: "100vh" }}
        >
            <Container className='py-3 px-sm-0' >
                <div className='d-grid gap-2 gap-sm-0 d-sm-flex align-items-center justify-content-sm-between'>
                    <h3 className='m-0'>{time} <span className='fw-semibold text-capitalize'>{userData?.name}!</span></h3>
                    <Dropdown>
                        <Dropdown.Toggle className='fw-semibold custom-store-btn w-100' id="dropdown-basic">
                            Quick Action
                        </Dropdown.Toggle>

                        <Dropdown.Menu className='w-100'>
                            <Dropdown.Item as={Link} className='link-to-normal fs-16' to={'/create-store'}>
                                <i className='bi bi-house-door fs-18'></i>&nbsp;Create Store
                            </Dropdown.Item>
                            <Dropdown.Item as={Link} className='link-to-normal fs-16' to={'/create-orders'}>
                                <i className='bi bi-truck fs-18'></i>&nbsp;Create Order
                            </Dropdown.Item>
                            <Dropdown.Item as={Link} className='link-to-normal fs-16' to={'/category'}>
                                <i className='bi bi-tag fs-18'></i>&nbsp;Add Products
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </Container>

            {/* Store Details Banner */}
            <Container className='px-2 px-lg-0 pb-4'>
                <div className='d-grid d-sm-flex justify-content-center justify-content-sm-between seller-box p-3 gap-3 gap-sm-0'>
                    <div className='flex-between-align gap-2'>
                        <div className='logo-image-container'>
                            <img src={(storeData?.logo) ? storeData?.logo : require('../../Assets/Images/try-logo.png')} alt="" />
                        </div>
                        <div className='d-flex align-content-center gap-2'>
                            <div className='d-grid'>
                                <h4 className='m-0 fw-semibold text-capitalize'>{storeData?.name}</h4>
                                <Link
                                    className='fs-14 fs-md-12 fw-semibold'
                                    // to={`https://${storeData?.storeType == "SHOPIFY" ? storeData?.shopifyStoreUrl : (storeData?.customDomain?.length > 0 && !userData?.expired) ? storeData?.customDomain : storeData?.domain}`}
                                    to={storeData?.storeBaseURL}
                                    target='_blanck'
                                >
                                    {
                                        // storeData?.storeType == "SHOPIFY" 
                                        // ? storeData?.shopifyStoreUrl 
                                        // : (storeData?.customDomain?.length > 0 && !userData?.expired) 
                                        //     ? storeData?.customDomain 
                                        //     : storeData?.domain
                                        storeData?.storeBaseLabel ?? storeData?.storeBaseURL
                                    }
                                    <CiShare1 />
                                </Link>
                            </div>
                            <div className='custom-setting-btn'>
                                <Link to={'/store-setting'}><IoSettingsSharp /></Link>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-center align-items-center custom-store-dtl-banner gap-3'>
                        <Link className={`custom-store-dtl-banner-switch flex-center-align w-100 gap-1 ${(show) && 'custom-css-toggle'}`} onClick={() => {
                            getStoreList();
                        }}>
                            Switch <HiOutlineSwitchVertical />
                        </Link>
                        <Modal show={show} onHide={toggleShow} className='custom-select-store-modal' aria-labelledby="contained-modal-title-vcenter" centered>
                            <Modal.Header closeButton>
                                <Modal.Title> <span className='d-flex align-items-center gap-2'><FaShop className='fs-25 color-temp' />Switch Store</span> </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className='d-grid gap-2'>
                                    {
                                        store?.length > 0 &&
                                        store.map((item, index) => (
                                            <Link
                                                key={index}
                                                className={`custom-store-modal flex-center-align p-2 gap-2 ${(item?.id === storeData?.id) && 'custom-store-modal-selected'}`}
                                                onClick={() => customContainClick(item)}
                                            >
                                                <div className='custom-image-container-modal'>
                                                    <img src={(item?.logo) ? item?.logo : require('../../Assets/Images/try-logo.png')} alt="" />
                                                </div>
                                                <div className='d-grid '>
                                                    <h4 className=' m-0 text-capitalize'>{item?.name}</h4>
                                                    <span className='fs-12 fs-md-12'>Domain : {item?.storeBaseLabel}</span>
                                                </div>
                                            </Link>
                                        ))
                                    }
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
            </Container>

            {/* Order Counting part */}
            <Container className='order-cnt-tab'>
                <Row className='gy-3 gy-xl-0'>
                    <Col className='custom-counting-part col-12 col-sm-6 col-lg-4 col-xl-3 px-2 px-lg-2 ps-lg-0'>
                        <div className='custom-order-tab-content p-2 d-grid'>
                            <div className='custom-order-tab-header flex-between-align'>
                                <div className='flex-between-align gap-2 gap-md-3'>
                                    <i className="bi bi-border-style"></i>
                                    <h5 className="m-0 fs-lg-18 fs-md-16 fs-sm-14 fw-semibold">Total Order</h5>
                                </div>
                                <Link className="bi bi-info-circle">
                                    <div className='custom-order-tab-info'>
                                        total number of orders
                                    </div>
                                </Link>
                            </div>
                            <hr className='my-1' />
                            <div className='custom-order-tab-sub-cnt'>
                                <h3 className='fw-semibold mt-2 mt-sm-3'>{(dashboard?.countBox?.totalOrders) ?? 0}</h3>
                                <div className='d-flex justify-content-end'>
                                    <Link to={'/orders'} className='text-capitalize fw-semibold'>Go to Orders</Link>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className='custom-counting-part col-12 col-sm-6 col-lg-4 col-xl-3 px-2 px-lg-2'>
                        <div className='custom-order-tab-content p-2 d-grid'>
                            <div className='custom-order-tab-header flex-between-align'>
                                <div className='flex-between-align gap-2 gap-md-3'>
                                    <GiProfit />
                                    <h5 className="m-0 fs-lg-18 fs-md-16 fs-sm-14 fw-semibold">Total Revenue</h5>
                                </div>
                                <Link className="bi bi-info-circle">
                                    <div className='custom-order-tab-info'>
                                        Total revenue till date
                                    </div>
                                </Link>
                            </div>
                            <hr className='my-1' />
                            <div className='custom-order-tab-sub-cnt'>
                                <h4 className='fw-semibold mt-2 mt-sm-3'>{keyCode?.inr}{(dashboard?.countBox?.totalProfit) ?? 0}</h4>
                            </div>
                        </div>
                    </Col>
                    <Col className='custom-counting-part col-12 col-sm-6 col-lg-4 col-xl-3 px-2 px-lg-2 pe-lg-0'>
                        <div className='custom-order-tab-content p-2 d-grid'>
                            <div className='custom-order-tab-header flex-between-align'>
                                <div className='flex-between-align gap-2 gap-md-3'>
                                    <i className="bi bi-wallet2"></i>
                                    <h5 className="m-0 fs-lg-18 fs-md-16 fs-sm-14 fw-semibold">Wallet balance</h5>
                                </div>
                                <Link className="bi bi-info-circle">
                                    <div className='custom-order-tab-info'>
                                        {/* current total remaining wallet balance */}
                                        Remaining wallet balance
                                    </div>
                                </Link>
                            </div>
                            <hr className='my-1' />
                            <div className='custom-order-tab-sub-cnt'>
                                <h4 className='fw-semibold mt-2 mt-sm-3'>{keyCode?.inr}{(dashboard?.countBox?.walletBalance) ?? 0}</h4>
                                <div className='d-flex justify-content-end'>
                                    <Link to={'/payment'} className='text-capitalize fw-semibold'>check Wallet</Link>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className='custom-counting-part col-12 col-sm-6 col-lg-4 col-xl-3 px-2 px-lg-2 ps-lg-0 ps-lg-2 pe-lg-0'>
                        <div className='custom-order-tab-content p-2 d-grid'>
                            <div className='custom-order-tab-header flex-between-align'>
                                <div className='flex-between-align gap-2 gap-md-3'>
                                    <IoShirtSharp />
                                    <h5 className="m-0 fs-lg-18 fs-md-16 fs-sm-14 fw-semibold">Total Products</h5>
                                </div>
                                <Link className="bi bi-info-circle">
                                    <div className='custom-order-tab-info'>
                                        Total products created till date
                                    </div>
                                </Link>
                            </div>
                            <hr className='my-1' />
                            <div className='custom-order-tab-sub-cnt'>
                                <h4 className='fw-semibold mt-2 mt-sm-3'>{(dashboard?.countBox?.totalProducts) ?? 0}</h4>
                                {/* {
                  (parseInt(dashboard?.countBox?.lastProductDay) !== 0) &&
                  <span className='text-secondary fs-14 fs-sm-12'>{dashboard?.countBox?.lastProductDay && `Last Update on ${dashboard?.countBox?.lastProductDay} day ago`}</span>
                } */}
                                <div className='d-flex justify-content-end'>
                                    <Link to={'/category'} className='text-capitalize fw-semibold'>Add new Products</Link>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

            {/* Banner */}
            <div className='py-4 px-2 px-sm-0'>
                <Container className='custom-seller-banner seller-box p-0 d-flex'>
                    <div className='custom-seller-banner-gif'>
                        <img src={require('../../Assets/Gif/Hello.gif')} alt="" />
                    </div>
                    <div className='custom-seller-banner-content d-grid align-content-between p-2 p-md-3 p-lg-4'>
                        <h3 className='fw-semibold fs-md-20 mb-2 text-capitalize'>" {userData?.name} ! "</h3>
                        <ul className='fs-sm-10 fs-md-12 mb-2'>
                            <li>Choose from 200+ custom products to sell online!</li>
                            <li className='custom-display-none'>Open a free snap store to maximize your profits and sell merch through your own unique store link.</li>
                            <li className='custom-display-none'>Create stunning designs for free using our Product Customizer!</li>
                        </ul>
                        <div className='d-flex justify-content-start gap-2'>
                            <Link className='fs-sm-10 fs-md-12 p-1 px-2 text-center px-md-4 py-md-2 text-capitalize' to={'/category'}>Start Designing Product</Link>
                            <Link className='fs-sm-10 fs-md-12 p-1 px-2 text-center px-md-4 py-md-2 text-capitalize' to={'/create-orders'}>Create manual order</Link>
                            </div>
                    </div>
                </Container>
            </div>

            {/* Chart */}
            <Container className='p-0'>
                <Row className='gy-4'>
                    {
                        dashboard?.chartData?.customerDetailChart?.length > 0 && (
                            <Col className={`col-12 ${dashboard?.chartData?.orderDetailChart?.length > 0 ? 'col-md-6' : ""}`}>
                                <Card className='border-0 h-100'>
                                    <CardHeader className='bg-transparent border-0 py-1 pt-3 pt-sm-3'>
                                        <CardTitle className='mb-0 fs-18 fw-medium text-center' style={{ color: "#464646" }}>Traffic & Conversion</CardTitle>
                                    </CardHeader>
                                    <CardBody className='pt-1 h-100 d-grid align-content-center overflow-hidden'>
                                        <ChartExample
                                            data={{
                                                // title: { text: "Traffic & Conversion" },
                                                /*
                                                data: [
                                                    { date: "Mon", customer: 5, visit: 30 },
                                                    { date: "Tue", customer: 7, visit: 50 },
                                                    { date: "Wednes", customer: 10, visit: 70 },
                                                    { date: "Thurs", customer: 20, visit: 100 },
                                                    { date: "Fri", customer: 50, visit: 150 },
                                                    { date: "Satur", customer: 60, visit: 95 },
                                                    { date: "Sun", customer: 72, visit: 175 },
                                                ],
                                                */
                                                data: dashboard?.chartData?.customerDetailChart,
                                                series: [
                                                    {
                                                        type: "line",
                                                        xKey: "date",
                                                        yKey: "customer",
                                                        yName: "Customer",
                                                        stroke: "green",
                                                        marker: {
                                                            shape: "circle",
                                                            size: 10,
                                                            fill: "green",
                                                            stroke: "#FFFFFF",
                                                            strokeWidth: 2,
                                                        },
                                                    },
                                                    {
                                                        type: "line",
                                                        xKey: "date",
                                                        yKey: "visit",
                                                        yName: "Visit",
                                                        stroke: "orange",
                                                        marker: {
                                                            shape: "circle",
                                                            size: 10,
                                                            fill: "orange",
                                                            stroke: "#FFFFFF",
                                                            strokeWidth: 2,
                                                        },
                                                    }
                                                ],
                                                axes: [
                                                    {
                                                        type: "category",
                                                        position: "bottom", // X-axis
                                                    },
                                                    {
                                                        type: "number",
                                                        position: "left", // Y-axis
                                                        interval: {
                                                            step:
                                                                (Math.max(
                                                                    ...dashboard?.chartData?.customerDetailChart.map((item) => Math.max(item.visit, item.customer))
                                                                ) == 1) ? 1 : null
                                                        }
                                                    },
                                                ],
                                            }}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        )
                    }
                    {
                        dashboard?.chartData?.orderDetailChart?.length > 0 &&
                        <Col className={`col-12 ${dashboard?.chartData?.customerDetailChart?.length > 0 ? 'col-md-6' : ""}`}>
                            <Card className='border-0 pb-0 h-100'>
                                <CardHeader className='bg-transparent border-0 py-1 pt-3 pt-sm-3'>
                                    <CardTitle className='mb-0 fs-18 fw-medium text-center' style={{ color: "#464646" }}>
                                        Top Selling Products
                                    </CardTitle>
                                </CardHeader>
                                <CardBody className='pt-1'>
                                    <div>
                                        <Table responsive>
                                            <thead>
                                                <tr className='fs-13 fs-sm-10 fw-semibold'>
                                                    <th className='p-color' style={{ color: "#303030" }}>No</th>
                                                    <th className='p-color' style={{ color: "#303030" }}>Image</th>
                                                    <th className='p-color' style={{ color: "#303030" }}>Product name</th>
                                                    <th className='p-color' style={{ color: "#303030" }}>Orders</th>
                                                    <th className='p-color' style={{ color: "#303030" }}>Viewed</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    dashboard?.chartData?.orderDetailChart?.map((row, index) =>
                                                        <tr className='fs-13 fs-sm-10 fw-medium'>
                                                            <td className='py-2 p-color'>{index + 1}</td>
                                                            <td className='py-2 p-color' style={{ width: "50px" }}>
                                                                <div className='w-100 aspect-1-1 d-grid'>
                                                                    <Link to={`${`https://${storeData?.storeType == "SHOPIFY" ? storeData?.shopifyStoreUrl : !userData?.expired ? storeData?.customDomain ?? storeData?.domain : storeData?.domain}`}/product/${row?.slug}?ref=1`} target='_blank' style={{ backgroundColor: row?.colorCode }}>
                                                                        <img src={row?.thumbnail} className='w-100 h-100 object-cover' alt="" />
                                                                    </Link>
                                                                </div>
                                                            </td>
                                                            <td className='py-2 p-color text-wrap' style={{ minWidth: "130px" }}>
                                                                <Link to={`${`https://${storeData?.storeType == "SHOPIFY" ? storeData?.shopifyStoreUrl : !userData?.expired ? storeData?.customDomain ?? storeData?.domain : storeData?.domain}`}/product/${row?.slug ?? 1}?ref=1`} target='_blank' className='text-decoration-none p-color fw-semibold'>
                                                                    {row?.product}
                                                                </Link>
                                                            </td>
                                                            <td className='py-2 p-color'>{row?.orders}</td>
                                                            <td className='py-2 p-color'>{row?.viewed}</td>
                                                        </tr>)
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    }
                </Row>
            </Container>

            {/* BestSeller tab */}
            <div className='pt-3'>
                {
                    (loader) ?
                        <div className='w-100' style={{ aspectRatio: "4/1" }}>
                            <Loader />
                        </div>
                        : (dashboard?.ourBestSellerData?.length > 0) &&
                        <Container className='seller-box px-3'>

                            <CommonSlider
                                data={dashboard?.ourBestSellerData}
                                fromAPI={true}
                                title={'Our Bestsellers'}
                                isPartition={true}
                                seeClose={true}
                            />
                        </Container>
                }
            </div>
        </div>
    );
}

export default SellerDashboard;