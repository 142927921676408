import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import Redirection from './Redirection/Redirection';
import { keyCode } from '../Config/Constant';
import { TbPointFilled } from 'react-icons/tb';

function SingleProductCard({ item = {}, catgoryName = "" }) {

    const location = useLocation()

    return (
        <div className='seller-product-detail-container d-grid gap-2'>
            <div className='seller-product-detail-img-container'>
                <Redirection
                    redirectValue={{
                        url: `${location?.pathname.startsWith('/view') ? `/view/product/${item.id}` : `/product/${item.id}`}`,
                        data: { product: item, categoryName: catgoryName ?? "" }
                    }}>
                    <img src={item.thumbnail} className='img-one' alt="" />
                    <img src={item.thumbnailSlide} className='img-two' alt="" />
                </Redirection>
            </div>
            <div className='seller-product-detail-cnt-container px-2 pb-2 d-grid gap-1'>
                <Redirection
                    redirectValue={{
                        url: `${location?.pathname.startsWith('/view') ? `/view/product/${item.id}` : `/product/${item.id}`}`,
                        data: { product: item, categoryName: catgoryName ?? "" }
                    }}>
                    <Link className='fw-bold fs-sm-18'>{item?.name}</Link>
                </Redirection>
                <span className='fs-14 fs-sm-12'>By {item?.brandName}</span>
                {
                    item?.expired ? (
                        <>
                            <div className='fw-semibold'>From {keyCode?.inr}{item?.productFromPrice}</div>
                            <p className='color-info fs-14 fs-sm-12 m-0'>From {keyCode?.inr}{item?.offeredProductPrice} with Printfuse premuim</p>
                        </>
                    ) : (<div className='fw-semibold'>From {keyCode?.inr}{item?.productFromPrice}</div>)
                }
                <div className='fs-14 d-flex align-items-center d-flex justify-content-between align-items-center'>
                    <div>
                        {
                            (item?.variantData) &&
                            item?.variantData?.map((variant, vIndex) => {
                                return (
                                    <span key={vIndex}>
                                        <span>{variant?.total} {variant?.name} </span>
                                        {
                                            (item?.variantData.length !== (vIndex + 1)) &&
                                            <i className="bi bi-dot" />
                                        }
                                    </span>
                                )
                            })
                        }
                        {
                            item?.canvasSize && (
                                <>
                                    <TbPointFilled /><span>Print area {item?.canvasSize}</span>
                                </>)
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SingleProductCard