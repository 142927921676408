import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Container, Dropdown, Form, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import CommonPagination from '../../../Components/CommonPagination'
import { frontEnd_API, header } from '../../../Config/Config';
import axios from 'axios';
import { FaArrowLeftLong } from 'react-icons/fa6';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Thumbs } from 'swiper/modules';
import ProductPreviewImage from '../Products/ProductPreviewImageComponent';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { updateCart } from '../../../Store/Slices/cartSlice';
import { Tooltip } from 'react-tooltip';

function SelectCombo({
    tabHolder,
    setTabHolder,
    thumbsSwiper,
    setThumbsSwiper,
    defaultCombo
}) {

    const [comboList, setComboList] = useState([]);
    const [totalCount, setTotalCount] = useState([]);
    const [filterValue, setFilterValue] = useState({});
    const [backColor, setBackColor] = useState();
    const [combo, setCombo] = useState();
    const [selectedCombo, setSelectedCombo] = useState({});
    const [isOutOfStock, setIsOutOfStock] = useState(true);
    const dispatch = useDispatch();
    const cartData = useSelector((state) => state.cart.value);

    // 
    // Product List
    // 
    const getComboList = async () => {
        try {
            const response = await axios.put(frontEnd_API?.comboProduct, filterValue, header)
            if (response?.status == 200) {
                setComboList(response?.data?.data)
                setTotalCount(response?.data?.totalCount);
            }
        }
        catch (e) {
            console.error("Err::", e);
        }
    }

    useEffect(() => {
        getComboList();
    }, [filterValue])

    // 
    // Product Detail
    // 
    const getSingleCombo = async (slug) => {
        try {
            const { data } = await axios.get(`${frontEnd_API?.comboProductDetail}/${slug}`, header)
            const comboData = data?.data
            setCombo(comboData);
            const check = comboData?.variantData?.find((item) => ["color", "colors", "colour", "colours"].includes(item?.name?.toLowerCase()))

            comboData.defaultColor = comboData?.defaultColor ? check?.data?.filter(e => e?.code == comboData?.defaultColor)[0]?.code : check?.data[0]?.code
            // setBackColor(comboData?.defaultColor)
            setBackColor(comboData?.defaultColor)

            setIsOutOfStock(false)

            setSelectedCombo({
                isComboProduct: true,
                comboProductCode: comboData?.comboProductCode,
                quantity: 1,
                productData: (comboData?.productData?.length > 0) ? comboData?.productData?.map((item)=>{
                    return{
                        productId: item?.productId,
                        variantData: item?.defaultCartesin,
                        quantity: 1
                    }
                }):[],
            });
        }
        catch (e) {
            console.error("e::", e);
        }
    }

    useEffect(()=>{
            if(defaultCombo){
                getSingleCombo(defaultCombo);
            }
        },[defaultCombo])

    const checkActive = (checkData) => {
        const preDataValues = Object.values(selectedCombo?.variantData);
        const isValueInPreData = preDataValues.includes(checkData.value);
        return isValueInPreData;
    }

    const changeVariant = (obj = {}) => {
        if (obj?.name) {
            if (["color", "colors", "colour", "colours"].includes(obj?.name.toLowerCase())) {
                setBackColor(obj?.data?.code);
            }
            const updatedVariant = {
                ...selectedCombo,
                variantData: {
                    ...selectedCombo.variantData,
                    [obj?.name]: obj?.data?.value
                }
            }

            setIsOutOfStock(false)
            setSelectedCombo({ ...updatedVariant })
        }
    }

    const updateCartLocal = (props = {}) => {
        console.log("selectedCombo::", selectedCombo);
        
        dispatch(updateCart(selectedCombo))
        // getSingleCombo();

        if (cartData?.length > 0) {
            const existId = cartData?.find((item) => item?.comboProductCode == selectedCombo?.comboProductCode)
            toast(`Combo ${existId ? 'Updated' : 'Added'} in Cart !`)
        }
        else {
            toast("Product Added in Cart !")
        }

        if (parseInt(props?.try) === 1)
            setTabHolder("selectUser")
    }

  return (
    <div>
        <Container className='py-3 seller-product-page'>
            {
                (tabHolder == "selectProduct" || !combo) ?
                    <Row className='gy-4'>
                        <Container className='custom-seller-category-search px-0 pt-2 pt-sm-3 d-flex gap-2'>
                            <div className='d-flex align-items-center gap-2 gap-lg-3 px-2 px-lg-3' style={{ width: "calc(100% - 141px)" }}>
                                <label className='bi bi-search' htmlFor='text'></label>
                                <input
                                    type="text"
                                    className='py-2 fs-sm-14 pe-2'
                                    id='text'
                                    placeholder='Search by Product name'
                                    autoComplete='off'
                                    onChange={(e) => {
                                        setFilterValue((prevData) => ({
                                            ...prevData,
                                            search: e?.target?.value
                                        }))
                                    }}
                                />
                            </div>
                            <Button as={Link} to={'/create-combo'} variant='success' className='custom-bg-temp' style={{ lineHeight: "1.75rem" }}>Add Combos</Button>
                        </Container>
                        {
                            (comboList?.length > 0) ?
                                comboList.map((item, index) => (
                                    <Col key={index} className='col-12 col-sm-6 col-lg-4 col-xl-3 px-0 px-sm-2 seller-product-detail'>
                                        <div className='seller-product-detail-container d-grid gap-2'>
                                            <div className='seller-product-detail-img-container'>
                                                <div onClick={() => {
                                                    setTabHolder("selectedProduct")
                                                    setThumbsSwiper(null)
                                                    getSingleCombo(item?.slug)
                                                }}>
                                                    <img src={item?.thumbnailData[0]} style={{ backgroundColor: item?.defaultColor ? `${item?.defaultColor}` : 'transparent' }} className='img-one' alt={item?.title} />
                                                    <img src={item?.thumbnailData?.length>1 ? item?.thumbnailData[1] : item?.thumbnailData[0]} style={{ backgroundColor: item?.defaultColor ? `${item?.defaultColor}` : 'transparent' }} className='img-two' alt={item?.title} />
                                                </div>
                                            </div>
                                            <div className='seller-product-detail-cnt-container px-2 pb-2 d-grid gap-1'>
                                                <Link className='fw-semibold fs-sm-18' onClick={() => {
                                                    setTabHolder("selectedProduct")
                                                    getSingleCombo(item?.slug)
                                                }}>{item?.title}</Link>
                                                <div className='fw-semibold'>₹{Number(item?.price)}</div>
                                            </div>
                                        </div>
                                    </Col>
                                ))
                                : <h2 className='fw-semibold text-center d-grid d-sm-flex flex-center-align gap-sm-3'><i className="bi bi-slash-circle text-danger" />No combos added yet</h2>
                        }
                        <Col className='col-12 d-grid'>
                            <CommonPagination
                                limit={filterValue?.limit ?? 12}
                                totalCount={totalCount}
                                changePage={(data) => {
                                    setFilterValue((prevData) => ({
                                        ...prevData,
                                        limit: data?.limit,
                                        page: data?.page
                                    }))
                                }}
                            />
                        </Col>
                    </Row>
                    :
                    <Row>
                        <Col className='col-12 overflow-hidden'>
                            <div className='d-flex'>
                                <Link onClick={() => setTabHolder("selectProduct")} className='custom-single-order-back-link'><FaArrowLeftLong />Back</Link>
                            </div>
                            <div className='custom-product-page'>
                                <Container className='py-4'>
                                    <Row className='gy-4 gy-lg-0'>
                                        <Col className='col-12 col-lg-5 ps-lg-0'>
                                            <Row style={{ position: 'sticky', top: '0' }}>
                                                <Col className='col-12 col-md-0 pe-lg-0 single-big-slider'>
                                                    <div className="slider-container">
                                                        <Swiper
                                                            modules={[Thumbs]}
                                                            thumbs={{ swiper: thumbsSwiper }}
                                                            // navigation
                                                            className="main-slider"
                                                        >
                                                            {
                                                                combo?.thumbnails?.length> 0 &&
                                                                combo?.thumbnails.map((item, index) => (
                                                                    <SwiperSlide key={index}>
                                                                        <div className="img-holder">
                                                                            <ProductPreviewImage
                                                                                colorCode={backColor ?? '#ffffff'}
                                                                                objectData={{ preview: item }}
                                                                            />
                                                                        </div>
                                                                    </SwiperSlide>
                                                                ))}
                                                        </Swiper>

                                                        {/* Thumbnail Slider */}
                                                        <Swiper
                                                            onSwiper={setThumbsSwiper}
                                                            slidesPerView={4}
                                                            spaceBetween={10}
                                                            speed={500}
                                                            watchSlidesProgress
                                                            className="pt-1"
                                                        >
                                                            {
                                                                combo?.thumbnails?.length > 0 &&
                                                                combo?.thumbnails.map((item, index) => (
                                                                    <SwiperSlide key={index}>
                                                                        <div className="img-holder cursor">
                                                                            <img
                                                                                src={item}
                                                                                alt={combo?.title}
                                                                                style={{ backgroundColor: backColor ?? 'transparent' }}
                                                                            />
                                                                        </div>
                                                                    </SwiperSlide>
                                                                ))}
                                                        </Swiper>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col className='col-12 col-lg-7 custom-page-content ps-lg-5'>
                                            <div className='d-grid custom-page-title gap-4'>
                                                <h2 className='fw-semibold mb-2'>₹{combo?.price}
                                                    {/* <span className='fw-semibold fs-14'>Excl. Tax</span> */}
                                                </h2>
                                                {
                                                    combo?.variantData?.map((item, index) => {
                                                        return (
                                                            <div key={index}
                                                                className={`d-grid gap-1 
                                                                    ${(item?.name === 'size') ? 'custom-size' : ''}
                                                                    ${(item?.name === 'color') ? 'custom-color' : ''}`
                                                                }>
                                                                <div className='d-flex gap-1 align-items-end'>
                                                                    <span className='p-color'>{item?.name} : </span>
                                                                    {
                                                                        item?.data?.length > 0 &&
                                                                        item?.data.map((subItem, subIndex) => {
                                                                            const check = checkActive(subItem)
                                                                            if (check) {
                                                                                return (
                                                                                    <span key={subIndex} className='fw-semibold text-capitalize m-0'>{subItem?.label}</span>
                                                                                )
                                                                            }
                                                                        })
                                                                    }
                                                                    <p className='fw-semibold m-0 text-capitalize'>
                                                                        {combo?.variantData[index].data.label}
                                                                    </p>
                                                                </div>
                                                                {
                                                                    (item?.name === "color") ?
                                                                        <div className='d-flex custom-color-list gap-2'>
                                                                            {
                                                                                item?.data.map((subItem, subIndex) => {
                                                                                    return (
                                                                                        <>
                                                                                            <span
                                                                                                onClick={() => changeVariant({ name: item?.name, data: subItem })}
                                                                                                key={subIndex}
                                                                                                className={`${(checkActive(subItem)) ? 'active' : ''}`}
                                                                                                style={{ backgroundColor: subItem?.code }}
                                                                                                data-tooltip-id="my-close"
                                                                                                data-tooltip-content={`${subItem?.label}`}
                                                                                            >
                                                                                            </span>
                                                                                            <Tooltip id="my-close" place="top" />
                                                                                        </>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                        :
                                                                        <div className='d-flex custom-size-list gap-2'>
                                                                            {
                                                                                item?.data.map((subItem, subIndex) => {
                                                                                    return (
                                                                                        <span
                                                                                            onClick={() => changeVariant({ name: item?.name, data: subItem })}
                                                                                            key={subIndex}
                                                                                            className={`text-uppercase ${(checkActive(subItem)) ? 'active' : ''}`}
                                                                                        >
                                                                                            {subItem?.code}
                                                                                        </span>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                }
                                                <div className='d-grid gap-1 custom-size'>
                                                    <div className='d-flex gap-1'>
                                                        <span className='p-color'>Quantity: </span>
                                                        <p className='fw-semibold m-0 text-capitalize'>{selectedCombo?.quantity}</p>
                                                    </div>
                                                    <div className='d-flex custom-qty-list gap-0'>
                                                        <button
                                                            onClick={() => setSelectedCombo((prevData) => ({
                                                                ...prevData,
                                                                quantity: (selectedCombo?.quantity > 1) ? selectedCombo?.quantity - 1 : 1,
                                                                productData: selectedCombo?.productData?.map((item) => {
                                                                    return{
                                                                        ...item,
                                                                        quantity: (selectedCombo?.quantity > 1) ? selectedCombo?.quantity - 1 : 1,
                                                                    }
                                                                })
                                                            }))}
                                                        >
                                                            <i className="bi bi-dash-lg"></i>
                                                        </button>
                                                        <input type="number" value={selectedCombo?.quantity} className='text-center' readOnly />
                                                        <button
                                                            onClick={() => setSelectedCombo((prevData) => ({
                                                                ...prevData,
                                                                quantity: (selectedCombo?.quantity < 20) ? selectedCombo?.quantity + 1 : 20,
                                                                productData: selectedCombo?.productData?.map((item) => {
                                                                    return{
                                                                        ...item,
                                                                        quantity: (selectedCombo?.quantity < 20) ? selectedCombo?.quantity + 1 : 20,
                                                                    }
                                                                })
                                                            }))}
                                                        >

                                                            <i className="bi bi-plus-lg" />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className='flex-between-align gap-2 custom-cart-btn'>
                                                    {/* <button
                                                        className='fw-semibold flex-center-align gap-2'
                                                        onClick={updateCartLocal}>
                                                        <i className="bi bi-cart-plus-fill fs-16" />
                                                        Add to Cart
                                                    </button> */}
                                                    {
                                                        !isOutOfStock ? (
                                                            <button className='fw-semibold' onClick={() => {
                                                                updateCartLocal({ try: "1" })
                                                                // getCartList();
                                                            }}>Buy Now</button>
                                                        ) : (<button className='fw-semibold bg-danger' disabled>OUT OF STOCK</button>)
                                                    }
                                                </div>
                                                <div className='d-grid'>
                                                    <h4 className='my-2 theme-color'>About product</h4>
                                                    <p className='fs-14' dangerouslySetInnerHTML={{ __html: combo?.description }} ></p>
                                                </div>
                                            </div>
                                            <div className='d-grid gap-3'>
                                                {
                                                    combo?.productData?.length > 0 &&
                                                    combo?.productData.map((product, index)=>{
                                                        const selectedProduct = selectedCombo?.productData?.find((item)=>item?.productId == product?.productId)

                                                        const findColorObject = product?.variantData?.find((state)=>["color", "colors", "colour", "colours"].includes(state?.name.toLowerCase()))
                                                        const bgColor = findColorObject 
                                                        ? selectedProduct?.variantData?.map((item)=>{
                                                            const check = findColorObject?.data.find((state)=>state?.value == item)
                                                            if(check){
                                                                return check;
                                                            }
                                                        }).find((state)=>state !== undefined)?.code
                                                        : "transparent"
                                                        
                                                        return(
                                                            <Card key={index} className='bg-transparent border border-1'>
                                                                <CardBody>
                                                                    <div className='flex-start-align'>
                                                                        <div className='custom-img-holder-container-temp flex-start-align gap-2'>
                                                                            <div className='custom-img-holder h-100' style={{width: "120px"}}>
                                                                                <Link
                                                                                    // href={`/product/${product?.slug}`}
                                                                                    style={{background: bgColor ?? "transparent"}}
                                                                                >
                                                                                    <img src={product.thumbnail} className='img-one' alt={product?.name} />
                                                                                </Link>
                                                                            </div>
                                                                            <div className='custom-img-cnt d-grid'>
                                                                                <Link
                                                                                    className='fw-semibold m-0 fs-sm-8 fs-md-10 fs-lg-12 fs-14'
                                                                                    // href={`/product/${product?.slug}`}
                                                                                >
                                                                                    {product?.name}
                                                                                </Link>
                                                                                <div className='d-flex gap-2 flex-wrap py-2 py-sm-0'>
                                                                                    {
                                                                                        product?.variantData?.length > 0 &&
                                                                                        product?.variantData?.map((variant,subIndex)=>{
                                                                                            const findValue = selectedProduct?.variantData?.map((item)=>{
                                                                                                const check = variant?.data.find((state)=>state?.value == item)
                                                                                                if(check){
                                                                                                    return check;
                                                                                                }
                                                                                            })
                                                                                            const filteredValue = findValue?.length>0 ? findValue.find((state)=>state !== undefined)?.value : null
                                                                                            
                                                                                            return(
                                                                                                <div className='d-grid gap-1' key={subIndex}>
                                                                                                    <span className='fs-12 fw-semibold fs-md-10 fs-sm-8 text-capitalize'>{variant?.name} : </span>
                                                                                                    <Form.Select 
                                                                                                        key={subIndex}
                                                                                                        aria-label={variant?.label} 
                                                                                                        className='fs-12 fw-semibold fs-md-10 fs-sm-8 text-uppercase' 
                                                                                                        style={{maxWidth:"100px"}}
                                                                                                        value={filteredValue}
                                                                                                        onChange={(e)=>{
                                                                                                            selectedCombo.productData[index].variantData[subIndex] = e?.target?.value;
                                                                                                            setSelectedCombo({...selectedCombo});
                                                                                                            // handleStoreVariant({product, variant: e?.target?.value})
                                                                                                        }}
                                                                                                    >
                                                                                                        {
                                                                                                            variant?.data?.length>0 &&
                                                                                                            variant?.data?.map((data, petaIndex)=>{
                                                                                                                return(
                                                                                                                    <option 
                                                                                                                        key={petaIndex} 
                                                                                                                        value={data?.value}
                                                                                                                    >
                                                                                                                        {data?.label}
                                                                                                                    </option>
                                                                                                                )
                                                                                                            })
                                                                                                        }
                                                                                                    </Form.Select>
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                    <div className='d-grid gap-1'>
                                                                                        <span className='fs-12 fw-semibold fs-md-10 fs-sm-8 text-capitalize'>Qty :</span>
                                                                                        <span className='fs-12 fw-semibold fs-md-10 fs-sm-8 text-uppercase border p-1 rounded-2 text-center'>
                                                                                            {selectedProduct?.quantity}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                                <span className='fw-bold pt-1 fs-sm-10 fs-md-10 fs-lg-12 fs-14'>
                                                                                    ₹{Number(product?.price)}
                                                                                    {
                                                                                        (Number(product?.ogPrice) > Number(product?.price)) &&
                                                                                        <span className='ps-1 fw-semibold text-decoration-line-through mrp-text fs-sm-10 fs-md-12 fs-lg-14 fs-10'>
                                                                                            ₹{Number(product?.ogPrice)}
                                                                                        </span>
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </CardBody>
                                                            </Card>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </Col>
                    </Row>
            }
        </Container>
    </div>
  )
}

export default SelectCombo