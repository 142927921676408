import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    value: null
};

export const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        storeNotification: (state, action) => {
            state.value = action.payload;
        },
        cleanNotification: (state) => {
            state.value = null;
        },
    },
});

// Action creators are generated for each case reducer function
export const { storeNotification, cleanNotification } = notificationSlice.actions;

export default notificationSlice.reducer;