import React, { useEffect } from 'react'
import { BASE_URL, frontEnd_API, siteConfig, storage } from '../../Config/Config'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import Swal from 'sweetalert2'

function VerifyEmailPage() {
    const { token } = useParams()

    const styles = {
        container: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh",
            fontFamily: '"DM Sans", sans-serif',
            color: "#0d1117",
            backgroundColor: "#fdfdfd",
        },
        heading: {
            fontSize: "2rem",
            lineHeight: "1.67",
            marginTop: "0",
            marginBottom: "0.5rem",
        },
        loading: {
            width: "100%",
            borderRadius: "9999px",
            height: "4px",
            background: "#f3f3f3",
            overflow: "hidden",
        },
        loadingBar: {
            borderRadius: "9999px",
            height: "100%",
            width: "40%",
            transformOrigin: "center",
            background: "#0065d1",
            animation: "progressbar 1s ease-in-out alternate infinite",
        },
        link: {
            color: "#0057b4",
        },
    };

    const progressBarAnimation = `
        @keyframes progressbar {
          0% {
            transform: translateX(-75%);
          }
          100% {
            transform: translateX(225%);
          }
        }
      `;

    // function for verify email -----
    const verifyEmail = async () => {
        console.log(frontEnd_API?.sendVerifictionMail)
        try {
            const { data, status } = await axios.get(`${frontEnd_API?.verifyEmail}${token}`, {
                headers: {
                    'token': 'essentials',
                    'Content-Type': 'application/json'
                }
            })
            if (status == 200) {
                localStorage.removeItem(storage?.user);
                localStorage.removeItem(storage?.canvas);
                localStorage.removeItem(storage?.store);
                toast.success(data?.message)
                setTimeout(() => {
                    window.location = `${BASE_URL}signin`
                }, 2500)
            }

        } catch (error) {
            console.log('error :: ', error?.response?.data?.message)
            Swal.fire(
                ``,
                `${error?.response?.data?.message ?? "Error Occurs"}`,
                'error'
            );
        }
    }
    // -----
    useEffect(() => {
        verifyEmail()
    }, [])

    document.title = `Verify Email | ${siteConfig?.name}`;

    return (
        <div style={styles.container}>
            <ToastContainer />
            <style>{progressBarAnimation}</style>
            <div>
                <h1 style={styles.heading}>Processing...</h1>
                <div style={styles.loading}>
                    <div style={styles.loadingBar}></div>
                </div>
                <p>
                    Please wait a while email verification process complete.
                </p>
            </div>
        </div>
    )
}

export default VerifyEmailPage