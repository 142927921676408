import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Alert, Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { FaArrowLeftLong, FaLocationArrow, FaLocationCrosshairs } from "react-icons/fa6";
import Dropdown from 'react-bootstrap/Dropdown';
import { MdEdit, MdOutlineDone } from "react-icons/md";
import { GoDotFill } from "react-icons/go";
import { BsDownload } from "react-icons/bs";
import { frontEnd_API, header, siteConfig } from '../../Config/Config';
import axios from 'axios';
import ProductPreviewImage from './Products/ProductPreviewImageComponent';
import Loader from '../../Components/Loader';
import Select from 'react-select'
import Swal from 'sweetalert2';
import { shipRocketTrackLink } from '../../Config/Constant';

function SellerSingleReturnPage() {
    const params = useParams();

    // state and const define -----
    const nextStatusData = [
        {
            label:'Select Next Status',
            value:'',
            compare:''
        },
        {
            label:'Accept',
            value:'accepted',
            compare:'pending'
        },
        {
            label:'Cancel',
            value:'cancelled',
            compare:'pending'
        },
        {
            label:'Picked Up',
            value:'picked-up',
            compare:'accepted'
        },
        {
            label:'In Transit',
            value:'in-transit',
            compare:'picked-up'
        },
        {
            label:'Received',
            value:'received',
            compare:'in-transit'
        }
    ]

    const [selectedStatus, setSelectedStatus] = useState('');
    const [returnData, setReturnData] = useState();
    const [loader, setLoader] = useState(true);
    const moment = require('moment');

    const getSingleReturn = async () => {
        try {
            const { data } = await axios.get(`${frontEnd_API.returnorder}/${params?.returnOrderNumber}`, header)
            console.log("data::", data);
            setReturnData(data?.data)
            if (data?.status === 400) {
                window.location = '/returns'
            }
            setLoader(false);
        }
        catch (e) {
            console.log("e::", e);
            setLoader(false);
        }
    }
    console.log("Number(returnData?.returnOrderCouponAmount) > 0 || Number(returnData?.returnOrderDiscountAmount) > 0 :: ", Number(returnData?.returnOrderCouponAmount) > 0 || Number(returnData?.returnOrderDiscountAmount) > 0)
    
    // function for update return order status -----
    const manageOrderStatus = async (returnOrderStatus = '') => {

        if (returnOrderStatus?.length > 0) {
            try {
                const { data, status } = await axios?.put(`${frontEnd_API?.returnorder}/status/${returnData?.id}`, { returnOrderStatus }, header)
                if (status === 200) {
                    Swal.fire(
                        data?.message,
                        `Your selected order status updated with ${returnOrderStatus?.replace(/-/g, ' ')}!`,
                        'success'
                    );
                    returnData.returnOrderStatus = returnOrderStatus
                    setReturnData({...returnData})
                }
                console.log("128 sss :: ", data)
            } catch (error) {
                console.log("erroor ::: ", error)
            }
        }
    }
    // -----


    useEffect(() => {
        getSingleReturn();
    }, [])    

    document.title = `#${returnData?.returnOrderNumber} | ${siteConfig?.name}`;

    return (
        <div className='py-2 custom-single-order-container'>
            <Container className='custom-single-order py-4'>
                <div className='d-grid'>
                    <div className='d-flex'>
                        <Link to={`/returns/${params?.groupStatus?? 'return'}/${params?.returnOrderStatus?? 'all'}`} className='custom-single-order-back-link'><FaArrowLeftLong />Back</Link>
                    </div>
                    <div className='d-grid gap-2 gap-lg-0 d-lg-flex justify-content-sm-between align-items-center pt-3'>
                        <div className='d-grid'>
                            <h4 className='h4 fw-semibold m-0 fs-md-20 fs-sm-16 text-capitalize'>Return Order : {returnData?.returnOrderNumber}</h4>
                            <div className='d-flex gap-2'>
                                <span className='p-color fs-14 fs-sm-12'>Created {moment(returnData?.returnOrderDate).format('MMM D, YYYY')}</span>
                            </div>
                        </div>
                        <div className='d-none d-sm-flex gap-2 custom-order-header-btn justify-content-sm-end'>
                            {/* <Link className='flex-between-align gap-2 fs-14'><RiDeleteBin6Line />Cancel order</Link> */}
                            {/* <Link className='flex-between-align gap-2 fs-14'><IoDuplicate />Duplicate</Link> */}
                            {/* <Link className='flex-between-align gap-2 fs-14'><BsDownload />Download Invoice</Link> */}
                            {/* <PDFDownloadLink
                                document={<Invoices data={
                                    {
                                        invoiceTitle: "Order Invoice",
                                        customerName: returnData?.userData?.name,
                                        customerAddress: {
                                            addressLine1: returnData?.returnOrderPickUpAddressLine1,
                                            addressLine2: returnData?.returnOrderPickUpAddressLine2,
                                            area: returnData?.returnOrderPickUpArea,
                                            city: returnData?.returnOrderPickUpCity,
                                            state: returnData?.returnOrderPickUpState,
                                            pinCode: returnData?.returnOrderPickUpPincode,
                                        },
                                        customerMobileNumber: returnData?.userData?.mobile,
                                        customerShippingAddressState: returnData?.returnOrderShippingCity,
                                        invoiceNumber: returnData?.returnOrderNumber,
                                        purchaseOrderNumber: returnData?.returnOrderId,
                                    }
                                } />}
                                fileName="myDocument.pdf"
                            >
                                {({ blob, url, loading, error }) =>
                                    loading ? 'Loading document...' : <span className='flex-center-align gap-2 fs-14'><BsDownload className='me-1' /> Download Invoice</span>
                                }
                            </PDFDownloadLink> */}
                            {/* <PDFViewer width="100%" height="600">
                                <Invoices />
                            </PDFViewer> */}
                        </div>
                        <div className='d-grid d-sm-none w-100'>
                            <Dropdown className='w-100'>
                                <Dropdown.Toggle variant="light" id="dropdown-basic" className='w-100' style={{ backgroundColor: "white" }}>
                                    More
                                </Dropdown.Toggle>

                                <Dropdown.Menu className='w-100'>
                                    <Dropdown.Item className='d-flex align-items-center gap-2'><BsDownload />Download Invoice</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>

                {
                    loader ?
                        <div className='d-grid align-content-center' style={{ minHeight: "80vh" }}>
                            <div className='w-100 aspect-4-1 aspect-md-2-1'>
                                <Loader />
                            </div>
                        </div>
                        : <Row className='pt-3'>
                            <Col className='col-12 col-md-8 d-grid gap-3 align-content-start' >

                                <div className='d-grid seller-box p-3 p-lg-4 gap-3'>

                                    {/* Order Status Bar */}
                                    <div className="d-block mt-2 d-sm-flex flex-between-align custom-order-status-bar mb-sm-3 d-flex w-100">
                                        <div
                                            className={`w-100 align-items-center custom-order-status-bar-div gap-2 fw-semibold fs-14 custom-bg-info bg-danger text-center text-light text-capitalize `}
                                            style={{ borderRadius: "2rem" }}
                                        >
                                            Product requested for return
                                        </div>
                                    </div>
                                    {/* Price List */}
                                    <div className='d-grid gap-1 gap-sm-2'>
                                        <div className='custom-price-list-table'>
                                            <div>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th className='fs-14 text-center'>Image</th>
                                                            <th className='fs-14 text-center'>Product name</th>
                                                            <th className='fs-14 text-center'>Qty</th>
                                                            <th className='fs-14 text-center'>Price</th>
                                                            {/* <th className='fs-14 text-center'>Sub Total</th> */}
                                                            <th className='fs-14 text-center'>Discount</th>
                                                            <th className='fs-14 text-center'>Total</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            (returnData?.returnOrderChildData?.length > 0) &&
                                                            returnData?.returnOrderChildData.map((row, pIndex) => {
                                                                // console.log("row::", row);
                                                                const colorCode = row?.childOrderVariantData?.filter(e => ['color', 'colors', 'colour', 'colours']?.includes(e?.parentName))?.map(e => e?.variantCode)

                                                                return (
                                                                    <tr className='fs-14'>
                                                                        <td className='text-center p-0 m-0' style={{ width: "75px" }}>
                                                                            <div className='custom-order-product-img border-card'>
                                                                                <ProductPreviewImage
                                                                                    colorCode={colorCode ?? '#ffffff'}
                                                                                    isButtonShow={false}
                                                                                    customScale={10}
                                                                                    objectData={{preview: row?.productThumbnail || null }} />
                                                                            </div>
                                                                        </td>
                                                                        <td className='ps-2 text-capitalize'>
                                                                            <div className="d-grid gap-1 align-content-center">
                                                                                {/* <span>{row?.productName.substring(0, 18) + '...'}</span> */}
                                                                                <span>{row?.productName}</span>
                                                                                <div className='d-flex flex-wrap gap-2'>
                                                                                    {
                                                                                        row?.childOrderVariantData?.length > 0 &&
                                                                                        row?.childOrderVariantData.map((variant, varIndex) => (
                                                                                            <span key={varIndex} className='text-capitalize fs-12 fs-sm-12'>{variant?.parentName} : <span className='text-uppercase'>{variant?.variantName}</span></span>
                                                                                        ))
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className='text-center'>{parseInt(row?.productQuantity)}</td>
                                                                        <td className='text-center'>{parseInt(row?.productPrice)}</td>
                                                                        {/* <td className='text-center'>{parseInt(row?.productSubTotal)}</td> */}
                                                                        <td className='text-center'>{Number(row?.productDiscount?? 0) + Number(row?.productCouponAmount?? 0)}</td>
                                                                        <td className='text-center fw-semibold'>₹{row?.productTotal}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <th className='fs-16 text-center' colSpan={5}>Product Total</th>
                                                            <th className='fs-16 text-center'>₹{parseFloat(returnData?.returnOrderNetAmount)}</th>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Order Main Tracking */}

                                <div className='d-grid seller-box p-3 p-lg-4 gap-2'>
                                    <h5 className='my-2 fw-semibold color-temp d-flex align-items-center gap-3'><FaLocationCrosshairs /> Order Summary :</h5>
                                    <div className='d-grid timeline'>
                                        {
                                            (returnData?.returnOrderTrackingData?.length > 0) &&
                                            returnData?.returnOrderTrackingData.map((item, index) => {
                                                return (
                                                    <div key={index} className={`timeline-item ${((returnData?.returnOrderTrackingData?.length - 1) === index) ? 'lastItem' : ''}`}>
                                                        <div
                                                            className={`timeline-icon circle-one mt-1 ms-1`}
                                                            style={{
                                                                backgroundColor: (index === 0) ? 'darkblue' : 'grey',
                                                                outlineColor: (index === 0) ? 'darkblue' : 'grey',
                                                            }}
                                                        ></div>
                                                        <div className='timeline-content'>
                                                            <span className='timestamp'>{moment(item?.updatedAt).format('MMM D, YYYY h:mm A')}</span>
                                                            <div>
                                                                <b className='text-capitalize'>{item?.trackingStatus}</b>
                                                                <span className='description'>{item?.trackingRemark}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </Col>
                            <Col className='col-12 col-md-4 gap-2 pt-4 pt-md-0 '>
                                <div className='position-sticky ' style={{ top: "25px" }}>
                                    {
                                        (returnData?.returnOrderCourierCompanyId > 0) &&
                                        <div className='d-grid mb-4 seller-box align-content-start' style={{ overflow: 'hidden' }}>
                                            <div className='flex-between-align p-3 border-bottom'>
                                                <h5 className='fw-semibold m-0 fs-lg-16'>Logistics Details</h5>
                                                {
                                                    (returnData?.returnShipRocketOrderId) > 0 && (
                                                        <Link
                                                            target='_blank'
                                                            to={shipRocketTrackLink?.replace('{{AWb_CODE}}', returnData?.returnShipRocketOrderTrackingDetail
                                                                ?.awb_code)}
                                                            className='fw-semibold bg-success text-white px-2 py-1 rounded '
                                                        >
                                                            <FaLocationArrow />&nbsp;Track
                                                        </Link>
                                                    )
                                                }
                                            </div>
                                            <div className='d-grid p-3'>
                                                <div className='w-100 aspect-3-1'>
                                                    <img src={require('../../Assets/Gif/truck.c55607e57cfb92d8eca1.gif')} className='w-100 h-100' style={{ objectFit: "contain" }} alt="" />
                                                </div>
                                                <h6 className='fw-semibold m-0 fs-lg-16 text-center'>{returnData?.returnOrderCourierDetail?.courierName}</h6>
                                            </div>
                                            {(returnData?.returnShipRocketOrderId) > 0 &&
                                                <div className='d-grid p-3'>
                                                    <section className='d-grid gap-2'>
                                                        <div className='d-grid d-flex justify-content-between gap-2 fs-12'>
                                                            <div className="d-grid">
                                                                <span>Shipping Order ID</span>
                                                                <span className='fw-semibold'>{returnData?.returnShipRocketOrderId}</span>
                                                            </div>
                                                            <div className="d-grid">
                                                                <span className='text-end'>Shipment ID</span>
                                                                <span className='fw-semibold text-end'>{returnData?.returnShipRocketShipmentId}</span>
                                                            </div>
                                                        </div>
                                                        <div className='d-grid d-flex justify-content-between gap-2 fs-12'>
                                                            <div className="d-grid">
                                                                <span>Routine Code</span>
                                                                <span className='fw-semibold'>{returnData?.returnShipRocketOrderTrackingDetail?.routing_code}</span>
                                                            </div>
                                                            <div className="d-grid">
                                                                <span className='text-end'>Pickup Date</span>
                                                                <span className='fw-semibold text-end'>{moment(returnData?.returnShipRocketOrderTrackingDetail?.pickup_scheduled_date).format('MMM D, YYYY h:mm A')}</span>
                                                            </div>
                                                        </div>
                                                    </section>
                                                </div>}
                                        </div>
                                    }

                                    <div className='d-grid seller-box align-content-start mb-4'>
                                        <div className='flex-between-align p-3 border-bottom'>
                                            <h5 className='fw-semibold m-0 fs-lg-16'>Update Status</h5>
                                        </div>
                                        <div className='d-grid p-3 gap-2'>
                                        {
                                            !['pending', 'cancelled', 'return-rto-delivered', 'delivered', 'received']?.includes(returnData?.returnOrderStatus) && (
                                                <>
                                                    <div className='d-flex gap-2'>
                                                        <Select
                                                            options={nextStatusData?.filter(e => ['', null, returnData?.returnOrderStatus]?.includes(e?.compare))}
                                                            value={nextStatusData?.filter(e => e?.value == selectedStatus)}
                                                            onChange={(e) => {
                                                                setSelectedStatus(e?.value)
                                                                manageOrderStatus(e?.value)
                                                            }}
                                                            className='w-100'
                                                        />
                                                    </div>
                                                    <hr className='my-1 mt-2' />
                                                </>
                                            )   
                                        }
                                            <Alert
                                                className='m-0 text-center fw-semibold pb-1 fs-lg-16 fs-18 text-capitalize py-1 px-2 px-sm-3'
                                                variant={"danger"}
                                            >
                                                {returnData?.returnOrderStatus?.replace(/-/g, ' ')?.toLowerCase()}
                                            </Alert>
                                        </div>
                                        <div className='d-flex flex-column align-items-start gap-2 px-3 py-2'>
                                            <span className='fw-semibold fs-16'>Reason</span>
                                            <span className='fs-15 fw-semibold text-secondary first-capitalize'>{returnData?.returnOrderReasonData?.reasonName?.replace(/-/g, ' ')?.toLowerCase()}</span>
                                            <span className='fs-15 fw-semibold text-secondary first-capitalize'>{returnData?.returnOrderReasonData?.descritpion?.replace(/-/g, ' ')?.toLowerCase()}</span>
                                        </div>
                                    </div>

                                    <div className='d-grid seller-box align-content-start' style={{ overflow: 'hidden' }}>
                                        <div className='flex-between-align p-3 border-bottom'>
                                            <h5 className='fw-semibold m-0 fs-lg-16'>Customer</h5>
                                            {/* <Link className='fs-18 fs-lg-14 link-to-normal flex-between-align gap-2'><MdEdit />Edit</Link> */}
                                        </div>
                                        <div className='d-grid p-3 border-bottom'>
                                            <b className='fw-semibold fs-lg-16 fs-18 pb-1'>Personal details</b>
                                            <span className='fs-lg-12 fs-14 d-flex gap-1 fw-semibold'>
                                                <i className="bi bi-person-circle"></i>
                                                {returnData?.returnOrderPickUpName}
                                            </span>
                                            <span className='fs-lg-12 fs-14 d-flex gap-1'>
                                                <i className="bi bi-envelope"></i>
                                                {returnData?.returnOrderPickUpEmail}
                                            </span>
                                            <span className='fs-lg-12 fs-14 d-flex gap-1'>
                                                <i className="bi bi-telephone-fill"></i>
                                                {returnData?.returnOrderPickUpMobile}
                                            </span>
                                        </div>
                                        <div className='d-grid p-3'>
                                            <section className='flex-between-align'>
                                                <b className='fw-semibold pb-1 fs-lg-16 fs-18'>Shipping address</b>
                                            </section>
                                            <p className='m-0 fs-lg-12 fs-14 p-color d-flex flex-wrap text-capitalize'>
                                                {returnData?.returnOrderShippingAddressLine1}, {returnData?.returnOrderShippingAddressLine2}, {returnData?.returnOrderShippingArea}, {returnData?.returnOrderShippingCity}, {returnData?.returnOrderShippingState}, {returnData?.returnOrderShippingCountry} - {returnData?.returnOrderShippingPincode}
                                            </p>
                                        </div>
                                    </div>
                                    <div className='mt-4 d-grid seller-box align-content-start' style={{ overflow: 'hidden' }}>
                                        <div className='flex-between-align p-3 border-bottom'>
                                            <h5 className='fw-semibold m-0 fs-lg-16'>Billing</h5>
                                            {/* <Link className='fs-16 fs-lg-14 fw-semibold link-to-normal seller-box flex-between-align  px-lg-3 py-lg-1 px-md-2 px-3 py-1 gap-2'><BsDownload />Download</Link> */}
                                        </div>
                                        <div className='flex-between-align p-3 border-bottom'>
                                            <span className='fs-lg-14'>Sub total</span>
                                            <b className='fs-lg-14 text-success'>₹{returnData?.returnOrderSubTotalAmount}</b>
                                        </div>
                                        {
                                            (Number(returnData?.returnOrderCouponAmount) > 0 || Number(returnData?.returnOrderDiscountAmount) > 0) && (
                                                <div className='d-grid gap-1 p-3 border-bottom'>
                                                    <div className='flex-between-align'>
                                                        <span className='fs-lg-14'>Discount</span>
                                                        <b className='fs-lg-14'>- ₹{Number(returnData?.returnOrderCouponAmount) + Number(returnData?.returnOrderDiscountAmount)}</b>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        {/* <div className='d-grid gap-1 p-3 border-bottom'>
                                            <div className='flex-between-align'>
                                                <span className='fs-lg-14'>Total Shipping cost</span>
                                                <b className='fs-lg-14 text-danger'>- ₹{returnData?.returnOrderShippingChargeAmount}</b>
                                            </div>
                                        </div> */}
                                        <div className='flex-between-align p-3'>
                                            <b className='fs-lg-14'>Total costs</b>
                                            <b className='color-info fs-lg-16 fs-18'>₹{returnData?.returnOrderNetAmount}</b>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                }
            </Container>
        </div>
    )
}

export default SellerSingleReturnPage