import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaArrowLeft } from "react-icons/fa6";
import { IoClose, IoSearch } from "react-icons/io5";
import { Link } from "react-router-dom";

const StickerPicker = ({ onSelect }) => {
  const [emojis, setEmojis] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [filteredEmojis, setFilteredEmojis] = useState([]);
  const [searchedText, setSearchedText] = useState("");
  const [searchedEmojis, setSearchedEmojis] = useState([]);

  // Function to fetch emojis and categories
  const fetchEmojis = async () => {
    try {
      const response = await fetch("https://raw.githubusercontent.com/hfg-gmuend/openmoji/master/data/openmoji.json");
      const data = await response.json();

      // Use Promise to extract unique categories
      const categoriesPromise = new Promise((resolve) => {
        const uniqueCategories = [...new Set(data.map((emoji) => emoji.group))];
        resolve(uniqueCategories);
      });

      // Wait for categories to be processed
      const uniqueCategories = await categoriesPromise;
      
      // Update state
      setEmojis(data);
      setCategories(uniqueCategories);
    } catch (error) {
      console.error("Failed to load OpenMoji:", error);
    }
  };

  // Call fetch function inside useEffect
  useEffect(() => {
    fetchEmojis();
  }, []);

  useEffect(()=>{
    handleFilterCategory();
  },[emojis])

  const handleFilterCategory = (categoryName = selectedCategory) => {
    const newData = (categoryName !== "")
      ? emojis.filter((emoji) => emoji.group === categoryName)
      : emojis.slice(0, 15); // Default emojis
    setFilteredEmojis([...newData])
  }

  const handleSearchProducts = (e) => {
    if(e?.target?.value == ""){
      setSearchedEmojis([]);
      setSearchedText("");
    } else {
      const lowerCaseQuery = e?.target?.value.toLowerCase();
      const searchData = emojis.filter(emojiObj => 
        emojiObj.annotation.toLowerCase().includes(lowerCaseQuery) || 
        emojiObj.tags.toLowerCase().includes(lowerCaseQuery)
      );
      setSearchedEmojis([...searchData]);
      setSearchedText(e?.target?.value);
    }
  }

  return (
    <div>
      {/* Dropdown to Select Category */}
        {/* <select 
            onChange={(e) => {
                setSelectedCategory(e.target.value)
                handleFilterCategory(e.target.value)
            }}
        >
        <option value="">Select Category</option>
        {categories.map((category) => (
          <option key={category} value={category}>
            {category.replace("-", " ")}
          </option>
        ))}
      </select> */}

      {/* Emoji Grid */}
      {/* <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fill, minmax(50px, 1fr))", gap: "10px", maxHeight: "200px", overflowY: "auto", padding: "10px" }}>
        {filteredEmojis.map((emoji, index) => {
          const imgURL = `https://printfuse.blr1.cdn.digitaloceanspaces.com/printfuse/stickers/${emoji.hexcode}.png`
          return(
              <img
                key={index}
                src={imgURL}
                alt={emoji.annotation}
                title={emoji.annotation}
                style={{ width: "40px", cursor: "pointer" }}
                onClick={() => onSelect(imgURL)}
              />
          )
        })}
      </div> */}

      {
        
        selectedCategory == "" ?
        <Container className="p-0 overflow-hidden d-grid gap-4">
          <Row className="">
            <Col className="col-12">
              <label htmlFor="" className="d-grid position-relative">
                {/* <span><IoSearch /></span> */}
                <input 
                  type="text"
                  className="search-input-clipart w-100"
                  value={searchedText}
                  placeholder="Search stickers..."
                  onChange={handleSearchProducts}
                />
                {
                  (searchedText !== "") &&
                  <span 
                    className="position-absolute end-0 cursor-pointer"
                    style={{
                      top: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                    onClick={()=>{
                      setSearchedText("");
                      setSearchedEmojis([])
                    }}
                  >
                    <IoClose />
                  </span>
                }
              </label>
            </Col>
          </Row>
          {
            searchedText !== ""
            ? <Row className="gy-2">
                <Col className="col-12">
                  <Col className="col-12">
                    <div className="flex-between-align">
                      <h5 className="fw-semibold">{searchedText}</h5>
                      {/* {
                          <Link 
                            className="fs-14 flex-center-align gap-1 text-dark text-decoration-none"
                            onClick={()=>{
                              setSelectedCategory("")
                            }}
                          >
                            <FaArrowLeft />
                            Back
                          </Link>
                      }  */}
                    </div>
                  </Col>
                </Col>
                <Col>
                  <Row  xs={5} md={5} className="p-0 gy-2">
                    {
                      searchedEmojis?.length > 0 ?
                      searchedEmojis?.map((emoji, index) => {
                        const imgURL = `https://printfuse.blr1.cdn.digitaloceanspaces.com/printfuse/stickers/${emoji.hexcode}.png`
                        return(
                            <Col>
                              <div className="w-100 aspect-1-1 cursor-pointer">
                                <img
                                  key={index}
                                  src={imgURL}
                                  alt={emoji.annotation}
                                  title={emoji.annotation}
                                  className="w-100 h-100 object-contain"
                                  // style={{ width: "40px", cursor: "pointer" }}
                                  onClick={() => onSelect(imgURL)}
                                />
                              </div>
                            </Col>
                        )
                      })
                      : <h5 className="text-center w-100 m-0 py-4">No search result found</h5>
                    }
                    </Row>
                </Col>
              </Row>
            : categories.map((category, mainIndex)=>{
              const emojiData = emojis.filter((emoji) => emoji.group === category);
              return(
                <Row className="">
                  <Col className="col-12">
                    <Col className="col-12">
                      <div className="flex-between-align">
                        <h6 className="fw-semibold first-cap m-0">{category.replace("-", " ")}</h6>
                        {
                          emojiData?.length > 15 && 
                            <span 
                              className="fs-14 text-dark text-decoration-underline pointer"
                              onClick={()=>{
                                setSelectedCategory(category)
                                handleFilterCategory(category)
                              }}
                            >
                              See all
                            </span>
                        } 
                      </div>
                    </Col>
                  </Col>
                  <Col>
                    <Row key={mainIndex} xs={5} md={5} className="p-0 gy-2">
                      {emojiData?.slice(0, 15).map((emoji, index) => {
                        const imgURL = `https://printfuse.blr1.cdn.digitaloceanspaces.com/printfuse/stickers/${emoji.hexcode}.png`
                        return(
                            <Col>
                              <div className="w-100 aspect-1-1 cursor-pointer">
                                <img
                                  key={index}
                                  src={imgURL}
                                  alt={emoji.annotation}
                                  title={emoji.annotation}
                                  className="w-100 h-100 object-contain"
                                  // style={{ width: "40px", cursor: "pointer" }}
                                  onClick={() => onSelect(imgURL)}
                                />
                              </div>
                            </Col>
                        )
                      })}
                      </Row>
                  </Col>
                </Row>
              )
            })
          }
        </Container>
      : <Container className="p-0 overflow-hidden d-grid gap-4">
          <Row className="gy-2">
            <Col className="col-12">
              <Col className="col-12">
                <div className="flex-between-align">
                  <h5 className="fw-semibold first-cap">{selectedCategory.replace("-", " ")}</h5>
                  {
                      <span 
                        className="fs-14 flex-center-align gap-1 text-dark text-decoration-none pointer"
                        onClick={()=>{
                          setSelectedCategory("")
                        }}
                      >
                        <FaArrowLeft />
                        Back
                      </span>
                  } 
                </div>
              </Col>
            </Col>
            <Col>
              <Row  xs={5} md={5} className="p-0 gy-2">
                {filteredEmojis?.map((emoji, index) => {
                  const imgURL = `https://printfuse.blr1.cdn.digitaloceanspaces.com/printfuse/stickers/${emoji.hexcode}.png`
                  return(
                      <Col>
                        <div className="w-100 aspect-1-1 cursor-pointer">
                          <img
                            key={index}
                            src={imgURL}
                            alt={emoji.annotation}
                            title={emoji.annotation}
                            className="w-100 h-100 object-contain"
                            // style={{ width: "40px", cursor: "pointer" }}
                            onClick={() => onSelect(imgURL)}
                          />
                        </div>
                      </Col>
                  )
                })}
                </Row>
            </Col>
          </Row>
        </Container>
      }
    </div>
  );
};

export default StickerPicker;
