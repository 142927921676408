import React from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import { TbPointFilled } from "react-icons/tb";
import { SERVER_URL } from '../Config/Config';
import { keyCode } from '../Config/Constant';

function CommonSlider(props = {}) {

    const custsettings = {
        dots: false,
        infinite: props?.data?.length > 1 ? true : false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: false,
        autoplaySpeed: 0,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    autoplay: false,
                    autoplaySpeed: 0,
                }
            }
        ]
    };

    return (
        <>
            {
                props?.data?.length > 0 && (
                    <Container className='custom-seller-category-slider-trending p-2 py-3 py-sm-4 overflow-hidden'>
                        <div>
                            <h4 className={`fw-semibold text-capitalize ${(!props?.description) && 'd-none'}`} style={{ maxWidth: "65%" }}>{props?.title}</h4>
                            <p className='mb-0'>{props?.description}</p>
                        </div>
                        <div className={`${(props?.seeClose && props?.description) && 'pb-5'}`}>
                            <h4 className={`fw-semibold text-capitalize fs-sm-18 ${(props?.description) ? 'd-none' : ""}`} style={{ maxWidth: "65%" }}>{props?.title}</h4>
                            <Link to={props?.url ?? "#"} className={`text-decoration-none ${(props?.seeClose) && 'd-none'}`}>See All</Link>
                        </div>
                        <hr className={(!props.isPartition) ? 'd-none' : "my-2"} />
                        <Slider {...custsettings}>
                            {
                                props?.data.map((item, index) => {
                                    return (
                                        <Link to={((props?.fromAPI) ? `/product/${item?.id}` : '')} key={index} className='text-decoration-none custom-slider-products'>
                                            <div className='slider-trending-container'>
                                                <img src={item.thumbnail} className='img-one' alt="" />
                                                <img src={item.thumbnailSlide} className='img-two' alt="" />
                                            </div>
                                            <div className='p-2 d-grid custom-slider-products-content gap-2'>
                                                <div className='text-capitalize'>{item.name}</div>
                                                <div className='d-grid gap-0'>
                                                    {
                                                        item?.expired ? (
                                                            <>
                                                                <div className='fw-semibold'>From {keyCode?.inr}{item?.productFromPrice}</div>
                                                                <p className='color-info fs-14 fs-sm-12 m-0'>From {keyCode?.inr}{item?.offeredProductPrice} with Printfuse premuim</p>
                                                            </>
                                                        ) : (<div className='fw-semibold'>From {keyCode?.inr}{item?.productFromPrice}</div>)
                                                    }
                                                </div>
                                                <div className='fs-14 p-color d-flex justify-content-between align-items-center'>
                                                    <div>
                                                        {
                                                            item?.variantData.map((vari, newIndex) => {
                                                                return (
                                                                    <React.Fragment key={newIndex}>
                                                                        <span>{vari.total} {vari.name}</span>
                                                                        {(newIndex + 1) !== item.variantData.length && <TbPointFilled />}
                                                                    </React.Fragment>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            item?.canvasSize && (
                                                                <>
                                                                    <TbPointFilled /><span>Print area {item?.canvasSize}</span>
                                                                </>)
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    )
                                })
                            }
                        </Slider>
                    </Container>
                )
            }
        </>
    )
}

export default CommonSlider