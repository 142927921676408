import React, { useEffect, useState } from 'react'
import { Button, Card, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Image, Modal } from 'react-bootstrap'
import { BASE_URL, frontEnd_API, header, siteConfig, storage } from '../../Config/Config';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { GiNotebook } from 'react-icons/gi';
import { BiSolidEdit } from 'react-icons/bi';
import { MdOutlineDone } from "react-icons/md";
import { IoCloseOutline } from 'react-icons/io5';
import { TbFileInvoice } from 'react-icons/tb';
import { Input } from 'reactstrap';
import { keyCode, shortCode } from '../../Config/Constant';
import Swal from 'sweetalert2';
import moment from 'moment';
import ProductPreviewImage from './Products/ProductPreviewImageComponent';
import { FaTrash } from 'react-icons/fa6';

const ReturnOrderList = ({ status = 'all', childStatus = 'all' }) => {

    const navigate = useNavigate()

    // state and define -----
    const returnOrderStatusData = [
        {
            label: 'all',
            value: 'all',
            class: 'bg-success text-white'
        },
        {
            label: 'Pending',
            value: 'pending',
            class: 'bg-warning'
        },
        {
            label: 'Accepted',
            value: 'accepted',
            class: 'bg-info'
        },
        {
            label: 'Picked Up',
            value: 'picked-up',
            class: 'bg-dark text-white'
        },
        {
            label: 'In Transit',
            value: 'in-transit',
            class: 'bg-light border'
        },
        {
            label: 'Received',
            value: 'received',
            class: 'bg-success text-white'
        },
        {
            label: 'Cancelled',
            value: 'cancelled',
            class: 'bg-danger text-white'
        }
    ]
    const [checkAll, setCheckAll] = useState(false)
    const [orderDataStatus, setOrderDataStatus] = useState(-1)
    const [filterObj, setFilterObj] = useState({
        page: 1,
        limit: 10,
        search: '',
        groupStatus: status,
        returnOrderStatus: childStatus,
        dateRange: []
    })
    const [returnOrderData, setReturnOrderData] = useState([])
    const [courierModalStatus, setCourierModalStatus] = useState(false)
    const [selectedReturnOrder, setSelectedReturnOrder] = useState({})
    const [courierData, setCourierData] = useState([])
    // -----

    // function for get return orders -----
    const getReturnOrders = async () => {
        try {
            const { data, status } = await axios?.put(`${frontEnd_API?.returnorder}`, filterObj, header);
            setTimeout(() => {
                setReturnOrderData(status == 200 ? data?.data : [])
                setOrderDataStatus(status == 200 ? 1 : orderDataStatus)
            }, 1000)
        } catch (error) {
            setOrderDataStatus(0)
            setReturnOrderData([])
        }
    }
    // -----

    // function for get courier list for return order -----
    const manageOrderStatus = async ({ row }) => {

        setSelectedReturnOrder({ ...row })
        console.log("row ::", row, !row?.updateStatus)
        // return;
        if (!row?.updateStatus) {
            try {
                const { data, status } = await axios?.get(`${frontEnd_API?.returnorder}/courier/${row?.returnOrderId}`, header)
                if (status === 200) {
                    setCourierData([...data?.data])
                    setCourierModalStatus(true)
                }
                return

            } catch (e) {
                Swal.fire(
                    'Failed to update',
                    e?.response?.data?.message,
                    'warning'
                );
            }
            return
        }

        const body = {
            returnOrderStatus: row?.returnOrderStatus,
            returnOrderCourierCompanyId: row?.returnOrderCourierCompanyId,
            returnShippingCharge: row?.returnShippingCharge,
            returnOrderCourierDetail: row?.returnOrderCourierDetail,
        }

        try {
            const { data, status } = await axios?.put(`${frontEnd_API?.returnorder}/status/${row?.returnOrderId}`, body, header)

            if (status == 200) {
                Swal.fire(
                    data?.message,
                    `Your selected order status updated with ${body?.returnOrderStatus?.replace(/-/g, ' ')}!`,
                    'success'
                );
                setCourierData([])
                setSelectedReturnOrder({})
                setCourierModalStatus(false)
                getReturnOrders()
            }
        } catch (error) {
            Swal.fire(
                'Failed to update',
                `Failed to update return order status with ${row?.returnOrderStatus}`,
                'warning'
            );
            console.log("erroor ::: ", error)
        }
    }
    // -----


    // useEffect to get return order detal -----
    useEffect(() => {
        setReturnOrderData([])
        setOrderDataStatus(-1)
        setFilterObj({
            ...filterObj,
            groupStatus: status,
            returnOrderStatus: childStatus,
        })
    }, [status, childStatus])

    useEffect(() => {
        getReturnOrders()
    }, [filterObj])
    // -----


    // function for create component for action buttons -----
    const OrderActionButton = ({ row }) => {
        return (
            <div className='custom-order-dropdown d-flex align-items-center gap-3'>
                {/* {
                    (['pending']?.includes(row?.returnOrderStatus)) && 
                    (
                        <Dropdown
                            className="p-0"
                            style={{ width: "fit-content" }}
                        >
                            <DropdownToggle id="dropdown-basic" className='px-2 py-1' variant='secondary'>
                                <span className='fs-16'>
                                    <BiSolidEdit />
                                </span>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem className='text-success'
                                    onClick={() => manageOrderStatus({ row: { ...row, returnOrderStatus: 'accepted' } })}
                                >
                                    <span><MdOutlineDone /> Accept Return Request</span>
                                </DropdownItem>
                                <DropdownItem className='text-danger'
                                    onClick={async () => {

                                        const response = await Swal.fire({
                                            title: `Are you sure to cancel this return order?`,
                                            text: `This action can't be reversed`,
                                            icon: 'question',
                                            showCancelButton: true,
                                            confirmButtonColor: '#04ac84',
                                            cancelButtonColor: '#d33',
                                            confirmButtonText: 'Confirm',
                                            cancelButtonText: 'No',
                                        });

                                        if (response?.isConfirmed)
                                            manageOrderStatus({ row: { ...row, returnOrderStatus: 'cancelled', updateStatus: true } })
                                    }}
                                >
                                    <span><IoCloseOutline /> Cancel Return Request</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    )
                } */}
                {
                    (['pending']?.includes(row?.returnOrderStatus)) &&
                    <div className='d-grid gap-2'>
                        <Button
                            variant='success'
                            className='fs-12 py-1 px-2 full-border-rad custom-bg-temp'
                            onClick={() => manageOrderStatus({ row: { ...row, returnOrderStatus: 'accepted' } })}
                        >
                            <MdOutlineDone /> <span>ACCEPT</span>
                        </Button>
                        <Button
                            variant='danger'
                            className='fs-12 py-1 px-2 full-border-rad'
                            onClick={async () => {

                                const response = await Swal.fire({
                                    title: `Are you sure to cancel this return order?`,
                                    text: `This action can't be reversed`,
                                    icon: 'question',
                                    showCancelButton: true,
                                    confirmButtonColor: '#04ac84',
                                    cancelButtonColor: '#d33',
                                    confirmButtonText: 'Confirm',
                                    cancelButtonText: 'No',
                                });

                                if (response?.isConfirmed)
                                    manageOrderStatus({ row: { ...row, returnOrderStatus: 'cancelled', updateStatus: true } })
                            }}
                        >
                            <IoCloseOutline /> <span>CANCEL</span>
                        </Button>
                    </div>
                }
                <Link
                    data-tooltip-id="my-tooltip-edit"
                    data-tooltip-content="More Details"
                    variant='success'
                    className='more-btn'
                    to={`/returns/return/${childStatus}/${row?.returnOrderNumber}`}
                    state={{ data: row }}
                    style={{ backgroundColor: "#162b9a" }}
                >
                    <TbFileInvoice className='fs-18' />
                </Link>
            </div>
        )
    }
    // -----

    // function for manage all rows check box -----
    const manageCheckBox = async (checked = false) => {
        const data = returnOrderData?.filter(e => e?.productQuantity > e?.usedQuantity)?.map(e => ({
            ...e,
            checked
        }))
        if (checked && data?.length <= 0) {
            Swal.fire(
                'Failed',
                'No products available for re-order or create order',
                'error'
            )
            setCheckAll(false)
            return
        }
        setCheckAll(checked)
        setReturnOrderData([...data])
    }
    // -----

    // create blob link function -----
    const createBlobLink = async (link = '', colorCode = '#ffffff') => {
        if (link) {

            try {
                const response = await fetch(link)
                const imageBlob = await response.blob()

                const image = new Image()
                const blobUrl = URL.createObjectURL(imageBlob)

                image.src = blobUrl

                image.onload = () => {
                    const canvas = document.createElement("canvas")
                    const context = canvas.getContext("2d")
                    canvas.width = image.width
                    canvas.height = image.height
                    context.fillStyle = colorCode
                    context.fillRect(0, 0, canvas.width, canvas.height)
                    context.drawImage(image, 0, 0)
                    canvas.toBlob((newBlob) => {
                        const newBlobUrl = URL.createObjectURL(newBlob)
                        window.open(newBlobUrl, '_blank')
                        URL.revokeObjectURL(blobUrl)
                    }, "image/png")
                };
            } catch (error) {
                Swal.fire(
                    'Failed to Open',
                    `Failed to open selected image in new tab`,
                    'error'
                );
            }
        }
    }
    // -----

    const deleteRow = async (id = 0) => {
        if (id > 0) {
            const response = await Swal.fire({
                title: `Are you sure to delete this return product?`,
                text: `This action can't be reversed`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#04ac84',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm',
                cancelButtonText: 'No',
            });

            if (response?.isConfirmed) {
                const { data, status } = await axios?.delete(`${frontEnd_API?.returnorder}/${id}`, header);
                if (status == 200) {
                    Swal.fire('Deleted', `${data?.message}`, 'success');
                    getReturnOrders()
                }
            }
        }
    }

    document.title = `Seller Return Orders | ${siteConfig?.name}`;

    return (
        <>
            <div className='pb-2 d-grid gap-3 custom-btn-scrollbar'>
                <div className='d-flex pt-2 pt-sm-3 overflow-hidden'>
                    <div className='main-custom-order-sub-category'>
                        <div className='d-flex custom-order-sub-category pointer text-capitalize fs-14 fs-md-12 fs-sm-10'>
                            {
                                returnOrderStatusData?.map((row, index) => (
                                    <span
                                        key={index}
                                        className={`fw-semibold text-center px-2 px-sm-3 py-1 text-black ${row?.value == childStatus ? row?.class : ''}`}
                                        title={row?.label}
                                        onClick={() => navigate(`/returns/${status}/${row?.value}`)}
                                    >
                                        {row?.label}
                                    </span>
                                ))
                            }
                        </div>
                    </div>
                </div>

                <div className="d-flex align-items-center gap-2">
                    {
                        returnOrderData?.filter(e => e?.checked)?.length > 0 && (
                            <div
                                className='fw-semibold fs-14 text-success border-success p-2 rounded-2 text-nowrap pointer border border-2 mx-2'
                                onClick={() => {
                                    const cartData = JSON.parse(localStorage?.getItem(storage?.cart) ?? '[]')
                                    let existRTNIdsData = cartData?.filter(e => e?.from == shortCode?.RTN)?.map(e => Number(e?.returnOrderChildId))
                                    let data = returnOrderData?.filter(e => e?.checked)?.filter(e => !existRTNIdsData?.includes(Number(e?.id)))?.map(e => ({
                                        from: shortCode?.RTN,
                                        productId: e?.productId,
                                        currentQuantity: e?.productQuantity - e?.usedQuantity,
                                        quantity: e?.productQuantity - e?.usedQuantity,
                                        productPrice: parseFloat(e?.productPrice),
                                        productVariantData: e?.variantData,
                                        variantData: e?.variantData?.reduce((acc, row) => {
                                            acc[row.variantParentName] = row.variantId;
                                            return acc;
                                        }, {}),
                                        returnOrderChildId: e?.id,
                                        returnOrderId: e?.returnOrderId,
                                    }))

                                    if (data?.length <= 0) {
                                        Swal.fire(
                                            'Failed',
                                            'Selected products already added into your cart from return orders',
                                            'error'
                                        )
                                        return
                                    }

                                    localStorage?.setItem(storage?.cart, JSON.stringify([...cartData, ...data]))
                                    window.location = `${BASE_URL}create-orders`
                                }}
                            >
                                Create order
                            </div>
                        )
                    }
                    <div className="custom-seller-category-search px-0 container-fluid">
                        <div className="d-flex align-items-center gap-2 gap-lg-3 px-2 px-lg-3">
                            <label className="bi bi-search" for="text"></label>
                            <input
                                type="text"
                                className="py-2 fs-sm-14 pe-2"
                                id="text"
                                placeholder="Search by return order ID"
                                autocomplete="off"
                                onChange={(e) => {
                                    setFilterObj({
                                        ...filterObj,
                                        search: e?.target?.value
                                    })
                                }}
                            />
                        </div>
                    </div>
                    <div className="d-md-none pointer">
                        <i className="bi bi-funnel-fill px-3 py-2 seller-box"></i>
                    </div>
                </div>

                <div className='peta-height-fix'>
                    <div className='custom-order-list-table-overflow'>
                        <div className='custom-order-list-table-overflow-div'>
                            <table className='custom-order-list-table'>
                                <thead className=''>
                                    <tr className='fs-12 fs-sm-10 fw-medium p-color'>
                                        {
                                            ['received']?.includes(childStatus) ? (
                                                <>
                                                    <th>
                                                        <label className='fs-12 d-flex align-items-center gap-1 pointer'>
                                                            <Input
                                                                type='checkbox'
                                                                checked={checkAll}
                                                                onChange={(e) => manageCheckBox(e?.target?.checked)}
                                                                className='mt-0'
                                                                style={{ width: '18px', height: '18px' }}
                                                            />
                                                            <span>All</span>
                                                        </label>
                                                    </th>
                                                    <th>Return Order ID</th>
                                                    <th>Date</th>
                                                    <th>Image</th>
                                                    <th>Product Name</th>
                                                    <th>Quantity</th>
                                                    <th>Left Quantity</th>
                                                    <th>Price</th>
                                                    <th>Remaning Days</th>
                                                    <th>Action</th>
                                                </>
                                            ) : (
                                                <>
                                                    <th>Return Order ID</th>
                                                    <th>Date</th>
                                                    <th>Customer Name</th>
                                                    <th>Customer Mobile</th>
                                                    <th>Net Amount</th>
                                                    <th>Status</th>
                                                    <th>Reason</th>
                                                    <th>Action</th>
                                                </>
                                            )
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        returnOrderData?.map((row, index) => {
                                            const daysLeft = moment(row?.returnOrderDate)?.add(60, 'days').diff(moment(), 'days')
                                            const colorData = row?.variantData?.filter(e => ['color', 'colors', 'colour', 'colours']?.includes(e?.variantParentName))
                                            return (
                                                <tr key={index} className="custom-order-list-bar-item fs-13 fs-sm-10">
                                                    {
                                                        ['received']?.includes(childStatus) ? (
                                                            <>
                                                                <td>
                                                                    {
                                                                        row?.productQuantity > row?.usedQuantity ? (
                                                                            <Input
                                                                                type='checkbox'
                                                                                checked={row?.checked}
                                                                                onChange={(e) => {
                                                                                    returnOrderData[index].checked = e?.target?.checked
                                                                                    setReturnOrderData([...returnOrderData])
                                                                                }}
                                                                                className='mt-0'
                                                                                style={{ width: '18px', height: '18px' }}
                                                                            />
                                                                        ) : (<>-</>)
                                                                    }
                                                                </td>
                                                                <td style={{ width: 'fit-content' }} >{row?.returnOrderChildNumber}</td>
                                                                <td style={{ width: 'fit-content' }} >{row?.returnOrderDate}</td>
                                                                <td style={{ width: 'fit-content' }} >
                                                                    <div className='list-img border' style={{ width: '90px!important', height: '90px!important' }} >
                                                                        <ProductPreviewImage
                                                                            colorCode={colorData?.length > 0 ? colorData[0]?.variantCode : '#ffffff'}
                                                                            objectData={{ preview: row?.productThumbnail ?? null }}
                                                                            isButtonShow={false}
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td style={{ width: 'fit-content' }} >
                                                                    <div className='d-flex flex-column align-items-start'>
                                                                        <span className='mb-1'>{row?.productName}</span>
                                                                        <div className='d-flex gap-2 align-items-center text-capitalize text-dark fw-semibold'>
                                                                            {
                                                                                row?.variantData?.map(e => <>
                                                                                    <span className='border rounded-3 px-2 alert-warning'>{e?.variantParentName} : {e?.variantName}</span>
                                                                                </>)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td style={{ width: 'fit-content' }} >{row?.productQuantity}</td>
                                                                <td style={{ width: 'fit-content' }} >{
                                                                    `${row?.productQuantity > row?.usedQuantity ? row?.productQuantity - row?.usedQuantity : 0} Qty`
                                                                }</td>
                                                                <td style={{ width: 'fit-content' }} >{keyCode?.inr}{row?.productPrice}</td>
                                                                <td>
                                                                    <div
                                                                        role="alert"
                                                                        class={`fade m-0 text-center fw-semibold py-1 px-2 px-sm-3 fs-12 fs-sm-10 alert alert-${(daysLeft <= 0) ? 'danger' : 'warning'} show`}
                                                                        style={{ width: 'fit-content' }}
                                                                    ><span>{daysLeft > 0 ? daysLeft + ' days left' : 'Expired'}</span></div>
                                                                </td>
                                                                <td>
                                                                    {/* <OrderActionButton row={row} /> */}
                                                                    <Button
                                                                        className='bg-success p-2 border-0'
                                                                        onClick={() => deleteRow(row?.id)}
                                                                    >
                                                                        <FaTrash />
                                                                    </Button>
                                                                </td>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <td style={{ width: 'fit-content' }} >{row?.returnOrderNumber}</td>
                                                                <td style={{ width: 'fit-content' }} >{row?.returnOrderDate}</td>
                                                                <td className='text-capitalize'>{row?.userName}</td>
                                                                <td>{row?.userMobile}</td>
                                                                <td>{keyCode?.inr}{row?.returnOrderNetAmount}</td>
                                                                {/*
                                                        <td>
                                                            <div role="alert" className="fade m-0 text-center fw-semibold text-capitalize py-1 px-2 px-sm-3 fs-12 fs-sm-10 alert alert-warning show" style={{width:'fit-content'}} ><span>{row?.returnOrderReason}</span></div>
                                                        </td>
                                                        */}
                                                                <td>
                                                                    <div
                                                                        className={`rounded m-0 text-center fw-semibold text-capitalize py-1 px-2 px-sm-3 fs-12 fs-sm-10 text-black ${returnOrderStatusData?.filter(e => e?.value == row?.returnOrderStatus)[0]?.class}`}
                                                                        style={{ width: 'fit-content' }}
                                                                    >{row?.returnOrderStatus}</div>
                                                                </td>
                                                                <td>{row?.reasonName?.replace(/-/g, ' ')}</td>
                                                                <td>
                                                                    <OrderActionButton row={row} />
                                                                </td>
                                                            </>
                                                        )
                                                    }
                                                </tr>
                                            )
                                        })
                                    }
                                    {
                                        returnOrderData?.length <= 0 && (
                                            <tr>
                                                <td colSpan={['received']?.includes(childStatus) ? 10 : 8} align='center'>
                                                    <div className='custom-transaction-tab d-grid justify-content-center text-center gap-2'>
                                                        <div>
                                                            <GiNotebook />
                                                        </div>
                                                        <h5 className='fw-semibold'>{orderDataStatus == 0 ? 'No returns yet' : 'Please wait a moment'}</h5>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {/* courier list modal start */}
            <Modal
                show={courierModalStatus}
                onHide={() => setCourierModalStatus(!courierModalStatus)}
                aria-labelledby="contained-modal-title-vcenter"
                size="md"
                // className='mh-75'
                style={{ maxHeight: '75vh' }}
                centered
                scrollable
            >
                <Modal.Header className='d-flex flex-column'>
                    <Modal.Title className='text-center w-100 fs-sm-14'>Select Courier</Modal.Title>
                    <div className='d-flex justify-content-between align-items-center w-100'>
                        <div className='d-flex flex-column jusity-content-start align-items-start'>
                            <label className='fs-12 fw-bold text-secondary'>Return Order Number</label>
                            <div className='fs-12 fw-bold text-success'>{selectedReturnOrder?.returnOrderNumber}</div>
                        </div>
                        <div className='d-flex flex-column jusity-content-end align-items-start'>
                            <div className='d-flex flex-column jusity-content-end align-items-end'>
                                <label className='fs-12 fw-bold text-secondary'>Return Order Date</label>
                                <div className='fs-12 fw-bold text-success'>{selectedReturnOrder?.returnOrderDate}</div>
                            </div>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className='custom-modal-radio-select'>
                    <div className="custom-radio-group px-0">
                        {
                            courierData?.map((row, index) => <>
                                <Card
                                    className={`pointer border ${selectedReturnOrder?.returnOrderCourierCompanyId == row?.id ? 'border-2 border-success' : 'border-secondary'} rounded-2 py-1 px-2 position-relative`}
                                    onClick={() => {
                                        selectedReturnOrder.returnOrderCourierCompanyId = row?.id
                                        selectedReturnOrder.returnShippingCharge = row?.rate
                                        selectedReturnOrder.returnOrderCourierDetail = row
                                        setSelectedReturnOrder({ ...selectedReturnOrder })
                                    }}
                                >
                                    <label className='position-absolute top-0 start-0 translate-middle'>
                                        <Input
                                            type='radio'
                                            className={selectedReturnOrder?.returnOrderCourierCompanyId != row?.id ? 'd-none' : ''}
                                            checked={selectedReturnOrder?.returnOrderCourierCompanyId == row?.id}
                                        />
                                    </label>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div className='fs-14 fw-bold'>{row?.courierName}</div>
                                        <div className='d-flex gap-3'>
                                            {
                                                row?.codCharges > 0 && (
                                                    <div className='d-flex flex-column align-items-end text-secondary'>
                                                        <div className='fs-12 fw-bold'>{keyCode?.inr}{row?.codCharges}</div>
                                                        <div className='fs-10 fw-semibold'>COD Charge</div>
                                                    </div>
                                                )
                                            }
                                            <div className='d-flex flex-column align-items-end text-secondary'>
                                                <div className='fs-12 fw-bold'>{keyCode?.inr}{row?.rate}</div>
                                                <div className='fs-10 fw-semibold'>Courier charge</div>
                                            </div>
                                            <div className='d-flex flex-column align-items-end text-secondary'>
                                                <div className='fs-12 fw-bold'>{row?.rating}</div>
                                                <div className='fs-10 fw-semibold'>Rating</div>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </>)
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer className='flex-center-align'>
                    <Button
                        variant="success"
                        onClick={() => {
                            if (selectedReturnOrder?.returnOrderCourierCompanyId > 0)
                                manageOrderStatus({ row: { ...selectedReturnOrder, updateStatus: true } })
                        }}
                        disabled={selectedReturnOrder?.returnOrderCourierCompanyId < 1}
                        className='text-capitalize'
                    >
                        Click here to confirm
                    </Button>
                    <Button variant="secondary" onClick={() => setCourierModalStatus(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
            {/* courier list modal close */}
        </>
    )
}

export default ReturnOrderList