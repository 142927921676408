import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Alert, Button, Card, CardBody, CardHeader, Col, Container, Dropdown, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, Nav, Placeholder, Row, Spinner, Tab, Table } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { frontEnd_API, header, SERVER_URL } from '../../../Config/Config'
import { RiImageEditLine } from "react-icons/ri";
import { MdKeyboardDoubleArrowRight, MdOutlineEditNote, MdOutlineProductionQuantityLimits } from "react-icons/md";
import { BsThreeDots } from "react-icons/bs";
import { GoDotFill } from "react-icons/go";
import { useSelector } from 'react-redux'
import { toast, ToastContainer } from 'react-toastify'
import { Tooltip } from 'react-tooltip'
import Swal from 'sweetalert2'
import CommonPagination from '../../../Components/CommonPagination'
import Loader from '../../../Components/Loader'
import { keyCode } from '../../../Config/Constant'
import { Input } from 'reactstrap'
import { FaShopify } from 'react-icons/fa6'

import zohoImg from '../../../Assets/Images/zoho.png'
import { TbPackages } from 'react-icons/tb'
import ComboListing from '../../ComboProduct/ComboListing'

function ProductHome() {

    const {type="products"} = useParams();
    const [productExist, setProductExist] = useState(false)
    const [productData, setProductData] = useState([])
    const [statusLoader, setStatusLoader] = useState(false);
    const [canvasLoader, setCanvasLoader] = useState(false);
    const [multiSelect, setMultiSelect] = useState([]);
    const [filterList, setFilterList] = useState([]);
    const [filters, setFilters] = useState({});
    const [totalCount, setTotalCount] = useState()
    const navigate = useNavigate();
    const userData = useSelector((state) => state.user.value);
    const activeStore = useSelector((state) => state.store.value);
    const activeStoreType = activeStore?.storeType?.toLowerCase()?.trim();
    const productStatusData = (activeStoreType == 'shopify') ? ['Active', 'Draft'] : ['Publish', 'Unpublish']
    const [loader, setLoader] = useState(true);
    const [tabChange, setTabChange] = useState(type);

    const getProductData = async () => {
        try {
            const response = await axios?.put(frontEnd_API?.storeProduct, { ...filters }, header);
            if (response?.status === 200) {
                setProductData([...response?.data?.data]);
                setTotalCount(response?.data?.totalCount);
                setLoader(false);
                console.log("response::", response);
                if (response?.data?.data?.length > 0) {
                    setProductExist(true)
                }
            }
        } catch (error) {
            console.log("error::", error);
            setProductExist(false)
            setLoader(false);
        }
    }

    const getProviderData = async () => {
        try {
            const response = await axios?.get(`${frontEnd_API?.storeProduct}/provider`, header);
            if (response?.status === 200) {
                setFilterList((prevData) => ({
                    ...prevData,
                    parentData: [
                        {
                            label: 'Provider Name',
                            title: 'providerId',
                            data: response?.data?.data
                        },
                        {
                            label: 'Status',
                            title: 'status',
                            data: [
                                {
                                    label: ["SHOPIFY", "shopify", "ZOHO", "zoho"].includes(activeStore?.storeType)
                                        ? (`${productStatusData[0]}`).replace('ee', 'e')
                                        : (`${productStatusData[0]}ed`).replace('ee', 'e'),
                                    value: "1"
                                },
                                {
                                    label: ["SHOPIFY", "shopify", "ZOHO", "zoho"].includes(activeStore?.storeType)
                                        ? (`${productStatusData[1]}`).replace('ee', 'e')
                                        : (`${productStatusData[1]}ed`).replace('ee', 'e'),
                                    value: "0"
                                },
                            ]
                        },
                    ]
                }));
            }
        } catch (error) {
            console.log("error::", error);
        }
    }

    useEffect(() => {
        getProductData();
        getProviderData();
    }, [])

    useEffect(() => {
        getProductData();
        getProviderData();
    }, [filters])

    const deleteProduct = async (id) => {
        try {
            const tempData = {
                'id': (multiSelect?.length > 0) ? multiSelect : [id]
            };

            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: 'grey',
                confirmButtonText: 'Delete',
                cancelButtonText: 'Cancel',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    setLoader(true);
                    const { data } = await axios.delete(`${frontEnd_API?.storeProduct}`, { data: tempData, ...header })
                    getProductData();
                    Swal.fire('Deleted!', 'Your product has been deleted.', 'success');
                    setLoader(false);
                    setMultiSelect([])
                }
            });
        }
        catch (e) {
            console.log("err::", e);
            setLoader(false);
        }
    }

    const handleProductStatus = async (item) => {

        let tempId = [];
        if (item?.id) {
            tempId = [item?.id]
        }
        else if (multiSelect?.length > 0) {
            tempId = multiSelect
        }

        try {
            const { data } = await axios.put(`${frontEnd_API?.storeProduct}/status`, {
                id: tempId,
                status: (multiSelect?.length > 0) ? item?.status : (item?.status == 1) ? 0 : 1
            }, header)
            console.log("data::", data);
            getProductData();
            if (multiSelect?.length > 0) {
                toast(`${multiSelect?.length > 0 ? 'All product' : 'Product'} status is changed to ${(item?.status === 1) ? "published" : "unpublished"}!`)
            } else {
                toast(`${multiSelect?.length > 0 ? 'All product' : 'Product'} status is changed to ${(item?.status === 1) ? "unpublished" : "published"}!`)
            }
            setMultiSelect([])
        }
        catch (e) {
            console.log("Err::", e);
        }
    }

    const handleCanvasEdit = async (item) => {
        try {
            const { data } = await axios.get(`${frontEnd_API?.storeProduct}/${item?.id}`, header)
            if (data?.data) {
                const editData = data?.data
                console.log("editData ", editData)
                setTimeout(() => {
                    editData.currentProductData.currentProfitInAmount = editData?.currentProductData?.profitInAmount
                    editData.currentProductData.currentMrp = editData?.currentProductData?.mrp
                    navigate(`/product-edit/${editData?.currentProductData?.masterProductId}/${editData?.providerData?.providerId}/canvas`, { state: { data: editData } });
                    setCanvasLoader(false)
                }, 750)
            }
        }
        catch (e) {
            console.log("Err::", e);
        }
    }

    // function for manage product detail edit for canvas or information -----
    const handleProductDetailEdit = async (obj = {}) => {

        if (!obj?.id || !obj?.to)
            return false

        try {
            let apiURL = `${frontEnd_API?.storeProduct}${obj?.to == 'detail' ? '/information' : ''}/${obj?.id}`
            const { data, status } = await axios.get(apiURL, header)
            console.log("207 homeProduct :: ", data, obj?.id)
            navigate(`/update-product/information`, {
                state: { data: data?.data }
            });
            console.log('information data :: ', data)

        } catch (e) {
            console.log("e :: ", e)
        }
    }
    // -----

    const handleCheck = (item) => {
        const check = multiSelect?.length > 0
            ? multiSelect.find((e) => e == item?.value)
            : null
        if (check) {
            const tempData = multiSelect.filter((e) => e !== item?.value);
            setMultiSelect(tempData);
        }
        else {
            const tempData = [...multiSelect, item?.value];
            setMultiSelect(tempData)
        }
    }

    // Filtration Data List

    const [showFilter, setShowFilter] = useState(false);

    const handleChange = (item) => {
        const tempData = filters[item?.title] ?? [];

        const newData = (tempData?.length > 0) ? tempData.find((e) => e == item?.value) : null;
        console.log("tempData::", newData);
        if (newData) {
            const check = filters[item?.title].filter((e) => e !== item?.value);
            setFilters((prevData) => ({
                ...prevData,
                [item?.title]: check
            }))
        }
        else {
            setFilters((prevData) => ({
                ...prevData,
                [item?.title]: [...tempData, item?.value]
            }))
        }
    }

    const [tempProductObj, setTempProductObj] = useState({})
    const [providerSwitchModalStatus, setProviderSwitchModalStatus] = useState(false)
    const [comboModalStatus, setComboModalStatus] = useState(false)
    const [selectedProvider, setSelectedProvider] = useState({ providerId: 0 })
    const [providerListData, setProviderListData] = useState([])

    // function for get provider list for switch -----
    const getProviderListForSwitch = async (id = 0) => {
        try {
            let apiURL = `${frontEnd_API?.storeProduct}/provider/${id}`
            const { data, status } = await axios.get(apiURL, header)
            setProviderListData([...data?.data?.providerData])

            delete data?.data?.providerData

            setTempProductObj(data?.data)

            setProviderSwitchModalStatus(true)
        } catch (e) {
            setTempProductObj({})
            setProviderListData([])
            Swal.fire('Failed', e?.response?.data?.message ?? 'Provider not found', 'warning')
        }
    }
    // -----

    return (
        <div className='seller-product-home seller-bg-grey' style={{ minHeight: "100vh" }}>
            <div className='pt-5 px-2 px-lg-3 d-grid'>
                <Container>
                    <Row>
                        <Col className='col-12 p-0'>
                        <Tab.Container activeKey={tabChange}>
                            
                                <Card className='rounded-0 px-3 pt-3 custom-product-tabs' style={{borderBottom: "none"}}>
                                    <Nav fill variant="pills" onSelect={(selectedKey) => setTabChange(selectedKey)}>
                                        <Nav.Item>
                                            <Nav.Link eventKey="products" className='flex-center-align gap-1'>
                                                <span className='custom-triangle'></span>
                                                <span className='fw-semibold fs-18'>All Products</span>
                                            </Nav.Link>
                                        </Nav.Item>
                                        {
                                            ((activeStore?.storeType == "SNAPSTORE") && (userData?.email == "ayushi@printfuse.in" || window.location.hostname === "localhost")) &&
                                            <Nav.Item>
                                                <Nav.Link eventKey="combo" className='flex-center-align gap-1'>
                                                    <span className='custom-triangle'></span>
                                                    <span className='fw-semibold fs-18'>All Combos</span>
                                                </Nav.Link>
                                            </Nav.Item>
                                        }
                                    </Nav>
                                </Card>
                            <Tab.Content>
                                <Tab.Pane eventKey="products" className=''>
                                    <Card className='rounded-0 p-0'>
                                        {/* <CardHeader className='bg-transparent py-3 d-flex justify-content-between align-items-center'>
                                            <h5 className='mb-0 fw-bolder'>My Products</h5>
                                            <div className='d-flex gap-2'>
                                                <Button
                                                    className='w-auto text-capitalize bg-success text-white border-0'
                                                    // onClick={() => navigate('/search-result')}
                                                    onClick={() => navigate('/category')}
                                                >
                                                    add new product
                                                </Button>
                                                {
                                                    window?.location?.hostname === "localhost" &&
                                                    <Link
                                                        className='w-auto text-capitalize bg-success text-white border-0 flex-center-align gap-1 text-decoration-none half-border-rad px-2'
                                                        // onClick={() => navigate('/search-result')}
                                                        onClick={() => setComboModalStatus(true)}
                                                        to={'/create-combo'}
                                                    >
                                                        <TbPackages />
                                                        Create Combo
                                                    </Link>
                                                }
                                            </div>
                                        </CardHeader> */}
                                        <CardBody>
                                            <Row className='gy-3 px-2 px-sm-0 justify-content-center'>
                                                <Col className='col-12 col-lg-12 py-2 d-grid gap-3 custom-btn-scrollbar px-0 px-lg-3'>
                                                    {/* Search Button */}
                                                    <div className='d-flex align-items-center gap-2 d-md-grid'>
                                                        <Container className='custom-seller-category-search px-0 flex-between-align gap-3'>
                                                            <Button
                                                                className='w-auto text-capitalize bg-success text-white border-0 py-2'
                                                                onClick={() => navigate('/category')}
                                                            >
                                                                add new product
                                                            </Button>
                                                            <div className='d-flex align-items-center gap-2 gap-lg-3 px-2 px-lg-3' style={{width: "calc(100% - 180px)"}}>
                                                                <label className='bi bi-search' htmlFor='text'></label>
                                                                <input
                                                                    type="text"
                                                                    className='py-2 fs-sm-14 pe-2'
                                                                    id='text'
                                                                    placeholder='Search by product name or description'
                                                                    autoComplete='off'
                                                                    onChange={(e) => {
                                                                        setFilters((prevData) => ({
                                                                            ...prevData,
                                                                            search: e?.target?.value,
                                                                        }))
                                                                        setLoader(true);
                                                                    }}
                                                                />
                                                            </div>
                                                        </Container>
                                                        <div className='d-block d-md-none'
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => setShowFilter(!showFilter)}
                                                        ><i className="bi bi-funnel-fill px-3 py-2 seller-box"></i></div>


                                                        {/* Filters */}
                                                        <Modal
                                                            show={showFilter}
                                                            onHide={() => setShowFilter(!showFilter)}
                                                            backdrop="static"
                                                            keyboard={false}
                                                        >
                                                            <Modal.Header closeButton>
                                                                <Modal.Title>Filter</Modal.Title>
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                                <div className='d-grid gap-3'>
                                                                    {
                                                                        (filterList?.parentData?.length > 0) &&
                                                                        filterList?.parentData?.map((item, index) => {
                                                                            return (
                                                                                <div key={index} className={`d-grid ${(filterList?.parentData?.length == 1) ? 'w-25' : 'w-100'} custom-select-option`}>
                                                                                    <Dropdown className='d-inline w-100' autoClose="outside">
                                                                                        <Dropdown.Toggle variant="light" id="dropdown-autoclose-outside" className='seller-box no-border-rad text-start w-100 fw-semibold text-capitalize'>
                                                                                            {item.label}
                                                                                        </Dropdown.Toggle>
                                                                                        <Dropdown.Menu className='w-100 gap-1 check-holder'>
                                                                                            {
                                                                                                item?.data.map((subItem, subIndex) => {
                                                                                                    return (
                                                                                                        <Dropdown.Item key={subIndex} as={'div'} className='flex-between-align gap-1 gap-md-2 justify-content-start w-100'>
                                                                                                            <input type="checkbox"
                                                                                                                className='ui-checkbox'
                                                                                                                name={subItem.label}
                                                                                                                id={subItem.label}
                                                                                                                value={subItem.value}
                                                                                                                checked={filters[item?.title]?.includes(subItem.value)}
                                                                                                                onChange={() => {
                                                                                                                    handleChange({
                                                                                                                        value: subItem?.value,
                                                                                                                        title: item?.title
                                                                                                                    })
                                                                                                                    setLoader(true);
                                                                                                                }} />

                                                                                                            <label htmlFor={subItem.label} className='text-capitalize fs-18 fs-lg-14 fs-sm-12'>{subItem.label}</label>
                                                                                                        </Dropdown.Item>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </Dropdown.Menu>
                                                                                    </Dropdown>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </Modal.Body>
                                                            <Modal.Footer>
                                                                <Button variant="secondary" onClick={() => setShowFilter(false)}>
                                                                    Close
                                                                </Button>
                                                                <Button variant="primary" onClick={() => setShowFilter(false)}>Apply Filters</Button>
                                                            </Modal.Footer>
                                                        </Modal>
                                                    </div>
                                                    {
                                                        (filterList?.parentData?.length > 0) &&
                                                        <div className='d-none d-md-grid gap-2'>
                                                            <span className='fs-14 ps-2 ms-1'>Sort By:</span>
                                                            <div className='d-none d-md-flex'>
                                                                {
                                                                    (filterList?.parentData?.length > 0) &&
                                                                    filterList?.parentData?.map((item, index) => {
                                                                        return (
                                                                            <div key={index} className={`d-grid ${(filterList?.parentData?.length == 1) ? 'w-25' : 'w-100'} custom-select-option`}>
                                                                                <Dropdown className='d-inline w-100' autoClose="outside">
                                                                                    <Dropdown.Toggle variant="light" id="dropdown-autoclose-outside" className='seller-box no-border-rad text-start w-100 fw-semibold text-capitalize'>
                                                                                        {item.label}
                                                                                    </Dropdown.Toggle>
                                                                                    <Dropdown.Menu className='w-100 gap-1 check-holder'>
                                                                                        {
                                                                                            item?.data.map((subItem, subIndex) => {
                                                                                                return (
                                                                                                    <Dropdown.Item key={subIndex} as={'div'} className='flex-between-align gap-1 gap-md-2 justify-content-start w-100'>
                                                                                                        <input type="checkbox"
                                                                                                            className='ui-checkbox'
                                                                                                            name={subItem.label}
                                                                                                            id={subItem.label}
                                                                                                            value={subItem.value}
                                                                                                            checked={filters[item?.title]?.includes(subItem.value)}
                                                                                                            onChange={() => {
                                                                                                                handleChange({
                                                                                                                    value: subItem?.value,
                                                                                                                    title: item?.title
                                                                                                                })
                                                                                                                setLoader(true);
                                                                                                            }} />

                                                                                                        <label htmlFor={subItem.label} className='text-capitalize fs-18 fs-lg-14 fs-sm-12'>{subItem.label}</label>
                                                                                                    </Dropdown.Item>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </Dropdown.Menu>
                                                                                </Dropdown>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                    </div>}
                                                </Col>
                                                <Col className='col-12 col-lg-12'>
                                                    <Row className='custom-table-responsive text-capitalize' style={{ minHeight: '35px' }}>
                                                        <Col className={`col-12 ${(multiSelect?.length > 0) ? '' : 'col-lg-9'} d-grid px-0 px-lg-2`}>
                                                            <div className='d-flex justify-content-start justify-content-lg-between align-items-lg-center gap-2'>
                                                                <div className='check-holder ps-lg-2 flex-center-align'>
                                                                    <input
                                                                        type="checkbox"
                                                                        className="ui-checkbox"
                                                                        id='checkAll'
                                                                        checked={(multiSelect?.length > 0) ? productData?.length == multiSelect?.length ? true : false : false} 
                                                                        onChange={(check) => {
                                                                            const tempData = productData?.filter((state)=>(state?.inCombo !== true))?.map((e) => e?.id)
                                                                            if(tempData?.length !== productData?.length){
                                                                                Swal.fire(
                                                                                    "Not all products are selected!",
                                                                                    "Some products are part of a combo.",
                                                                                    "warning"
                                                                                );                                                                                
                                                                            }
                                                                            setMultiSelect(() => {
                                                                                return (check?.target?.checked) ? tempData : []
                                                                            })
                                                                        }
                                                                    }
                                                                    />
                                                                </div>
                                                                <div className={`img-holder d-flex align-items-center gap-2 ${(multiSelect?.length > 0) ? "" : "w-100"}`} style={{ aspectRatio: "auto" }}>
                                                                    <label htmlFor='checkAll' className='pointer'>
                                                                        {
                                                                            (multiSelect?.length > 0) ?
                                                                                <span className='fw-semibold fs-18 fs-sm-14'>{multiSelect?.length}/{productData?.length}</span>
                                                                                : 'Select all'
                                                                        }
                                                                    </label>
                                                                </div>
                                                                <Row className='w-100 gy-1 gy-lg-0 align-items-lg-center p-2 p-lg-0 d-flex flex-wrap'>
                                                                    {(multiSelect?.length > 0) ?
                                                                        <Col className='col-12 d-flex flex-wrap gap-2'>
                                                                            <Button
                                                                                className='py-1 fs-sm-12'
                                                                                variant="success"
                                                                                onClick={() => handleProductStatus({ status: 1 })}
                                                                            >
                                                                                {productStatusData[0]}
                                                                            </Button>
                                                                            <Button
                                                                                className='py-1 fs-sm-12'
                                                                                variant="secondary"
                                                                                onClick={() => handleProductStatus({ status: 0 })}
                                                                            >
                                                                                {productStatusData[1]}
                                                                            </Button>
                                                                            <Button
                                                                                className='py-1 fs-sm-12'
                                                                                variant="danger"
                                                                                onClick={() => {
                                                                                    deleteProduct()
                                                                                    // setLoader(true)
                                                                                    // setMultiSelect([])
                                                                                }}
                                                                            >
                                                                                <span className='d-none d-lg-block'>Delete</span>
                                                                                <i className="bi bi-trash d-block d-lg-none"></i>
                                                                            </Button>
                                                                        </Col>
                                                                        : <>
                                                                            <Col className='col-7 col-lg-7 d-none d-lg-grid align-content-center gap-2'>
                                                                                Product
                                                                            </Col>
                                                                            <Col className='col-3 col-lg-3 d-none d-lg-grid align-content-center gap-2'>
                                                                                category
                                                                            </Col>
                                                                            <Col className='col-2 col-lg-2 d-none d-lg-block'>Inventory</Col>
                                                                        </>
                                                                    }
                                                                </Row>
                                                            </div>
                                                        </Col>
                                                        {
                                                            (multiSelect?.length == 0) &&
                                                            <Col className='col-12 col-lg-3 d-none d-lg-grid align-items-center'>
                                                                Status
                                                            </Col>
                                                        }

                                                    </Row>
                                                    <hr className={`mb-0 d-none d-lg-block`} />
                                                </Col>
                                                {
                                                    loader ?
                                                        <div className='w-100 aspect-4-1 aspect-md-2-1'>
                                                            <Loader />
                                                        </div>
                                                        : productExist ?
                                                            <>
                                                                <Col className='col-12'>
                                                                    <Row className='position-relative gy-4'>
                                                                        {
                                                                            productData?.map((item, index) => {
                                                                                const tempId = multiSelect?.find((e) => e === item?.id)
                                                                                return (
                                                                                    <>
                                                                                        <Col key={index} className='col-12 col-sm-6 col-lg-12'>
                                                                                            <Row className='custom-table-responsive text-capitalize custom-product-item'>
                                                                                                <Col className='col-12 col-lg-9 d-grid px-0 px-lg-2'>
                                                                                                    <div className='d-grid d-lg-flex justify-content-lg-between align-items-lg-center gap-2'>
                                                                                                        <div className='product-top-corner-check check-holder flex-center-align ps-lg-2 pe-lg-1'>
                                                                                                            <input
                                                                                                                type="checkbox"
                                                                                                                className="ui-checkbox"
                                                                                                                checked={(tempId) ? true : false}
                                                                                                                onChange={(e) => {
                                                                                                                    if(item?.inCombo){
                                                                                                                        Swal.fire(
                                                                                                                            "You are not allowed to change the status of this product!",
                                                                                                                            "This product is part of a combo. Please delete the combo first before deleting this product.",
                                                                                                                            "error"
                                                                                                                        );                              
                                                                                                                    } else {
                                                                                                                        handleCheck({
                                                                                                                            value: item?.id
                                                                                                                        })
                                                                                                                    }
                                                                                                                }}
                                                                                                            />
                                                                                                        </div>
                                                                                                        <div className='img-holder'>
                                                                                                            <img style={item?.defaultColor ? { backgroundColor: `${item?.defaultColor}` } : {}} src={item?.thumbnail} alt={item?.name} />
                                                                                                        </div>
                                                                                                        <Row className='w-100 gy-1 gy-lg-0 align-items-lg-center p-2 p-lg-0'>
                                                                                                            <Col className='col-7 col-7 d-grid align-content-center gap-2'>
                                                                                                                <h6 className='fw-semibold m-0 d-flex gap-2'>
                                                                                                                    <span>{item?.name}</span>
                                                                                                                    {
                                                                                                                        item?.shopifyProductId &&
                                                                                                                        <FaShopify className='color-temp fs-18' />
                                                                                                                    }
                                                                                                                    {
                                                                                                                        item?.zohoCommerceProductId &&
                                                                                                                        <img src={zohoImg} style={{ height: '20px' }} />
                                                                                                                    }
                                                                                                                </h6>
                                                                                                                <span className='p-color fs-14 fs-sm-12'>{item?.businessName}</span>
                                                                                                                <div className='d-flex gap-1 flex-wrap align-items-center fs-14 fs-sm-12 p-color'>
                                                                                                                    {
                                                                                                                        item?.variantData.map((subItem, subIndex) => (
                                                                                                                            <>
                                                                                                                                <span key={subIndex} className='text-lowercase'>
                                                                                                                                    <span className='pe-1'>
                                                                                                                                        {subItem?.value}
                                                                                                                                    </span>
                                                                                                                                    {subItem?.label}
                                                                                                                                </span>
                                                                                                                                <GoDotFill className={`fs-12 fs-sm-10`} />
                                                                                                                            </>
                                                                                                                        ))
                                                                                                                    }
                                                                                                                    <span>
                                                                                                                        Total
                                                                                                                        <span className='px-1'>
                                                                                                                            {item?.cartesianCount}
                                                                                                                        </span>
                                                                                                                        variants
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </Col>
                                                                                                            <Col className='col-5'>
                                                                                                                <Row className=''>
                                                                                                                    <Col className='col-12 col-lg-7 fs-14 fs-sm-12'>
                                                                                                                        <div className='d-flex flex-column gap-1 justify-content-start'>
                                                                                                                            {item?.categoryName?.map(e => <div>{e}</div>)}
                                                                                                                        </div>
                                                                                                                    </Col>
                                                                                                                    <Col className='col-12 col-lg-5 fs-14 fs-sm-12'>All in stock</Col>
                                                                                                                </Row>
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                    </div>
                                                                                                </Col>
                                                                                                <hr className='mb-0 d-lg-none' />
                                                                                                <Col className='col-12 col-lg-3 d-grid py-2 py-lg-0'>
                                                                                                    <div className='flex-between-align'>
                                                                                                        {/* {
                                                                                                            (statusLoader) ?
                                                                                                                <Placeholder as={"Alert"}
                                                                                                                    aria-hidden="true"
                                                                                                                    animation="glow"
                                                                                                                    className="w-100"
                                                                                                                >
                                                                                                                    <Placeholder xs={8} />
                                                                                                                </Placeholder>
                                                                                                                : <Alert variant={(item?.status == 0) ? "secondary" : "success"} className='product-status-label py-1 px-2 fs-14 fs-sm-12 m-0'>
                                                                                                                    {(item?.status == 0) ? "Unpublished" : "Published"}
                                                                                                                </Alert>
                                                                                                        } */}
                                                                                                        <Alert variant={(item?.status == 0) ? "secondary" : "success"} className='product-status-label py-1 px-2 fs-14 fs-sm-12 m-0'>
                                                                                                            {item?.shopifyProductId ?
                                                                                                                (`${(item?.status == 0) ? productStatusData[1] : productStatusData[0]}`).replace('ee', 'e')
                                                                                                                : (`${(item?.status == 0) ? productStatusData[1] : productStatusData[0]}ed`).replace('ee', 'e')
                                                                                                            }
                                                                                                        </Alert>

                                                                                                        <div className='d-flex justify-content-end gap-4 gap-lg-2 custom-icon-list'>
                                                                                                            <span
                                                                                                                data-tooltip-id="my-tooltip"
                                                                                                                data-tooltip-content="Edit Design"
                                                                                                                className='fs-25'
                                                                                                                onClick={() => handleCanvasEdit({ id: item?.id, to: "canvas" })}
                                                                                                            >
                                                                                                                <RiImageEditLine />
                                                                                                            </span>
                                                                                                            <Tooltip id="my-tooltip" place="top" />
                                                                                                            <span
                                                                                                                data-tooltip-id="my-tooltip"
                                                                                                                data-tooltip-content="Update Details"
                                                                                                                className='fs-25'
                                                                                                                onClick={() => handleProductDetailEdit({ id: item?.id, to: "detail" })}
                                                                                                            >
                                                                                                                <MdOutlineEditNote />
                                                                                                            </span>
                                                                                                            <Tooltip id="my-tooltip" place="top" />
                                                                                                            <Dropdown className="p-0">
                                                                                                                <Dropdown.Toggle id="dropdown-basic" className="p-0">
                                                                                                                    <span className='fs-20'>
                                                                                                                        <BsThreeDots />
                                                                                                                    </span>
                                                                                                                </Dropdown.Toggle>

                                                                                                                <Dropdown.Menu>

                                                                                                                    {
                                                                                                                        (statusLoader) ?
                                                                                                                            <Placeholder as={Dropdown.Item}
                                                                                                                                aria-hidden="true"
                                                                                                                                animation="glow"
                                                                                                                                className="w-100"
                                                                                                                            >
                                                                                                                                <Placeholder xs={12} className="w-100" />
                                                                                                                            </Placeholder>
                                                                                                                            : <Dropdown.Item
                                                                                                                                onClick={() => {
                                                                                                                                    if(item?.inCombo && (item?.status == 1)){
                                                                                                                                        Swal.fire(
                                                                                                                                            "You are not allowed to change the status of this product!",
                                                                                                                                            "This product is part of a combo. Please delete the combo first before deleting this product.",
                                                                                                                                            "error"
                                                                                                                                        );                              
                                                                                                                                    } else {
                                                                                                                                        handleProductStatus({ id: item?.id, status: item?.status })
                                                                                                                                    }
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                {(item?.status == 1) ? productStatusData[1] : productStatusData[0]}
                                                                                                                            </Dropdown.Item>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        /*
                                                                                                                            (item?.status == 1) &&
                                                                                                                            <Dropdown.Item
                                                                                                                                href={`${`${activeStore?.storeType == "SHOPIFY" ? 
                                                                                                                                `https://${activeStore?.shopifyStoreUrl}/products/${item?.name.toLowerCase().replace(/-/g, '~').replace(/ /g, '-')}` 
                                                                                                                                : `https://${!(userData?.expired) ? activeStore?.customDomain ?? activeStore?.domain : activeStore?.domain}/product/${item?.slug}?ref=1`}`}`} target='_blank'>
                                                                                                                                Live Preview
                                                                                                                            </Dropdown.Item>
                                                                                                                            */
                                                                                                                    }
                                                                                                                    {
                                                                                                                        (item?.status == 1) &&
                                                                                                                        <Dropdown.Item
                                                                                                                            href={item?.productUrl}
                                                                                                                            target='_blank'
                                                                                                                        >
                                                                                                                            Live Preview
                                                                                                                        </Dropdown.Item>
                                                                                                                    }
                                                                                                                    <Dropdown.Item
                                                                                                                        onClick={() => getProviderListForSwitch(item?.id)}
                                                                                                                    >
                                                                                                                        Switch Provider
                                                                                                                    </Dropdown.Item>
                                                                                                                    <Dropdown.Item 
                                                                                                                        className='text-danger' 
                                                                                                                        onClick={() => {
                                                                                                                            if(item?.inCombo){
                                                                                                                                Swal.fire(
                                                                                                                                    "You are not allowed to change the status of this product!",
                                                                                                                                    "This product is a part of a combo. Please delete the combo first before deleting this product.",
                                                                                                                                    "error"
                                                                                                                                );                              
                                                                                                                            } else {
                                                                                                                                deleteProduct(item?.id)
                                                                                                                            }
                                                                                                                            // setLoader(true);
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        Delete
                                                                                                                    </Dropdown.Item>
                                                                                                                </Dropdown.Menu>
                                                                                                            </Dropdown>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </Col>
                                                                                            </Row>
                                                                                            <hr className={`d-none d-lg-block ${(productData?.length === index + 1) ? 'd-lg-none' : ''}`} />
                                                                                        </Col>
                                                                                    </>
                                                                                )
                                                                            })
                                                                        }
                                                                        {
                                                                            (canvasLoader) &&
                                                                            <div className='position-absolute h-100 w-100 flex-center-align half-border-rad' style={{ backgroundColor: "rgba(0,0,0,0.15" }}>
                                                                                <Spinner animation="border" variant="success" />
                                                                            </div>
                                                                        }
                                                                    </Row>
                                                                </Col>
                                                                <Col className='col-12 pt-2 pt-sm-3'>
                                                                    <CommonPagination
                                                                        totalCount={totalCount}
                                                                        changePage={(data) => {
                                                                            setFilters((prevData) => ({
                                                                                ...prevData,
                                                                                limit: data?.limit,
                                                                                page: data?.page
                                                                            }))
                                                                        }}
                                                                    />
                                                                </Col>
                                                            </>
                                                            :
                                                            <div className='py-2 flex-center-align py-5'>
                                                                <div className='custom-transaction-tab d-grid justify-content-center gap-2 text-center'>
                                                                    <div>
                                                                        <MdOutlineProductionQuantityLimits className='display-4 color-temp' />
                                                                    </div>
                                                                    <h5 className='fw-semibold m-0'>No Products</h5>
                                                                </div>
                                                            </div>
                                                }
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Tab.Pane>
                                <Tab.Pane eventKey="combo" className=''>
                                    <ComboListing totalProducts={totalCount} callProductsAPI={getProductData}/>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                            
                        </Col>
                    </Row>
                </Container>
            </div>
            <ToastContainer />
            {/* Modal for Change Provider start */}
            <Modal
                show={providerSwitchModalStatus}
                onHide={() => setProviderSwitchModalStatus(!providerSwitchModalStatus)}
                aria-labelledby="contained-modal-title-vcenter"
                size="md"
                style={{
                    maxHeight: '75vh'
                }}
                centered
                scrollable
            >
                <ModalHeader className='d-flex flex-column'>
                    <ModalTitle className='text-center w-100 fs-sm-14'>Switch provider</ModalTitle>
                </ModalHeader>
                <ModalBody className=''>
                    <div className='d-flex flex-column gap-3'>
                        {
                            providerListData?.map((row, index) => <>
                                <div
                                    className={`border rounded-3 px-2 position-relative pointer ${row?.providerId == selectedProvider?.providerId
                                        ? 'border-3 border-success py-3'
                                        : 'border-2 border-black py-2'} p-color`}
                                    onClick={() => {
                                        setTempProductObj({ ...tempProductObj })
                                        setSelectedProvider({ ...row })
                                    }}
                                    style={{ transition: "all 0.3s" }}
                                >
                                    <div className={`d-flex align-items-center justify-content-between first-capitalize fs-15`}>
                                        <div className={`d-flex flex-column align-items-start`} style={{ minWidth: "20%" }}>
                                            <span className='fs-14 fw-semibold'>Provider</span>
                                            <span>{row?.providerName}</span>
                                        </div>
                                        {/* <div className='d-flex flex-column align-items-start'>
                                            <span className='fs-14 p-color fw-semibold'>Business</span>
                                            <span className='p-color'>{row?.providerBusinessName}</span>
                                        </div> */}
                                        <div className='d-flex flex-column align-items-end'>
                                            <span className='fs-14 fw-semibold'>Product price</span>
                                            <span>{keyCode?.inr}{row?.productPrice}</span>
                                        </div>
                                    </div>

                                    {row?.providerId == selectedProvider?.providerId && <div className='top-0 start-0 position-absolute pe-2' style={{ transform: "translate(20%, -50%)" }}>
                                        {/* <Input type="checkbox" checked className='bg-success border-0 m-0' style={{ width: "1.5em", height: "1.5em" }} /> */}
                                        <span className='fw-bold fs-16 text-success px-2 bg-light-main'> <i className='bi bi-check '></i> Selected</span>
                                    </div>}
                                </div>
                            </>)
                        }
                    </div>

                </ModalBody>
                <ModalFooter className='d-flex justify-content-center'>
                    <div className='d-flex justify-content-start gap-2 align-items-center'>
                        <Button
                            color='success'
                            className='bg-success border-0'
                            disabled={Number(selectedProvider?.providerId) <= 0}
                            onClick={() => {
                                console.log("selected 763 ::", selectedProvider)
                                // return
                                navigate('/product-provider-switch', {
                                    state: {
                                        product: tempProductObj,
                                        provider: selectedProvider
                                    }
                                })
                            }}
                        >Continue to process</Button>
                        <Button
                            color='danger'
                            className='bg-danger border-0'
                            onClick={() => {
                                setSelectedProvider({ providerId: 0 })
                                setProviderSwitchModalStatus(false)
                            }}
                        >Close</Button>
                    </div>
                </ModalFooter>
            </Modal>
            {/* Modal for Change Provider close */}

            {/* Modal for Change Provider start */}
            <Modal
                show={comboModalStatus}
                onHide={() => setComboModalStatus(!comboModalStatus)}
                aria-labelledby="contained-modal-title-vcenter"
                size="xl"
                style={{
                    maxHeight: '75vh'
                }}
                centered
                scrollable
            >
                <ModalHeader className='d-flex flex-column'>
                    <ModalTitle className='text-center w-100 fs-sm-14 flex-center-align gap-2'>
                        <TbPackages />
                        Create Combo Products
                    </ModalTitle>
                </ModalHeader>
                <ModalBody className=''>
                </ModalBody>
                <ModalFooter className='d-flex justify-content-center'>
                    <div className='d-flex justify-content-start gap-2 align-items-center'>
                        
                    </div>
                </ModalFooter>
            </Modal>
            {/* Modal for Change Provider close */}
        </div>
    )
}

export default ProductHome