import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Container, Row, Table,  } from 'react-bootstrap'
import { TbPackages } from 'react-icons/tb';
import { Link, useNavigate } from 'react-router-dom';
import { frontEnd_API, header, siteConfig } from '../../Config/Config';
import { FaTrashAlt } from 'react-icons/fa';
import { FaPencil } from 'react-icons/fa6';
import Swal from 'sweetalert2';
import { MdOutlineProductionQuantityLimits } from 'react-icons/md';

function ComboListing({ totalProducts = 0, callProductsAPI }) {
    const [filters, setFilters] = useState({});
    const navigate = useNavigate();
    const [comboList, setComboList] = useState([]);
    const [loader, setLoader] = useState(true);

    const getComboList = async () => {
        try{
            const {data} = await axios.put(frontEnd_API?.comboProduct, {...filters}, header)
            console.log("data::", data);
            setComboList(data?.data);
        } catch (err) {
            console.error("error::", err);
            setComboList([]);
        }
    }

    useEffect(()=>{
        getComboList();
    },[filters])

    const deleteCombo = async (item) => {
        Swal.fire({
            title: `Are you sure to delete this combo?`,
            text: `This action can't be reversed.`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#04ac84',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'No',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try{
                    const response = await axios.delete(frontEnd_API?.comboProduct, {
                        data: { comboProductCode: [item?.comboProductCode] },
                        ...header
                    }
                )
                    if (response?.status == 200) {
                        Swal.fire(
                            'Combo Deleted Successfully!',
                            // 'Your selected combo is deleted!',
                            '',
                            'success'
                        );
                        getComboList();
                        return callProductsAPI();
                    }
                } catch(err) {
                    console.error("error::", err);
                }
            }
        });
    }

  return (
    <div>
        <Card className='rounded-0 p-0'>
            <Card.Body>
                <Row className='gy-3 px-2 px-sm-0 justify-content-center'>
                    <Col className='col-12 col-lg-12 py-2 d-grid gap-3 custom-btn-scrollbar px-0 px-lg-3'>
                        {/* Search Button */}
                        <div className='d-flex align-items-center gap-2 d-md-grid'>
                            <Container className='custom-seller-category-search px-0 flex-between-align gap-3'>
                                <Link
                                    className='w-auto text-capitalize bg-success text-white border-0 p-2 flex-center-align gap-1 text-decoration-none half-border-rad px-2'
                                    to={'/create-combo'}
                                >
                                    <TbPackages />
                                    Create Combo
                                </Link>
                                <div className='d-flex align-items-center gap-2 gap-lg-3 px-2 px-lg-3' style={{width: "calc(100% - 175px)"}}>
                                    <label className='bi bi-search' htmlFor='text'></label>
                                    <input
                                        type="text"
                                        className='py-2 fs-sm-14 pe-2'
                                        id='text'
                                        placeholder='Search by combo name'
                                        autoComplete='off'
                                        onChange={(e) => {
                                            setFilters((prevData) => ({
                                                ...prevData,
                                                search: e?.target?.value,
                                            }))
                                            setLoader(true);
                                        }}
                                    />
                                </div>
                            </Container>
                        </div>
                    </Col>
                    <Col className='col-12 col-lg-12 d-grid'>
                       <Table responsive>
                            <thead>
                                <tr>
                                    <th>Code</th>
                                    {/* <th>Thumbnail</th> */}
                                    <th>Name</th>
                                    <th>Price</th>
                                    <th>Products</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    comboList?.length > 0 ?
                                    comboList.map((item,index)=>{
                                        
                                        return(
                                            <tr key={index}>
                                                <td><span>{item?.comboProductCode}</span></td>
                                                {/* <td>
                                                    <div className='aspect-1-1 rounded-2 overflow-hidden' style={{width: "100px"}}>
                                                        <img src={item?.thumbnail != "" ? item?.thumbnail : siteConfig?.logoText} alt="" className='h-100 w-100 object-contain' />
                                                    </div>
                                                </td> */}
                                                <td>{item?.title}</td>
                                                <td>₹{item?.price}</td>
                                                <td>{item?.productData?.length??0}</td>
                                                <td>
                                                    <div className='d-flex gap-3'>
                                                        <Link className='btn btn-info fs-12' to={`/update-combo/${item?.comboProductCode}`}><FaPencil /></Link>
                                                        <Button 
                                                            className='fs-12' 
                                                            variant='danger'
                                                            onClick={()=>deleteCombo(item)}
                                                        >
                                                            <FaTrashAlt />
                                                        </Button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    : <tr>
                                        <td colSpan={6}>
                                            <div className='py-2 flex-center-align py-5 w-100'>
                                                <div className='custom-transaction-tab d-grid justify-content-center gap-2 text-center'>
                                                    <div>
                                                        <MdOutlineProductionQuantityLimits className='display-4 color-temp' />
                                                    </div>
                                                    {
                                                        totalProducts > 2
                                                        ?<h5 className='fw-semibold m-0'>No Combos</h5>
                                                        :<h5 className='fw-semibold m-0'>No Products</h5>
                                                    }
                                                    
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                       </Table>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    </div>
  )
}

export default ComboListing