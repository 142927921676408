import React, { useEffect, useState } from 'react'
import { Card, Nav, Tab, Tabs } from 'react-bootstrap'
import SelectProduct from './SelectProduct';
import SelectCombo from './SelectCombo';
import { useSelector } from 'react-redux';

function SelectProductTab({
    tempTabValue = "products", 
    tabHolder,
    setTabHolder,
    thumbsSwiper,
    setThumbsSwiper
}) {
    const [tabvalue, setTabValue] = useState(tempTabValue?.tab);
    const userData = useSelector((state) => state.user.value);
    const activeStore = useSelector((state) => state.store.value);

    useEffect(()=>{
        setTabValue(tempTabValue?.tab);
    },[tempTabValue])

  return (
    <div className='seller-product-home'>
        {/* <Tabs
            activeKey={tabvalue}
            id="fill-tab-example"
            className="mb-3 custom-tab"
            onSelect={(k) => {
                setTabValue(k);
            }}
            fill
        > 
            <Tab eventKey="products" title="Products">
                
            </Tab>
            <Tab eventKey="combos" title="Combos">
                <SelectCombo
                    tabHolder={tabHolder}
                    setTabHolder={setTabHolder}
                    thumbsSwiper={thumbsSwiper}
                    setThumbsSwiper={setThumbsSwiper}
                    defaultCombo={tempTabValue?.value}
                />
            </Tab>
        </Tabs> */}
        <Tab.Container activeKey={tabvalue}>
            {
                ((activeStore?.storeType == "SNAPSTORE") && (userData?.email == "ayushi@printfuse.in" || window.location.hostname === "localhost")) &&
                <div className='rounded-0 custom-product-tabs p-0' style={{borderBottom: "1px solid lightgray"}}>
                    <Nav fill variant="pills" onSelect={(selectedKey) => setTabValue(selectedKey)}>
                        
                            <>
                                <Nav.Item className=''>
                                    <Nav.Link eventKey="products" className='flex-center-align gap-1 py-1 pt-2'>
                                        <span className='custom-triangle'></span>
                                        <span className='fw-semibold fs-16'>Products</span>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="combos" className='flex-center-align gap-1 py-1 pt-2'>
                                        <span className='custom-triangle'></span>
                                        <span className='fw-semibold fs-16'>Combos</span>
                                    </Nav.Link>
                                </Nav.Item>
                            </>
                    </Nav>
                </div>
            }
            <Tab.Content>
                <Tab.Pane eventKey="products" className=''>
                    <SelectProduct 
                        tabHolder={tabHolder}
                        setTabHolder={setTabHolder}
                        thumbsSwiper={thumbsSwiper}
                        setThumbsSwiper={setThumbsSwiper}
                        defaultProduct={tempTabValue?.value}
                    />
                </Tab.Pane>
                <Tab.Pane eventKey="combos" className=''>
                    <SelectCombo
                        tabHolder={tabHolder}
                        setTabHolder={setTabHolder}
                        thumbsSwiper={thumbsSwiper}
                        setThumbsSwiper={setThumbsSwiper}
                        defaultCombo={tempTabValue?.value}
                    />
                </Tab.Pane>
            </Tab.Content>
        </Tab.Container>
    </div>
  )
}

export default SelectProductTab