import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { ImFilesEmpty } from "react-icons/im";
import { frontEnd_API, header } from '../../Config/Config';
import CommonPagination from '../../Components/CommonPagination';
import Swal from 'sweetalert2';
import { FaTrash } from "react-icons/fa";
import { FaDownload } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import Loader from '../../Components/Loader';

function SellerAccountFiles() {
    const [photos, setPhotos] = useState([]);
    const [totalCount, setTotalCount] = useState();
    const [filters, setFilters] = useState({limit: 15});
    const [loader, setLoader] = useState(true);

    const getFilesList = async () => {
        let loaderTimeout;
        try {
            // Start a timeout for 500ms, if API response takes longer, show loader
            loaderTimeout = setTimeout(() => {
                setLoader(true);
            }, 500);
    
            const response = await axios.put(frontEnd_API?.document, { ...filters }, header);
            
            // Clear the timeout if API responds before 500ms
            clearTimeout(loaderTimeout);
            setLoader(false);
    
            const filesData = response.data?.data || [];
            setTotalCount(response?.data?.totalCount);
    
            const loadImageDimensions = filesData.map((item, index) => {
                return new Promise((resolve) => {
                    const img = new Image();
                    img.src = item?.link;
                    img.onload = () => {
                        resolve({
                            src: img.src,
                            width: img.width,
                            height: img.height,
                            id: item?.id,
                            alt: `Image ${index + 1}`
                        });
                    };
                });
            });
    
            const imagesWithDimensions = await Promise.all(loadImageDimensions);
            setPhotos(imagesWithDimensions);
        } catch (e) {
            console.error("Err::", e);
        } finally {
            // Ensure the loader is hidden after API response, even in case of an error
            clearTimeout(loaderTimeout);
            setLoader(false);
        }
    };

    useEffect(() => {
        getFilesList();
    }, [filters]);

    const deleteFile = async (item) => {
        console.log("item::", item);
        try {
            Swal.fire({
                title: `Are you sure to delete photo?`,
                text: `This action cannot be undone.`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#04ac84',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm',
                cancelButtonText: 'No',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await axios.delete(`${frontEnd_API.document}/${item?.id}`, header);
                    getFilesList();
                }
            });
        } catch (e) {
            console.log("Err::", e);
        }
    };

    // const downloadImage = (src) => {
    //     const link = document.createElement('a');
    //     link.href = src;
    //     link.download = src.substring(src.lastIndexOf('/') + 1); // Extract filename from URL
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    // };

    // Custom render function for Gallery
    const imageRenderer = ({ index, photo }) => (
        <div className="image-container" key={index}>
            <img
                {...photo}
                alt={photo.alt}
                className="gallery-image"
            />
            <div className='custom-bg-black' />
            <span
                className='icon-holder p-1'
                onClick={() => deleteFile(photo)}
            >
                <FaTrash
                    className="delete-icon fs-14"
                />
            </span>
        </div>
    );

    return (
        <div className='p-2 seller-account'>
            <Container>
                <h3 className='py-4 m-0 fw-semibold d-flex align-items-center gap-2 gap-sm-3'>
                    <ImFilesEmpty className='color-temp' />
                    Account Files
                </h3>
                <div className='seller-box d-grid py-4 px-3'>
                    {
                        loader
                        ?
                            <div className='w-100 aspect-3-1'>
                                <Loader/>
                            </div>
                        : (photos?.length > 0) ?
                            <Row xl={5} md={4} sm={3} xs={2} className='gy-2 gy-md-3'>
                                {
                                    photos.map((item, index) => (
                                        // <Col key={index} className="col-6 col-sm-4 col-md-3 col-xl-2 px-1 px-md-2">
                                        <Col key={index} className="px-1 px-md-2">
                                            {/* <div className='grid-img-holder'>
                                    <img src={item?.src} alt={item?.alt} />
                                </div> */}
                                            <div className="image-container grid-img-holder" key={index}>
                                                <img
                                                    src={item?.src}
                                                    alt={item?.alt}
                                                    className="gallery-image"
                                                />
                                                <div className='custom-bg-black' />
                                                <div className='icon-holder'>
                                                    <Link className='icon-btn p-1' to={item?.src} >
                                                        <FaDownload className="delete-icon fs-14 text-dark" />
                                                    </Link>
                                                    {/* <span className='icon-btn p-1' onClick={() => deleteFile(item)} >
                                            <FaTrash className="delete-icon fs-14 text-danger" />
                                        </span> */}
                                                </div>
                                            </div>
                                        </Col>
                                    ))
                                }
                            </Row>
                            :
                            <Row className='gy-2 gy-md-3'>
                                {
                                    <Col className="col-12 py-4">
                                        <h2 className='text-center'>No Files yet</h2>
                                    </Col>
                                }
                            </Row>
                    }
                    <div className="w-100 pt-3">
                        <CommonPagination
                            totalCount={totalCount}
                            limit={filters?.limit ?? 12}
                            changePage={(data) => {
                                setFilters((prevData) => ({
                                    ...prevData,
                                    limit: data?.limit,
                                    page: data?.page
                                }));
                            }}
                        />
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default SellerAccountFiles;
