import React from "react";

const CustomDomainSteps = ({ domain = 'example.com', ipAddress = '152.42.158.37'}) => {

    const steps = [
        {
            content: "Login to your domain provider (GoDaddy, Google domains, NameCheap, etc.)"
        },
        {
            content: (<>Locate the page from updating your domain's DNS records. The page might be called something like <b>DNS Management, Name Server Management, or Advanced Settings.</b></>)
        },
        {
            content: (<>
                <span>Create a new record with:</span><br />
                <span>type: <b>A record</b></span><br/>
                <span>host, name or alias, field:<b>@ or leave a black for exact domain name, for subdomain enter any value(store, shop, merch, etc.)</b><br />
                target or points to field: {ipAddress}</span>
            </>)
        },
        {
            content: (<>
                <span>Add one more record if you are not using subdomain (i.e using apex domain, eg: yourdomain.com)</span><br />
                <span>type: <b>A record</b></span><br/>
                <span>host, name or alias, field:<b>www</b><br />
                target or points to field: {ipAddress}</span>
            </>)
        },
    ];

    return (
        <div className="d-flex flex-column gap-2 py-2">
            <span className="fs-18 fw-semibold">Steps to Connect Domain</span>
            <div className="ps-2 d-flex flex-column gap-3">
                {steps.map((step, index) => (
                    <div key={index} className="d-flex gap-2">
                        <span>
                            <div className="rounded-5 bg-success text-white d-flex align-items-center justify-content-center mt-1 fs-13" style={{height:'30px', width:'30px'}}>{index + 1}</div>
                        </span>
                        <div>
                            {step.content}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CustomDomainSteps;