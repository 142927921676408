import ErrorPage from "../Components/ErrorPage";
import SignupPage from "../View/SignUp_LogIn/SignupPage";
import SigninPage from "../View/SignUp_LogIn/SigninPage";
import SellerCreateStore from "../View/SellerDashboard/SellerCreateStore";
// import ProductCanvas from "../View/SellerDashboard/Products/ProductCanvasFABRIC-Old";
// import ProductCanvas from "../View/SellerDashboard/Products/ProductCanvasKONVA";
// import ProductCanvas from "../View/SellerDashboard/Products/ProductCanvas-05092024";
import ProductCanvas from "../View/SellerDashboard/Products/ProductCanvas";
import SellerDashboard from "../View/SellerDashboard/SellerDashboard";
import SellerOrders from "../View/SellerDashboard/SellerOrders";
import SellerCategoryPage from "../View/SellerDashboard/SellerCategoryPage";
import SellerHomeCategory from "../View/SellerDashboard/SellerHomeCategory";
import SellerProductListingPage from "../View/SellerDashboard/SellerProductListingPage";
import SellerWallet from "../View/SellerDashboard/SellerWallet";
import SellerSingleOrderPage from "../View/SellerDashboard/SellerSingleOrderPage";
import SellerInsights from "../View/SellerDashboard/SellerInsights";
import SellerSubscription from "../View/SellerDashboard/SellerSubscription";
import SellerStoreListing from "../View/SellerDashboard/SellerStoreListing";
import SellerAccount from "../View/SellerDashboard/SellerAccount";
import SellerAccountSetting from "../View/SellerDashboard/SellerAccountSetting";
import ProductHome from "../View/SellerDashboard/Products/ProductHome";
import SellerSingleProduct from "../View/SellerDashboard/SellerSingleProduct";
import SellerStoreSetting from "../View/SellerDashboard/SellerStoreSetting";
import ProductCreate from "../View/SellerDashboard/Products/ProductCreate";
import ProductUpdate from "../View/SellerDashboard/Products/ProductUpdate";
import SellerAccountFiles from "../View/SellerDashboard/SellerAccountFiles";
import SellerReturns from "../View/SellerDashboard/SellerReturns";
import SellerSingleReturnPage from "../View/SellerDashboard/SellerSingleReturnPage";
import NotificationList from "../Components/NotificationList";
import SellerCreateOrder from "../View/SellerDashboard/SellerCreateOrder";
import SellerBranding from "../View/SellerDashboard/SellerBranding";
import SellerSingleRtoOrderPage from "../View/SellerDashboard/SellerSingleRtoOrderPage";
import SellerSingleRtsOrderPage from "../View/SellerDashboard/SellerSingleRtsOrderPage";
import ForgotPassword from "../View/SignUp_LogIn/ForgotPassword";
import ProductEditCanvas from "../View/SellerDashboard/Products/ProductEditCanvas";
import ProductProviderSwitch from "../View/SellerDashboard/Products/ProductProviderSwitch";
import ProductEdit from "../View/SellerDashboard/Products/ProductEdit";
import SellerUserList from "../View/SellerDashboard/SellerUserList";
import VerifyEmailPage from "../View/SignUp_LogIn/VerifyEmailPage";
import CustomizeStore from "../View/CustomizeStore/CustomizeStore";
import CreateComboProduct from "../View/ComboProduct/CreateComboProduct";

const routes = [
    {
        path: '*',
        element: <ErrorPage />
    },
    {
        path: '/verifyemail/:token',
        element: <VerifyEmailPage />
    },
    {
        path: '/master/signin/:sellerId',
        element: <SigninPage />
    },
    {
        path: '/signin',
        element: <SigninPage />
    },
    {
        path: '/signup',
        element: <SignupPage />
    },
    {
        path: '/forgot-password',
        element: <ForgotPassword />
    },
    {
        path: '/from-home/:domain',
        element: <SigninPage />
    },
    {
        path: '/zohoauth',
        element: <SellerCreateStore />
    },
    {
        path: '/create-store',
        element: <SellerCreateStore />
    },
    {
        path: '/app/create-store',
        element: <SellerCreateStore />
    },
    {
        path: '/',
        element: <SellerDashboard />
    },
    {
        path: '/dashboard',
        element: <SellerDashboard />
    },
    {
        path: '/products',
        element: <ProductHome />
    },
    {
        path: '/products/:type',
        element: <ProductHome />
    },
    {
        path: '/category',
        element: <SellerHomeCategory />
    },
    {
        path: '/category/:category',
        element: <SellerCategoryPage />
    },
    {
        path: '/category/:category/:product',
        element: <SellerProductListingPage />
    },
    {
        path: '/product/:productId',
        element: <SellerSingleProduct />
    },
    
    // For User View Only
    {
        path: '/view/category',
        element: <SellerHomeCategory />
    },
    {
        path: '/view/category/:category',
        element: <SellerCategoryPage />
    },
    {
        path: '/view/category/:category/:product',
        element: <SellerProductListingPage />
    },
    {
        path: '/view/product/:productId',
        element: <SellerSingleProduct />
    },

    // For User View Only Close

    {
        path: '/product/:productId/:providerId/canvas',
        element: <ProductCanvas />
    },
    {
        path: '/product/:productId/:providerId/create-product',
        element: <ProductCreate />
    },
    {
        path: '/product/:productId/:providerId/edit-product',
        element: <ProductEdit />
    },
    {
        path: '/product-provider-switch',
        element: <ProductProviderSwitch />
    },
    {
        path: '/update-product/information',
        element: <ProductUpdate />
    },
    {
        path: '/product-edit/:productId/:providerId/canvas',
        element: <ProductEditCanvas />
    },
    {
        path: '/search-result/:search',
        element: <SellerProductListingPage />
    },
    {
        path: '/search-result',
        element: <SellerProductListingPage />
    },
    {
        path: '/create-orders',
        element: <SellerCreateOrder />
    },
    {
        path: '/orders',
        element: <SellerOrders />
    },
    {
        path: '/orders/:groupStatus',
        element: <SellerOrders />
    },
    {
        path: '/orders/:groupStatus/:orderStatus',
        element: <SellerOrders />
    },
    {
        path: '/orders/:groupStatus/:orderStatus/:orderId',
        element: <SellerSingleOrderPage />
    },
    {
        path: '/returns',
        element: <SellerReturns />
    },
    {
        path: '/returns/:status',
        element: <SellerReturns />
    },
    {
        path: '/returns/:status/:childStatus',
        element: <SellerReturns />
    },
    {
        path: '/returns/return/:childStatus/:returnOrderNumber',
        element: <SellerSingleReturnPage />
    },
    {
        path: '/returns/rto/:childStatus/:orderAnotherNumber',
        element: <SellerSingleRtoOrderPage />
    },
    {
        path: '/returns/rts/:childStatus/:orderAnotherNumber',
        element: <SellerSingleRtsOrderPage />
    },
    {
        path: '/returns-detail/:returnOrderNumber',
        element: <SellerSingleReturnPage />
    },
    {
        path: '/create-store',
        element: <SellerCreateStore />
    },
    {
        path: '/store-setting',
        element: <SellerStoreSetting />
    },
    {
        path: '/payment',
        element: <SellerWallet />
    },
    {
        path: '/payment/:backRoute',
        element: <SellerWallet />
    },
    {
        path: '/branding',
        element: <SellerBranding />
    },
    {
        path: '/users',
        element: <SellerUserList />
    },
    {
        path: '/insights',
        element: <SellerInsights />
    },
    {
        path: '/subscription',
        element: <SellerSubscription />
    },
    {
        path: '/my-store',
        element: <SellerStoreListing />
    },
    {
        path: '/account/details',
        element: <SellerAccount />
    },
    {
        path: '/account/settings',
        element: <SellerAccountSetting />
    },
    {
        path: '/account/uploaded-files',
        element: <SellerAccountFiles />
    },
    {
        path: '/notification',
        element: <NotificationList />
    },

    // Customize theme

    {
        path: '/customize-store',
        element: <CustomizeStore />
    },

    // Combo products related module
    
    {
        path: '/create-combo',
        element: <CreateComboProduct />
    },
    {
        path: '/update-combo/:comboProductCode',
        element: <CreateComboProduct />
    },
]


export default routes;